import React from 'react';
import {Button} from "antd";

interface ResponsiveButtonProps {
    icon: any,
    onClick?: () => void,
    text: string,
    callback?: () => void
}

const ResponsiveButton: React.FC<ResponsiveButtonProps> = props => {
    return (
       <Button
           type='primary'
           size='small'
           className='responsive-button'
           icon={props.icon}
           onClick={props.onClick}
       >
           {props.text && props.text}
       </Button>
    );
};

ResponsiveButton.defaultProps = {
    icon: null,
    text: null
}

export default ResponsiveButton;
