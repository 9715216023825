import React, {useEffect, useRef, useState} from 'react';
import {IPageData, IPageProps} from "../../../interfaces/page-data";
import {AppState} from "../../../redux/store";
import {useDispatch, useSelector} from "react-redux";
import {
    IPoint,
    IPolygon,
    ITag,
    IUser,
    IUserCreate,
    IMarker,
    IUserFile,
    IUserFileUpload,
    IUserUpdateInterest
} from "../../../interfaces/api";
import moment from "moment";
import './EditProfile.scss';
import toast from "react-hot-toast";
import {Button, Card, DatePicker, Form, Input, Switch, Tag, Upload} from "antd";
import {
    CheckOutlined,
    CloudUploadOutlined,
    DeleteOutlined,
    FacebookFilled,
    InboxOutlined,
    InstagramOutlined,
    LinkedinFilled,
    TwitterSquareFilled
} from "@ant-design/icons";
import {updateUser} from "../../../redux/user/actions";
import {
    DATETIME_FORMAT,
    FACEBOOK_ROOT_URL,
    INSTAGRAM_ROOT_URL,
    LINKEDIN_ROOT_URL,
    S3_SERVER_URL,
    SERVER_DATETIME_FORMAT,
    TWITTER_ROOT_URL,
    WEBADDRESS_ROOT_URL
} from "../../../utils/Constants";
import {objIsEmpty} from "../../../utils/GlobalFuncs";
import AutoCompleteInterests, {
    IUserInterestAComplete
} from "../../../components/AutoCompleteInterests/AutoCompleteInterests";
import TextEditor from "../../../components/TextEditor/TextEditorWithoutButton";
import {
    IDtoGetUserCoordinates,
    IDtoUpdateUserCoordinate,
    IDtoUpdateUserCoordinateValue, IUserCoordinate
} from "../../../interfaces/api/IUser";
import GMForProfile, {EVENT_TYPES} from "../../../components/GoogleMaps/ForProfile/GMForProfile";
import GUID from '../../../utils/GUID';
import ApiService from "../../../utils/services/api.service";

const UserForm: React.FC<any> = props => {
    const dispatch = useDispatch();
    const userFromStore: IUser = useSelector((state: AppState) => state).user;
    const [user, setUser] = useState<IUser>({} as IUser);
    const [marker, setMarker] = useState<IMarker>(null);
    const [polygons, setPolygons] = useState<(IPolygon)[]>([]);
    const formRef = useRef(null);

    const prefixFb = FACEBOOK_ROOT_URL;
    const prefixIns = INSTAGRAM_ROOT_URL;
    const prefixTw = TWITTER_ROOT_URL;
    const prefixLi = LINKEDIN_ROOT_URL;
    const prefixWeb = WEBADDRESS_ROOT_URL;

    useEffect(() => {
        getUser();
        getLocations();
    }, [])

    const getUser = async () => {
        let response = await ApiService.User.GetUserById(userFromStore.id);
        let t: IUser = response.data;
        setUser(response.data);
        setMarker({id: t.id, point: ({lat: t.latitude, lng: t.longitude} as IPoint)} as IMarker);
    }

    const getLocations = async () => {
        let response = await ApiService.UserCoordinate.GetUserCoordinateByUserId(userFromStore.id);
        let t: IDtoGetUserCoordinates = response.data;

        // Group points
        let groupedCoords = t.points.reduce((r, a) => {
            r[a.coordinateGroupId] = [...r[a.coordinateGroupId] || [], a];
            return r;
        }, {});

        // Grouped points in object, now key by key create polygon groups
        let polygons: IPolygon[] = [];
        for (const [key, value] of Object.entries(groupedCoords)) {
            let tmpPoints = Array.isArray(value) && value.map(x => {
                // TODO - Fix here after API update
                return {lat: x.latitude, lng: x.longitude, createdAt: x.modifiedAt} as IPoint
            })
            polygons.push({id: key, points: tmpPoints} as IPolygon);
        }
        // compeleted business result
        setPolygons(polygons);
    }


    const onFinish = async (data) => {
        data.latitude = marker.point.lat;
        data.longitude = marker.point.lng;
        data.dateOfBirth = moment(data.dateOfBirth, DATETIME_FORMAT).format(SERVER_DATETIME_FORMAT);
        if (data.userTypeId !== 1) {
            data.userTypeId = data.isHaveLand ? 2 : 3;
        }

        data.socialTwitter = (data.socialTwitter !== null && data.socialTwitter.length > 0) ? data.socialTwitter : '';
        data.socialFacebook = (data.socialFacebook !== null && data.socialFacebook.length > 0) ? data.socialFacebook : '';
        data.socialInstagram = (data.socialInstagram !== null && data.socialInstagram.length > 0) ? data.socialInstagram : '';
        data.socialLinkedin = (data.socialLinkedin !== null && data.socialLinkedin.length > 0) ? data.socialLinkedin : '';
        data.webAddress = (data.webAddress !== null && data.webAddress.length > 0) ? data.webAddress : '';

        let t = {...user, ...data}
        let response = await ApiService.User.PutUser(user.id, t as IUserCreate);
        if (response.status === 200)
            dispatch(updateUser(t));

        let lands: any[] = [];
        polygons.map(x => {
            const guid = GUID.newGuid();
            let t = x.points.map((y) => {
                return ({latitude: y.lat, longitude: y.lng, coordinateGroupId: guid} as IDtoUpdateUserCoordinateValue);
            })
            lands = [...lands, ...t];
        });
        let coords = {userId: user.id, coordinates: lands} as IDtoUpdateUserCoordinate;
        await ApiService.User.PutUserUpdateCoordinates(user.id, coords);
        await getUser();
    }

    return (
        <Card className='ant-card-invisible' loading={objIsEmpty(user)}>
            <Form onFinish={onFinish} requiredMark={false} ref={formRef} colon={false}
                  labelCol={{span: 4}} wrapperCol={{span: 20}}
            >
                {
                    user && user.userTypeId !== 1 ?
                        <>
                            <Form.Item label='Business Name' name='name' initialValue={user.name}
                                       rules={[{
                                           required: true,
                                           message: 'Please input your business name!',
                                           pattern: new RegExp(/^[a-zA-Z\s]*$/)
                                       }]}>
                                <Input size='small'/>
                            </Form.Item>

                            <Form.Item label='Land-based' name='isHaveLand'
                                       initialValue={user.userTypeId === 2}
                                       valuePropName='checked'>
                                <Switch checkedChildren={'Yes'} unCheckedChildren={'No'}/>
                            </Form.Item>
                        </>
                        :
                        <>
                            <Form.Item label='Name' name='name' initialValue={user.name}
                                       rules={[{
                                           required: true,
                                           message: 'Please input your name!',
                                           pattern: new RegExp(/^[a-zA-Z\s]*$/)
                                       }]}>
                                <Input size='small'/>
                            </Form.Item>

                            <Form.Item label='Surname' name='surname' initialValue={user.surname}
                                       rules={[{
                                           required: true,
                                           message: 'Please input your name!',
                                           pattern: new RegExp(/^[a-zA-Z\s]*$/)
                                       }]}>
                                <Input size='small'/>
                            </Form.Item>
                        </>
                }
                <Form.Item label='Bio' name='bio'
                           rules={[{
                               required: true,
                               message: 'Please input your bio!',
                               //pattern: new RegExp(/^[a-zA-Z\s]*$/)
                           }]}>
                    <TextEditor toolBarHidden={true} defaultValue={user.bio}/>
                </Form.Item>

                <div>

                    {/*<Form.Item label='Latitude' name='latitude'*/}
                    {/*           initialValue={user.latitude}*/}
                    {/*    //style={{display: 'inline-block', width: 'calc(50% - 12px)'}}*/}
                    {/*>*/}
                    {/*    <Input size='small' onChange={(data) => LatLngChange(data, 'lat')}/>*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item label='Longitude' name='longitude'*/}
                    {/*           initialValue={user.longitude}*/}
                    {/*    //style={{display: 'inline-block', width: 'calc(50% - 12px)', marginLeft: 24}}*/}
                    {/*>*/}
                    {/*    <Input size='small' onChange={(data) => LatLngChange(data, 'lng')}/>*/}
                    {/*</Form.Item>*/}


                    {
                        user && user.userTypeId !== 1 &&
                        <Form.Item label={'Place'}>
                            <GMForProfile
                                polygons={polygons && polygons}
                                markers={marker != null && [marker]}
                                eventCallback={(type, data) => {
                                    if (type === EVENT_TYPES.MARKER_CREATED) {
                                        setMarker(data as IMarker);
                                        return;
                                    }
                                    else if (type === EVENT_TYPES.MARKER_UPDATED) {
                                        setMarker(data as IMarker);
                                        return;
                                    }
                                    else if (type === EVENT_TYPES.MARKER_REMOVED) {
                                        setMarker({id: '0', point: {lat: 0, lng: 0}} as IMarker);
                                        return;
                                    }
                                    else if (type === EVENT_TYPES.POLYGON_CREATED) {
                                        let t = polygons;
                                        t.push(data as IPolygon);
                                        setPolygons(t);
                                        return;
                                    }
                                    else if (type === EVENT_TYPES.POLYGON_UPDATED) {
                                        let t = polygons;
                                        let index = t.findIndex(x => data.id == x.id);
                                        t[index] = (data as IPolygon);
                                        setPolygons(t);
                                        return;
                                    }
                                    else if (type === EVENT_TYPES.POLYGON_REMOVED) {
                                        let t = polygons;
                                        let index = t.findIndex(x => data.id == x.id);
                                        t.splice(index, 1);
                                        setPolygons(t);
                                        return;
                                    }
                                }}
                            />

                            {/*<GMForModule*/}
                            {/*    selectedMark={(data) => {*/}
                            {/*        selectedMarkUpdate(data);*/}
                            {/*        setUpdatedLatLng(data);*/}
                            {/*    }}*/}
                            {/*    haveSearchBox*/}
                            {/*    value={updatedLatLng}/>*/}
                        </Form.Item>
                    }

                </div>


                <Form.Item label='Date of Birth' name='dateOfBirth'
                           initialValue={moment(user.dateOfBirth, 'YYYY-MM-DD')}>
                    <DatePicker format="DD.MM.YYYY" size='small'
                                style={{width: '100%'}}/>
                </Form.Item>

                {/*<Form.Item label='Phone number' name='phone' initialValue={user.phoneNumber}>*/}
                {/*    <Input/>*/}
                {/*</Form.Item>*/}

                {/*<Form.Item label='Address' name='address' initialValue={user.address}>*/}
                {/*    <Input.TextArea/>*/}
                {/*</Form.Item>*/}

                <Form.Item label='Web Address' name='webAddress'
                           initialValue={user.webAddress && user.webAddress.replace(prefixWeb, '')}>
                    <Input
                        className='input-link' prefix={prefixWeb}
                        size='small' placeholder='https://www.example.com'/>
                </Form.Item>

                <Form.Item label={<InstagramOutlined className='instagram' style={{
                    fontSize: '25px',
                    backgroundColor: '#ff0000',
                    color: '#fff',
                    borderRadius: '7px'
                }}/>}
                           name='socialInstagram'
                           initialValue={user.socialInstagram && user.socialInstagram.replace(prefixIns, '')}
                           colon={false}
                >
                    <Input className='input-link' prefix={prefixIns} size='small'/>
                </Form.Item>

                <Form.Item label={<LinkedinFilled style={{fontSize: '25px', color: '#0073b1'}}/>}
                           name='socialLinkedin'
                           initialValue={user.socialLinkedin && user.socialLinkedin.replace(prefixLi, '')}
                           colon={false}
                >
                    <Input className='input-link' prefix={prefixLi} size='small'/>
                </Form.Item>

                <Form.Item label={<TwitterSquareFilled style={{fontSize: '25px', color: '#1da1f2'}}/>}
                           name='socialTwitter'
                           initialValue={user.socialTwitter && user.socialTwitter.replace(prefixTw, '')}
                           colon={false}>
                    <Input className='input-link' prefix={prefixTw} size='small'/>
                </Form.Item>

                <Form.Item label={<FacebookFilled style={{fontSize: '25px', color: '#4267B2'}}/>}
                           name='socialFacebook'
                           initialValue={user.socialFacebook && user.socialFacebook.replace(prefixFb, '')}
                           colon={false}
                >
                    <Input className='input-link' prefix={prefixFb} size='small'/>
                </Form.Item>

                <Form.Item wrapperCol={{md: {span: 19, offset: 5}, xs: {offset: 0}}}>
                    <div className='d-flex justify-content-between'>
                        {/*<Button danger ghost>*/}
                        {/*    Delete*/}
                        {/*</Button>*/}
                        <span/>

                        <Button type='primary' htmlType='submit' className='responsive-button' icon={<CheckOutlined/>}>
                            Save changes
                        </Button>
                    </div>
                </Form.Item>

            </Form>
        </Card>
    );
};

const InterestsForm: React.FC<any> = props => {
    const [isLoaded, setLoaded] = useState<boolean>(false);
    const [interests, setInterests] = useState<Array<IUserInterestAComplete>>();
    const [oldestInterests, setOldestInterests] = useState<Array<IUserInterestAComplete>>([]);
    const user: IUser = useSelector((state: AppState) => state).user;

    useEffect(() => {
        getInterests();
    }, [])

    const onFinish = async (data) => {
        let interestsObj: IUserUpdateInterest = {} as IUserUpdateInterest;
        interestsObj.userId = user.id;
        let unknownTagsStr: string = '';
        interests.filter(x => x.value == x.label).map((x) => {
            unknownTagsStr = unknownTagsStr + x.label + ','
        })
        unknownTagsStr = unknownTagsStr.slice(0, -1);
        unknownTagsStr = unknownTagsStr.length < 1 ? '' : unknownTagsStr;

        let tags = interests.filter(x => x.value != x.label).map((item) => {
            return {tagId: item.value};
        });

        interestsObj.tags = tags;
        interestsObj.unknownTags = unknownTagsStr;

        await ApiService.User.PutUserUpdateInterests(user.id, interestsObj);
    }

    const getInterests = async () => {
        let response = await ApiService.UserInterest.GetUserInterestByUserId(user.id);
        let t: Array<IUserInterestAComplete> = response.data.map((item) => {
            return {label: item.tag.tagName, value: item.tagId, key: item.tagId} as IUserInterestAComplete;
        })
        setOldestInterests(t);
        setLoaded(true);
    }

    return (
        <Card className='ant-card-invisible' loading={!isLoaded}>
            <Form
                onFinish={onFinish}
                colon={false}
                requiredMark='optional'>
                <Form.Item label='' name='tags'>
                    <AutoCompleteInterests
                        selectedTags={setInterests}
                        //endpoint='/Tag/SearchWithType/1/'
                        singleTag={false}
                        unknownTag={true}
                        tags={oldestInterests}
                    />
                </Form.Item>
                <Form.Item>

                    <div className='d-flex justify-content-between'>
                        <span/>

                        <Button
                            type='primary'
                            htmlType='submit'
                            className='responsive-button'
                            icon={<CheckOutlined/>}>Submit</Button>
                    </div>
                </Form.Item>
            </Form>
            <div className='elem-list skills-list'>
                {user.tags && user.tags.map((item: ITag, index: number) => {
                    return (
                        <Tag
                            key={index}
                            color={'#cd5447'}
                            style={{color: '#fff', borderRadius: 500, fontSize: 14}}>
                            {item}
                        </Tag>
                    )
                })}
            </div>
        </Card>
    )
}

const AddFilesForm: React.FC<any> = props => {
    const [isLoaded, setLoaded] = useState<boolean>(false);
    const [oldestFiles, setOldestFiles] = useState<IUserFile[]>([]);
    const [uploadFile, setUploadFile] = useState<any[]>();
    const user: IUser = useSelector((state: AppState) => state).user;

    useEffect(() => {
        getPrevFiles();
    }, [])

    const onFinish = (data) => {
        uploadFile.map(async (file) => {
            let response = await ApiService.User.PostUserUploadFile(user.id, {userId: user.id, uploadedFile: file} as IUserFileUpload);
            if (response.status === 200){
                getPrevFiles();
            }
        })
    }

    const getPrevFiles = async () => {
        let response = await ApiService.UserFile.GetUserFileByUserId(user.id);
        setOldestFiles(response.data);
        setLoaded(true);
    }

    const deleteItem = async (itemId) => {
        let response = await ApiService.User.DeleteUserFile(itemId);
        if (response.status === 200){
            getPrevFiles();
        }
    }

    // TODO only media types
    const uploadProps = {
        name: 'file',
        multiple: true,
        maxCount: 1,
        onChange(info) {
            let t: Array<any> = info.fileList.map((file) => {
                return (file.originFileObj);
            })
            setUploadFile(t);
            return false;
        },
        beforeUpload(file) {
            const isForbidden =
                file.type === ('application/x-msdownload'
                    || 'application/bin'
                    || 'application/x-bin'
                    || 'application/bat'
                    || 'application/x-bat'
                    || 'application/x-msdos-program'
                    || 'application/x-vbs'
                    || 'application/vbs'
                    || 'application/javascript'
                    || 'application/x-javascript');
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (isForbidden) {
                toast.error('Forbidden file type!');
                return Upload.LIST_IGNORE;
            }
            if (!isLt2M) {
                toast.error('File must less than 2MB!');
                return Upload.LIST_IGNORE;
            }
            return false;
        },
    };

    return (
        <Card className='ant-card-invisible' loading={!isLoaded}>

            {oldestFiles.length > 0 ? oldestFiles.map((item, index) => {
                return (
                    <div key={item.id} style={{paddingBottom: '5px'}} className='d-flex justify-content-between'>
                        <Button key={item.id} type='primary' size='small'>
                            <a
                                key={item.id}
                                href={S3_SERVER_URL + item.fileURI}
                                //target={'_blank'}
                                //download={S3_SERVER_URL + item.fileURI}
                                download
                            >Download #{index + 1}</a>
                        </Button>

                        <div style={{paddingTop: '5px'}}>
                            <DeleteOutlined onClick={(e) => {
                                deleteItem(item.id)
                            }}
                                            style={{fontSize: '15px', color: 'red'}}
                            />
                        </div>
                    </div>
                )
            }) : <div style={{textAlign: "center"}}><span>No file uploaded</span></div>}

            <div style={{padding: '20px'}}/>
            <Form
                onFinish={onFinish}
                colon={false}
                requiredMark='optional'>

                {/*TODO*/}
                {/* Çoktan yüklenmiş dosyalar burada listelenecek*/}
                {/* Ayrıca yeni dosyalar yüklenebilmesi için gerekli geliştirmeler yapılacak*/}
                {/* Dosyaları download butonu ile mi listelemek gerekiyor.*/}

                <Form.Item required>
                    <Upload.Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined/>
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    </Upload.Dragger>
                </Form.Item>

                <Form.Item>
                    <div className='d-flex justify-content-between'>
                        <span/>

                        <Button
                            type='primary'
                            htmlType='submit'
                            className='responsive-button'
                            icon={<CloudUploadOutlined/>}>Upload</Button>
                    </div>
                </Form.Item>
            </Form>
        </Card>
    )
}

const EditProfile: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'EditProfile',
        loaded: true
    };
    useEffect(() => {
        onSetPage(pageData);
    }, []); // eslint-disable-next-line react-hooks/exhaustive-deps


    return (
        <div className='row justify-content-center'>
            <div className='col col-12 col-xl-8'>

                <Card title='User Information'>
                    <UserForm/>
                </Card>

                <Card title='Interests'>
                    <InterestsForm/>
                </Card>

                <Card title='Additional Files'>
                    <AddFilesForm/>
                </Card>

            </div>
        </div>
    );
};

export default EditProfile;
