import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../interfaces/page-data';
import {Button, Card, Col, Form, Input, Row, Select, Switch, Upload} from "antd";
import {CheckOutlined, LeftOutlined, UploadOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {AppState, history} from "../../redux/store";
import {IResourceType, IUser} from "../../interfaces/api";
import AutoCompleteTags from "../../components/AutoCompleteTags/AutoCompleteTags";
import TextEditor from "../../components/TextEditor/TextEditorWithoutButton";
import ImgUploader from "../../components/ImgUploader/ImgUploader";
import {TITLE_NAME_REGEX} from "../../utils/Constants";
import ApiService from "../../utils/services/api.service";

const AddResource: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'Share Your Voice',
        loaded: true
    };
    const user: IUser = useSelector((state: AppState) => state).user;
    const [fileURIOrLinkEmbedCode, setFileURIOrLinkEmbedCode] = useState<boolean>(false);  // true embedcode link
    //false file or fileURI
    const [thumbnail, setThumbnail] = useState<any>(null);
    const [uploadFiles, setUploadFiles] = useState<any>(null);
    const [types, setTypes] = useState<IResourceType[]>([]);
    const [selectedType, setSelectedType] = useState<any>(1);
    const [form] = Form.useForm(null);

    useEffect(() => {
        onSetPage(pageData)
        any().then(null);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const any = async () => {
        let response = await ApiService.ResourceType.GetResourceType();
        setTypes(response.data);
    }

    const onFinish = async (data) => {
        console.dir(data);
        console.dir(thumbnail);
        console.dir(uploadFiles);

        data.files = uploadFiles;
        data.userId = user.id;


        // TODO wait for response from Kaan K.
        //region Resource tags oldest
        // let tTags: ITagAComplete[] = tagList.filter((item) => item.value !== item.label);
        // let tags: IDTOResourceTag[] = tTags.map((item) => {
        //     return ({tagId: item.value} as IDTOResourceTag)
        // });
        // console.log(tags)
        // console.log(tags.length)

            //data.resourceTags = tags.length > 0 ? tags: [];

        // let unkTags: string = "";
        // tagList.filter((item) => {
        //     if (item.value === item.label)
        //         unkTags = unkTags + item.label + ',';
        // })
        // unkTags = unkTags.length > 0 && unkTags.slice(0, -1)
        // data.unknownTags = unkTags;
        //endregion

        //region Resource Tags
        console.log(data.resourceTags);
        let resourceTags = data.resourceTags ? data.resourceTags : [];
        data.resourceTags = resourceTags.filter((item) => item.value !== item.label).map((item) => {
            return item.value
        });

        let unkTagsStr = resourceTags.filter((item) => item.value === item.label).map((item) => {
            return item.label
        }).join(',');
        console.dir(unkTagsStr)
        data.unknownTags = unkTagsStr
        //endregion



        console.dir(data)

        let response = await ApiService.Resource.PostResource(data);
        if (response.status === 200){
            history.push('/vertical/resources')
        }
    }

    const isVideoType = () => {
        return (selectedType !== 2 && selectedType !== 1);
    }

    const isImageType = () => {
        return selectedType == 3;
    }

    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <Card className='card-head-none'
                          loading={types.length < 1}
                          title={<Row>
                              <Col span={24}>
                                  <span className='card-title-page'>Share Your Voice</span>
                              </Col>
                          </Row>}>
                        <Form
                            colon={false}
                            form={form}
                            id='myForm'
                            onFinish={onFinish}
                            labelAlign="right"
                            labelCol={{sm: {span: 7}, lg: {span: 6}, md: {span: 6}}}
                            wrapperCol={{span: 12}}
                            initialValues={{isFileOrUrl: false}}
                            requiredMark={false}
                        >
                            <Form.Item label='Type' name='resourceTypeId' initialValue={selectedType}
                                       rules={[{required: true, message: 'Type cannot be blank'}]}>
                                <Select onChange={(value) => {
                                    setSelectedType(value)
                                    form.resetFields(['linkOrEmbedCode', 'files', 'fileURI']);
                                }}>
                                    {
                                        types && types.map((item) => {
                                            return (<Select.Option key={item.id}
                                                                   value={item.id}>{item.resourceTypeName}</Select.Option>)
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label='Title' name='title'
                                       rules={[{required: true, message: 'Title cannot be blank'},
                                           {required:true, pattern: TITLE_NAME_REGEX, message:'Title wrong format'}]}>
                                <Input size='small'/>
                            </Form.Item>
                            <Form.Item label='Thumbnail' name='thumbnail'
                                       valuePropName=''
                                       rules={[{required: true, message: 'Thumbnail cannot be blank'}]}
                                       extra={<span
                                           style={{fontSize: '10px'}}>Image must be PNG and less than 5MB</span>}>
                                <ImgUploader
                                    fileTypes={['image/png']}
                                    aspectRatio={2}
                                    imagesCount={1}
                                    fileMaxSize={5120}
                                    onChange={setThumbnail}
                                />
                            </Form.Item>
                            <Form.Item label='Description' name='description'
                                       rules={[{required: true, message: 'Description cannot be blank'}]}>
                                <TextEditor image={false}/>
                            </Form.Item>
                            <Form.Item label='Tags' name='resourceTags' validateTrigger={'selectedTags'}
                                       rules={[{required: true, message: 'Resource tags cannot be blank'}]}>
                                <AutoCompleteTags singleTag={false} unknownTag={true}
                                                  endpoint={'/Tag/SearchWithType/1/'}/>
                            </Form.Item>
                            <Form.Item label={!isVideoType() ? 'File or Embed' : 'Upload or URI'} name='isEmbedCode'
                                       valuePropName='checked'
                                       initialValue={fileURIOrLinkEmbedCode}
                                       rules={[{required: true, message: 'Type cannot be blank'}]}>
                                <Switch
                                    onChange={setFileURIOrLinkEmbedCode}
                                    checkedChildren={isVideoType() ? 'URI' : 'Embed'}
                                    unCheckedChildren={isVideoType() ? 'Upload' : 'File'}/>
                            </Form.Item>
                            {
                                // true embed code or link
                                // false upload file or URI
                                fileURIOrLinkEmbedCode ?
                                    <>
                                        <Form.Item
                                            label={!isVideoType() ? 'Embed Code' : 'URI'}
                                            name='linkOrEmbedCode'
                                            rules={[{
                                                required: true,
                                                message: `${!isVideoType() ? 'Embed Code' : 'URI'} cannot be empty`
                                            }]}>
                                            <Input.TextArea
                                                autoSize={{minRows: 2, maxRows: 7}}
                                            />
                                        </Form.Item>
                                    </>
                                    :
                                    <>
                                        {
                                            (isVideoType()) ?
                                                <Form.Item label='Files'
                                                           name='files'
                                                           dependencies={['fileURI']}
                                                           valuePropName=''
                                                           rules={[{required: true, message: 'Files cannot be blank'}]}
                                                >
                                                    <Upload
                                                        maxCount={isImageType() ? 5 : 1}
                                                        listType='picture'
                                                        onChange={(info) => {
                                                            let t: any = info.fileList.map((file) => {
                                                                return (file.originFileObj);
                                                            })
                                                            setUploadFiles(t);
                                                            return false;
                                                        }
                                                        }
                                                        beforeUpload={(file, fileList) => {
                                                            return false;
                                                        }
                                                        }
                                                    >
                                                        <Button icon={<UploadOutlined/>} size='small'>Click to
                                                            upload</Button>
                                                    </Upload>
                                                </Form.Item>
                                                :
                                                <Form.Item label='File URI' name='fileURI'
                                                           dependencies={['files']}
                                                           rules={[{
                                                               required: true,
                                                               message: 'File URI cannot be blank'
                                                           }]}
                                                >
                                                    <Input.TextArea
                                                        autoSize={{minRows: 2, maxRows: 7}}
                                                    />
                                                </Form.Item>
                                        }
                                    </>
                            }

                            <Form.Item wrapperCol={{
                                lg: {span: 12, offset: 6},
                                md: {span: 12, offset: 6},
                                xs: {offset: 0},
                                sm: {span: 12, offset: 7}
                            }}>
                                <div className='d-flex justify-content-between'>
                                    <Button className='responsive-button' onClick={() => {
                                        history.go(-1)
                                    }} danger icon={<LeftOutlined/>}>Cancel</Button>

                                    <Button className='responsive-button' htmlType='submit' type='primary'
                                            icon={<CheckOutlined/>}>Submit</Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </div>
        </>
    )
};

export default AddResource;
