import React from "react";

const FillWhiteSpace: React.FunctionComponent<any> = props => {

    return (
        <>
            <div style={{height: '450px'}}/>
        </>
    );
};

export default FillWhiteSpace;


