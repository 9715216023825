import React, {Suspense, useEffect, useState} from 'react';
import {Route, Redirect, matchPath} from 'react-router-dom';
import {IRoute, routes} from './routes';

import './assets/sass/App.scss';
import './assets/sass/styles.scss';
import 'draft-js/dist/Draft.css';
import {store} from "./redux/store";
import 'antd/es/slider/style';
import {removeUser} from "./redux/user/actions";
import {useDispatch} from "react-redux";

const App: React.FC = () => {
    const user = store.getState().user;
    const [route, setRoute] = useState<string>(undefined);
    const dispatch = useDispatch();

    useEffect(() => {
        let splitURL: string[] = window.location.pathname.split('/');
        let layoutName: string = splitURL[1] ? splitURL[1] : '';
        let pageName: string = splitURL[2] ? splitURL.splice(2,splitURL.length).join('/') : '';

        let isLoginUser: boolean = isLoggedInUser();

        let layoutRoute: IRoute|undefined = layoutIsExist(layoutName);
        let isLayoutExist: boolean = layoutRoute !== undefined;

        let pageRoute: IRoute|undefined = pageIsExist(layoutRoute, pageName);
        let isPageExist: boolean = pageRoute !== undefined;

        let isHaveAuth: boolean = haveAuth(pageRoute, user.userRoleId);

        if (isLoginUser) {
            if (isLayoutExist && layoutName !== 'auth') {
                if (isPageExist) {
                    if (isHaveAuth)
                        setRoute(window.location.pathname);
                    else {
                        dispatch(removeUser());
                        setRoute('/auth/sign-in');
                    }
                }
                else
                    setRoute('/vertical/home');
            }
            else
                setRoute('/vertical/home');
        }
        else {
            if (isLayoutExist && layoutName === 'auth'){
                setRoute(window.location.pathname);
            }
            else
                setRoute('/auth/sign-in');
        }

    }, [])// eslint-disable-line react-hooks/exhaustive-deps


    // any logged in user ?
    const isLoggedInUser = () => {
        return Object.keys(user).length > 0;
    }

    // layout is exist in routes
    const layoutIsExist = (layoutName: string) => {
        if (layoutName.length < 1)
            return undefined;
        layoutName = "/" + layoutName;
        return routes.find((route) => route.path === layoutName) as IRoute;
    }

    // page is exist for that layout
    const pageIsExist = (layoutRoute: IRoute, pageName: string) => {
        if (pageName.length < 1 || layoutRoute == undefined)
            return undefined;
        pageName = "/" + pageName;
        let currentPage: IRoute = layoutRoute.children.find((route) => {
            //return (route.path == pageName)
            return (matchPath(pageName, {path: route.path, exact: true}) != null)
        });
        if (currentPage === undefined || (currentPage.path.split('/').length !== pageName.split('/').length))
            return undefined;
        return currentPage;
    }

    // user have auth for current page ?
    const haveAuth = (pageRoute: IRoute|undefined, roleId: number) => {
        if (pageRoute === undefined)
            return false;
        return pageRoute.auth.includes(roleId);
    }

    return (
        <React.Fragment>
            <Suspense fallback={null}>
                <div className='App'>
                    {routes.map((route, i) => (
                        <Route key={i} path={route.path} component={route.component}/>
                        //<Route key={i} path='/' component={() => <route.component/>}/>
                    ))}
                    {/*<Route exact path='/' render={() => <Redirect exact to={route}/>}/>*/}
                    {route && <Redirect exact to={route}/>}
                </div>
            </Suspense>
        </React.Fragment>
    );
};

export default App;
