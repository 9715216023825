import React, {useState} from 'react';
import {Button, Checkbox, Form, Input, Switch} from 'antd';
import ThemeButton from '../../../components/ThemeButton/TheameButton';
import {MailOutlined, UserOutlined} from "@ant-design/icons";
import {IDtoUserRegister} from "../../../interfaces/api";
import {history} from '../../../redux/store';
import ApiService from "../../../utils/services/api.service";

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0
        },
        sm: {
            span: 24,
            offset: 0
        }
    }
};

const SingUpForm: React.FC<any> = props => {
    const [isBusiness, setBusiness] = useState<boolean>(false);
    const [isHaveLand, setHaveLand] = useState<boolean>(false);

    const submitSign = async (data) => {
        data.userTypeId = !isBusiness ? 1 : (isHaveLand ? 2 : 3);
        data.surname = isBusiness ? '' : data.surname;
        let response = await ApiService.User.PostUser(data as IDtoUserRegister);
        if (response.status === 200){
            history.push('/auth/sign-in');
        }
    }

    return (
        <Form style={{width: '100%'}} onFinish={submitSign}>
            <Form.Item label='Are you a business?'
                       rules={[{required: true, message: 'Please choose your type!'}]}>
                <Switch onChange={setBusiness}/>
            </Form.Item>
            {
                isBusiness &&
                <Form.Item label='Are you a land-based business?'
                           rules={[{required: true, message: 'Please choose your type!'}]}>
                    <Switch onChange={setHaveLand}/>
                </Form.Item>
            }
            {
                !isBusiness ?
                    <>
                        <Form.Item name='name'
                                   rules={[{
                                       required: true,
                                       message: 'Please input your name!',
                                       pattern: new RegExp(/^[a-zA-Z\s]*$/)
                                   }]}
                        >
                            <Input placeholder='Name' prefix={<UserOutlined/>}/>
                        </Form.Item>
                        <Form.Item name='surname'
                                   rules={[{
                                       required: true,
                                       message: 'Please input your surname!',
                                       pattern: new RegExp(/^[a-zA-Z\s]*$/)
                                   }]}>
                            <Input placeholder='Surname' prefix={<UserOutlined/>}/>
                        </Form.Item>
                    </>
                    :
                    <>
                        <Form.Item name='name'
                                   rules={[{
                                       required: true,
                                       message: 'Please input your business name!',
                                       pattern: new RegExp(/^[a-zA-Z\s]*$/)
                                   }]}>
                            <Input placeholder='Business Name' prefix={<UserOutlined/>}/>
                        </Form.Item>
                    </>
            }


            <Form.Item name='email'
                       rules={[{
                           required: true,
                           message: 'Please input your email in correct form!',
                           whitespace: false,
                           pattern: new RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i)
                       }]}>
                <Input placeholder='Email' prefix={<MailOutlined/>}/>
            </Form.Item>

            <Form.Item name='password'
                       rules={[{
                           required: true,
                           message: 'Please input your password!',
                           whitespace: false,
                           pattern: new RegExp(/^\S*$/)
                       }, {
                           required: true,
                           min: 5,
                           message: 'Password must be at least 5 digits!'
                       }]}
                       hasFeedback
            >
                <Input.Password placeholder='Password'/>
            </Form.Item>


            <Form.Item name='confirm'
                       dependencies={['password']}
                       hasFeedback
                       rules={[
                           {
                               required: true,
                               message: 'Please input your password!',
                               whitespace: false,
                               pattern: new RegExp(/^\S*$/)
                           }, {
                               required: true,
                               min: 5,
                               message: 'Password must be at least 5 digits!'
                           },
                           ({ getFieldValue }) => ({
                               validator(_, value) {
                                   if (!value || getFieldValue('password') === value) {
                                       return Promise.resolve();
                                   }
                                   return Promise.reject(new Error('The two passwords that you entered do not match!'));
                               },
                           }),
                       ]}>
                <Input.Password placeholder='Confirm password'/>
            </Form.Item>
            <Form.Item {...tailFormItemLayout} name='agreement' valuePropName='checked'>
                <Checkbox>
                    I have read the <a>terms and condition</a>
                </Checkbox>
            </Form.Item>
            <Form.Item>
        <span className='d-flex flex-row-reverse justify-content-between'>
          <Button type='primary' htmlType='submit'>
            Sign Up
          </Button>
        </span>
            </Form.Item>
        </Form>
    );
};

//const WrappedSignUpForm = Form.create({ name: 'sign-up' })(SingUpForm);

export default SingUpForm;
