import React from 'react';
import {NavLink} from 'react-router-dom';
import {Button, Form, Input} from 'antd';
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {IUser} from "../../../interfaces/api";
import {useDispatch} from "react-redux";
import {history} from "../../../redux/store";
import {setUser} from "../../../redux/user/actions";
import {IUserAuthenticate} from "../../../interfaces/api";
import ApiService from "../../../utils/services/api.service";

const SingInForm: React.FC<any> = props => {
    //const {user} = useSelector((state:AppState) => state)
    //console.dir(user);

    const dispatch = useDispatch();

    //console.log(dispatch);
    const login = async (e) => {
        let user: IUserAuthenticate = e;
        let response = await ApiService.User.PostUserAuthenticate(user);
        if (response.status === 200){
            let user: IUser = response.data;
            dispatch(setUser(user));
            history.push('/vertical/home');
        }
    }

    return (
        <Form style={{width: '100%'}} onFinish={login}>
            <Form.Item name='email' rules={[{
                required: true,
                message: 'Please input your email in correct form!',
                whitespace: false,
                pattern: new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)
            }]}
            >
                <Input
                    prefix={<UserOutlined/>}
                    placeholder='E-mail'
                />
            </Form.Item>
            <Form.Item name='password' rules={[{
                required: true,
                message: 'Please input your password!',
                whitespace: false,
                pattern: new RegExp(/^\S*$/)
            }, {
                required: true,
                min: 5,
                message: 'Password must be at least 5 digits!'
            }]}
            >
                <Input.Password prefix={<LockOutlined/>} placeholder='Password'/>
            </Form.Item>
            <Form.Item>
                <div className='span d-flex justify-content-between'>
                    {/*<NavLink to='/auth/forgot-password' className='link pl-sm-0'>*/}
                    {/*    Forgot password ?*/}
                    {/*</NavLink>*/}
                    <span></span>
                    <Button type="primary" htmlType="submit">
                        Sign In
                    </Button>
                </div>
            </Form.Item>
        </Form>
    );
};

//const WrappedForm = Form.create({ name: 'sign-in' })(SingInForm);

export default SingInForm;
