import React, {useEffect} from 'react';
import {IResource} from "../../interfaces/api";
import {Card, Tooltip} from "antd";
import {history} from "../../redux/store";
import blankImg from "../../assets/img/blank/blank-image.png";
import {DATE_FORMAT, S3_SERVER_URL} from "../../utils/Constants";
import './ResourceCard.scss';
import {objIsEmpty} from "../../utils/GlobalFuncs";
import ImgViewer from '../ImgViewer/ImgViewer';

interface CardProps {
    resource: IResource
}

const ResourceCard: React.FC<CardProps> = props => {

    return (
        <div className='resource-card'>
            <Card
                loading={objIsEmpty(props.resource)}
                hoverable
                onClick={() => {
                    history.push(`/vertical/resources/detail/${props.resource.id}`);
                }}
                cover={
                    <>
                        <div className='img-item-wrapper'>
                            {props.resource.uploadedThumbnail.length < 1 ?
                                <ImgViewer src={blankImg}  ratio={2}/>
                                :
                                <ImgViewer src={S3_SERVER_URL + props.resource.uploadedThumbnail} ratio={2} width='100%'/>}
                        </div>
                    </>
                }
            >
                <Card.Meta
                    title={
                        <Tooltip title={props.resource.title}>
                            <div className='meta-title'>
                                {props.resource.title}
                            </div>

                        </Tooltip>
                    }
                    description={<div className='meta-desc'>
                        <div className='sub-desc' dangerouslySetInnerHTML={{__html: props.resource.description.replace('\r\n','')}}/>
                        {
                            props.resource.uploadedBy &&
                            <div className='meta-provider-text'>
                                {props.resource.uploadedBy.userTypeId == 1 ? (props.resource.uploadedBy.name + ' ' + props.resource.uploadedBy.surname) : props.resource.uploadedBy.name}
                            </div>
                        }
                        <div className='fill'/>
                    </div>
                    }
                />
            </Card>
        </div>
    );
};

ResourceCard.defaultProps = {
    resource: {} as IResource
}

export default ResourceCard;
