const SALT: string = 'ENCRYPTED_LOGGED_IN_USER';
let Crypto = require("crypto-js");

class EncryptionService {

    static encrypt = (o) => {
        return Crypto.AES.encrypt(JSON.stringify(o), SALT).toString();
    }

    static decrypt = (o) => {
        var bytes = Crypto.AES.decrypt(o, SALT);
        return JSON.parse(bytes.toString(Crypto.enc.Utf8));
    }
}

export default EncryptionService;

