import React, {useEffect, useState} from 'react';
import {IPageData, IPageProps} from "../../../interfaces/page-data";
import {AppState} from "../../../redux/store";
import {useDispatch, useSelector} from "react-redux";
import {Button, Card, Modal} from "antd";
import {IUser, IUserPremium} from "../../../interfaces/api";
import moment from "moment";
import toast from "react-hot-toast";
import {setUser} from "../../../redux/user/actions";
import { DollarOutlined } from '@ant-design/icons';
import ApiService from '../../../utils/services/api.service';

const BuyMembership: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const dispatch = useDispatch();
    const user: IUser = useSelector((state: AppState) => state).user;
    const [premiumType, setPremiumType] = useState<string>();
    const [isPurchaseModalVisible, setPurchaseModalVisible] = useState<boolean>(false);
    const [isTransactionComplete, setTransactionComplete] = useState<boolean>(true);
    const pageData: IPageData = {
        title: 'Buy Membership',
        loaded: true
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => onSetPage(pageData), []);

    const purchase = async (type) => {
        setTransactionComplete(false);
        let days = 0;
        switch (type) {
            case 'daily':
                days = 1;
                break;
            case 'monthly':
                days = 30;
                break;
            case 'annual':
                days = 365;
                break;
        }
        let response = await ApiService.User.PostUserSetPremium({id: user.id, premiumDays: days} as IUserPremium);
        if (response.status === 200){
            toast.success('New premium days added successfully');
            let userResponse = await ApiService.User.GetUserById(user.id);
            if (userResponse.status === 200){
                let user: IUser = JSON.parse(response.data);
                dispatch(setUser(user));
                setTransactionComplete(true);
                setPurchaseModalVisible(false);
            }
        }
    }

    return (
        <div>
            <div className='row'>
                <div className='col-12'>
                    <h6>Current Membership: <span
                        style={{color: 'green'}}>{user.isPremium ? "Premium" : "Standart"}</span></h6>
                    {
                        user.isPremium &&
                        <>
                            <h6>Premium membership is valid until <span
                                style={{color: 'green'}}>
                                    {/*{moment(premiumDate, "YYYY-MM-DDThh:mm:ss").format("DD.MM.YYYY HH:mm")}*/}
                                {moment(user.premiumValidUntil, "YYYY-MM-DDThh:mm:ss").format("DD.MM.YYYY HH:mm")}
                                </span>
                            </h6>
                        </>
                    }
                </div>
            </div>

            <div className='row'>
                <div className='col-12 col-md-4' style={{marginTop:'10px'}}>
                    <Card className='mb-0' title='Daily Premium' style={{textAlign: 'center'}}>
                        <div className='d-flex justify-content-center align-items-center'>
                            <div className='mr-2' style={{fontSize: 50}}>
                                10
                            </div>
                            <div style={{color: '#8f8f90'}}>
                                <div style={{fontSize: 20}}>USD</div>
                                {/*<div>day</div>*/}
                            </div>
                        </div>

                        <hr className='mt-4 mb-4'/>

                        <ul className='list-unstyled text-left'>
                            <li className='d-flex align-items-center pt-2 pb-2'>
                                <span className='icofont-check-circled' style={{color: '#8f8f90'}}/>
                                <span className='ml-1'>Opportunity 1</span>
                            </li>
                            <li className='d-flex align-items-center pt-2 pb-2'>
                                <span className='icofont-check-circled' style={{color: '#8f8f90', visibility: 'hidden'}}/>
                                <span className='ml-1' style={{visibility: 'hidden'}}>Opportunity 2</span>
                            </li>
                            <li className='d-flex align-items-center pt-2 pb-2'>
                                <span className='icofont-check-circled' style={{color: '#8f8f90', visibility: 'hidden'}}/>
                                <span className='ml-1' style={{visibility: 'hidden'}}>Opportunity 3</span>
                            </li>
                        </ul>

                        <Button
                                type='primary'
                                icon={<DollarOutlined />}
                                className='mb-3 responsive-button' ghost block onClick={() => {
                            setPurchaseModalVisible(true);
                            setPremiumType('daily');
                        }}>
                            Purchase
                        </Button>
                    </Card>
                </div>

                <div className='col-12 col-md-4' style={{marginTop:'10px'}}>
                    <Card className='mb-0' title='Monthly Premium' style={{textAlign: 'center'}}>
                        <div className='d-flex justify-content-center align-items-center'>
                            <div className='mr-2' style={{fontSize: 50}}>
                                250
                            </div>
                            <div style={{color: '#8f8f90'}}>
                                <div style={{fontSize: 20}}>USD</div>
                                {/*<div>month</div>*/}
                            </div>
                        </div>

                        <hr className='mt-4 mb-4'/>

                        <ul className='list-unstyled text-left'>
                            <li className='d-flex align-items-center pt-2 pb-2'>
                                <span className='icofont-check-circled' style={{color: '#8f8f90'}}/>
                                <span className='ml-1'>Opportunity 1</span>
                            </li>
                            <li className='d-flex align-items-center pt-2 pb-2'>
                                <span className='icofont-check-circled' style={{color: '#8f8f90'}}/>
                                <span className='ml-1'>Opportunity 2</span>
                            </li>
                            <li className='d-flex align-items-center pt-2 pb-2'>
                                <span className='icofont-check-circled' style={{color: '#8f8f90', visibility: 'hidden'}}/>
                                <span className='ml-1' style={{visibility: 'hidden'}}>Opportunity 3</span>
                            </li>
                        </ul>

                        <Button type='primary'
                                className='mb-3 responsive-button' ghost block
                                icon={<DollarOutlined />}
                                onClick={() => {
                            setPurchaseModalVisible(true);
                            setPremiumType('monthly');
                        }}>
                            Purchase
                        </Button>
                    </Card>
                </div>

                <div className='col-12 col-md-4' style={{marginTop:'10px'}}>
                    <Card className='mb-0' title='Annual Premium' style={{textAlign: 'center'}}>
                        <div className='d-flex justify-content-center align-items-center'>
                            <div className='mr-2' style={{fontSize: 50}}>
                                2500
                            </div>
                            <div style={{color: '#8f8f90'}}>
                                <div style={{fontSize: 20}}>USD</div>
                                {/*<div>month</div>*/}
                            </div>
                        </div>

                        <hr className='mt-4 mb-4'/>

                        <ul className='list-unstyled text-left'>
                            <li className='d-flex align-items-center pt-2 pb-2'>
                                <span className='icofont-check-circled' style={{color: '#8f8f90'}}/>
                                <span className='ml-1'>Opportunity 1</span>
                            </li>
                            <li className='d-flex align-items-center pt-2 pb-2'>
                                <span className='icofont-check-circled' style={{color: '#8f8f90'}}/>
                                <span className='ml-1'>Opportunity 2</span>
                            </li>
                            <li className='d-flex align-items-center pt-2 pb-2'>
                                <span className='icofont-check-circled' style={{color: '#8f8f90'}}/>
                                <span className='ml-1'>Opportunity 3</span>
                            </li>
                        </ul>

                        <Button type='primary' className='mb-3 responsive-button'
                                icon={<DollarOutlined />} ghost block onClick={() => {
                            setPurchaseModalVisible(true);
                            setPremiumType('annual');
                        }}>
                            Purchase
                        </Button>
                    </Card>
                </div>
            </div>

            <Modal
                visible={isPurchaseModalVisible}
                title='Are you sure?'
                okText='Yes'
                onOk={() => purchase(premiumType)}
                okButtonProps={{loading: !isTransactionComplete}}
                onCancel={() => setPurchaseModalVisible(false)}
            >
                <h6>Are you sure you want to buy premium membership?</h6>
            </Modal>
        </div>
    );
};

export default BuyMembership;
