import {history, store} from "../../redux/store";
import axios, {
    AxiosAdapter,
    AxiosBasicCredentials, AxiosProxyConfig,
    AxiosRequestConfig,
    AxiosTransformer, CancelToken,
    Method,
    ResponseType
} from "axios";
import {API_REQUEST_TIMEOUT, API_REQUEST_TIMEOUT_FILE, API_URL} from "../Constants";
import {IUser} from "../../interfaces/api";
import toast from "react-hot-toast";
import {removeUser, setUser} from "../../redux/user/actions";


const user: IUser = store.getState().user;
//region Axios basics
axios.defaults.baseURL = API_URL;
axios.defaults.timeout = API_REQUEST_TIMEOUT;

//region Headers
axios.defaults.headers = {
    'Access-Control-Allow-Origin': '*',
    'Authorization': 'Bearer ' + user.token,
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
};
//endregion

//region Interceptors
axios.interceptors.request.use( (config) => {
    const token = store.getState().user.token;
    config.headers.Authorization = `Bearer ${token}`;

    return config;
});

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const orgConfig = error.config;
        if (error.response && error.response.status === 401 && !orgConfig._retry) // Unauthorized
        {
            // Remove the interceptor
            orgConfig._retry = true;
            const oldUser = store.getState().user;
            await User.PostUseRefresh({id: oldUser.id, refreshToken: oldUser.refreshToken}).then(response => {
                store.dispatch(setUser(response.data))
            }).catch(err => {
                store.dispatch(removeUser())
                history.push('/auth/sign-in');
            });
            return axios(orgConfig);
        }
        else if (error.response) {
            toast.error(error.response.data.message ? error.response.data.message : 'Unknown error')
        }
        return Promise.reject(error);
    }
);

//endregion

//endregion

//region Interfaces

// Same with AxiosRequestConfig interface
export interface IRequest {
    url?: string;
    method?: Method;
    baseURL?: string;
    transformRequest?: AxiosTransformer | AxiosTransformer[];
    transformResponse?: AxiosTransformer | AxiosTransformer[];
    headers?: any;
    params?: any;
    paramsSerializer?: (params: any) => string;
    data?: any;
    timeout?: number;
    timeoutErrorMessage?: string;
    withCredentials?: boolean;
    adapter?: AxiosAdapter;
    auth?: AxiosBasicCredentials;
    responseType?: ResponseType;
    xsrfCookieName?: string;
    xsrfHeaderName?: string;
    onUploadProgress?: (progressEvent: any) => void;
    onDownloadProgress?: (progressEvent: any) => void;
    maxContentLength?: number;
    validateStatus?: (status: number) => boolean;
    maxRedirects?: number;
    socketPath?: string | null;
    httpAgent?: any;
    httpsAgent?: any;
    proxy?: AxiosProxyConfig | false;
    cancelToken?: CancelToken;
}

// Same with axios response
export interface AxiosResponse<T = any> {
    data: T;
    status: number;
    statusText: string;
    headers: any;
    config: AxiosRequestConfig;
    request?: any;
}

//endregion

const makeRequest = async (options: AxiosRequestConfig) => {

    const response: AxiosResponse<any> = await axios(options);

    // For post, put and delete toast message.
    if (response.config.method.toUpperCase() !== 'GET'){
        if (response.status === 200) {
            toast.success(response.statusText);
        }
        else {
            toast.error(response.statusText);
        }
    }

    return response;
}

const makeRequestWithOutToast = async (options: AxiosRequestConfig) => {
    const response: AxiosResponse<any> = await axios(options);

    // For post, put and delete toast message.
    return response;
}

const makeRequestForToken = async (options: AxiosRequestConfig) => {
    const response: AxiosResponse<any> = await axios(options);
    return response;
}

//region Helper
const convertToFormData = (data: any) => {
    let formData: FormData = new FormData();
    Object.keys(data).forEach((item) => {
        if (Array.isArray(data[item])) {
            let t = data[item];
            data[item].map((x, index) => {
                formData.append(item, t[index]);
            })
            return;
        }
        formData.append(item, data[item]);
    });
    return formData;
}
//endregion

const Test = {
    Unauthorized: async () => {
        return makeRequest({
            url: 'https://api.openweathermap.org/data/3.0/onecall?lat=33.44&lon=-94.04&exclude=hourly,daily&appid=1',
            method: 'GET'
        } as AxiosRequestConfig)
    },
    Download: async (URL) => {
        return makeRequest({
            url: URL,
            method: 'GET',
            responseType: 'blob'
        } as AxiosRequestConfig)
    }
}

const Country = {
    GetCountry: async () => {
        return makeRequest({
            url: `/Country`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetCountryById: async (id: string) => {
        return makeRequest({
            url: `/Country/${id}`,
            method: 'GET'
        } as AxiosRequestConfig)
    }
}

const Event = {
    GetEvent: async () => {
        return makeRequest({
            url: `/Event`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    PostEvent: async (data: any) => {
        return makeRequest({
            url: `/Event`,
            method: 'POST',
            data: convertToFormData(data),
            timeout: API_REQUEST_TIMEOUT_FILE
        } as AxiosRequestConfig)
    },
    GetEventListLastN: async (query: { numOfRecords: number }) => {
        return makeRequest({
            url: `/Event/ListLastN`,
            method: 'GET',
            params: query
        } as AxiosRequestConfig)
    },
    GetEventListByPage: async (query: { numOfRecords: number, page: number, skillId: string }) => {
        // numOfRecords, page, skillId
        return makeRequest({
            url: `/Event/ListByPage`,
            method: 'GET',
            params: query
        } as AxiosRequestConfig)
    },
    GetEventApproved: async () => {
        return makeRequest({
            url: `/Event/Approved`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetEventUnapproved: async () => {
        return makeRequest({
            url: `/Event/Unapproved`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetEventByOrganizerId: async (providedById: string, query: { isFinished: boolean }) => {
        return makeRequest({
            url: `/Event/GetByOrganizer/${providedById}`,
            method: 'GET',
            params: query
        } as AxiosRequestConfig)
    },
    GetEventByApplicant: async (userId) => {
        return makeRequest({
            url: `/Event/GetByApplicant/${userId}`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetEventById: async (id: string) => {
        return makeRequest({
            url: `/Event/${id}`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    PutEventById: async (id: string, data: any) => {
        return makeRequest({
            url: `/Event/${id}`,
            method: 'PUT',
            data: data
        } as AxiosRequestConfig)
    },
    DeleteEventById: async (id: string) => {
        return makeRequest({
            url: `/Event/${id}`,
            method: 'DELETE'
        } as AxiosRequestConfig)
    },
    PutEventCancelByEventId: async (eventId: string) => {
        return makeRequest({
            url: `/Event/Cancel/${eventId}`,
            method: 'PUT'
        } as AxiosRequestConfig)
    },
    PostEventApprove: async (data: any) => {
        return makeRequest({
            url: `/Event/Approve`,
            method: 'POST',
            data: data
        } as AxiosRequestConfig)
    },
    PostEventReject: async (data: any) => {
        return makeRequest({
            url: `/Event/Reject`,
            method: 'POST',
            data: data
        } as AxiosRequestConfig)
    },
    PostEventApprovePayment: async (data: any) => {
        return makeRequest({
            url: `/Event/ApprovePayment`,
            method: 'POST',
            data: data
        } as AxiosRequestConfig)
    },
    GetEventSearchByKeyword: async (searchKeyword, query: { numOfRecords: number, page: number}) => {
        return makeRequest({

            url:`/Event/Search/${searchKeyword}`,
            method: 'GET',
            params: query
        })
    },
    GetEventSearchBarByKeyword: async (searchKeyword, query: { numOfRecords: number, page: number}) => {
        return makeRequest({
            url:`/Event/SearchBar/${searchKeyword}`,
            method: 'GET'
        })
    }
}

const EventApplication = {
    PostEventApplicationRegister: async (data: any) => {
        return makeRequest({
            url: `/EventApplication/Register`,
            method: 'POST',
            data: data
        } as AxiosRequestConfig)
    },
    GetEventApplication: async (eventId: string) => {
        return makeRequest({
            url: `/EventApplication/${eventId}`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    PostEventApplicationSetApplicantScore: async (data: any, query: { eventId: string, providerId: string }) => {
        return makeRequest({
            url: `/EventApplication/SetApplicantScore`,
            method: 'POST',
            data: data,
            params: query
        } as AxiosRequestConfig)
    },
}

const EventImage = {
    GetEventImageByEvent: async (eventId: string) => {
        return makeRequest({
            url: `/EventImage/GetByEvent/${eventId}`,
            method: 'GET'
        } as AxiosRequestConfig)
    }
}

const EventRequiredSkill = {
    GetEventRequiredSkillByEvent: async (eventId: string) => {
        return makeRequest({
            url: `/EventRequiredSkill/GetByEvent/${eventId}`,
            method: 'GET'
        } as AxiosRequestConfig)
    }
}

const EventReward = {
    GetEventRewardByEvent: async (eventId: string) => {
        return makeRequest({
            url: `/EventReward/GetByEvent/${eventId}`,
            method: 'GET'
        } as AxiosRequestConfig)
    }
}

const EventTag = {
    GetEventTag: async () => {
        return makeRequest({
            url: `/EventTag`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetEventTagById: async (id: string) => {
        return makeRequest({
            url: `/EventTag/${id}`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetEventTagByEvent: async (eventId: string) => {
        return makeRequest({
            url: `/EventTag/GetByEvent/${eventId}`,
            method: 'GET',
        } as AxiosRequestConfig)
    }
}

const Resource = {
    GetResource: async () => {
        return makeRequest({
            url: `/Resource`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    PostResource: async (data: any) => {
        return makeRequest({
            url: `/Resource`,
            method: 'POST',
            data: convertToFormData(data),
            timeout: API_REQUEST_TIMEOUT_FILE
        } as AxiosRequestConfig)
    },
    PutResource: async (id:string, data: any) => {
        return makeRequest({
            url: `/Resource/${id}`,
            method: 'PUT',
            //data: convertToFormData(data),
            data: data,
            timeout: API_REQUEST_TIMEOUT_FILE
        } as AxiosRequestConfig)
    },
    GetResourceApproved: async () => {
        return makeRequest({
            url: `/Resource/Approved`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetResourceUnapproved: async () => {
        return makeRequest({
            url: `/Resource/Unapproved`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetResourceById: async (id: string) => {
        return makeRequest({
            url: `/Resource/${id}`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    DeleteResource: async (id: string) => {
        return makeRequest({
            url: `/Resource/${id}`,
            method: 'DELETE'
        } as AxiosRequestConfig)
    },
    PostResourceApprove: async (data: any) => {
        return makeRequest({
            url: `/Resource/Approve`,
            method: 'POST',
            data: data
        } as AxiosRequestConfig)
    },
    PostResourceReject: async (data: any) => {
        return makeRequest({
            url: `/Resource/Reject`,
            method: 'POST',
            data: data
        } as AxiosRequestConfig)
    },
    GetResourceListLastN: async (query: { numOfRecords: number }) => {
        return makeRequest({
            url: `/Resource/ListLastN`,
            method: 'GET',
            params: query
        } as AxiosRequestConfig)
    },
    GetResourceListByPage: async (query: { numOfRecords: number, page: number }) => {
        return makeRequest({
            url: `/Resource/ListByPage`,
            method: 'GET',
            params: query
        } as AxiosRequestConfig)
    },
    GetResourceByUser: async (userId: string, query: {numOfRecords: number, page: number}) => {
        return makeRequest({
            url: `/Resource/GetByUser/${userId}`,
            method: 'GET',
            params: query
        } as AxiosRequestConfig)
    },
    GetResourceSearchByKeyword: async (searchKeyword, query: { numOfRecords: number, page: number}) => {
        return makeRequest({
            url:`/Resource/Search/${searchKeyword}`,
            method: 'GET',
            params: query
        })
    },
    GetResourceSearchBarByKeyword: async (searchKeyword) => {
        return makeRequest({
            url:`/Resource/SearchBar/${searchKeyword}`,
            method: 'GET'
        })
    }
}

const ResourceFile = {
    GetResourceFileByResource: async (resourceId: string) => {
        return makeRequest({
            url: `/ResourceFile/GetByResource/${resourceId}`,
            method: 'GET'
        } as AxiosRequestConfig)
    }
}

const ResourceLike = {
    PostResourceLike: async (data: any) => {
        return makeRequestWithOutToast({
            url: `/ResourceLike/Like`,
            method: 'POST',
            data: data
        } as AxiosRequestConfig)
    },
    PostResourceDislike: async (data: any) => {
        return makeRequestWithOutToast({
            url: `/ResourceLike/Dislike`,
            method: 'POST',
            data: data
        } as AxiosRequestConfig)
    },
    GetResourceLikeByResource: async (resourceId: string, query: {userId: string}) => {
        return makeRequest({
            url: `/ResourceLike/${resourceId}`,
            method: 'GET',
            params: query
        } as AxiosRequestConfig)
    }
}

const ResourceTag = {
    GetResourceTag: async () => {
        return makeRequest({
            url: `/ResourceTag`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetResourceTagById: async (id: string) => {
        return makeRequest({
            url: `/ResourceTag/${id}`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetResourceTagByResource: async (resourceId: string) => {
        return makeRequest({
            url: `/ResourceTag/GetByResource/${resourceId}`,
            method: 'GET',
        } as AxiosRequestConfig)
    }
}

const ResourceType = {
    GetResourceType: async () => {
        return makeRequest({
            url: `/ResourceType`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetResourceTypeById: async (id: number) => {
        return makeRequest({
            url: `/ResourceType/${id}`,
            method: 'GET'
        } as AxiosRequestConfig)
    }
}

const Search = {
    GetSearchByKeyword: async (searchKeyword: string) => {
        return makeRequest({
            url:`/Search/${searchKeyword}`,
            method: 'GET'
        })
    },
    GetSearchBarByKeyword: async (searchKeyword: string) => {
        return makeRequest({
            url:`/Search/SearchBar/${searchKeyword}`,
            method: 'GET'
        })
    },
    GetSearchMapByKeyword: async (searchKeyword: string) => {
        return makeRequest({
            url:`/Search/SearchMap/${searchKeyword}`,
            method: 'GET'
        })
    },
    GetSearchMapAllResults: async () => {
        return makeRequest({
            url:`/Search/SearchMapAllResults`,
            method: 'GET'
        })
    }
}

const Skill = {
    GetSkill: async () => {
        return makeRequest({
            url: `/Skill`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    PostSkill: async (data: any) => {
        return makeRequest({
            url: `/Skill`,
            method: 'POST',
            data: convertToFormData(data),
            timeout: API_REQUEST_TIMEOUT_FILE
        } as AxiosRequestConfig)
    },
    GetSkillApproved: async () => {
        return makeRequest({
            url: `/Skill/Approved`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetSkillUnapproved: async () => {
        return makeRequest({
            url: `/Skill/Unapproved`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetSkillById: async (id: string) => {
        return makeRequest({
            url: `/Skill/${id}`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    PutSkill: async (id: string, data: any) => {
        return makeRequest({
            url: `/Skill/${id}`,
            method: 'PUT',
            data: data
        } as AxiosRequestConfig)
    },
    DeleteSkill: async (id: string) => {
        return makeRequest({
            url: `/Skill/${id}`,
            method: 'DELETE'
        } as AxiosRequestConfig)
    },
    PostSkillApprove: async (data: any) => {
        return makeRequest({
            url: `/Skill/Approve`,
            method: 'POST',
            data: data
        } as AxiosRequestConfig)
    },
    PostSkillReject: async (data: any) => {
        return makeRequest({
            url: `/Skill/Reject`,
            method: 'POST',
            data: data
        } as AxiosRequestConfig)
    },
    GetSkillListLastN: async (query: { numOfRecords: number }) => {
        // numOfRecords
        return makeRequest({
            url: `/Skill/ListLastN`,
            method: 'GET',
            params: query
        } as AxiosRequestConfig)
    },
    GetSkillListByPage: async (query: { numOfRecords: number, page: number }) => {
        // numOfRecords
        return makeRequest({
            url: `/Skill/ListByPage`,
            method: 'GET',
            params: query
        } as AxiosRequestConfig)
    },
    GetSkillSearch: async (keyword: string) => {
        return makeRequest({
            url: `/Skill/Search/${keyword}`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetSkillPrerequisitesById: async (skillId: string) => {
        return makeRequest({
            url: `/Skill/GetPrerequisites/${skillId}`,
            method: 'GET'
        } as AxiosRequestConfig)
    }
}

const StakeholderGroup = {
    GetStakeholderGroup: async () => {
        return makeRequest({
            url: `/StakeholderGroup`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    PostStakeholderGroup: async (data: any) => {
        return makeRequest({
            url: `/StakeholderGroup`,
            method: 'POST',
            data: data
        } as AxiosRequestConfig)
    },
    GetStakeholderGroupById: async (id: string) => {
        return makeRequest({
            url: `/StakeholderGroup/${id}`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    PutStakeholderGroup: async (id: string, data: any) => {
        return makeRequest({
            url: `/StakeholderGroup/${id}`,
            method: 'PUT',
            data: data
        } as AxiosRequestConfig)
    },
    DeleteStakeholderGroup: async (id: string) => {
        return makeRequest({
            url: `/StakeholderGroup/${id}`,
            method: 'DELETE'
        } as AxiosRequestConfig)
    }
}

const StakeholderGroupUser = {
    GetStakeholderGroupUser: async () => {
        return makeRequest({
            url: `/StakeholderGroupUser`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    PostStakeholderGroupUser: async (data: any) => {
        return makeRequest({
            url: `/StakeholderGroupUser`,
            method: 'POST',
            data: data
        } as AxiosRequestConfig)
    },
    GetStakeholderGroupUserById: async (id: string) => {
        return makeRequest({
            url: `/StakeholderGroupUser/${id}`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    PutStakeholderGroupUser: async (id: string, data: any) => {
        return makeRequest({
            url: `/StakeholderGroupUser/${id}`,
            method: 'PUT',
            data: data
        } as AxiosRequestConfig)
    },
    DeleteStakeholderGroupUser: async (id: string) => {
        return makeRequest({
            url: `/StakeholderGroupUser/${id}`,
            method: 'DELETE'
        } as AxiosRequestConfig)
    }
}

const StakeholderGroupUserType = {
    GetStakeholderGroupUserType: async (id: string) => {
        return makeRequest({
            url: `/StakeholderGroupUserType`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetStakeholderGroupUserTypeById: async (id: string) => {
        return makeRequest({
            url: `/StakeholderGroupUserType/${id}`,
            method: 'GET'
        } as AxiosRequestConfig)
    }
}

const Tag = {
    GetTag: async () => {
        return makeRequest({
            url: `/Tag`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetTagById: async (id: string) => {
        return makeRequest({
            url: `/Tag/${id}`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetTagSearch: async (keyword: string) => {
        return makeRequest({
            url: `/Tag/Search/${keyword}`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetTagSearchWithType: async (tagTypeId: string, keyword: string) => {
        return makeRequest({
            url: `/Tag/SearchWithType/${tagTypeId}/${keyword}`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
}

const TagType = {
    GetTagType: async () => {
        return makeRequest({
            url: `/TagType`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetTagTypeById: async (id: string) => {
        return makeRequest({
            url: `/TagType/${id}`,
            method: 'GET'
        } as AxiosRequestConfig)
    }
}

const Training = {
    GetTraining: async () => {
        return makeRequest({
            url: `/Training`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    PostTraining: async (data: any) => {
        return makeRequest({
            url: `/Training`,
            method: 'POST',
            data: convertToFormData(data),
            timeout: API_REQUEST_TIMEOUT_FILE
        } as AxiosRequestConfig)
    },
    GetTrainingListLastN: async (query: { numOfRecords: number }) => {
        // numOfRecords
        return makeRequest({
            url: `/Training/ListLastN`,
            method: 'GET',
            params: query
        } as AxiosRequestConfig)
    },
    GetTrainingListByPage: async (query: { numOfRecords: number, page: number, skillId: string }) => {
        // numOfRecords, page, skillId
        return makeRequest({
            url: `/Training/ListByPage`,
            method: 'GET',
            params: query
        } as AxiosRequestConfig)
    },
    GetTrainingApproved: async () => {
        return makeRequest({
            url: `/Training/Approved`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetTrainingUnapproved: async () => {
        return makeRequest({
            url: `/Training/Unapproved`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetTrainingByTrainerId: async (trainerId: string, query: {isFinished: boolean}) => {
        return makeRequest({
            url: `/Training/GetByTrainer/${trainerId}`,
            method: 'GET',
            params: query
        } as AxiosRequestConfig)
    },
    GetTrainingByApplicant: async (userId) => {
        return makeRequest({
            url: `/Training/GetByApplicant/${userId}`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetTrainingById: async (id: string) => {
        return makeRequest({
            url: `/Training/${id}`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    PutTrainingById: async (id: string, data: any) => {
        return makeRequest({
            url: `/Training/${id}`,
            method: 'PUT',
            data: data
        } as AxiosRequestConfig)
    },
    DeleteTrainingById: async (id: string) => {
        return makeRequest({
            url: `/Training/${id}`,
            method: 'DELETE'
        } as AxiosRequestConfig)
    },
    PutTrainingCancelByEventId: async (trainerId: string) => {
        return makeRequest({
            url: `/Training/Cancel/${trainerId}`,
            method: 'PUT'
        } as AxiosRequestConfig)
    },
    PostTrainingApprove: async (data: any) => {
        return makeRequest({
            url: `/Training/Approve`,
            method: 'POST',
            data: data
        } as AxiosRequestConfig)
    },
    PostTrainingReject: async (data: any) => {
        return makeRequest({
            url: `/Training/Reject`,
            method: 'POST',
            data: data
        } as AxiosRequestConfig)
    },
    PostTrainingApprovePayment: async (data: any) => {
        return makeRequest({
            url: `/Training/ApprovePayment`,
            method: 'POST',
            data: data
        } as AxiosRequestConfig)
    },
    GetTrainingSearchByKeyword: async (searchKeyword, query: { numOfRecords: number, page: number}) => {
        return makeRequest({
            url:`/Training/Search/${searchKeyword}`,
            method: 'GET',
            params: query
        })
    },
    GetTrainingSearchBarByKeyword: async (searchKeyword) => {
        return makeRequest({
            url:`/Training/SearchBar/${searchKeyword}`,
            method: 'GET'
        })
    }
}

const TrainingApplication = {
    PostTrainingApplicationEnroll: async (data: any) => {
        return makeRequest({
            url: `/TrainingApplication/Enroll`,
            method: 'POST',
            data: data
        } as AxiosRequestConfig)
    },
    GetTrainingApplication: async (trainingId: string) => {
        return makeRequest({
            url: `/TrainingApplication/${trainingId}`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    PostTrainingApplicationSetApplicantScore: async (data: any, query: { trainingId: string, trainerId: string }) => {
        return makeRequest({
            url: `/TrainingApplication/SetApplicantScore`,
            method: 'POST',
            data: data,
            params: query
        } as AxiosRequestConfig)
    },
}

const TrainingImage = {
    GetTrainingImageByTrainingId: async (trainingId: string) => {
        return makeRequest({
            url: `/TrainingImage/GetByTraining/${trainingId}`,
            method: 'GET'
        } as AxiosRequestConfig)
    }
}

const TrainingPrerequisite = {
    GetTrainingPrerequisiteByTrainingId: async (trainingId: string) => {
        return makeRequest({
            url: `/TrainingPrerequisite/GetByTraining/${trainingId}`,
            method: 'GET'
        } as AxiosRequestConfig)
    }
}

const TrainingReward = {
    GetTrainingRewardByTrainingId: async (trainingId: string) => {
        return makeRequest({
            url: `/TrainingReward/GetByTraining/${trainingId}`,
            method: 'GET'
        } as AxiosRequestConfig)
    }
}

const TrainingTag = {
    GetTrainingTag: async () => {
        return makeRequest({
            url: `/TrainingTag`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetTrainingTagById: async (id: string) => {
        return makeRequest({
            url: `/TrainingTag/${id}`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetTrainingTagByTraining: async (trainingId: string) => {
        return makeRequest({
            url: `/TrainingTag/GetByTraining/${trainingId}`,
            method: 'GET',
        } as AxiosRequestConfig)
    }
}

const User = {
    GetUserById: async (id: string) => {
        return makeRequest({
            url: `/User/${id}`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    PutUser: async (id: string, data: any) => {
        return makeRequest({
            url: `/User/${id}`,
            method: 'PUT',
            data: convertToFormData(data),
            timeout: API_REQUEST_TIMEOUT_FILE
        } as AxiosRequestConfig)
    },
    DeleteUser: async (id: string) => {
        return makeRequest({
            url: `/User/${id}`,
            method: 'DELETE'
        } as AxiosRequestConfig)
    },
    GetUserByUsername: async (username: string) => {
        return makeRequest({
            url: `/User/GetByUsername/${username}`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    PostUser: async (data: any) => {
        return makeRequest({
            url: `/User`,
            method: 'POST',
            data: data
        } as AxiosRequestConfig)
    },
    PostUserAuthenticate: async (data: any) => {
        return makeRequestWithOutToast({
            url: `/User/Authenticate`,
            method: 'POST',
            data: data
        } as AxiosRequestConfig)
    },
    // TODO - Fix URL
    PostUserResetPassword: async (data: any) => {
        return makeRequestWithOutToast({
            url: `/User/USERUSERUSER`,
            method: 'POST',
            data: data
        } as AxiosRequestConfig)
    },
    //{id: string, refreshToken: string}
    PostUseRefresh: async (data) => {
        return makeRequestForToken({
            url: `/User/Refresh`,
            method: 'POST',
            data: data
        } as AxiosRequestConfig)
    },
    PostUserSetPremium: async (data: any) => {
        return makeRequest({
            url: `/User/SetPremium`,
            method: 'POST',
            data: data
        } as AxiosRequestConfig)
    },
    PostUserFollow: async (data: any) => {
        return makeRequestWithOutToast({
            url: `/User/Follow`,
            method: 'POST',
            data: data
        } as AxiosRequestConfig)
    },
    PostUserUnfollow: async (data: any) => {
        return makeRequestWithOutToast({
            url: `/User/Unfollow`,
            method: 'POST',
            data: data
        } as AxiosRequestConfig)
    },
    PostUserFollowers: async (data: any, query: { numOfRecords: number, page: number }) => {
        return makeRequestWithOutToast({
            url: `/User/Followers`,
            method: 'POST',
            data: data,
            params: query
        } as AxiosRequestConfig)
    },
    PostUserFollowing: async (data: any, query: { numOfRecords: number, page: number }) => {
        return makeRequestWithOutToast({
            url: `/User/Following`,
            method: 'POST',
            data: data,
            params: query
        } as AxiosRequestConfig)
    },
    PutUserUpdatePassword: async (id: string, data: any) => {
        return makeRequest({
            url: `/User/UpdatePassword/${id}`,
            method: 'PUT',
            data: data
        } as AxiosRequestConfig)
    },
    GetUserCheckUnameAvailability: async (username: string) => {
        return makeRequest({
            url: `/User/CheckUsernameAvailability/${username}`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    PutUserClaimUsername: async (username: string, data: any) => {
        return makeRequest({
            url: `/User/ClaimUsername/${username}`,
            method: 'PUT',
            data: data
        } as AxiosRequestConfig)
    },
    PutUserUpdateInterests: async (userId: string, data: any) => {
        return makeRequest({
            url: `/User/UpdateInterests/${userId}`,
            method: 'PUT',
            data: data
        } as AxiosRequestConfig)
    },
    PutUserUpdateCoordinates: async (userId: string, data: any) => {
        return makeRequest({
            url: `/User/UpdateCoordinates/${userId}`,
            method: 'PUT',
            data: data
        } as AxiosRequestConfig)
    },
    PostUserFollowerFollowingCount: async (query: {userId: any}) => {
        return makeRequestWithOutToast({
            url: `/User/FollowerFollowingCount`,
            method: 'POST',
            params: query
        } as AxiosRequestConfig)
    },
    PostUserCheckFollowing: async (data: {loggedInUser: string, followedUser: string}) => {
        return makeRequestWithOutToast({
            url: `/User/CheckUserFollowing`,
            method: 'POST',
            data: data
        } as AxiosRequestConfig)
    },
    PutUserUpdateProfilePhoto: async (id: string, data: any) => {
        return makeRequest({
            url: `/User/UpdateProfilePhoto/${id}`,
            method: 'PUT',
            data: convertToFormData(data),
            timeout: API_REQUEST_TIMEOUT_FILE
        } as AxiosRequestConfig)
    },
    PutUserUpdateCoverImage: async (id: string, data: any) => {
        return makeRequest({
            url: `/User/UpdateCoverImage/${id}`,
            method: 'PUT',
            data: convertToFormData(data),
            timeout: API_REQUEST_TIMEOUT_FILE
        } as AxiosRequestConfig)
    },
    DeleteUserProfilePhoto: async (userId: string) => {
        return makeRequest({
            url: `/User/DeleteProfilePhoto/${userId}`,
            method: 'DELETE',
            timeout: API_REQUEST_TIMEOUT_FILE
        } as AxiosRequestConfig)
    },
    DeleteUserCoverImage: async (userId: string) => {
        return makeRequest({
            url: `/User/DeleteCoverImage/${userId}`,
            method: 'DELETE',
            timeout: API_REQUEST_TIMEOUT_FILE
        } as AxiosRequestConfig)
    },
    PostUserUploadFile: async (userId: string, data: any) => {
        return makeRequest({
            url: `/User/UploadFile/${userId}`,
            method: 'POST',
            data: convertToFormData(data),
            timeout: API_REQUEST_TIMEOUT_FILE
        } as AxiosRequestConfig)
    },
    DeleteUserFile: async (userFileId: string) => {
        return makeRequest({
            url: `/User/DeleteFile/${userFileId}`,
            method: 'DELETE',
            timeout: API_REQUEST_TIMEOUT_FILE
        } as AxiosRequestConfig)
    },
    GetUserSearchByKeyword: async (searchKeyword, query: {numOfRecords: number, page: number, isAllResults:boolean}) => {
        return makeRequest({
            url:`/User/Search/${searchKeyword}`,
            method: 'GET',
            params: query
        } as AxiosRequestConfig)
    },
    PostUserGetFollowDetails: async (userId: string, data: any) => {
        return makeRequestWithOutToast({
            url: `/User/GetFollowDetails/${userId}`,
            method: 'POST',
            data: data
        } as AxiosRequestConfig)
    },
    GetUserSearchBarByKeyword: async (searchKeyword) => {
        return makeRequest({
            url:`/User/SearchBar/${searchKeyword}`,
            method: 'GET'
        })
    }
}

const UserCoordinate = {
    GetUserCoordinateById: async (id: string) => {
        return makeRequest({
            url: `/UserCoordinate/${id}`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetUserCoordinateByUserId: async (userId: string) => {
        return makeRequest({
            url: `/UserCoordinate/GetByUser/${userId}`,
            method: 'GET'
        } as AxiosRequestConfig)
    }
}

const UserFile = {
    GetUserFileByUserId: async (userId: string) => {
        return makeRequest({
            url: `/UserFile/GetByUser/${userId}`,
            method: 'GET'
        } as AxiosRequestConfig)
    }
}

const UserInterest = {
    GetUserInterestById: async (id: string) => {
        return makeRequest({
            url: `/UserInterest/${id}`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetUserInterestByUserId: async (userId: string) => {
        return makeRequest({
            url: `/UserInterest/GetByUser/${userId}`,
            method: 'GET'
        } as AxiosRequestConfig)
    }
}

const UserSkill = {
    GetUserSkill: async () => {
        return makeRequest({
            url: `/UserSkill`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    PostUserSkill: async (data: any) => {
        return makeRequest({
            url: `/UserSkill`,
            method: 'POST',
            data: convertToFormData(data),
            timeout: API_REQUEST_TIMEOUT_FILE
        } as AxiosRequestConfig)
    },
    GetUserSkillApproved: async () => {
        return makeRequest({
            url: `/UserSkill/Approved`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetUserSkillUnapproved: async () => {
        return makeRequest({
            url: `/UserSkill/Unapproved`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetUserSkillById: async (id: string) => {
        return makeRequest({
            url: `/UserSkill/${id}`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    DeleteUserSkill: async (id: string) => {
        return makeRequest({
            url: `/UserSkill/${id}`,
            method: 'DELETE'
        } as AxiosRequestConfig)
    },
    GetUserSkillByUserId: async (userId: string) => {
        return makeRequest({
            url: `/UserSkill/GetByUser/${userId}`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetUserSkillTrainerPermission: async (query: { trainerId: string, skillId: string }) => {
        return makeRequest({
            url: `/UserSkill/TrainerPermission`,
            method: 'GET',
            params: query
        } as AxiosRequestConfig)
    },
    PostUserSkillApprove: async (data: any) => {
        return makeRequest({
            url: `/UserSkill/Approve`,
            method: 'POST',
            data: data
        } as AxiosRequestConfig)
    },
    PostUserSkillReject: async (data: any) => {
        return makeRequest({
            url: `/UserSkill/Reject`,
            method: 'POST',
            data: data
        } as AxiosRequestConfig)
    },
}

const UserSkillCertificate = {
    GetUserSkillCertificateGetByUserSkill: async (userSkillId: string) => {
        return makeRequest({
            url: `/UserSkillCertificate/GetByUserSkill/${userSkillId}`,
            method: 'GET'
        } as AxiosRequestConfig)
    }
}

const UserSkillType = {
    GetUserSkillType: async () => {
        return makeRequest({
            url: `/UserSkillType`,
            method: 'GET'
        } as AxiosRequestConfig)
    }
}

const WOApplication = {
    PostWOApplicationApply: async (data: any) => {
        return makeRequest({
            url: `/WOApplication/Apply`,
            method: 'POST',
            data: convertToFormData(data),
            timeout: API_REQUEST_TIMEOUT_FILE
        } as AxiosRequestConfig)
    },
    GetWOApplicationByWOId: async (workOpportunityId: string) => {
        return makeRequest({
            url: `/WOApplication/${workOpportunityId}`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    PostWOApplicationSetApplicantSelection: async (data: any, query: {workOpportunityId: string, providerId: string}) => {
        return makeRequest({
            url: `/WOApplication/SetApplicantSelection`,
            method: 'POST',
            data: data,
            params: query
        } as AxiosRequestConfig)
    },
}

const WOApplicationFile = {
    GetWOApplicationFileByWOApplication: async (woApplicationId: string) => {
        return makeRequest({
            url: `/WOApplicationFile/GetByWOApplication/${woApplicationId}`,
            method: 'GET'
        } as AxiosRequestConfig)
    }
}

const WOFile = {
    GetWOFileByWOId: async (workOpportunityId: string) => {
        return makeRequest({
            url: `/WOFile/GetByWorkOpportunity/${workOpportunityId}`,
            method: 'GET'
        } as AxiosRequestConfig)
    }
}

const WOImage = {
    GetWOImageByWOId: async (workOpportunityId: string) => {
        return makeRequest({
            url: `/WOImage/GetByWorkOpportunity/${workOpportunityId}`,
            method: 'GET'
        } as AxiosRequestConfig)
    }
}

const WORemoteOnsite = {
    GetWORemoteOnsite: async () => {
        return makeRequest({
            url: `/WORemoteOnsite`,
            method: 'GET'
        } as AxiosRequestConfig)
    }
}

const WORequiredSkill = {
    GetWoRequiredSkillByWOId: async (workOpportunityId: string) => {
        return makeRequest({
            url: `/WORequiredSkill/GetByWorkOpportunity/${workOpportunityId}`,
            method: 'GET'
        } as AxiosRequestConfig)
    }
}

const WorkOpportunity = {
    GetWO: async () => {
        return makeRequest({
            url: `/WorkOpportunity`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    PostWO: async (data: any) => {
        return makeRequest({
            url: `/WorkOpportunity`,
            method: 'POST',
            data: convertToFormData(data),
            timeout: API_REQUEST_TIMEOUT_FILE
        } as AxiosRequestConfig)
    },
    GetWOListLastN: async (query: { numOfRecords: number}) => {
        return makeRequest({
            url: `/WorkOpportunity/ListLastN`,
            method: 'GET',
            params: query
        } as AxiosRequestConfig)
    },
    GetWOListByPage: async (query: { numOfRecords: number, page: number, skillId: string }) => {
        return makeRequest({
            url: `/WorkOpportunity/ListByPage`,
            method: 'GET',
            params: query
        } as AxiosRequestConfig)
    },
    GetWOApproved: async () => {
        return makeRequest({
            url: `/WorkOpportunity/Approved`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetWOUnapproved: async () => {
        return makeRequest({
            url: `/WorkOpportunity/Unapproved`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetWOByProviderId: async (providerId: string, query: {isFinished: boolean}) => {
        return makeRequest({
            url: `/WorkOpportunity/GetByProvider/${providerId}`,
            method: 'GET',
            params: query
        } as AxiosRequestConfig)
    },
    GetWOByApplicant: async (userId) => {
        return makeRequest({
            url: `/WorkOpportunity/GetByApplicant/${userId}`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetWOById: async (id: string) => {
        return makeRequest({
            url: `/WorkOpportunity/${id}`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    PutWOById: async (id: string, data: any) => {
        return makeRequest({
            url: `/WorkOpportunity/${id}`,
            method: 'PUT',
            data: data
        } as AxiosRequestConfig)
    },
    DeleteWOById: async (id: string) => {
        return makeRequest({
            url: `/WorkOpportunity/${id}`,
            method: 'DELETE'
        } as AxiosRequestConfig)
    },
    PutWOCancelByEventId: async (id: string) => {
        return makeRequest({
            url: `/WorkOpportunity/Cancel/${id}`,
            method: 'PUT'
        } as AxiosRequestConfig)
    },
    PostWOApprove: async (data: any) => {
        return makeRequest({
            url: `/WorkOpportunity/Approve`,
            method: 'POST',
            data: data
        } as AxiosRequestConfig)
    },
    PostWOReject: async (data: any) => {
        return makeRequest({
            url: `/WorkOpportunity/Reject`,
            method: 'POST',
            data: data
        } as AxiosRequestConfig)
    },
    GetWOSearchByKeyword: async (searchKeyword, query: { numOfRecords: number, page: number}) => {
        return makeRequest({
            url:`/WorkOpportunity/Search/${searchKeyword}`,
            method: 'GET',
            params: query
        })
    },
    GetWOSearchBarByKeyword: async (searchKeyword) => {
        return makeRequest({
            url:`/WorkOpportunity/SearchBar/${searchKeyword}`,
            method: 'GET'
        })
    }
}

const WorkOpportunityTag = {
    GetWOTag: async () => {
        return makeRequest({
            url: `/WorkOpportunityTag`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetWOTagById: async (id: string) => {
        return makeRequest({
            url: `/WorkOpportunityTag/${id}`,
            method: 'GET'
        } as AxiosRequestConfig)
    },
    GetWOTagByWOId: async (woId: string) => {
        return makeRequest({
            url: `/WorkOpportunityTag/GetByWorkOpportunity/${woId}`,
            method: 'GET'
        } as AxiosRequestConfig)
    }
}

const WOTime = {
    GetWOTime: async () => {
        return makeRequest({
            url: `/WOTime`,
            method: 'GET'
        } as AxiosRequestConfig)
    }
}

const WOType = {
    GetWOType: async () => {
        return makeRequest({
            url: `/WOType`,
            method: 'GET'
        } as AxiosRequestConfig)
    }
}

const Timeline = {
    GetByOwnerId: async (ownerId: string, query: { numOfRecords: number, page: number }) => {
        return makeRequest({
            url: `/Timeline/GetByOwnerId/${ownerId}`,
            method: 'GET',
            params: query
        } as AxiosRequestConfig)
    },
    GetByOwnerIdSelf: async (ownerId: string, query: { numOfRecords: number, page: number }) => {
        return makeRequest({
            url: `/Timeline/GetByOwnerIdSelf/${ownerId}`,
            method: 'GET',
            params: query
        } as AxiosRequestConfig)
    }
}

const ApiService = {
    Country,
    Event,
    EventApplication,
    EventImage,
    EventRequiredSkill,
    EventReward,
    EventTag,
    Resource,
    ResourceFile,
    ResourceLike,
    ResourceTag,
    ResourceType,
    Search,
    Skill,
    StakeholderGroup,
    StakeholderGroupUser,
    StakeholderGroupUserType,
    Tag,
    TagType,
    Training,
    TrainingApplication,
    TrainingImage,
    TrainingPrerequisite,
    TrainingReward,
    TrainingTag,
    User,
    UserCoordinate,
    UserFile,
    UserInterest,
    UserSkill,
    UserSkillCertificate,
    UserSkillType,
    WOApplication,
    WOApplicationFile,
    WOFile,
    WOImage,
    WORemoteOnsite,
    WORequiredSkill,
    WorkOpportunity,
    WorkOpportunityTag,
    WOTime,
    WOType,
    Test,
    Timeline
}

export default ApiService;