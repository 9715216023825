import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../interfaces/page-data';
import {Button, Card, Col, Modal, Popconfirm, Row, Space, Table} from "antd";
import {CheckOutlined, DeleteOutlined, EyeOutlined, LeftOutlined, PlusOutlined} from "@ant-design/icons";
import toast from "react-hot-toast";
import {history} from "../../redux/store";
import {IResource, IResourceType} from "../../interfaces/api";
import ResponsiveButton from "../../components/ResponsiveButton/ResponsiveButton";
import ApiService from "../../utils/services/api.service";


const ResourcesAdmin: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'Resources',
        loaded: true
    };

    const [isApproved, setApproved] = useState('approved');
    const [isLoadResource, setLoadResource] = useState<boolean>(false);
    const [resources, setResources] = useState<Array<IResource>>([]);
    const [resourceTypes, setResourceTypes] = useState<Array<IResourceType>>([]);

    useEffect(() => {
        onSetPage(pageData)
        setLoadResource(true)
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(!isLoadResource)
            return;
        any().then(null);
        setLoadResource(true);
    },[isLoadResource])

    const any = async () => {
        let response = isApproved === 'approved' ? await ApiService.Resource.GetResourceApproved() : await ApiService.Resource.GetResourceUnapproved();
        if (response.status === 200){
            setResources(response.data)
        }

        let typesResponse = await ApiService.ResourceType.GetResourceType();
        setResourceTypes(typesResponse.data);

        setLoadResource(false);
    }

    const goDetailOrApprove = (type: string, rowData: IResource) => {
        history.push({
            pathname: `resources/${type}/${rowData.id}`
        });
    }

    const deleteResource = async (rowData: IResource) => {
        Modal.confirm({
            closable: false,
            title: 'Are you sure to delete?',
            okText: 'Confirm',
            cancelText: 'Cancel',
            okButtonProps: {
                className: 'responsive-button',
                icon: <CheckOutlined/>
            },
            cancelButtonProps: {
                className: 'responsive-button',
                icon: <LeftOutlined/>,
                danger: true
            },
            onOk: async () => {
                let response = await ApiService.Resource.DeleteResource(rowData.id);
                if (response.status === 200){
                    setLoadResource(true)
                }
            },
            onCancel: async () => {
              return;
            }
        });
    }

    const columns: Array<any> = [
        {
            title: "Title",
            dataIndex: "title",
            ellipsis: false,
        },
        // {
        //     title: "Description",
        //     dataIndex: "description",
        //     ellipsis: false,
        // },
        {
            title: "Type",
            dataIndex: "resourceTypeId",
            ellipsis: false,
            render: (col: number, row: IResource) => {
                let t: IResourceType = resourceTypes && resourceTypes.find(x => x.id === col);
                return (
                    t && <span key={t.id}>{t.resourceTypeName}</span>
                )
            }
        },
        // {
        //     title: "Resource",
        //     dataIndex: "uploadedFileURI",
        //     ellipsis: false,
        //     // render: (col:string) => {
        //     //     return (S3_URL+col)
        //     // }
        // },
        {
            title: "",
            ellipsis: false,
            render: (data, rowData: IResource) => {
                return (
                    <div style={{textAlign: 'center'}}>
                        <Space size='small'>
                            <Button icon={<EyeOutlined/>} size='small' shape='circle'
                                    onClick={() => goDetailOrApprove('detail', rowData)}/>
                            {!rowData.isApproved &&
                                <Button size='small'
                                        onClick={() => goDetailOrApprove('approve', rowData)}>Approve</Button>}
                                <Button icon={<DeleteOutlined/>} size='small' shape='circle' danger onClick={() => {deleteResource(rowData)}}></Button>
                        </Space>
                    </div>
                )
            }
        }
    ]

    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <Card title={
                        <Row gutter={[10, 10]}>
                            <Col xs={{span: 24}} md={{span: 8}}>
                                <span className='card-title-page'>Open Stage</span>
                            </Col>
                            <Col xs={{span: 19}} sm={{span: 21}} md={{span: 6, offset: 8}} lg={{span: 6, offset: 4}}
                                 xl={{span: 4, offset: 7}} xxl={{span: 3, offset: 10}}>
                                {/*<Select onChange={(e) => {*/}
                                {/*    setApproved(e);*/}
                                {/*    setLoadResource(true);*/}
                                {/*}} defaultValue={isApproved}>*/}
                                {/*    <Select.Option key='1' value='approved'>Approved</Select.Option>*/}
                                {/*    <Select.Option key='2' value='unapproved'>Unapproved</Select.Option>*/}
                                {/*</Select>*/}
                                <></>
                            </Col>
                            <Col xs={{span: 5}} sm={{span: 3}} md={{span: 2}} lg={{span: 6}} xl={{span: 5}}
                                 xxl={{span: 3}} style={{textAlign: 'right'}}>
                                <ResponsiveButton
                                    text='Share Your Voice'
                                    icon={<PlusOutlined/>}
                                    onClick={() => {
                                        history.push('/vertical/resources/add')
                                    }}/>
                            </Col>
                        </Row>
                    }>
                        <Table
                            rowKey={(x) => x.id}
                            dataSource={resources}
                            columns={columns}
                            loading={isLoadResource}
                        />
                    </Card>
                </div>
            </div>
        </>
    )
};

export default ResourcesAdmin;
