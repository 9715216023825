import React, {useEffect, useState} from 'react';
import ReactPlayer from "react-player";

interface AudioPlayerProps {
    url: string
}

const AudioPlayer: React.FC<AudioPlayerProps> = props => {
    // const [url, setUrl] = useState<string>(null);
    //
    // useEffect(() => {
    //     setUrl(props.url);
    // }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <ReactPlayer
                url={props.url}
                width='100%'
                height='80px'
                //playing={playing}
                // muted={muted}
                //volume={volume}
                //playing={false}
                controls={true} // linkten gelen default oynatici
                //onProgress={(state) => handleProgress(state)}
            />
        </div>
    );
};

AudioPlayer.defaultProps = {
    url: ''
}

export default AudioPlayer;
