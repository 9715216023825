import React from 'react';
import {IWorkOpportunity, IUser} from "../../interfaces/api";
import {Card, Col, Row, Tooltip} from "antd";
import {history} from "../../redux/store";
import blankImg from "../../assets/img/blank/blank-image.png";
import {DATE_FORMAT, S3_SERVER_URL} from "../../utils/Constants";
import moment from "moment";
import './WOHorizontalCard.scss';
import ImgViewer from "../ImgViewer/ImgViewer";

interface Props {
    wo: IWorkOpportunity
}

const WOHorizontalCard: React.FC<Props> = props => {
    const wo = props.wo;
    const provider = props.wo.providedBy;
    return (
        <div className='training-horizontal-card'>
            <Row className='training-quick-card'>
                <Col xxl={{span:6}} lg={{span:8}} xl={{span: 8}} md={{span:8}} sm={{span:24}} xs={{span:24}} className='training-info training-img'>
                    <ImgViewer
                        src={S3_SERVER_URL + wo.uploadedImage}
                        onClick={() => {history.push(`/vertical/trainings/enroll/${wo.id}`);}}
                        ratio={2}
                        width={'100%'}
                    />
                </Col>
                <Col xxl={{span: 16}} lg={{span: 13}} xl={{span:14}} md={{span:14}} sm={{span:21}} xs={{span:20}} className='training-info'>

                    <div style={{fontWeight:'600', fontSize:'18px', cursor:'pointer'}}
                         onClick={() => {history.push(`/vertical/trainings/enroll/${wo.id}`);}}
                    >{wo.title}</div>
                    <div className='sub-desc' style={{padding:'2px'}}>
                        <div dangerouslySetInnerHTML={{__html: wo.description.replace('\r\n','')}}/>
                    </div>
                    {wo.remoteOnsite && <div>Remote Onsite: {wo.remoteOnsite.remoteOnsiteName}</div>}
                    {wo.workOpportunityTime && <div>Time: {wo.workOpportunityTime.timeName}</div>}
                    {wo.workOpportunityType && <div>Type: {wo.workOpportunityType.typeName}</div>}
                    <div className='meta-deadline-text'>
                        Deadline&nbsp;
                        {moment(wo.deadline).format(DATE_FORMAT)}&nbsp;
                        <i className="icofont-ui-calendar"/>
                    </div>
                    <div>
                        {provider && (provider.userTypeId == 1 ? (provider.name + ' ' + provider.surname) : provider.name)}
                    </div>
                </Col>

            </Row>
        </div>
    );
};

WOHorizontalCard.defaultProps = {
    wo: {} as IWorkOpportunity
}

export default WOHorizontalCard;
