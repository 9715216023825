import React, {useEffect, useState} from 'react';
import './Countdown.scss';
import moment from "moment";

interface Props {
    date: string,
    loaded?: boolean
}

interface ICountdown {
    years: number,
    days: number,
    hours: number,
    min: number,
    sec: number
}

const Countdown: React.FC<Props> = props => {
    const [countDown, setCountDown] = useState<ICountdown>({years: 0, days: 0, hours: 0, min: 0, sec: 0});
    const calculateCountdown = (date: string) => {
        let diff: number = (moment(date).valueOf() - moment().valueOf()) / 1000;
        if (diff <= 0) return false;

        const timeLeft = {days: 0, hours: 0, min: 0, sec: 0} as ICountdown;

        // calculate time difference between now and expected date
        if (diff >= 365.25 * 86400) {
            // 365.25 * 24 * 60 * 60
            timeLeft.years = Math.floor(diff / (365.25 * 86400));
            diff -= timeLeft.years * 365.25 * 86400;
        }
        if (diff >= 86400) {
            // 24 * 60 * 60
            timeLeft.days = Math.floor(diff / 86400);
            diff -= timeLeft.days * 86400;
        }
        if (diff >= 3600) {
            // 60 * 60
            timeLeft.hours = Math.floor(diff / 3600);
            diff -= timeLeft.hours * 3600;
        }
        if (diff >= 60) {
            timeLeft.min = Math.floor(diff / 60);
            diff -= timeLeft.min * 60;
        }
        timeLeft.sec = diff;

        return timeLeft;
    }

    useEffect(() => {
        const interval =
            setInterval(() => {
                const date = calculateCountdown(props.date);
                date ? setCountDown(date) : clearInterval(interval);
            }, 1000);
        return (() => {
            clearInterval(interval);
        })
    }, []);

    const addLeadingZeros = (value) => {
        value = String(value);
        while (value.length < 2) {
            value = "0" + value;
        }
        return value;
    }

    return (
        <div className="Countdown">
            {
                props.loaded ?
                    <>
                        {
                            countDown.days > 99 ?
                                <span className='Countdown-col'>
                        <span className="Countdown-col-element">
                        <strong>More than 99 days</strong>
                    </span>

                    </span>
                                :
                                <>
                        <span className="Countdown-col">
                            <span className="Countdown-col-element">
                                {/*<strong className='time-element'>{addLeadingZeros(countDown.days)}</strong>*/}
                                <span className='time-element'>{addLeadingZeros(countDown.days)}</span>
                                <span className='time-text'>{countDown.days === 1 ? "Day" : "Days"}</span>
                            </span>
                        </span>

                                    <span className="Countdown-col">
                            <span className="Countdown-col-element">
                                <strong className='time-element'>{addLeadingZeros(countDown.hours)}</strong>
                                <span className='time-text'>Hours</span>
                            </span>
                        </span>

                                    <span className="Countdown-col">
                            <span className="Countdown-col-element">
                                <strong className='time-element'>{addLeadingZeros(countDown.min)}</strong>
                                <span className='time-text'>Minutes</span>
                            </span>
                        </span>

                                    <span className="Countdown-col">
                            <span className="Countdown-col-element">
                                <strong className='time-element'>{addLeadingZeros(countDown.sec.toFixed())}</strong>
                                <span className='time-text'>Seconds</span>
                            </span>
                        </span>
                                </>
                        }
                    </>
                    :
                    <>
                         <span>
                            Loading...
                         </span>
                    </>
            }

        </div>
    );
};

Countdown.defaultProps = {
    date: '',
    loaded: true
}

export default Countdown;
