//region Generic UI Settings
export const API_URL: string = 'https://regenhive-api.azurewebsites.net';
export const S3_SERVER_URL: string = 'https://regenhivestorage.blob.core.windows.net/regenhivefiles/';
export const API_REQUEST_TIMEOUT: number = 8000;
export const API_REQUEST_TIMEOUT_FILE : number = 120000; // 2 min
export const SERVER_DATETIME_FORMAT: string = "YYYY-MM-DD HH:mm:ss";
export const DATETIME_FORMAT: string = "DD.MM.YYYY HH:mm";
export const DATE_FORMAT: string = "DD.MM.YYYY";
//endregion

//region Social media prefixes
export const LINKEDIN_ROOT_URL: string = 'https://www.linkedin.com/in/';
export const TWITTER_ROOT_URL: string = 'https://twitter.com/';
export const FACEBOOK_ROOT_URL: string = 'https://www.facebook.com/';
export const INSTAGRAM_ROOT_URL: string = 'https://www.instagram.com/';
export const WEBADDRESS_ROOT_URL: string = 'https://';
//endregion

//region Component Settings
export const ACOMPLETE_DEBOUNCE_TIMEOUT: number = 900;
export const GM_PROFILE_MAX_POLYGON: number = 5;
//endregion

export const MAX_APPLICATIONS_FOR_MODULES: number = 250;
export const TITLE_NAME_REGEX = new RegExp(/^[A-Za-z0-9wığüşöçĞÜŞÖÇİ,\.?! -+:,/\.()\-;|]{0,70}$/);

export const enum ModuleColors {
    User = '#257BC1', // <i class="icofont-people"></i>
    //Tags = '#414A79', // <i class="icofont-tags"></i>
    //Skills = '#008D35',  // <i class="icofont-badge"></i>
    Resources = '#008D35',
    WorkOpportunities = '#C1569D', // <i class="icofont-search-job"></i>
    Trainings = '#3AC7CE', // <i class="icofont-notebook"></i>
    Events = '#FF3301'
}