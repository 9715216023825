import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../interfaces/page-data';
import {Button, Card, Col, Form, Input, Row, Space, Image} from "antd";
import {AppState, history} from "../../redux/store";
import {ISkill, ISkillApprove} from "../../interfaces/api";
import toast from "react-hot-toast";
import {useSelector} from "react-redux";
import {IUser} from "../../interfaces/api";
import {useParams} from "react-router-dom";
import AutoCompleteSkills, {ISkillAComplete} from "../../components/AutoCompleteSkills/AutoCompleteSkills";
import {CheckOutlined, CloseOutlined, LeftOutlined} from "@ant-design/icons";
import {S3_SERVER_URL} from "../../utils/Constants";
import ApiService from "../../utils/services/api.service";

const ApproveSkill: React.FC<IPageProps> = props => {

    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'Approve Skill',
        loaded: true
    };
    const [desc, setDesc] = useState<string>('');
    const user: IUser = useSelector((state: AppState) => state).user;
    const skillId = useParams<{ id?: string }>().id;
    const [skill, setSkill] = useState<ISkill>(null);
    const [skillPrerequisite, setSkillPrerequisite] = useState<ISkill[]>([]);

    useEffect(() => {
        onSetPage(pageData);
        any().then(null);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const any = async () => {
        let skillResponse = await ApiService.Skill.GetSkillById(skillId);
        setSkill(skillResponse.data);

        let prereqResponse = await ApiService.Skill.GetSkillPrerequisitesById(skillId);
        setSkillPrerequisite(prereqResponse.data);
    }

    const onOk = async (isOk) => {
        // isOk => false unapprove
        // isOk => true approve
        let data = {skillId: skillId, approvedBy: user.id, reason: desc} as ISkillApprove
        let response = isOk ? await ApiService.Skill.PostSkillApprove(data) : await ApiService.Skill.PostSkillReject(data)
        if (response.status === 200) {
            history.push('/vertical/skills')
        }
    }

    return (
        <div className='row'>
            <div className='col-12'>
                <Card className='card-head-none' loading={skill == null} title={<Row>
                    <Col span={24}>
                        <span className='card-title-page'>Approve Skill</span>
                    </Col>
                </Row>}>
                    <Form
                        colon={false}
                        labelCol={{sm: {span: 7}, lg: {span: 6}, md: {span: 6}}}
                        wrapperCol={{span: 12}}
                        //onFinish={(values) => onOk(values, true)}
                        //onFinishFailed={(values) => onOk(values, false)}
                    >
                        <Form.Item label='Name' name='skillName' initialValue={skill && skill.skillName}>
                            <Input disabled size='small'/>
                        </Form.Item>
                        <Form.Item label='Image' name='uploadedSkillImage' valuePropName={'src'}
                                   initialValue={skill && S3_SERVER_URL + skill.uploadedSkillImage}>
                            <Image preview={false} width={100}/>
                        </Form.Item>
                        <Form.Item label='Description' name='description' initialValue={skill && skill.description}>
                            <div style={{padding: 20}}>
                                <div dangerouslySetInnerHTML={{__html: skill && skill.description}}/>
                            </div>
                        </Form.Item>
                        <Form.Item label='Prerequisites' name='skillPrerequisites'
                                   validateTrigger={'onChange'}>
                            <AutoCompleteSkills
                                tags={skillPrerequisite.map(item => {
                                    return ({
                                        value: item.id,
                                        label: item.skillName,
                                        key: item.id
                                    } as ISkillAComplete);
                                })}
                                selectedTags={() => {
                                }}
                                disabled
                            />
                        </Form.Item>
                        <Form.Item label='Reason' name='reason' initialValue={skill && skill.reason}>
                            <Input.TextArea
                                //rows={2}
                                autoSize={{minRows: 2, maxRows: 2}}
                                onChange={(e) => {
                                    setDesc(e.target.value)
                                }}
                            />
                        </Form.Item>
                        <Form.Item wrapperCol={{
                            lg: {span: 12, offset: 6},
                            md: {span: 12, offset: 6},
                            xs: {offset: 0},
                            sm: {span: 12, offset: 7}
                        }}>
                            <div className='d-flex justify-content-between'>

                                <Button className='responsive-button' onClick={() => {
                                    history.push('/vertical/skills')
                                }} danger icon={<LeftOutlined/>}>Cancel</Button>

                                <Space>
                                    <Button className='responsive-button' type='primary' htmlType='submit' danger
                                            onClick={() => onOk(false)} icon={<CloseOutlined/>}>Decline</Button>
                                    <Button className='responsive-button' type='primary' htmlType='submit'
                                            onClick={() => onOk(true)} icon={<CheckOutlined/>}>Approve</Button>
                                </Space>

                            </div>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </div>
    )
};

export default ApproveSkill;
