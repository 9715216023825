import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../interfaces/page-data';
import {Button} from "antd";
import {AppState, history} from "../../redux/store";

import {useSelector} from "react-redux";
import {IEventRegister, ITrainingEnroll, IUser} from "../../interfaces/api";
import {useParams} from "react-router-dom";
import ApiService from "../../utils/services/api.service";

const Payment: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'Payment',
        loaded: true
    };

    const user: IUser = useSelector((state: AppState) => state).user;
    const [enrollRegister, setEnrollRegister] = useState<boolean>(false);
    const paymentId = useParams<{ type?: string, id?: string }>().id;
    const paymentType = useParams<{ type?: string, id?: string }>().type;
    useEffect(() => {
        onSetPage(pageData);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    const registerClick = async () => {
        switch (paymentType.toLowerCase()) {
            case 'training':
                let trainingData = {trainingId: paymentId, userId: user.id} as ITrainingEnroll
                let trainingResponse = await ApiService.TrainingApplication.PostTrainingApplicationEnroll(trainingData);
                if (trainingResponse.status === 200)
                    setEnrollRegister(true)
                break;
            case 'event':
                let eventData = {eventId: paymentId, userId: user.id} as IEventRegister
                let eventResponse = await ApiService.EventApplication.PostEventApplicationRegister(eventData);
                if (eventResponse.status === 200)
                    setEnrollRegister(true);
                break;
        }
    }

    const completeClick = async () => {
        switch (paymentType.toLowerCase()) {
            case 'training':
                let trainingData = {trainingId: paymentId, userId: user.id} as ITrainingEnroll
                let trainingResponse = await ApiService.Training.PostTrainingApprovePayment(trainingData);
                if (trainingResponse.status === 200)
                    history.push(`/vertical/my-trainings`);
                break;
            case 'event':
                let eventData = {eventId: paymentId, userId: user.id} as IEventRegister
                let eventResponse = await ApiService.EventApplication.PostEventApplicationRegister(eventData);
                if (eventResponse.status === 200)
                    history.push(`/vertical/my-events`);
                break;
        }
    }

    const declineClick = async () => {
        history.push(`/payment/decline`);
    }

    return (
        <div>
            <h5>Payment Process</h5>
            <br/>

            <Button
                onClick={registerClick}>Complete Enrollment or Register</Button>

            <br/>
            <br/>

            <Button
                disabled={!enrollRegister}
                onClick={completeClick}>Complete Payment</Button>

            <Button
                disabled={!enrollRegister}
                onClick={declineClick}>Decline Payment</Button>
        </div>
    )
};

export default Payment;
