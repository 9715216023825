import React, {useEffect, useState} from 'react';
import axios from 'axios';

import '../BaseLayout/BaseLayout.scss';
import './LoggedInVertical.scss';

import {connect, useSelector} from 'react-redux';
import {Route} from 'react-router';

import {toggleSidebar} from '../../redux/settings/actions';

import BaseLayout from '../BaseLayout/BaseLayout';
import Navbar from '../components/Navbar/Navbar';
import Search from '../components/Search/Search';
import Menu from '../components/Menu/Menu';
import Logo from '../components/Logo/Logo';
import Actions from '../components/Actions/Actions';
import NavbarSkeleton from '../components/NavbarSkeleton/NavbarSkeleton';

import {IPageData} from '../../interfaces/page-data';
import {IMenuItem} from '../../interfaces/menu';
import {IAppSettings} from '../../interfaces/settings';

import {AppState} from '../../redux/store';

import BasePage from '../../pages/BasePage/BasePage';

import {loggedInRoutes} from '../../routes';

import {resetPageData, setPageData} from '../../redux/pages/actions';
import FooterBottomPage from "../components/FooterBottompage/FooterBottomPage";
import toast, {ToastBar, Toaster} from "react-hot-toast";
import FillWhiteSpace from "../../utils/FillWhiteSpace";
import {Button} from 'antd';
import {IUser} from '../../interfaces/api';

const getGradientString = (firstColor: string, secondColor: string): string =>
    `linear-gradient(188deg, ${firstColor}, ${secondColor} 65%)`;

interface VerticalLayoutProps {
    pageData: IPageData;
    settings: IAppSettings;
    onSidebarToggle: () => void;
    onSettingsReset: () => void;
    onSettingsSet: (data: IAppSettings) => void;
    onSettingsUpdate: (data: IAppSettings) => void;
    onSetPage: (data: IPageData) => void;
    onPageReset: () => void;
}

const VerticalLayout: React.FunctionComponent<VerticalLayoutProps> = props => {
    const {pageData, settings, onSidebarToggle, onPageReset, onSetPage} = props;
    const {
        sidebarAccentColor,
        sidebarColor,
        topbarColor,
        topbarBg,
        sidebarBg2,
        sidebarBg,
        sidebarAccentContrastColor,
        sidebarOpened,
        //layout,
        boxed
    } = settings;
    const [menuData, setMenuData] = useState<IMenuItem[]>([]);
    // const [settingsVisibility, setSettingsVisibility] = useState(false);
    const routes = loggedInRoutes;
    const user: IUser = useSelector((state: AppState) => state).user;

    useEffect(() => {
        async function fetchData() {
            const result = await axios({baseURL: '', url: './data/menu-route.json', method: 'get'});
            let authorizedRoutes: any[] = result.data.filter((route) => route.auth?.includes(user.userRoleId)).map((route, i) => {
                return route;
            });
            setMenuData(authorizedRoutes);
        }

        fetchData().catch(err => console.log('Server Error', err));
        return (() => {

        })
    }, []);

    const handleToggleSidebar = () => {
        onSidebarToggle();
    };

    // const handleSettingsModalClose = () => {
    //   setSettingsVisibility(false);
    // };

    // const handleSettingsClick = () => setSettingsVisibility(true);

    return (
        <div className='layout vertical'>
            <div className={`app-container ${boxed && 'boxed'}`}>
                <Navbar
                    style={{backgroundImage: getGradientString(sidebarBg, sidebarBg2)}}
                    orientation='vertical'
                    opened={sidebarOpened}
                    onCloseNavbar={handleToggleSidebar}>
                    <button
                        className='no-style navbar-close icofont-close-line d-lg-none'
                        onClick={onSidebarToggle}
                    />
                    <Logo style={{backgroundColor: topbarBg}} height={33} width={123}/>
                    <Menu
                        color={sidebarColor}
                        accentContrast={sidebarAccentContrastColor}
                        accentColor={sidebarAccentColor}
                        data={menuData}
                        layout={'vertical'}
                    />
                    <NavbarSkeleton type='vertical'
                        //loaded={props.pageData.fullFilled}
                                    loaded={true}
                    />
                </Navbar>

                <Navbar
                    boxed={boxed}
                    style={{backgroundColor: topbarBg, color: topbarColor}}
                    orientation='horizontal'
                    className='horizontal-nav'
                    minHeight={60}>
                    <button className='navbar-toggle d-lg-none' onClick={handleToggleSidebar}>
                        <span/>
                        <span/>
                        <span/>
                    </button>

                    <Search
                        style={{color: topbarColor}}
                        dataKey='title'
                        // Fix here search invisible on sm screen
                        className='d-md-block d-xs-inline-block'
                    />

                    <Actions/>

                    <NavbarSkeleton type='vertical'
                        //loaded={props.pageData.fullFilled}
                                    loaded={true}
                    />
                </Navbar>


                <Toaster
                    position="top-right"
                    toastOptions={{
                        ariaProps: {
                            "aria-live": 'off',
                            role: 'status'
                        },
                        style: {
                            width: '240px',
                            height: '50px',
                            fontSize: '14px',
                            color: 'white'
                        },
                        success: {
                            duration: 3000,
                            iconTheme: {
                                primary: '#65CC6D',
                                secondary: 'white'
                            },
                            style: {
                                background: '#65CC6D',
                            },
                        },
                        error: {
                            iconTheme: {
                                primary: '#FF6961',
                                secondary: 'white'
                            },
                            duration: 3000,
                            style: {
                                background: '#FF6961',
                            },
                        },
                        custom: {
                            iconTheme: {
                                primary: '#FFA500',
                                secondary: 'white'
                            },
                            duration: 3000,
                            style: {
                                background: '#FFA500',
                            },
                        }
                    }}
                >
                    {(t) => (
                        <ToastBar toast={t}>
                            {({icon, message}) => (
                                <div style={{width: '100%'}}
                                     onClick={() => t.type !== 'loading' && toast.dismiss(t.id)}>
                                    {/*{icon}*/}
                                    {message}
                                </div>
                            )}
                        </ToastBar>
                    )}
                </Toaster>
                    <BaseLayout
                        pageData={pageData}
                        settings={settings}
                        onPageReset={onPageReset}
                        onSidebarToggle={onSidebarToggle}>
                        {routes.filter((route) => route.auth?.includes(user.userRoleId)).map((route, i) => {
                            return (
                                <Route
                                    exact
                                    key={i}
                                    path={`/vertical${route.path}`}
                                    render={() => (
                                        <div style={{maxWidth:'1280px'}}>
                                        <BasePage>
                                            <route.component key={i} onSetPage={onSetPage}/>
                                        </BasePage>
                                        </div>
                                    )}
                                />
                            );
                        })}

                <FillWhiteSpace/>

                <FooterBottomPage
                    //loaded={props.pageData.fullFilled}
                    loaded={true}
                    boxed={false}
                />
            </BaseLayout>
        </div>
</div>
)
    ;
};

const mapStateToProps = (state: AppState) => ({
    pageData: state.pageData,
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({
    onSidebarToggle: () => dispatch(toggleSidebar()),
    onSetPage: (data: IPageData) => dispatch(setPageData(data)),
    onPageReset: () => dispatch(resetPageData())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VerticalLayout);