/*
* If string is guid return true, otherwise false
*/
import {IPoint} from "../interfaces/api";
//import { google } from 'google-maps';

const isGuid = (stringToTest) => {
    if (stringToTest[0] === "{") {
        stringToTest = stringToTest.substring(1, stringToTest.length - 1);
    }
    let regexGuid = /^({){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(}){0,1}$/gi;
    return regexGuid.test(stringToTest);
}

// /*
// * If GUID is blank return true
// */
// const guidIsEmpty = (guid: string) => {
//     return !(guid || guid.length == 0 || guid == '00000000-0000-0000-0000-000000000000');
// }

/*
* If object is null or undefined return true, otherwise false
*/
const objIsEmpty = (obj: Object) => {
    return Object.values(obj).length < 1;
}


//
//   Get random color
//
const randomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

/*
* Detect locale date-time
*/
const localeUI = () => {
    let locale = navigator.language;
    let locale2 = navigator;
    console.log(locale)
    console.log(locale2)
    console.log(navigator.userAgent)
}

const convertToFormData = (data: any) => {
    let formData: FormData = new FormData();
    Object.keys(data).forEach((item) => {
        if (Array.isArray(data[item])) {
            let t = data[item];
            data[item].map((x, index) => {
                formData.append(item, t[index]);
            })
            return;
        }
        formData.append(item, data[item]);
    });
    return formData;
}

const Delay = ms => new Promise(res => setTimeout(res, ms));

const fixContent = (content: string, user1Text: string, user2Text: string, typeName: string) => {
    return content
        .replace('{user1}', user1Text)
        .replace('{user2}', user1Text)
        .replace('{resource}', typeName);
}

function timeSince(date) {

    // @ts-ignore
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
}

const clearCacheData = () => {
    caches.keys().then((names) => {
        names.forEach((name) => {
            caches.delete(name);
        });
    });
    //alert('Complete Cache Cleared')
};

export {
    isGuid,
    objIsEmpty,
    randomColor,
    convertToFormData,
    Delay,
    fixContent,
    timeSince,
    clearCacheData
};