import React, {CSSProperties, useEffect, useState} from 'react';

import classNames from '../../../utils/classNames'

import './FooterBottomPage.scss';
import Logo from "../Logo/Logo";
import {Button, Col, Modal, Radio, Row, Select} from "antd";
import {useTranslation} from "react-i18next";
import { GlobalOutlined } from '@ant-design/icons';
import {IMenuItem} from "../../../interfaces/menu";
import {loggedInRoutes} from "../../../routes";
import axios from "axios";
import { NavLink } from 'react-router-dom';
import {useSelector} from "react-redux";
import {AppState} from "../../../redux/store";
import {IUser} from "../../../interfaces/api";

interface FooterProps {
    loaded?: boolean;
    boxed?: boolean;
}

const FooterBottomPage: React.FunctionComponent<FooterProps> = props => {
    let footerClasses = classNames({
        'footer': true,
        'loaded': props.loaded,
        'boxed': props.boxed,
        //'full-filled': true
    });
    const [langVisible, setLangVisible] = useState<boolean>(false);
    const {i18n} = useTranslation();
    const [selectedLang, setSelectedLang] = useState();
    const radioBtnStyle: CSSProperties = {
        width: '100%'
    }
    const user: IUser = useSelector((state: AppState) => state).user;

    const [menuData, setMenuData] = useState<IMenuItem[]>([]);

    useEffect(() => {
        async function fetchData() {
            const result = await axios({baseURL: '', url: './data/menu-route.json', method: 'get'});
            setMenuData(result.data);
        }
        fetchData().catch(null);
        return( () => {

        })
    }, []);

    return (
        <div className={footerClasses}>
            <div className='footer-wrap'>
                <Row>
                    <Col xs={{span:16}} md={{span:15}} lg={{span:18}}>
                        <Row>
                            {
                                menuData && menuData
                                    //.filter(route => !route.title.toLowerCase().includes('admin')).
                                    .filter(route => !route.sub && route.auth?.includes(user.userRoleId))
                                    .map( (item) => {
                                    return(<Col key={item.title} xs={{span:24}} sm={{span:12}} md={{span:8}} lg={{span:6}}>
                                        {/*<a href={`/vertical/${item.routing}`}>{item.title}</a>*/}
                                        <NavLink
                                            to={`/vertical/${item.routing}`}
                                            className='item-link'
                                            style={{color:'white'}}
                                            activeClassName='active'
                                            replace
                                        >
                                            {item.title}
                                        </NavLink>
                                    </Col>)
                                })
                            }
                        </Row>
                    </Col>
                    <Col xs={{span: 7, offset:1}} md={{span: 3, offset:6}} lg={{span:4, offset: 2}} style={{textAlign: 'center', display:'none'}}>
                        {/*<Button onClick={() => setLangVisible(true)} size='small' icon={<GlobalOutlined/>}>{t()}</Button>*/}
                        <Select
                            defaultValue={i18n.language}
                            size='middle'
                            style={{width:'100%'}}
                            suffixIcon={<GlobalOutlined/>}
                            onChange={(e) => {
                            if (i18n.language !== e)
                                i18n.changeLanguage(e)
                        }}>
                            <Select.Option value='en' key='en'>English</Select.Option>
                            <Select.Option value='tr' key='tr'>Türkçe</Select.Option>
                        </Select>
                    </Col>
                </Row>
                <Row className='footer-logo-info'>
                    <Col xs={{span: 24}} md={{span: 24}}>
                        <div className='d-flex justify-content-between' style={{width: '100%'}}>
                            <Logo height={120} width={130} light/>

                            <div className='info'>
                                    &#169; 2022 RegenHive
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal
                title='Languages'
                visible={langVisible}
                footer={[
                    <Button type='primary' onClick={() => {
                        if (i18n.language !== selectedLang)
                            i18n.changeLanguage(selectedLang)
                        setLangVisible(false);
                    }}>OK</Button>
                ]}
                closable={false}
            >
                <Radio.Group onChange={(event) => {
                    setSelectedLang(event.target.value);
                }} buttonStyle='solid' defaultValue={i18n.language}>
                    <Radio value='tr' style={radioBtnStyle} key='tr'>
                        Türkçe
                        {/*<Image src={Turkey} preview={false} alt=''/>*/}
                    </Radio>
                    <Radio value='en' style={radioBtnStyle} key='eng'>
                        English
                        {/*<Image src={UK} preview={false} alt=''/>*/}
                    </Radio>
                </Radio.Group>
            </Modal>
        </div>
    );
};

export default FooterBottomPage;