import React, {ChangeEvent, CSSProperties, KeyboardEvent, MouseEvent, useEffect, useState} from 'react';
import Input, {InputProps} from '../Input/Input';

import classNames from '../../utils/classNames';

import './AutocompleteSearch.scss';
import {IAutocompleteOption} from '../../interfaces/autocomplete-option';
import {ACOMPLETE_DEBOUNCE_TIMEOUT} from "../../utils/Constants";
import {history} from "../../redux/store";
import ApiService from "../../utils/services/api.service";
import {IResultType, ISearchBarResult, ISearchResult} from "../../interfaces/api";

interface AutocompleteProps {
    data?: IAutocompleteOption[];
    listColor?: string;
    opened?: () => void;
    style?: CSSProperties;
    optionSelected?: (val: string) => void;
}

let debounce: NodeJS.Timeout;
const AutocompleteSearch: React.FC<AutocompleteProps & InputProps> = props => {
    const [value, setValue] = useState<string>('');
    const [results, setResults] = useState<ISearchBarResult>(null);
    const [resultsItemCount, setResultsItemCount] = useState<number>(0);
    const [itemIndex, setItemIndex] = useState<number>(-1);
    const [openState, setOpenState] = useState<boolean>(false);
    const [keyword, setKeyword] = useState<string>(null);

    const selectItem = (item: string, index: number) => {
        setValue(item);
        setItemIndex(index);
        setOpenState(false);

        if (props.optionSelected) {
            props.optionSelected(item);
        }
    };

    const listClasses = classNames({
        'autocomplete-list': true,
        opened: resultsItemCount > 0 && openState
    });

    const itemClasses = (index: number) =>
        classNames({
            'search-list-item': true,
            'list-li': true,
            active: index === itemIndex
        });

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {

        const inputValue = event.target.value;
        if (inputValue.trim().length < 1) {
            setResults(null);
            setOpenState(false);
            //setKeyword('')
            return;
        }

        //region Loading Text
        // listItems = [
        //     (<li
        //         className={itemClasses(0)}
        //         key={1}>
        //             <span className='item-title'>
        //                 Loading...
        //             </span>
        //     </li>)];
        // setOpenState(prevState => {return true})
        // setResultsItemCount(prevState => {return 1});
        //endregion

        setKeyword(inputValue);
        //setResults(null);
        clearTimeout(debounce);

        debounce = setTimeout(async () => {
            let response = await ApiService.Search.GetSearchBarByKeyword(inputValue);
            if (response.status === 200) {
                let result: ISearchBarResult = response.data
                setValue(inputValue);
                setItemIndex(-1);

                let count =
                    result.EventResults.Results.length +
                    result.ResourceResults.Results.length +
                    result.TrainingResults.Results.length +
                    result.UserResults.Results.length +
                    result.WOResults.Results.length;

                setResultsItemCount(count)
                if (count < 1) {
                    return;
                }
                // TODO - Ask here
                setResults(result);
                if (window.location.pathname.startsWith('/vertical/search/'))
                    return;
                setOpenState(true);
            }
        }, ACOMPLETE_DEBOUNCE_TIMEOUT);
    };

    const handleInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (keyword === null)
            return;
        if (event.keyCode === 38) {
            if (itemIndex > -1) {
                setItemIndex(itemIndex - 1);
            }
        }

        if (event.keyCode === 40) {
            if (itemIndex < resultsItemCount - 1) {
                setItemIndex(itemIndex + 1);
            }
        }

        // Enter event
        if (event.keyCode === 13) {
            //props.optionSelected(results[itemIndex].value);
            //history.push(`/vertical/search/${keyword}`);
            window.location.replace(`/vertical/search/${keyword}`);
            setOpenState(false);
            //setValue(results[itemIndex].text);
        }
    };

    const handleMouseDown = (event: MouseEvent) => {
        event.preventDefault();
    };

    const handleInputFocus = () => {
        if (resultsItemCount > 0) {
            setOpenState(true);
        }
    };

    const handleInputBlur = () => {
        setOpenState(false);
    };

    let listItems : any[] = results !== null && resultsItemCount > 0 && Object.entries(results).map((entry, index) => {
        let currentValue: IResultType = entry[1];
        if (currentValue.Results.length < 1)
            return;
        let icon = <></>;
        switch (currentValue.TypeName) {
            case 'Users':
                icon = <i className="icofont-ui-user"/>;
                break;
            case 'Trainings':
                icon = <i className="icofont-notebook"/>;
                break;
            case 'Events':
                icon = <i className="icofont-ebook"/>;
                break;
            case 'Resources':
                icon = <i className="icofont-dropbox"/>;
                break;
            case 'WorkOpportunities':
                icon = <i className="icofont-search-job"/>
                break;
        }
        return (currentValue.Results.map((item, index) => {
            if (currentValue.TypeName === "Users")
                return <li
                    className={itemClasses(index)}
                    key={item + index}
                    onClick={() => selectItem(item.Name + ' ' + item.Surname, index)}>
                    <span className='item-title'>
                        {icon}
                        {item.Name + ' ' + item.Surname}
                    </span>
                </li>;
            return (
                <li
                    className={itemClasses(index)}
                    key={item + index}
                    onClick={() => selectItem(item, index)}>

                    <span className='item-title'>
                        {icon}
                        {item}
                    </span>
                </li>
            )
        }))
    })

    useEffect(() => {
        history.listen(location => {
            let paths: string[] = location.pathname.split('/').filter(n => n);

            if(paths.length == 0 || paths[1].toLowerCase() !== 'search'){
                setKeyword((prevState => {
                    return null;
                }))
                setOpenState((prevState => {
                    return false;
                }))
                setValue(prevState => {
                    return '';
                })
                setResults(prevState => {
                    return null;
                })
                setResultsItemCount(prevState => {
                    return 0;
                })
            }
        })
    },[])

    return (
        <div className='tc-autocomplete'>
            <Input
                {...props}
                style={props.style}
                value={value}
                onKeyDown={handleInputKeyDown}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
            />

            <div className={listClasses} onMouseDown={handleMouseDown}>
                <ul className='list-ul'>{listItems}</ul>
            </div>
        </div>
    );
};

AutocompleteSearch.defaultProps = {
    data: []
};

// @ts-ignore
export default AutocompleteSearch;
