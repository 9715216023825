import React, {CSSProperties} from 'react';

import './Logo.scss';
import className from "../../../utils/classNames";

import LogoSvg from '../../../assets/img/logo/logo.png';
import LogoLightSvg from '../../../assets/img/logo/logo-white.png';

import LogoHive from '../../../assets/img/logo/RH_Yatay_Saydam.png';

interface LogoProps {
    alt?: string;
    light?: boolean;
    width?: number | string;
    height?: number | string;
    className?: string;
    style?: CSSProperties;
}

const Logo: React.FunctionComponent<LogoProps> = props => {
    //const { width, height, alt, className: classNames, light, style } = props;
    const {className: classNames, style, light} = props;

    const logoClassNames = className({
        'logo': true,
        [classNames]: props.className
    });

    //const LogoSrc = light ? LogoLightSvg : LogoSvg;
    const LogoSrc = LogoHive;

    return (
        <div className={logoClassNames} style={style}>
            <div className='logo-wrap'>
                <a href={'/'}>
                    <img src={LogoSrc} alt={props.alt} width={props.width} height={props.height}/>
                </a>
            </div>
        </div>
    )
};

Logo.defaultProps = {
    width: 'auto',
    height: 'auto',
    light: false,
    alt: ''
};

export default Logo;
