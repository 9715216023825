import React, {useEffect} from 'react';

interface ImgViewerProps {
    src: string,
    ratio?: number,
    shape?: string,
    maxWidth?: string,
    width?: string,
    minWidth?: string,
    onClick?: any,
    onErrorImage?: any
}

const ImgViewer: React.FC<ImgViewerProps> = props => {

    return (
        <span
            onClick={props.onClick}
        >
                <img
                    alt={''}
                    src={props.src}
                    style={{
                        minWidth: `${props.minWidth}`,
                        width: `${props.width}`,
                        maxWidth: `${props.maxWidth}`,
                        //maxHeight: `${maxWidth * (1/props.ratio)}px`,
                        aspectRatio: `${props.ratio}`,
                        borderRadius: props.shape === 'circle' ? '50%' : '0'
                    }}
                    onError={(event:any) => {event.target.src = props.onErrorImage}}
                />
            </span>
    );
};

ImgViewer.defaultProps = {
    src: null,
    ratio: 1,
    shape: 'square'
}

export default ImgViewer;
