import React, {useEffect, useState} from 'react';
import {Button, Card} from 'antd';
import './User.scss';
import {IUser} from "../../../interfaces/api";
import {objIsEmpty} from "../../../utils/GlobalFuncs";
import {S3_SERVER_URL} from "../../../utils/Constants";
import GUID from "../../../utils/GUID";
import greenCover from "../../../assets/img/blank/blank-cover.jpg";
import ImgViewer from "../../../components/ImgViewer/ImgViewer";
import blankUser from '../../../assets/img/blank/blank-user.png';

interface UserProps {
    className?: string;
    style?: string;
    key?: any;
    user: IUser;
    callback?: (user: IUser, b: boolean) => void;
    isFollowed: boolean
}

const User: React.FC<UserProps> = props => {
    const [followUser, setFollowUser] = useState<IUser>(props.user);

    useEffect(() => {
        console.log(props.isFollowed)
    },[])

    const unfollow = () => {
        props.callback(props.user, false);
    };
    const follow = () => {
        props.callback(props.user, true);
    }

    return (
        <div className='user-wrapper'>
            <Card
                loading={objIsEmpty(followUser)}
                className={`${props.className ? props.className : ''}`}
                cover={
                    <div className='img-item'>
                        <ImgViewer
                            src={(followUser.uploadedCoverImage) ? (S3_SERVER_URL + followUser.uploadedCoverImage + `?random=${GUID.newGuid()}`) : greenCover}
                            ratio={2}
                            shape={'square'}
                            width={'100%'}
                        />
                    </div>
                }>
                <div className='user-info'>
                    <div className='user-img'>
                        <p style={{cursor: 'pointer'}} onClick={() => {
                            window.location.replace(`/vertical/user-profile/${followUser.username ? followUser.username : followUser.id}`)
                        }}>
                            <ImgViewer
                                src={(followUser.uploadedProfilePhoto) ? (S3_SERVER_URL + followUser.uploadedProfilePhoto + `?random=${GUID.newGuid()}`) : blankUser}
                                ratio={1}
                                shape={'circle'}
                                maxWidth={'100px'}
                            />
                        </p>
                    </div>

                    <h5 className='user-name'>
                        <span className='user-link'>
                            {followUser.name ? followUser.name : '  '}
                        </span>
                    </h5>

                    <span className='user-role'>{followUser.username && followUser.username}</span>
                    {/*<span >{followUser.bio}</span>*/}
                    {
                        followUser.bio &&
                        <>
                            <span className='user-bio' dangerouslySetInnerHTML={{__html: followUser.bio}}/>
                        </>
                    }
                </div>

                {props.isFollowed ? (
                    <Button onClick={unfollow} block>
                        Unfollow
                    </Button>
                ) : (
                    <Button onClick={follow} type='primary' block>
                        Follow
                    </Button>
                )}
            </Card>
        </div>
    );
};

User.defaultProps = {
    user: {} as IUser,
    isFollowed: false
}

export default User;
