import React, {useEffect} from 'react';
import {IEvent, IEventApplication, IUser} from "../../interfaces/api";
import {Button, Card, Tooltip} from "antd";
import {history} from "../../redux/store";
//import blankImg from "../../../assets/img/blank/blank-image.png";
import blankImg from "../../assets/img/blank/blank-image.png";
import {DATE_FORMAT, S3_SERVER_URL} from "../../utils/Constants";
import moment from "moment";
import './EventCard.scss';
import {objIsEmpty} from "../../utils/GlobalFuncs";
import {CheckCircleTwoTone, CloseCircleTwoTone} from "@ant-design/icons";
import ImgViewer from "../ImgViewer/ImgViewer";

interface CardProps {
    event: IEvent,
    provider: IUser,
    isOwned?: boolean,
    applicant?: IEventApplication
}

const EventCard: React.FC<CardProps> = props => {
    const event = props.event;
    const provider = props.provider;

    return (
        <div className='event-card'>
            <Card
                loading={objIsEmpty(event)}
                hoverable
                onClick={() => {
                    history.push(`/vertical/events/register/${event.id}`);
                }}
                cover={
                    <>
                        <div
                            className='img-item-wrapper'
                        >
                            {event.uploadedThumbnail.length < 1 ?
                                <ImgViewer src={blankImg} ratio={2}/>
                                :
                                <ImgViewer src={S3_SERVER_URL + event.uploadedThumbnail} ratio={2} width='100%'/>
                            }
                            {props.isOwned &&
                                <>
                                    {
                                        props.applicant.isFinished ?

                                            <div className='complete-status'>
                                                {props.applicant.score ?
                                                    // <CheckCircleFilled style={{color:'green', fontSize:'24px', backgroundColor: 'white'}}/>
                                                    <CheckCircleTwoTone twoToneColor='green'
                                                                        style={{fontSize: '32px'}}/>
                                                    :
                                                    <CloseCircleTwoTone twoToneColor='red' style={{fontSize: '32px'}}/>
                                                }
                                            </div>
                                            :
                                            <>
                                                <span className='complete-status continues-frame'>
                                                    <span>Continues</span>
                                                </span>
                                            </>
                                    }
                                </>
                            }
                        </div>

                    </>
                }
                // actions={[<InfoCircleOutlined onClick={() => {
                //     history.push(`/event/${item.id}`);
                // }}/>]}
            >
                <Card.Meta
                    title={
                        <Tooltip title={event.eventName}>
                            <div className='meta-title'>
                                {event.eventName}
                            </div>

                        </Tooltip>
                    }
                    description={<div className='meta-desc'>
                        <div className='sub-desc' dangerouslySetInnerHTML={{__html: event.description.replace('\r\n','')}}/>
                        {
                            provider &&
                            <div className='meta-provider-text'>
                                {provider.name + (provider.userTypeId == 1 ? ` ${provider.surname}` : '')}
                            </div>
                        }


                        {props.isOwned ?
                            <>
                                <div className='meta-deadline-text'>
                                    {moment(event.startDate).format(DATE_FORMAT)} - {moment(event.finishDate).format(DATE_FORMAT)}
                                </div>
                            </>
                            :
                            <>
                                <div
                                    className='meta-seat-text'>
                                    {event.maxParticipants == 0 ? 'No limit ' : 'Total '} {event.maxParticipants} {event.maxParticipants == 1 ? ' seat' : ' seats'}
                                </div>
                                <div className='meta-deadline-text'>
                                    Deadline&nbsp;
                                    {moment(event.deadline).format(DATE_FORMAT)}&nbsp;
                                    <i className="icofont-ui-calendar"/>
                                </div>
                            </>

                        }


                        {/*<div className='date-title'>*/}
                        {/*    Between */}
                        {/*    {moment(event.startDate).format(DATE_FORMAT)}*/}
                        {/*    -*/}
                        {/*    {moment(event.finishDate).format(DATE_FORMAT)}*/}
                        {/*</div>*/}
                        {
                            !props.isOwned &&
                            <div className='meta-price-title'>
                                {
                                    event.participationFee === 0 ?
                                        <div>
                                            Free
                                        </div>
                                        :
                                        <div>
                                            {event.participationFee === 0 ? 'Free' : event.participationFee}<i
                                            className="icofont-dollar"/>
                                        </div>
                                }

                            </div>
                        }
                        {
                            props.isOwned && props.applicant.isFinished &&
                            <>
                                <div className='meta-deadline-text'>Skill1</div>
                                <div className='meta-deadline-text'>Skill2</div>
                            </>


                        }

                        <div className='fill'/>
                    </div>
                    }
                />
            </Card>
        </div>
    );
};

EventCard.defaultProps = {
    event: {} as IEvent,
    applicant: {} as IEventApplication,
    provider: {} as IUser,
    isOwned: false
}

export default EventCard;
