import React, {useEffect, useState} from 'react';
import VideoPlayer from "./VideoPlayer";
import {S3_SERVER_URL} from '../../utils/Constants';
import {IResourceFile, IResourceType} from "../../interfaces/api";
import {Button} from "antd";
import AudioPlayer from "./AudioPlayer";
import ApiService from '../../utils/services/api.service';

interface ResourceDetailProps {
    URIorEmbedCode: string,
    isEmbedCode: boolean,
    resourceTypeId: number,
    resourceId: string,
    likeFeedback?: boolean
}

enum DisplayType {
    OtherEmbed,
    OtherFile,
    VideoEmbed,
    VideoURI,
    AudioEmbed,
    AudioURI
}

//DESCRIPTION
// id 1 audio
// ESKI AUDIO
//  audio file URI ise player var ise oynatilir
//  audio embedCode ise ekranda oynatilir
// YENI AUDIO
//  audio file upload ise upload edilen dosyalar getByResource ile cekilir ve indirilmek uzere ekrana gelir
//  audio file URI ise indirilmek uzere ekrana gelir
// id 2 Video
//  video file URI ise player var ise oynatilir
//  video embedCode ise ekranda oynatilir
// id 3 Image
//  image file upload ise upload edilen dosyalar getByResource ile cekilir ve indirilmek uzere ekrana gelir
//  image file URI ise indirilmek uzere ekrana gelir
// id 4 Text
//  text file upload ise upload edilen dosyalar getByResource ile cekilir ve indirilmek uzere ekrana gelir
//  text file URI ise indirilmek uzere ekrana gelir
// id 5 Other
//  other file upload ise upload edilen dosyalar getByResource ile cekilir ve indirilmek uzere ekrana gelir
//  other file URI ise indirilmek uzere ekrana gelir

//         "id": 1,
//         "resourceTypeName": "Audio",
//
//         "id": 2,
//         "resourceTypeName": "Video",
//
//         "id": 3,
//         "resourceTypeName": "Image",
//
//         "id": 4,
//         "resourceTypeName": "Text",
//
//         "id": 5,
//         "resourceTypeName": "Other",

const ResourcePlayer: React.FC<ResourceDetailProps> = props => {
    const [displayType, setDisplayType] = useState<DisplayType>(null);
    const [fileURI, setFileURI] = useState<IResourceFile[]>(null);
    const [isLoaded, setLoaded] = useState<boolean>(false);
    // useEffect(() => {
    //     getResourceTypes();
    // }, [])

    useEffect(() => {
        // console.log('URI or EmbedCode emitted succesfully. Now type find started.')
        findType(props.resourceTypeId);
    }, [props.URIorEmbedCode]) // eslint-disable-line react-hooks/exhaustive-deps

    const findType = async (type: number) => {
        if (type === undefined)
            return;

        let response = await ApiService.ResourceType.GetResourceTypeById(props.resourceTypeId);
        let typeNameFromAPI: string = (response.data as IResourceType).resourceTypeName;
        // audio
        if (typeNameFromAPI.toLowerCase().startsWith('audio')) {
            // console.log('File have audio or video type')
            if (props.isEmbedCode) {
                // console.log('Audio or Video embed code')
                // embed code ise direk oynat
                setDisplayType(DisplayType.AudioEmbed)
            }
            else {
                setDisplayType(DisplayType.AudioURI)
                //await getResourceFileById();
            }
        }
        // video
        else if (typeNameFromAPI.toLowerCase().startsWith('video')) {
            // console.log('File have audio or video type')
            if (props.isEmbedCode) {
                // console.log('Audio or Video embed code')
                // embed code ise direk oynat
                setDisplayType(DisplayType.VideoEmbed)
            }
            else {
                setDisplayType(DisplayType.VideoURI)
            }
        }
        // image text and other
        else {
            // console.log('File have image, text or other type')
            if (props.isEmbedCode) {
                // console.log('Image,text or other URI')
                // embed code ise yani file URI ise yeni sekmede oynatacak sekilde ayarla
                setDisplayType(DisplayType.OtherEmbed)
            }
            else {
                // getResource/{resourceId} ile sunucudan getir yeni sayfada actiran buton yapisi ekle
                //endpoint = S3_SERVER_URL + props.URIorEmbedCode;
                // console.log('Image,text or other uploaded files')
                setDisplayType(DisplayType.OtherFile)
                await getResourceFileById();
            }
        }
        setLoaded(true);
    }


    // const getResourceTypes = async () => {
    //     let response = await ApiService.ResourceType.GetResourceType();
    //     setResourceTypes(response.data);
    // }

    const getResourceFileById = async () => {
        let response = await ApiService.ResourceFile.GetResourceFileByResource(props.resourceId);
        let t: IResourceFile[] = response.data;
        setFileURI(t);
    }

    return (
        <div className='resource-player-wrapper'>
            {
                isLoaded &&
                <div className='resource-display-list' style={{paddingBottom: '5px'}}>
                    {
                        DisplayType.AudioEmbed === displayType &&
                        <>
                            <div key={displayType} style={{width: '100%'}}>
                                <div dangerouslySetInnerHTML={{__html: props.URIorEmbedCode}}/>
                            </div>
                        </>
                    }
                    {
                        DisplayType.AudioURI === displayType &&
                        <>
                            <AudioPlayer key={displayType} url={props.URIorEmbedCode}/>
                        </>
                    }
                    {
                        DisplayType.VideoEmbed === displayType &&
                        <div key={displayType} style={{width: '100%'}}>
                            <div dangerouslySetInnerHTML={{__html: props.URIorEmbedCode}}/>
                        </div>
                    }
                    {
                        DisplayType.VideoURI === displayType &&
                        <>
                            <VideoPlayer key={displayType} url={props.URIorEmbedCode}/>
                        </>
                    }
                    {
                        DisplayType.OtherEmbed === displayType &&
                        <>
                            <Button key={displayType} type='primary'>
                                <a
                                    href={props.URIorEmbedCode}
                                    target={'_blank'}
                                    download={props.URIorEmbedCode}
                                >
                                    Download
                                    {
                                        props.resourceTypeId === 1 && ' Audio'
                                    }
                                    {
                                        props.resourceTypeId === 2 && ' Video'
                                    }
                                    {
                                        props.resourceTypeId === 3 && ' Image'
                                    }
                                    {
                                        props.resourceTypeId === 4 && ' Text'
                                    }
                                    {
                                        props.resourceTypeId === 5 && ' Other'
                                    }
                                </a>
                            </Button>
                        </>
                    }
                    {
                        DisplayType.OtherFile === displayType &&
                        <>
                            {
                                fileURI &&
                                fileURI.map((item, index) => {
                                    return (
                                        <div style={{paddingBottom: '5px'}} key={displayType+'d'}>
                                            <Button key={displayType} type='primary'>

                                                <a
                                                    href={S3_SERVER_URL + item.fileURI}
                                                    target={'_blank'}
                                                    download
                                                    rel="noopener noreferrer"
                                                >
                                                    Download
                                                    {
                                                        props.resourceTypeId === 1 && ' Audio '
                                                    }
                                                    {
                                                        props.resourceTypeId === 2 && ' Video '
                                                    }
                                                    {
                                                        props.resourceTypeId === 3 && ' Image '
                                                    }
                                                    {
                                                        props.resourceTypeId === 4 && ' Text '
                                                    }
                                                    {
                                                        props.resourceTypeId === 5 && ' Other '
                                                    }
                                                     #{index+1}
                                                </a>
                                            </Button>
                                        </div>
                                    )
                                })
                            }
                        </>
                    }

                </div>
            }

        </div>
    );
};

ResourcePlayer.defaultProps = {
    URIorEmbedCode: null,
    isEmbedCode: false,
    resourceTypeId: null,
    likeFeedback: false
}

export default ResourcePlayer;
