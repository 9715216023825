import React, {useEffect, useState} from 'react';
import {IUser} from "../../interfaces/api";
import {Button, Upload} from "antd";
import './ImgUploader.scss';
import {UploadOutlined} from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import toast from "react-hot-toast";
import {UploadChangeParam, UploadFile} from "antd/es/upload/interface";

interface Props {
    aspectRatio: number,
    imagesCount: number,
    fileMaxSize: number,//kb
    fileTypes: string[],// png jpg ...
    onChange?: (files: any, originalFiles?: UploadFile<any>[]) => void,
    shape?: 'round' | 'rect',
    innerHTML?: any
}

const ImgUploader: React.FC<Props> = props => {

    const [uploadFiles, setUploadFiles] = useState<any[]>([]);
    const [isRendered, setRendered] = useState<boolean>(false);

    const onPreview = async (file) => {
        //console.log('on preview')
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const uploadOnChange = (info) => {
        // console.dir('onChange Upload')
        // let file: any | unknown = info.file != null && info.file;
        let fileList = info.fileList != null && info.fileList;
        console.dir(info);

        let filteredList: UploadFile<any>[] = [];
        filteredList = fileList.filter( (fileItem) => {
            let isLtPickedSize = fileItem.size / 1024 < props.fileMaxSize;
            let isPickedType = props.fileTypes.filter((item) => {
                return fileItem.type === item;
            })
            if (isPickedType.length > 0 && isLtPickedSize){
                return fileItem;
            }
        })
        console.dir(filteredList);
        setUploadFiles(filteredList)
        // if (fileList[0].status !== 'done'){
        //
        // }

        //setUploadFiles(filteredList[0].status === 'done' && filteredList);
    }

    const beforeCrop = (file, fileList) => {
        const isPickedType = props.fileTypes.filter((item) => {
            return file.type === item;
        })
        const isLtPickedSize = file.size / 1024 < props.fileMaxSize;
        if (isPickedType.length < 1) {
            toast.error(`File must be image(${fileTypesString()})!`)
            return false;
        }
        if (!isLtPickedSize) {
            toast.error(`Image must smaller than ${props.fileMaxSize > 1024 ? `${props.fileMaxSize / 1024}MB` : `${props.fileMaxSize}KB`}!`)
            return false;
        }
        return true;
    }

    const fileTypesString = () => {
        return (props.fileTypes.map((x) => x.split('/')[1].toUpperCase())).toString()
    }

    useEffect(() => {
        setRendered(true);
    }, [])

    useEffect(() => {
        let t = [];
        if (uploadFiles.length < 1 && !isRendered)
            return;
        if (uploadFiles.length > 0) {
            t = uploadFiles.map((item) => {
                return item.originFileObj
            })
        }
        props.onChange(t,uploadFiles);
    }, [uploadFiles])

    return (
        <div className='img-upload-wrapper'>
            <ImgCrop
                shape={props.shape}
                grid
                rotate
                aspect={props.aspectRatio}
                beforeCrop={beforeCrop}
            >
                <Upload
                    accept='image/*'
                    maxCount={props.imagesCount}
                    multiple={false}
                    listType='picture'
                    showUploadList={!props.innerHTML}
                    fileList={uploadFiles}
                    onPreview={onPreview}
                    customRequest={(options) => {
                        options.onSuccess([]);
                    }}
                    onChange={uploadOnChange}
                >
                    {
                        props.innerHTML ?
                            <>
                                {props.innerHTML}
                            </>
                            :
                            <Button icon={<UploadOutlined/>} size='small'>Click to upload</Button>
                    }
                </Upload>
            </ImgCrop>
        </div>
    );
};

ImgUploader.defaultProps = {
    aspectRatio: 1,
    imagesCount: 1,
    fileMaxSize: 1024,//kb
    fileTypes: [],
    shape: 'rect',
    innerHTML: null
}

export default ImgUploader;
