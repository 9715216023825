import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../interfaces/page-data';
import {Button, Card, Col, Form, Image, Input, Row, Select, Space, Switch} from "antd";
import {AppState, history} from "../../redux/store";
import {IResource, IResourceApprove, IResourceType} from "../../interfaces/api";
import {useSelector} from "react-redux";
import {IUser} from "../../interfaces/api";
import {CheckOutlined, CloseOutlined, LeftOutlined} from "@ant-design/icons";
import {toast} from "react-hot-toast";
import {useParams} from 'react-router-dom';
import ResourcePlayer from "../../components/ResourceComps/ResourcePlayer";
import {S3_SERVER_URL} from "../../utils/Constants";
import ApiService from "../../utils/services/api.service";

const ApproveResource: React.FC<IPageProps> = props => {

    const {onSetPage} = props;
    const resourceId = useParams<{ id?: string }>().id;
    const [desc, setDesc] = useState<string>('');
    const user: IUser = useSelector((state: AppState) => state).user;
    const [resource, setResource] = useState<IResource>(null);
    const [resourceTypes, setResourceTypes] = useState<IResourceType[]>([]);
    const pageData: IPageData = {
        title: 'Approve Resource',
        loaded: true
    };

    useEffect(() => {
        onSetPage(pageData);
        any().then(null);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const any = async () => {
        let typesResponse = await ApiService.ResourceType.GetResourceType();
        setResource(typesResponse.data);

        let resourceResponse = await ApiService.Resource.GetResourceById(resourceId);
        setResource(resourceResponse.data);

        let fileResponse = await ApiService.ResourceFile.GetResourceFileByResource(resourceId);
        console.log(fileResponse.data);
    }

    const onOk = async (isOk) => {
        // isOk => false unapprove
        // isOk => true approve
        console.log(desc);
        console.log(isOk);
        let data = {
            resourceId: resourceId,
            approvedBy: user.id,
            reason: desc
        } as IResourceApprove;
        let response = isOk ? await ApiService.Resource.PostResourceApprove(data) : await ApiService.Resource.PostResourceReject(data);
        if (response.status === 200){
            history.push('/vertical/resources')
        }
    }

    return (
        <div className='row'>
            <div className='col-12'>
                <Card className='card-head-none' loading={resource == null} title={<Row>
                    <Col span={24}>
                        <span className='card-title-page'>Approve Resource</span>
                    </Col>
                </Row>}>
                    <Form
                        colon={false}
                        labelCol={{sm: {span: 7}, lg: {span: 6}, md: {span: 6}}}
                        wrapperCol={{span: 12}}
                    >
                        <Form.Item label='Resource Type' name='resourceTypeId'
                                   initialValue={resource && resource.resourceTypeId}>
                            <Select disabled>
                                {
                                    resourceTypes && resourceTypes.map((item) => {
                                        return (<Select.Option key={item.id}
                                                               value={item.id}>{item.resourceTypeName}</Select.Option>)
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label='Title' name='title' initialValue={resource && resource.title}>
                            <Input disabled size='small'/>
                        </Form.Item>
                        <Form.Item label='Thumbnail' name='uploadedThumbnail' valuePropName={'src'}
                                   initialValue={resource && S3_SERVER_URL + resource.uploadedThumbnail}>
                            <Image preview={true} width={200}/>
                        </Form.Item>
                        <Form.Item label='Description' name='description'
                                   initialValue={resource && resource.description}>
                            <div style={{padding: 20}}>
                                <div dangerouslySetInnerHTML={{__html: resource && resource.description}}/>
                            </div>
                        </Form.Item>
                        <Form.Item label='Is Embed Code' name='isEmbedCode' valuePropName='checked'
                                   initialValue={resource && resource.isEmbedCode}>
                            <Switch disabled/>
                        </Form.Item>

                        <Form.Item label='Preview'>
                            <ResourcePlayer
                                resourceId={resourceId}
                                isEmbedCode={resource && resource.isEmbedCode}
                                URIorEmbedCode={resource && resource.fileURI}
                                resourceTypeId={resource && resource.resourceTypeId}
                                // likeFeedback={true}
                            />
                        </Form.Item>

                        <Form.Item label='Reason' name='reason'>
                            <Input.TextArea
                                //rows={2}
                                autoSize={{minRows: 2, maxRows: 2}}
                                onChange={(e) => {
                                    setDesc(e.target.value)
                                }}
                            />
                        </Form.Item>
                        <Form.Item wrapperCol={{
                            lg: {span: 12, offset: 6},
                            md: {span: 12, offset: 6},
                            xs: {offset: 0},
                            sm: {span: 12, offset: 7}
                        }}>
                            <div className='d-flex justify-content-between'>

                                <Button className='responsive-button' onClick={() => {
                                    history.push('/vertical/resources')
                                }} danger icon={<LeftOutlined/>}>Cancel</Button>

                                <Space>
                                    <Button className='responsive-button' type='primary' htmlType='submit'
                                            danger
                                            onClick={() => onOk(false)} icon={<CloseOutlined/>}>Decline</Button>
                                    <Button className='responsive-button' type='primary' htmlType='submit'
                                            onClick={() => onOk(true)} icon={<CheckOutlined/>}>Approve</Button>
                                </Space>

                            </div>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </div>
    )
};


export default ApproveResource;
