import React, {ChangeEvent, useEffect, useRef} from 'react';
import {Input} from "antd";
import {ACOMPLETE_DEBOUNCE_TIMEOUT } from "../../utils/Constants";
import {EVENT_TYPES} from "../GoogleMaps/ForProfile/GMForProfile";

export enum SEARCH_STATUS{
    Loading,
    Loaded,
}

interface SearchGridProps {
    request: any,
    callback: (type: SEARCH_STATUS, data: any[]) => void,
    clear?: boolean
}

let debounce: NodeJS.Timeout;
const SearchGrid: React.FC<SearchGridProps> = props => {
    const inputRef: React.MutableRefObject<any> = useRef(null);
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        console.dir(event);
        const inputValue = event.target.value;
        if (inputValue.trim().length < 1) {
            // TODO - Eger input boş ise tüm datayı göster
            return;
        }
        props.callback(SEARCH_STATUS.Loading, []);
        clearTimeout(debounce);
        debounce = setTimeout(async () => {
            let response = await props.request(inputValue);
            if (response.status === 200) {
                    props.callback(SEARCH_STATUS.Loaded,response.data)
            }else {
                props.callback(SEARCH_STATUS.Loaded,[])
            }
        }, ACOMPLETE_DEBOUNCE_TIMEOUT);
    };

    useEffect(() => {
        inputRef.current.state.value = null;
    },[props.clear])

    return (
        <Input
            ref={inputRef}
            id="search-input"
            size='small'
            placeholder='Keyword'
            onChange={handleInputChange}

        >

        </Input>
    );
};

SearchGrid.defaultProps = {
    request: null,
    callback: null
}

export default SearchGrid;
