import React, {useEffect, useState} from 'react';
import axios from 'axios';

import '../BaseLayout/BaseLayout.scss';
import './LoggedIn.scss';

import {connect, useSelector} from 'react-redux';
import {Route} from 'react-router';

import {
    resetSettings,
    setSettings,
    updateSettings,
    toggleSidebar
} from '../../redux/settings/actions';

import BaseLayout from '../BaseLayout/BaseLayout';
import Navbar from '../components/Navbar/Navbar';
import Search from '../components/Search/Search';
import Menu from '../components/Menu/Menu';
import Logo from '../components/Logo/Logo';
//import Footer from '../components/Footer/Foooter';
import Actions from '../components/Actions/Actions';
import NavbarSkeleton from '../components/NavbarSkeleton/NavbarSkeleton';

import {IMenuItem} from '../../interfaces/menu';
import {IAppSettings} from '../../interfaces/settings';
import {AppState} from '../../redux/store';
import {IPageData} from '../../interfaces/page-data';
import BasePage from '../../pages/BasePage/BasePage';
import {loggedInRoutes} from '../../routes';
import {resetPageData, setPageData} from '../../redux/pages/actions';
import toast, {ToastBar, Toaster} from "react-hot-toast";
import FooterBottomPage from "../components/FooterBottompage/FooterBottomPage";
import {IUser} from "../../interfaces/api";

const getGradientString = (firstColor: string, secondColor: string): string =>
    `linear-gradient(188deg, ${firstColor}, ${secondColor} 65%)`;

interface LoggedInLayoutProps {
    pageData: IPageData;
    settings: IAppSettings;
    onSidebarToggle: () => void;
    onSettingsReset: () => void;
    onSettingsSet: (data: IAppSettings) => void;
    onSettingsUpdate: (data: IAppSettings) => void;
    onSetPage: (data: IPageData) => void;
    onPageReset: () => void;
}

const LoggedInLayout: React.FunctionComponent<LoggedInLayoutProps> = props => {
    const {pageData, settings} = props;
    const user: IUser = useSelector((state: AppState) => state).user;

    const {
        sidebarAccentColor,
        sidebarColor,
        topbarColor,
        topbarBg,
        sidebarBg2,
        sidebarBg,
        sidebarAccentContrastColor,
        sidebarOpened,
        sidebarContrast,
        boxed
    } = settings;

    const [menuData, setMenuData] = useState<IMenuItem[]>([]);
    const routes = loggedInRoutes;

    useEffect(() => {
        async function fetchData() {
            const result = await axios({baseURL: '', url: './data/menu-route.json', method: 'get'});
            let authorizedRoutes: any[] = result.data.filter((route) => route.auth?.includes(user.userRoleId)).map((route, i) => {
                return route;
            });
            setMenuData(authorizedRoutes);
        }

        fetchData().catch(err => console.log('Server Error', err));
    }, []);

    const handleToggleSidebar = () => {
        props.onSidebarToggle();
    };

    return (
        <div className='layout horizontal'>
            <div className={`app-container ${boxed && 'boxed'}`}>
                <Navbar
                    boxed={boxed}
                    className='top-bar'
                    orientation='horizontal'
                    minHeight='60px'
                    style={{backgroundColor: topbarBg, color: topbarColor}}>
                    <Logo className='d-lg-block d-none p-0' height={30} width={120}/>
                    <button className='navbar-toggle d-lg-none' onClick={handleToggleSidebar}>
                        <span/>
                        <span/>
                        <span/>
                    </button>

                    <Search
                        layout='horizontal'
                        dataKey='title'
                        className='d-none d-md-block'
                    />

                    <Actions/>

                    {/*<NavbarSkeleton type='horizontal' loaded={props.pageData.fullFilled} />*/}
                    <NavbarSkeleton type='horizontal' loaded={true}/>
                </Navbar>

                <Navbar
                    orientation='horizontal-vertical'
                    className='menu-bar'
                    boxed={boxed}
                    opened={sidebarOpened}
                    onCloseNavbar={handleToggleSidebar}
                    style={{backgroundImage: getGradientString(sidebarBg, sidebarBg2)}}>
                    <Logo className='d-lg-none' height={20} width={100}/>

                    <button
                        className='no-style navbar-close icofont-close-line d-lg-none'
                        onClick={props.onSidebarToggle}
                    />

                    <Menu
                        color={sidebarColor}
                        accentContrast={sidebarAccentContrastColor}
                        accentColor={sidebarAccentColor}
                        contrast={sidebarContrast}
                        data={menuData}
                        layout='logged-in'
                        orientation='horizontal'
                    />

                    {/*<NavbarSkeleton type='horizontal' loaded={props.pageData.fullFilled} />*/}
                    <NavbarSkeleton type='horizontal' loaded={true}/>
                </Navbar>

                <Toaster
                    position="top-right"
                    toastOptions={{
                        style: {
                            width: '240px',
                            height: '50px',
                            fontSize: '14px',
                            color: 'white'
                        },
                        success: {
                            duration: 3000,
                            iconTheme: {
                                primary: '#65CC6D',
                                secondary: 'white'
                            },
                            style: {
                                background: '#65CC6D',
                            }
                        },
                        error: {
                            iconTheme: {
                                primary: '#FF6961',
                                secondary: 'white'
                            },
                            duration: 3000,
                            style: {
                                background: '#FF6961',
                            },
                        },
                        custom: {
                            iconTheme: {
                                primary: '#FFA500',
                                secondary: 'white'
                            },
                            duration: 3000,
                            style: {
                                background: '#FFA500',
                            },
                        }
                    }}
                >
                    {(t) => (
                        <ToastBar toast={t}>
                            {({icon, message}) => (
                                <div style={{width: '100%'}}
                                     onClick={() => t.type !== 'loading' && toast.dismiss(t.id)}>
                                    {/*{icon}*/}
                                    {message}
                                </div>
                            )}
                        </ToastBar>
                    )}
                </Toaster>

                <BaseLayout
                    pageData={pageData}
                    settings={settings}
                    onPageReset={props.onPageReset}
                    onSidebarToggle={props.onSidebarToggle}>
                    {routes.filter((route) => route.auth?.includes(user.userRoleId)).map((route, i) => {
                        return (
                            <Route
                                exact
                                key={i}
                                path={`/logged-in${route.path}`}
                                render={() => (
                                    <div style={{maxWidth: '1280px'}}>
                                        <BasePage>
                                            <route.component onSetPage={props.onSetPage}/>
                                        </BasePage>
                                    </div>
                                )}
                            />
                        );
                    })}

                    <FooterBottomPage
                        loaded={false}
                        boxed={false}
                    />
                </BaseLayout>
            </div>
        </div>
    );
};

const mapStateToProps = (state: AppState) => ({
    pageData: state.pageData,
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({
    onSidebarToggle: () => dispatch(toggleSidebar()),
    onSettingsReset: () => dispatch(resetSettings()),
    onSettingsSet: (data: IAppSettings) => dispatch(setSettings(data)),
    onSettingsUpdate: (data: IAppSettings) => dispatch(updateSettings(data)),
    onSetPage: (data: IPageData) => dispatch(setPageData(data)),
    onPageReset: () => dispatch(resetPageData())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoggedInLayout);
