import React from 'react';

import {authRoutes} from '../../routes';
import {Route} from 'react-router';

import Logo from '../components/Logo/Logo';
import './Authentication.scss';
import toast, {ToastBar, Toaster} from "react-hot-toast";
import FooterAuth from "../components/FooterAuth/FooterAuth";

const AuthenticationLayout = props => (
    <div className='public-layout'>

        <Toaster
            position="top-right"
            toastOptions={{
                style: {
                    width: '240px',
                    height: '50px',
                    fontSize: '14px',
                    color: 'white'
                },
                success: {
                    duration: 3000,
                    iconTheme: {
                        primary: '#65CC6D',
                        secondary: 'white'
                    },
                    style: {
                        background: '#65CC6D',
                    },
                },
                error: {
                    iconTheme: {
                        primary: '#FF6961',
                        secondary: 'white'
                    },
                    duration: 3000,
                    style: {
                        background: '#FF6961',
                    },
                },
                custom: {
                    iconTheme: {
                        primary: '#FFA500',
                        secondary: 'white'
                    },
                    duration: 3000,
                    style: {
                        background: '#FFA500',
                    },
                }
            }}
        >
            {(t) => (
                <ToastBar toast={t}>
                    {({icon, message}) => (
                        <div style={{width: '100%'}}
                             onClick={() => t.type !== 'loading' && toast.dismiss(t.id)}>
                            {/*{icon}*/}
                            {message}
                        </div>
                    )}
                </ToastBar>
            )}
        </Toaster>

        <div className='bg'/>

        <div className='content-box'>
            <div className='content-header'>
                <Logo width={600} light={true}/>
            </div>

            <div className='content-body'>
                {authRoutes.filter((route) => route.auth?.includes(0)).map((route, i) => (
                    <Route
                        exact
                        key={i}
                        path={`/auth${route.path}`}
                        render={() => <route.component onSetPage={props.onSetPage}/>}
                    />
                ))}
            </div>



        </div>

        <FooterAuth
            //loaded={true}
            //boxed={false}
        />
        {/*<div className='content-footer'>*/}

        {/*</div>*/}
    </div>
);

export default AuthenticationLayout;
