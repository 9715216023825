import {IUser} from "../../interfaces/api";

export const SET_USER = '[User] Set User';
export const REMOVE_USER = '[User] Reset User';
export const UPDATE_USER = '[User] Update User';

export interface SetUserAction {
    type: typeof SET_USER;
    payload: IUser;
}

export interface RemoveUserAction {
    type: typeof REMOVE_USER;
}

export interface UpdateUserAction {
    type: typeof UPDATE_USER;
    payload: IUser;
}

export type UserActionTypes = SetUserAction | RemoveUserAction | UpdateUserAction;
