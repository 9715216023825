import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../interfaces/page-data';
import {Button, Card, Col, Modal, Row, Select, Space, Table} from "antd";
import {history} from "../../redux/store";
import {IUserSkill} from "../../interfaces/api";
import moment from "antd/node_modules/moment";
import {DATETIME_FORMAT} from "../../utils/Constants";
import ApiService from "../../utils/services/api.service";
import {CheckOutlined, DeleteOutlined, LeftOutlined, PlusOutlined} from "@ant-design/icons";
import ResponsiveButton from "../../components/ResponsiveButton/ResponsiveButton";

const SkillsApplications: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'Skills Applications',
        loaded: true
    };

    const columnsInitial = [
        {
            title: "Name Surname",
            dataIndex: "userId",
            ellipsis: false,
            render: (cell, row: IUserSkill) =>
                <>
                    {
                        row && row.user.userTypeId == 1 ? row.user.name + ' ' + row.user.surname : row.user.name
                    }
                </>
        },
        {
            title: "User Skill",
            dataIndex: "userSkillTypeId",
            ellipsis: false,
            render: (cell, row: IUserSkill) =>
                <>
                    {
                        row && row.userSkillType.name
                    }
                </>
        },
        {
            title: "App. Date",
            dataIndex: "createdAt",
            width: "150px",
            ellipsis: false,
            render: date => <>{
                date && moment(date).format(DATETIME_FORMAT)
            }</>
        },
        {
            title: "",
            dataIndex: "isApproved",
            width: '150px',
            ellipsis: false,
            hidden: false,
            // onFilter: (value: boolean, record: ISkill) => value == record.isApproved,
            // filters: [
            //     {text: 'Approved', value: true},
            //     {text: 'Non-approved', value: false},
            // ],
            render: (data, row: IUserSkill) => {
                if (data === true) {
                    return (<div style={{textAlign: 'center'}}>
                        <Button
                            icon={<DeleteOutlined/>}
                            size='small'
                            shape='circle'
                            danger
                            onClick={() => {deleteSkillApplication(row)}}>
                        </Button>
                        </div>)
                }
                else {
                    return (
                        <div style={{textAlign:'center'}}>
                            <Button
                                size='small'
                                className='responsive-button'
                                icon={<CheckOutlined />}
                                onClick={() => {
                                    history.push({pathname: `skill-applications/approve/${row.id}`});
                                }}>
                                Approve
                            </Button>
                            <Button
                                icon={<DeleteOutlined/>}
                                size='small'
                                shape='circle'
                                danger
                                onClick={() => {deleteSkillApplication(row)}}>
                            </Button>
                            {/*<Button*/}
                            {/*    size='small'*/}
                            {/*    onClick={() => {*/}
                            {/*        history.push({pathname: `skill-applications/detail/${row.id}`});*/}
                            {/*    }}>*/}
                            {/*    Detail*/}
                            {/*</Button>*/}
                        </div>)
                }
            }
        }
    ]

    const [columns, setColumns] = useState<object[]>(columnsInitial.filter(item => !item.hidden));
    const [skills, setSkills] = useState<IUserSkill[]>([]);
    const [skillIsLoaded, skillSetLoaded] = useState<boolean>(false);
    const [isApproved, setApproved] = useState('approved');

    useEffect(() => {
        onSetPage(pageData);
    }, []);

    const any = async () => {
        skillSetLoaded(true);
        let response = isApproved == 'approved' ?
            await ApiService.UserSkill.GetUserSkillApproved()
            :
            await ApiService.UserSkill.GetUserSkillUnapproved();

        skillSetLoaded(false);
        setSkills(response.data);
    }

    useEffect(() => {
        any().then(null);
    },[isApproved])

    const deleteSkillApplication = async (rowData: IUserSkill) => {
        Modal.confirm({
            closable: false,
            title: 'Are you sure to delete?',
            okText: 'Confirm',
            cancelText: 'Cancel',
            okButtonProps: {
                className: 'responsive-button',
                icon: <CheckOutlined/>
            },
            cancelButtonProps: {
                className: 'responsive-button',
                icon: <LeftOutlined/>,
                danger: true
            },
            onOk: async () => {
                let response = await ApiService.UserSkill.DeleteUserSkill(rowData.id);
                if (response.status === 200){
                    any().then(null);
                }
            },
            onCancel: async () => {
                return;
            }
        });
    }

    return (
        <div>
            <div className='row'>
                <div className='col-12'>
                    <Card title={

                        <Row gutter={[10, 10]}>
                            <Col xs={{span: 24}} md={{span: 8}}>
                                <span className='card-title-page'>Skill Applications</span>
                            </Col>
                            <Col xs={{span: 24}} sm={{span: 24}} md={{span: 6, offset: 1}} lg={{span: 6}}
                                 xl={{span: 4, offset: 4}} xxl={{span: 5}}>
                                {/*<SearchGrid*/}
                                {/*    request={ApiService.Event.GetEventSearchByKeyword}*/}
                                {/*    callback={callbackSearch}*/}
                                {/*    clear={clear}*/}
                                {/*/>*/}
                            </Col>
                            <Col xs={{span: 24}} sm={{span: 24}} md={{span: 6, offset: 3}} lg={{span: 6, offset: 4}}
                                 xl={{span: 4}} xxl={{span: 3}}>
                                <Select onChange={(e) => {
                                    setApproved(e);
                                    skillSetLoaded(true);
                                    e === 'approved' ? setColumns(columnsInitial.filter(item => !item.hidden)) : setColumns(columnsInitial);
                                }} defaultValue={isApproved}>
                                    <Select.Option key='1' value='approved'>Approved</Select.Option>
                                    <Select.Option key='2' value='unapproved'>Unapproved</Select.Option>
                                </Select>
                            </Col>
                            {/*<Col xs={{span: 5}} sm={{span: 3}} md={{span: 2}} lg={{span: 3}} xl={{span: 2}}*/}
                            {/*     xxl={{span: 1}} style={{textAlign: 'right'}}>*/}
                            {/*    <ResponsiveButton*/}
                            {/*        text='Post'*/}
                            {/*        icon={<PlusOutlined/>}*/}
                            {/*        onClick={() => {*/}
                            {/*            history.push('/vertical/events/new')*/}
                            {/*        }}/>*/}
                            {/*</Col>*/}
                        </Row>
                    } loading={skillIsLoaded}>
                        <Table
                            rowKey={(row: IUserSkill) => row.id}
                            dataSource={skills.length > 0 && skills}
                            columns={columns}
                            loading={skillIsLoaded}
                        />
                    </Card>
                </div>
            </div>

        </div>
    )
};

export default SkillsApplications;
