import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../interfaces/page-data';
import {Button, Card, Col, DatePicker, Form, Input, Row, Select, Upload} from "antd";
import {AppState, history} from "../../redux/store";
import {IUser, IWORemoteOnSite, IWorkOpportunityCreate, IWOTime, IWOType} from "../../interfaces/api";
import toast from "react-hot-toast";
import TextEditor from "../../components/TextEditor/TextEditorWithoutButton";
import {CheckOutlined, LeftOutlined, UploadOutlined} from "@ant-design/icons";
import AutoCompleteSkills, {ISkillAComplete} from "../../components/AutoCompleteSkills/AutoCompleteSkills";
import moment from "moment";
import {useSelector} from "react-redux";
import {DATE_FORMAT, ModuleColors, SERVER_DATETIME_FORMAT, TITLE_NAME_REGEX} from "../../utils/Constants";
import ImgUploader from "../../components/ImgUploader/ImgUploader";
import GMForModule from "../../components/GoogleMaps/ForModule/GMForModule";
import AutoCompleteTags from "../../components/AutoCompleteTags/AutoCompleteTags";
import ApiService from "../../utils/services/api.service";
import {convertToFormData} from "../../utils/GlobalFuncs";


const NewWorkOpportunity: React.FC<IPageProps> = props => {
    const user: IUser = useSelector((state: AppState) => state).user;

    const [remoteOnsite, setRemoteOnsite] = useState<IWORemoteOnSite[]>([]);
    const [times, setTimes] = useState<IWOTime[]>([]);
    const [types, setTypes] = useState<IWOType[]>([]);

    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'New Work Opportunity',
        loaded: true
    };

    useEffect(() => {
        onSetPage(pageData);
        const any = async () => {
            let roResponse = await ApiService.WORemoteOnsite.GetWORemoteOnsite();
            setRemoteOnsite(roResponse.data);

            let timeResponse = await ApiService.WOTime.GetWOTime();
            setTimes(timeResponse.data);

            let typeResponse = await ApiService.WOType.GetWOType();
            setTypes(typeResponse.data);
        }
        any().then(null);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const disableDateDeadline = (current) => {
        return (
            moment().add(-1, "minutes") > current
        )
    };

    const onFinish = async (values) => {
        let workOppor: IWorkOpportunityCreate = values as IWorkOpportunityCreate;

        //region Additional Images and Docs
        let tImages : Array<any> = values.additionalImages ? values.additionalImages.fileList.map((file) => {
            return (file.originFileObj);
        }) : null;
        let tDocs : Array<any> = values.additionalFiles ? values.additionalFiles.fileList.map((file) => {
            return (file.originFileObj);
        }) : null;
        workOppor.additionalImages = tImages;
        workOppor.additionalFiles = tDocs;
        //endregion

        //region WO Tags
        let woTags = values.workOpportunityTags ? values.workOpportunityTags : [];
        workOppor.workOpportunityTags = woTags.filter((item) => item.value !== item.label).map((item) => {
            return item.value
        });

        let unkTagsStr = woTags.filter((item) => item.value === item.label).map((item) => {
            return item.label
        }).join(',');
        //workOppor.unknownTags = unkTagsStr.length > 0 ? unkTagsStr : [];
        workOppor.unknownTags = unkTagsStr;
        //endregion

        //region Required skills
        let requiredSkillList: any[] = values.woRequiredSkills ? values.woRequiredSkills.map( (skill:ISkillAComplete) => {
            return {skillId: skill.value};
        }) : null;

        workOppor.strWORequiredSkills = JSON.stringify(requiredSkillList);
        workOppor.woRequiredSkills = null;
        //endregion

        workOppor.image = values.image ? values.image[0] : null;
        workOppor.deadline = moment(values.deadline).format(SERVER_DATETIME_FORMAT);
        workOppor.latitude = values.location.lat;
        workOppor.longitude = values.location.lng;
        workOppor.providedById = user.id;

        // Display the key/value pairs
        for (var pair of convertToFormData(workOppor).entries()) {
            console.log(pair[0]+ ', ' + pair[1]);
        }

        let response = await ApiService.WorkOpportunity.PostWO(workOppor);
        if (response.status === 200){
            history.push('/vertical/work-opportunities')
        }
    }

    return (
        <div className='row'>
            <div className='col-12'>
                <Card className='card-head-none' title={<Row>
                    <Col span={24}>
                        <span className='card-title-page'>New Work Opportunity</span>
                    </Col>
                </Row>}>
                    <Form
                        colon={false}
                        labelCol={{sm: {span: 7}, lg: {span: 6}, md: {span: 6}}}
                        wrapperCol={{span: 12}}
                        onFinish={onFinish}
                        requiredMark={false}
                        initialValues={{'woRemoteOnsiteId': 1, 'woTypeId': 1, 'woTimeId': 1}}
                    >
                        <Form.Item label='Title' name='title'
                                   rules={[{required: true, message: 'Title cannot be blank'},
                                       {required:true, pattern: TITLE_NAME_REGEX, message:'Title wrong format'}]}>
                            <Input size='small' placeholder='Work of the work opportunity'/>
                        </Form.Item>
                        <Form.Item label='Image'
                                   name='image'
                                   rules={[{required: true, message: 'Image cannot be blank'}]}
                                   valuePropName='files'
                                   extra={<span
                                       style={{fontSize: '10px'}}>Image must be PNG and less than 5MB</span>}>
                            <ImgUploader
                                fileTypes={['image/png']}
                                aspectRatio={2}
                                imagesCount={1}
                                fileMaxSize={5120}
                            />
                        </Form.Item>
                        <Form.Item label='Description' name='description'
                                   rules={[{required: true, message: 'Description cannot be blank'}]}>
                            <TextEditor image={false}/>
                        </Form.Item>

                        <Form.Item label='Additional Images'
                                   name='additionalImages'
                                   valuePropName='file'
                                   extra={<span
                                       style={{fontSize: '10px'}}>Files must be image(JPG, JPEG, PNG) and less than 5MB</span>}>
                            <Upload
                                accept='image'
                                maxCount={5}
                                multiple={true}
                                listType='picture'
                                onChange={() => {
                                    return false;
                                }}
                                beforeUpload={(file) => {
                                    const isPNG =
                                        file.type === 'image/png' ||
                                        file.type === 'image/jpeg' ||
                                        file.type === 'image/jpg';
                                    const isLt250KB = file.size / 1024 < 5120;
                                    if (!isPNG) {
                                        toast.error('File must be image(PNG,JPG,JPEG)!')
                                        return Upload.LIST_IGNORE;
                                    }
                                    if (!isLt250KB) {
                                        toast.error('Image must smaller than 5MB!')
                                        return Upload.LIST_IGNORE;
                                    }
                                    return false;
                                }}
                            >
                                <Button icon={<UploadOutlined/>} size='small'>Click to upload</Button>
                            </Upload>
                        </Form.Item>

                        <Form.Item label='Additional Documents'
                                   name='additionalFiles'
                                   valuePropName={'file,fileList'}
                                   extra={<span
                                       style={{fontSize: '10px'}}>Files must be less than 2MB</span>}>
                            <Upload
                                accept='image'
                                maxCount={5}
                                multiple={true}
                                listType='picture'
                                onChange={info => {
                                    // let t : Array<any> = info.fileList.map( (file) => {
                                    //     return (file.originFileObj);
                                    // })
                                    return false;
                                }}
                                beforeUpload={(file) => {
                                    const isLt250KB = file.size / 1024 < 2048;
                                    if (!isLt250KB) {
                                        toast.error('File must smaller than 2MB!')
                                        return Upload.LIST_IGNORE;
                                    }
                                    return false;
                                }}
                            >
                                <Button icon={<UploadOutlined/>} size='small'>Click to upload</Button>
                            </Upload>
                        </Form.Item>

                        <Form.Item label='Application Deadline' name='deadline'
                                   rules={[{required: true, message: 'Deadline cannot be blank'}]}>
                            <DatePicker format={DATE_FORMAT} placeholder='' size='small'
                                        style={{width: '100%'}}
                                        disabledDate={disableDateDeadline}
                                        //showTime={{format: 'HH:mm'}}
                            />
                        </Form.Item>

                        <Form.Item label={'Address'} name='location'>
                            <GMForModule
                                haveSearchBox
                                markerColorByModule={ModuleColors.WorkOpportunities}
                            />
                        </Form.Item>

                        <Form.Item label='Remote Onsite' name='woRemoteOnsiteId'
                                   rules={[{required: true, message: 'Remote onsite cannot be blank'}]}>
                            <Select>
                                {
                                    remoteOnsite && remoteOnsite.map((item) => {
                                        return (<Select.Option value={item.id} key={item.id}>{item.remoteOnsiteName}</Select.Option>)
                                    })
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item label='Time' name='woTimeId'
                                   rules={[{required: true, message: 'Time cannot be blank'}]}>
                            <Select>
                                {
                                    times && times.map((item) => {
                                        return (<Select.Option value={item.id} key={item.id}>{item.timeName}</Select.Option>)
                                    })
                                }
                            </Select>
                        </Form.Item>


                        <Form.Item label='Type' name='woTypeId'
                                   rules={[{required: true, message: 'Type cannot be blank'}]}>
                            <Select>
                                {
                                    types && types.map((item) => {
                                        return (<Select.Option value={item.id} key={item.id}>{item.typeName}</Select.Option>)
                                    })
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item label='Tags' name='workOpportunityTags' validateTrigger={'selectedTags'}>
                            <AutoCompleteTags
                                singleTag={false}
                                unknownTag={true}
                                endpoint={'/Tag/SearchWithType/1/'}/>
                        </Form.Item>

                        <Form.Item label='Required Skills' name='woRequiredSkills'
                                   validateTrigger={'onChange'}
                            //rules={[{required: true, message: 'Prerequisites cannot be blank'}]}
                        >
                            <AutoCompleteSkills
                                //permissionEndpoint={'/UserSkill/TrainerPermission'}
                                singleTag={false}
                                unknownTag={false}
                            />
                        </Form.Item>

                        <Form.Item wrapperCol={{
                            lg: {span: 12, offset: 6},
                            md: {span: 12, offset: 6},
                            xs: {offset: 0},
                            sm: {span: 12, offset: 7}
                        }}>
                            <div className='d-flex justify-content-between'>
                                <Button className='responsive-button' onClick={() => {
                                    history.push('/vertical/work-opportunities')
                                }} danger icon={<LeftOutlined/>}>Cancel</Button>

                                <Button className='responsive-button' type='primary' htmlType='submit'
                                        icon={<CheckOutlined/>}>Submit</Button>
                            </div>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </div>
    )
};

export default NewWorkOpportunity;
