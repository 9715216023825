import React, {ReactElement, useEffect, useState} from 'react';

import {Menu, Dropdown, Avatar, Badge, Button} from 'antd';

import './Actions.scss';
import {LoginOutlined, SettingOutlined, UserAddOutlined} from '@ant-design/icons';
import {useHistory} from "react-router-dom";
import {removeUser} from "../../../redux/user/actions";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../redux/store";
import {S3_SERVER_URL} from "../../../utils/Constants";
import ImgViewer from '../../../components/ImgViewer/ImgViewer';
import {IUser} from "../../../interfaces/api";
import GUID from "../../../utils/GUID";

const Actions: React.FunctionComponent = () => {
    const {user} = useSelector((state: AppState) => state);
    const history = useHistory();
    const dispatch = useDispatch();
    const loggedUser:IUser = user;

    const logout = () => {
        dispatch(removeUser());
        window.location.replace('/auth/sign-in');
    }

    const accountMenu: ReactElement = (
        <Menu style={{minWidth: '220px'}}>
            <Menu.Item key={'0'}>
                <a onClick={() => {
                    window.location.replace(`/vertical/user-profile/${loggedUser.username ? loggedUser.username : loggedUser.id}`);
                }}>View Profile</a>
            </Menu.Item>
            <Menu.Item key={'1'}>
                <span className='d-flex d-flex justify-content-between align-item-center'>
                  <a className='d-flex w-100' onClick={() => {
                      window.location.replace('/vertical/edit-profile');
                  }}>
                    Edit Profile
                  </a>
                  <span className='d-flex align-items-center'><SettingOutlined/></span>
                </span>
            </Menu.Item>
            <Menu.Item key={'2'}>
                <span className='d-flex d-flex justify-content-between align-item-center'>
                  <a className='d-flex w-100' onClick={() => {
                      window.location.replace('/vertical/security-and-login')
                  }}>
                    Security and Login
                  </a>
                  <span className='d-flex align-items-center icofont-shield'/>
                </span>
            </Menu.Item>
            <Menu.Item key={'3'}>
                <span className='d-flex d-flex justify-content-between align-item-center'>
                  <a className='d-flex w-100' onClick={() => {
                      window.location.replace('/vertical/claim-username')
                  }}>
                    Claim Username
                  </a>
                  <span className='d-flex align-items-center icofont-contact-add'/>
                </span>
            </Menu.Item>
            <Menu.Item key={'4'}>
                <a onClick={() => {
                    window.location.replace('/vertical/apply-for-skill')
                }}>Apply For Skill</a>
            </Menu.Item>
            <Menu.Divider/>
            <Menu.Item key={'5'}>
                <a onClick={() => {
                    window.location.replace('/vertical/my-trainings')
                }}>My Trainings</a>
            </Menu.Item>
            <Menu.Item key={'5a'}>
                <a onClick={() => {
                    window.location.replace('/vertical/my-events')
                }}>My Events</a>
            </Menu.Item>
            <Menu.Item key={'5b'}>
                <a onClick={() => {
                    window.location.replace('/vertical/my-work-opportunities')
                }}>My Job Apps</a>
            </Menu.Item>
            <Menu.Divider/>
            <Menu.Item key={'6'}>
                <a onClick={() => {
                    window.location.replace('/vertical/my-trainings/trainer')
                }}>Given Trainings</a>
            </Menu.Item>
            <Menu.Item key={'6a'}>
                <a onClick={() => {
                    window.location.replace('/vertical/my-events/organize')
                }}>Organized Events</a>
            </Menu.Item>
            <Menu.Item key={'6b'}>
                <a onClick={() => {
                    window.location.replace('/vertical/my-work-opportunities/open')
                }}>Created Job Posts</a>
            </Menu.Item>
            <Menu.Divider/>
            {/*<Menu.Item key={'7'}>*/}
            {/*    <a onClick={() => {*/}
            {/*        window.location.replace('/vertical/buy-membership')*/}
            {/*    }}>Buy Membership</a>*/}
            {/*</Menu.Item>*/}
            <Menu.Item key={'8'}>
                <a onClick={() => {
                    window.location.replace('/vertical/help')
                }}>Help</a>
            </Menu.Item>
            <Menu.Divider/>
            <Menu.Item key={'9'}>
                <span className='d-flex d-flex justify-content-between align-item-center'>
                  <a className='d-flex w-100' onClick={logout}>
                    Log out
                  </a>
                  <span className='d-flex align-items-center icofont-logout'/>
                </span>
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <div className='actions'>
                {
                    loggedUser.id == undefined ?
                        <div>
                            {/*<Button type='primary' shape='circle' icon={<LoginOutlined/>}/>*/}
                            <Button type='primary' shape='circle' size='large' icon={<LoginOutlined/>}
                                    onClick={() => {
                                        history.push('/auth/sign-in')
                                    }}
                            />
                            <span style={{margin: '5px'}}/>
                            <Button type='primary' shape='circle' size='large' icon={<UserAddOutlined/>}
                                    onClick={() => {
                                        history.push('/auth/sign-up')
                                    }}
                            />
                        </div>
                        :
                        <>
                            {/*        <Dropdown className='item mr-3' overlay={notificationsMenu} trigger={['click']}>*/}
                            {/*            <Badge*/}
                            {/*                style={{*/}
                            {/*                    alignItems: 'center',*/}
                            {/*                    //background: 'linear-gradient(250deg,#cd5447,#b3589c 65%,#9d5ce5)',*/}
                            {/*                    boxShadow: 'none',*/}
                            {/*                    display: 'flex',*/}
                            {/*                    justifyContent: 'center',*/}
                            {/*                    height: 18,*/}
                            {/*                    minWidth: 18*/}
                            {/*                }}*/}
                            {/*                count={3}>*/}
                            {/*<span*/}
                            {/*    className='icon notification-icon icofont-notification'*/}
                            {/*    style={{fontSize: '24px'}}*/}
                            {/*/>*/}
                            {/*            </Badge>*/}
                            {/*        </Dropdown>*/}

                            <Dropdown overlay={accountMenu} trigger={['click']}>
                                <div className='item'>
                                    {/*{*/}
                                    {/*    user.photo ?*/}
                                    {/*        <Avatar className='mr-1' src={user.photo}/>*/}
                                    {/*        :*/}
                                    {/*        <Avatar className='mr-1'*/}
                                    {/*                style={{verticalAlign: 'middle'}}>{user.name.charAt(0) + user.surname.charAt(0)}</Avatar>*/}
                                    {/*}*/}
                                    {
                                        (loggedUser.uploadedProfilePhoto == null || loggedUser.uploadedProfilePhoto.length < 1)  ?
                                            <Avatar className='mr-1'
                                                    style={{verticalAlign: 'middle'}}>{
                                                loggedUser.name.charAt(0).toUpperCase() + loggedUser.surname.charAt(0).toUpperCase()
                                            }</Avatar>
                                            :
                                            <ImgViewer src={(S3_SERVER_URL + loggedUser.uploadedProfilePhoto + `?random=${GUID.newGuid()}`)} ratio={1}
                                                       shape='circle' maxWidth='32px'/>
                                    }

                                    <span className='d-none d-md-inline icofont-simple-down'/>
                                </div>
                            </Dropdown>
                        </>
                }
            </div>

        </>
    );
};

export default Actions;
