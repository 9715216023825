import React, { useEffect, useState} from 'react';

interface TimelineFormatterProps {
    text: string,
    title: string,
    user1: string,
    user2?: string,
    eventId: string
}

const TimeLineFormatter: React.FC<TimelineFormatterProps> = props => {

    const [tag, setTag] = useState<string>();

    useEffect(() => {
        let updatedText = props.text
            .replace("{user1}", props.user1)
            .replace("{user2}", props.user2);
        updatedText = `<span>${updatedText}<span/>`;
        if (updatedText.search('{training}') > 0) {
            updatedText = updatedText.replace("{training}", `<a href='/vertical/trainings/enroll/${props.eventId}'>${props.title}</a>`)
        }
        if (updatedText.search('{event}') > 0) {
            updatedText = updatedText.replace("{event}", `<a href='/vertical/events/register/${props.eventId}'>${props.title}</a>`)
            // cfc1b113-504c-4425-b5a0-fb6ab2939a54
        }
        if (updatedText.search('{workOpportunity}')) {
            updatedText = updatedText.replace("{workOpportunity}", `<a href='/vertical/work-opportunities/application/${props.eventId}'>${props.title}</a>`)
        }
        if (updatedText.search('{skill}') > 0) {
            updatedText = updatedText.replace("{skill}", `<a href='/vertical/skills/detail/${props.eventId}'>${props.title}</a>`)
        }
        if (updatedText.search('{resource}') > 0) {
            updatedText = updatedText.replace("{resource}", `<a href='/vertical/resources/detail/${props.eventId}'>${props.title}</a>`)
        }
        setTag(updatedText);
    }, [])

    return (
        <React.Fragment>
            <span dangerouslySetInnerHTML={{__html: tag}}/>
        </React.Fragment>
    );
};

export default TimeLineFormatter;
