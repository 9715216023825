import * as UserTypes from './types';
import {IUser} from "../../interfaces/api";

export const setUser = (data: IUser): UserTypes.SetUserAction => ({
    type: UserTypes.SET_USER,
    payload: data
});

export const updateUser = (data: IUser): UserTypes.UpdateUserAction => ({
    type: UserTypes.UPDATE_USER,
    payload: data
});

export const removeUser = (): UserTypes.RemoveUserAction => ({
    type: UserTypes.REMOVE_USER
});
