import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../../interfaces/page-data';
import {Button, Card, Col, Form, Image, Input, Modal, Row, Tag, Upload} from "antd";
import {
    ISkill,
    IUser,
    IWOApplicationApply,
    IWOFile,
    IWOImage,
    IWorkOpportunity,
    IWorkOpportunityTag
} from "../../../interfaces/api";
import toast from "react-hot-toast";
import {useParams} from "react-router-dom";
import {ModuleColors, S3_SERVER_URL} from "../../../utils/Constants";
import {AppState, history} from "../../../redux/store";
import moment from "moment";
import './ApplicationWorkOpportunity.scss';
import {isGuid} from "../../../utils/GlobalFuncs";
import SkillCard from "../../../components/SkillCard/SkillCard";
import Countdown from "../../../components/Countdown/Countdown";
import PersonCard from "../../../components/PersonCard/PersonCard";
import {useSelector} from "react-redux";
import {CheckOutlined, InboxOutlined, LeftOutlined} from "@ant-design/icons";
import TextEditorWithoutButton from "../../../components/TextEditor/TextEditorWithoutButton";
import GMForModule from "../../../components/GoogleMaps/ForModule/GMForModule";
import ImgViewer from "../../../components/ImgViewer/ImgViewer";
import ApiService from "../../../utils/services/api.service";

const ApplicationWorkOpportunity: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'Application Work Opportunity',
        loaded: true
    };

    const user: IUser = useSelector((state: AppState) => state).user;
    const [isLoaded, setLoaded] = useState<boolean>(false);
    const [workOppor, setWorkOppor] = useState<IWorkOpportunity>({} as IWorkOpportunity);
    const [provider, setProvider] = useState<IUser>({} as IUser);
    const [requiredSkills, setRequiredSkills] = useState<ISkill[]>([]);
    const [isApplicant, setApplicant] = useState<boolean>(false);
    const [addImages, setAddImages] = useState<IWOImage[]>([]);
    const [addFiles, setAddFiles] = useState<IWOFile[]>([]);
    const [deadlineIsOver, setDeadlineIsOver] = useState<boolean>(false);
    const [preAppFormVisible, setPreAppFormVisible] = useState<boolean>(false);
    const [woTags, setWOTags] = useState<IWorkOpportunityTag[]>([]);
    const workOpporId = useParams<{ id?: string }>().id;
    const gutter: any = [0, 0];

    const uploadProps = {
        name: 'file',
        multiple: true,
        maxCount: 2,
        onChange() {
            return false;
        },
        beforeUpload(file) {
            console.dir(file);
            console.log(file.type)
            console.log(file.type === 'text/javascript')
            const isForbidden =
                file.type === ('application/x-msdownload'
                    || 'application/bin'
                    || 'application/x-bin'
                    || 'application/bat'
                    || 'application/x-bat'
                    || 'application/x-msdos-program'
                    || 'application/x-vbs'
                    || 'application/vbs'
                    || 'application/javascript'
                    || 'application/x-javascript');
            console.log(isForbidden);
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (isForbidden) {
                toast.error('Forbidden file type!');
                return Upload.LIST_IGNORE;
            }
            if (!isLt2M) {
                toast.error('File must less than 2MB!');
                return Upload.LIST_IGNORE;
            }
            return false;
        },
    };

    useEffect(() => {
        onSetPage(pageData);
        if (!isGuid(workOpporId))
            return;

        const any = async () => {
            let woResponse = await ApiService.WorkOpportunity.GetWOById(workOpporId);
            let wo: IWorkOpportunity = woResponse.data;
            setWorkOppor(woResponse.data);
            setProvider(wo.providedBy == null ? null : wo.providedBy);
            setDeadlineIsOver((moment(wo.deadline).valueOf() - moment().valueOf()) / 1000 < 0)

            let applicantResponse = await ApiService.WorkOpportunity.GetWOByApplicant(workOpporId);
            let woIsExist: boolean = applicantResponse.data.findIndex((item) => item.workOpprtunityId === workOpporId) > -1;
            setApplicant(woIsExist);

            let tagsResponse = await ApiService.WorkOpportunityTag.GetWOTagByWOId(workOpporId);
            setWOTags(tagsResponse.data);

            let requiredResponse = await ApiService.WORequiredSkill.GetWoRequiredSkillByWOId(workOpporId);
            setRequiredSkills(requiredResponse.data);

            let imageResponse = await ApiService.WOImage.GetWOImageByWOId(workOpporId);
            setAddImages(imageResponse.data);

            let filesResponse = await ApiService.WOFile.GetWOFileByWOId(workOpporId);
            setAddFiles(filesResponse.data);

            setLoaded(true);
        }
        any().then(null);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    const applicationClick = async (values) => {
        values.additionalFiles = values.additionalFiles && values.additionalFiles.fileList.map((file) => {
            return (file.originFileObj);
        });
        let data: IWOApplicationApply = values;
        data.workOpportunityId = workOpporId;
        data.userId = user.id;

        let response = await ApiService.WOApplication.PostWOApplicationApply(data);
        if (response.status === 200)
            history.push('/vertical/my-work-opportunities');
    }

    return (
        <div className='application-wo-page'>
            <Card
                className='ant-card-invisible'
                // bodyStyle={{padding:'20px'}}
                loading={!isLoaded}>
                {/*WO Details*/}
                <Row>
                    {/*WO name, desc, time left....*/}
                    <Col className='cell-gap' md={{order: 1, span: 12}} lg={{order: 1, span: 12}}
                         xs={{order: 2, span: 24}}>
                        <br/>
                        <h3>{workOppor.title}</h3>
                        <br/>
                        <div style={{padding: '10px'}} className='desc-tag'>
                            <div dangerouslySetInnerHTML={{__html: workOppor && workOppor.description}}/>
                        </div>
                        {/*{*/}
                        {/*    (!objIsEmpty(provider)) &&*/}
                        {/*    <>*/}
                        {/*        <div>{provider.name + ' ' + provider.surname}</div>*/}
                        {/*    </>*/}
                        {/*}*/}

                        {
                            user.id === provider.id ?
                                <>
                                </>
                                :
                                <>{
                                    isApplicant ?
                                        <h5>
                                            You have already did job application
                                        </h5>
                                        :
                                        <>
                                            {
                                                deadlineIsOver ?
                                                    <h5>
                                                        Job application is over
                                                    </h5>
                                                    :
                                                    <>
                                                        <h6>Time left before application ends</h6>
                                                        <div className='align-items'>
                                                            <Countdown date={workOppor.deadline}/>
                                                        </div>
                                                        <br/>
                                                        <div className='align-items'>
                                                            <Button onClick={() => {setPreAppFormVisible(true)}} type='primary'>Application
                                                                Now</Button>
                                                        </div>
                                                    </>
                                            }
                                        </>
                                }</>
                        }

                    </Col>
                    {/*wo image, price, space left, location*/}
                    <Col className='cell-gap' md={{order: 2, span: 12}}
                         lg={{order: 2, span: 12}} xs={{order: 1, span: 24}}>
                        <Row className='wo-info-border'>
                            <Col span={24}>
                                <div className='img-item'>
                                    <ImgViewer src={S3_SERVER_URL + workOppor.uploadedImage} ratio={2} shape='square' width='100%'/>
                                </div>
                            </Col>
                            <div className='info-item'>
                                {
                                    workOppor.workOpportunityTime &&
                                    <Col className='info-item' span={24} sm={{span: 12}} md={{span: 24}}>
                                        <div>
                                            <span className='header'>Time</span>
                                            <br/>
                                            <span
                                                className='info'>{workOppor.workOpportunityTime.timeName}</span>
                                        </div>
                                    </Col>
                                }
                                {
                                    workOppor.workOpportunityType &&
                                    <Col className='info-item' span={24} sm={{span: 12}} md={{span: 24}}>
                                        <div>
                                            <span className='header'>Type</span>
                                            <br/>
                                            <span
                                                className='info'>{workOppor.workOpportunityType.typeName}</span>
                                        </div>
                                    </Col>
                                }
                                {
                                    workOppor.remoteOnsite &&
                                    <Col className='info-item' span={24} sm={{span: 12}} md={{span: 24}}>
                                        <div>
                                            <span className='header'>Remote Onsite</span>
                                            <br/>
                                            <span
                                                className='info'>{workOppor.remoteOnsite.remoteOnsiteName}</span>
                                        </div>
                                    </Col>
                                }
                            </div>
                        </Row>
                    </Col>
                </Row>

                {/*WO tags*/}
                <Row className='cell-gap' justify='space-around'>
                    {
                        woTags.length > 0 &&
                        <Col span={24}>
                            <h5>Tags</h5>
                        </Col>
                    }

                    <Col span={24}>
                        {woTags.length > 0 && woTags.map((tag, i) => (
                            <Tag
                                onClick={() => {
                                    history.push(`/vertical/search/${tag.tag.tagName}`);
                                }}
                                key={i}
                                //color={randomColor()}
                                color='#3FCC79'
                                style={{
                                    color: '#fff',
                                    borderRadius: 500,
                                    fontSize: 14,
                                    margin: '5px',
                                    cursor: 'pointer'
                                }}>
                                {tag.tag.tagName}
                            </Tag>
                        ))}
                    </Col>

                </Row>

                {/*WO Required*/}
                {
                    requiredSkills.length > 0 &&
                    <Row className='cell-gap' justify='center'>
                        <Col span={24} className='align-items'>
                            <h5>What Do You Need to Know?</h5>
                        </Col>
                        {
                            requiredSkills.map((item) => {
                                //const provider:IUser = item.providedBy;
                                return (
                                    <>
                                        <Col key={item.id + 1} lg={{span: 2.5}} md={{span: 2}}
                                             sm={{span: 2}}
                                             xs={{span: 8}}>
                                        </Col>
                                        <Col key={item.id} lg={{span: 3}} md={{span: 3}} sm={{span: 4}}
                                             xs={{span: 6}}>
                                            <SkillCard key={item.id} skill={item}/>
                                        </Col>
                                        <Col key={item.id + 2} lg={{span: 2.5}} md={{span: 2}}
                                             sm={{span: 2}}
                                             xs={{span: 8}}>
                                        </Col>
                                    </>
                                )
                            })
                        }
                    </Row>
                }

                {/*WO Images*/}
                {
                    addImages.length > 0 &&
                    <Row className='cell-gap' justify='center' gutter={[20, 14]}>
                        <Col span={24} className='align-items'>
                            <h5>Images</h5>
                        </Col>
                        {addImages.map((item) => {
                            return (
                                <>
                                    <Col key={item.id} md={{span: 8}} sm={{span: 12}} xs={{span: 24}}
                                         style={{textAlign: 'center'}}>
                                        <Image key={item.id} src={S3_SERVER_URL + item.imageURI}
                                               style={{maxHeight: '150px'}}/>
                                    </Col>
                                </>
                            );
                        })}
                    </Row>
                }

                {/*WO Files*/}
                {
                    addFiles.length > 0 &&
                    <Row className='cell-gap' justify='center' gutter={[20, 14]}>
                        <Col span={24} className='align-items'>
                            <h5>Files</h5>
                        </Col>
                        {addFiles.map((item, index) => {
                            return (
                                <Col span={24} className='align-items'>
                                    <Button type='primary' size='small'>
                                        <a
                                            href={S3_SERVER_URL + item.fileURI}
                                            target={"_blank"}
                                            download
                                        >Download #{index + 1}</a>
                                    </Button>
                                </Col>
                            )
                        })}
                    </Row>
                }

                {/*WO Location*/}
                <Row className='cell-gap'>
                    <Col span={24} className='align-items'>
                        <h5>Location</h5>
                    </Col>
                    <Col span={24} className='map-row'>
                        <GMForModule
                            disabled
                            haveSearchBox={false}
                            defaultValue={{lat: workOppor.latitude, lng: workOppor.longitude}}
                            scrollWheel={false}
                            markerColorByModule={ModuleColors.WorkOpportunities}
                        />
                    </Col>
                </Row>

                {
                    workOppor && provider &&
                    <Row gutter={gutter} className='cell-gap'>
                        <Col span={24}>
                            <h5>Provider</h5>
                        </Col>
                        <Col span={24}>
                            <PersonCard user={provider}/>
                        </Col>
                    </Row>
                }


                {/*WO Application*/}
                <Row className='align-items cell-gap'>
                    <Col span={24}>
                        {
                            user.id !== provider.id &&
                            <>{
                                isApplicant ?
                                    <h5>
                                        You have already did job application
                                    </h5>
                                    :
                                    <>
                                        {
                                            deadlineIsOver ?
                                                <h5>
                                                    Job application is over
                                                </h5>
                                                :
                                                <>
                                                    <h6>Time left before application ends</h6>
                                                    <div className='align-items'>
                                                        <Countdown date={workOppor.deadline}/>
                                                    </div>
                                                    <br/>
                                                    <div className='align-items'>
                                                        <Button onClick={() => {setPreAppFormVisible(true)}} type='primary'>Application
                                                            Now</Button>
                                                    </div>
                                                </>
                                        }
                                    </>
                            }</>
                        }

                    </Col>
                </Row>
            </Card>

            <Modal
                destroyOnClose
                title='Pre-Application Work Opportunity'
                visible={preAppFormVisible}
                footer={null}
                closable={false}
            >
                <Form onFinish={applicationClick} requiredMark={false} colon={false}
                      labelCol={{span: 4}} wrapperCol={{span: 20}}>
                    <Form.Item label='Email' name='email' rules={[{required: true, message: 'Email cannot be blank'}]}>
                        <Input size='small'/>
                    </Form.Item>
                    <Form.Item label='Message' name='message'>
                        <TextEditorWithoutButton toolBarHidden/>
                    </Form.Item>
                    <Form.Item label='Files' name='additionalFiles' valuePropName='fileList|file'>
                        <Upload.Dragger {...uploadProps}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined/>
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Upload.Dragger>
                    </Form.Item>
                    <Form.Item wrapperCol={{md: {span: 20, offset: 4}, xs: {offset: 0}}}>
                        <div className='d-flex justify-content-between'>
                            <Button className='responsive-button' onClick={() => {
                                setPreAppFormVisible(false)
                            }} danger icon={<LeftOutlined/>}>Cancel</Button>

                            <Button className='responsive-button' type='primary' htmlType='submit'
                                    icon={<CheckOutlined/>}>Submit</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
};

export default ApplicationWorkOpportunity;
