import React, {useEffect} from "react";
import {IPageData, IPageProps} from '../../interfaces/page-data';

const Wallet: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'Wallet',
        loaded: true
    };

    useEffect(() => onSetPage(pageData), []);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            WALLET
        </>
    )
};

export default Wallet;
