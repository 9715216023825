import React, {useEffect, useState} from 'react';
import {Select, Spin} from 'antd';
import HTTPService from "../../utils/services/httprequest.service";
import {ITag} from "../../interfaces/api";
import {ACOMPLETE_DEBOUNCE_TIMEOUT} from "../../utils/Constants";

export interface ITagAComplete {
    label: string,
    value: string,
    key: string,
    isNew?: boolean,
    disabled?: boolean
}

interface AutocompleteProps {
    selectedTags?: (text: any) => void,
    endpoint: string,
    disabled?: boolean,
    singleTag?: boolean,
    unknownTag?: boolean,
    onChange?: (text: any) => void,
    resultChild?: (text: any) => void,
    tags?: Array<ITagAComplete>
}

let debounce: NodeJS.Timeout;
const AutoCompleteTags: React.FC<AutocompleteProps> = props => {

    const [children, setChildren] = useState<any>([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<Array<string>>([]);

    const onSearch = async (searchText: string) => {
        setChildren([]);
        if (searchText.length < 1) {
            return;
        }
        searchText = searchText.toLocaleLowerCase('tr');
        setLoading(true);
        clearTimeout(debounce);
        debounce = setTimeout(async () => {
            let res: Array<ITagAComplete> = [];
            await HTTPService.GET(props.endpoint + searchText).then((response) => {
                let result: Array<ITag> = JSON.parse(response.data);
                if (result.length < 1) {
                    setLoading(false)
                    return;
                }
                res = result.map((item: ITag) => {
                    return {label: item.tagName, value: item.id} as ITagAComplete;
                })
                setLoading(false)
            });

            if (props.unknownTag) {
                res.push({label: searchText, value: searchText} as ITagAComplete);
            }
            setChildren(res)
        }, ACOMPLETE_DEBOUNCE_TIMEOUT);
    };

    const onSelect = () => {
        setChildren([]);
    }

    const onChange = (objs: Array<any>) => {
        console.log('onchange')
        props.onChange(objs);
        if (objs == null)
            return;
        if (props.singleTag && objs.length > 1) {
            objs = objs.slice(1);
        }
        setData(objs);
    }


    useEffect(() => {
        if (data.length < 1){
            return;
        }
        props.selectedTags(data);
        //props.onChange(data);
    }, [data])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.tags.length < 1){
            return;
        }
        let t: Array<any> = [...children, ...props.tags];
        setData(t)
    }, [props.tags])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Select
            filterOption={false}
            labelInValue
            mode='multiple'
            notFoundContent={isLoading ? <Spin size="small"/> : null}
            style={{width: '100%'}}
            onSearch={onSearch}
            onSelect={onSelect}
            onChange={onChange}
            options={children}
            disabled={props.disabled}
            aria-multiline={true}
            value={data}
        >
            {/*{children}*/}
        </Select>
    );
};

AutoCompleteTags.defaultProps = {
    unknownTag: true,
    disabled: false,
    singleTag: false,
    onChange: () => {
    },
    selectedTags: () => {
    },
    resultChild: () => {
    },
    tags: []
}

export default AutoCompleteTags;
