import React from 'react';
import {IResource, ITraining, IUser} from "../../interfaces/api";
import {Card, Col, Row, Tooltip} from "antd";
import {history} from "../../redux/store";
import blankImg from "../../assets/img/blank/blank-image.png";
import {DATE_FORMAT, S3_SERVER_URL} from "../../utils/Constants";
import moment from "moment";
import './ResourceHorizontalCard.scss';
import ImgViewer from "../ImgViewer/ImgViewer";

interface Props {
    resource: IResource
}

const ResourceHorizontalCard: React.FC<Props> = props => {
    const resource = props.resource;
    const uploader = props.resource.uploadedBy;
    return (
        <div className='resource-horizontal-card-wrapper' onClick={() => {history.push(`/vertical/resources/detail/${resource.id}`);}}>
            <Row className='resource-horizontal-card'>
                <Col xxl={{span: 6}} lg={{span: 9}} xl={{span: 8}} md={{span: 8}} sm={{span: 24}} xs={{span: 24}}
                     className='resource-info'>
                    <div className='resource-img'>
                        <ImgViewer
                            src={S3_SERVER_URL + resource.uploadedThumbnail}
                            ratio={2}
                            width={'100%'}
                        />
                    </div>
                </Col>
                <Col xxl={{span: 18}} lg={{span: 15}} xl={{span: 16}} md={{span: 16}} sm={{span: 24}} xs={{span: 24}}
                     className='resource-info'>

                    <div className='resource-title'>{resource.title}</div>
                    <div className='sub-desc'>
                        <div dangerouslySetInnerHTML={{__html: resource.description.replace('\r\n','')}}/>
                    </div>
                    <div>
                        {uploader && (uploader.userTypeId == 1 ? (uploader.name + ' ' + uploader.surname) : uploader.name)}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

ResourceHorizontalCard.defaultProps = {
    resource: {} as IResource
}

export default ResourceHorizontalCard;
