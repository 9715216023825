import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../interfaces/page-data';
import {Button, Card, Col, DatePicker, Form, Image, Input, Row, Select} from "antd";
import {AppState, history} from "../../redux/store";
import {
    IUser, IWOFile, IWOImage,
    IWorkOpportunity, IWorkOpportunityApprove,
    IWorkOpportunityTag,
} from "../../interfaces/api";
import {CheckOutlined, LeftOutlined} from "@ant-design/icons";
import AutoCompleteSkills, {ISkillAComplete} from "../../components/AutoCompleteSkills/AutoCompleteSkills";
import moment from "moment";
import {useSelector} from "react-redux";
import {DATE_FORMAT, ModuleColors, S3_SERVER_URL} from "../../utils/Constants";
import {useParams} from "react-router-dom";
import GMForModule from "../../components/GoogleMaps/ForModule/GMForModule";
import AutoCompleteTags, {ITagAComplete} from "../../components/AutoCompleteTags/AutoCompleteTags";
import ApiService from "../../utils/services/api.service";


const ApproveWorkOpportunity: React.FC<IPageProps> = props => {
    const user: IUser = useSelector((state: AppState) => state).user;
    const workOpporId = useParams<{ id?: string }>().id;
    const [workOppor, setWorkOppor] = useState<IWorkOpportunity>(null);
    const [desc, setDesc] = useState<string>('');

    const [addFiles, setAddFiles] = useState<IWOFile[]>([]);
    const [addImages, setAddImages] = useState<IWOImage[]>([]);
    const [reqSkills, setReqSkills] = useState<ISkillAComplete[]>([]);
    const [woTags, setWOTags] = useState<ITagAComplete[]>([]);

    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'Approve Work Opportunity',
        loaded: true
    };

    useEffect(() => {
        onSetPage(pageData);
        const any = async () => {
            let woResponse = await ApiService.WorkOpportunity.GetWOById(workOpporId);
            setWorkOppor(woResponse.data);

            let addImagesResponse = await ApiService.WOImage.GetWOImageByWOId(workOpporId);
            setAddImages(addImagesResponse.data);

            let addFilesResponse = await ApiService.WOFile.GetWOFileByWOId(workOpporId);
            setAddFiles(addFilesResponse.data);

            let tagsResponse = await ApiService.WorkOpportunityTag.GetWOTagByWOId(workOpporId);
            let t1 = tagsResponse.data.length > 0 ? tagsResponse.data.map((item: IWorkOpportunityTag) => {
                return {value: item.tag.id, label: item.tag.tagName } as ITagAComplete
            }) : [];
            setWOTags(t1);

            let reqSkillsResponse = await ApiService.WORequiredSkill.GetWoRequiredSkillByWOId(workOpporId);
            let t2: Array<ISkillAComplete> = reqSkillsResponse.data.map((item) => {
                return ({label: item.skillName, key: item.id, value: item.id} as ISkillAComplete);
            })
            setReqSkills(t2);
        }

        any().then(null);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const onOk = async (isOk) => {
        // isOk => false unapprove
        // isOk => true approve
        let data = {
            workOpportunityId: workOpporId,
            approvedBy: user.id,
            reason: desc
        } as IWorkOpportunityApprove
        let response = isOk ? await ApiService.WorkOpportunity.PostWOApprove(data) :  await ApiService.WorkOpportunity.PostWOReject(data);
        if (response.status === 200)
            history.push('/vertical/work-opportunities')
    }

    return (
        <div className='row'>
            <div className='col-12'>
                <Card className='card-head-none' loading={workOppor == null} title={<Row>
                    <Col span={24}>
                        <span className='card-title-page'>New Work Opportunity</span>
                    </Col>
                </Row>}>
                    <Form
                        colon={false}
                        labelCol={{sm: {span: 7}, lg: {span: 6}, md: {span: 6}}}
                        wrapperCol={{span: 12}}
                        requiredMark={false}
                    >
                        <Form.Item label='Title' name='title'
                                   rules={[{required: true, message: 'Title cannot be blank'}]}
                                   initialValue={workOppor && workOppor.title}
                        >
                            <Input size='small' placeholder='Work of the work opportunity' disabled/>
                        </Form.Item>
                        <Form.Item label='Image'
                                   name='image'
                                   rules={[{required: true, message: 'Image cannot be blank'}]}
                                   valuePropName={'src'}
                                   initialValue={workOppor && S3_SERVER_URL + workOppor.uploadedImage}>
                            <Image preview={true} width={200}/>
                        </Form.Item>
                        <Form.Item label='Description' name='description'
                                   rules={[{required: true, message: 'Description cannot be blank'}]}>
                            <div style={{padding: 20}}>
                                <div dangerouslySetInnerHTML={{__html: workOppor && workOppor.description}}/>
                            </div>
                        </Form.Item>

                        {addImages.length > 0 &&
                            <Form.Item label='Additional Images'
                                       name='additionalImages'>
                                <Image.PreviewGroup>
                                    {
                                        addImages.map((item) => {
                                            return (<div style={{padding: '2px'}}><Image width={200}
                                                                                         src={S3_SERVER_URL + item.imageURI}
                                                                                         key={item.id}/></div>)
                                        })
                                    }
                                </Image.PreviewGroup>
                            </Form.Item>
                        }
                        {
                            addFiles.length > 0 && <Form.Item label='Additional Documents'
                                                              name='additionalFiles'>
                                {addFiles.map((item, index) => {
                                    return (
                                        <div style={{paddingBottom: '5px'}}>
                                            <Button type='primary' size='small'>
                                                <a
                                                    href={S3_SERVER_URL + item.fileURI}
                                                    target={'_blank'}
                                                    style={{color: 'white'}}
                                                    download
                                                >Download #{index + 1}</a>
                                            </Button>
                                        </div>
                                    )
                                })}
                            </Form.Item>
                        }


                        <Form.Item label='Application Deadline' name='deadline'
                                   rules={[{required: true, message: 'Deadline cannot be blank'}]}
                                   initialValue={workOppor && moment(workOppor.deadline)}>
                            <DatePicker format={DATE_FORMAT} placeholder='' size='small'
                                        style={{width: '100%'}} disabled
                            />
                        </Form.Item>

                        {/*<Form.Item label='Location' name='location'*/}
                        {/*           rules={[{required: true, message: 'Location cannot be blank'}]}*/}
                        {/*>*/}
                        {/*    <Input size='small' placeholder='Enter a physical meeting location'/>*/}
                        {/*</Form.Item>*/}

                        <Form.Item label={'Address'} //name={['latitude','longitude']}
                        >
                            <GMForModule
                                disabled
                                defaultValue={workOppor && {lat: workOppor.latitude, lng: workOppor.longitude}}
                                markerColorByModule={ModuleColors.WorkOpportunities}
                            />
                        </Form.Item>

                        <Form.Item label='Remote Onsite' name='woRemoteOnsiteId'
                                   initialValue={workOppor && workOppor.woRemoteOnsiteId}
                                   rules={[{required: true, message: 'Remote onsite cannot be blank'}]}>
                            <Select disabled>
                                {
                                    workOppor && workOppor.remoteOnsite &&
                                    <Select.Option
                                        value={workOppor.remoteOnsite.id}
                                        key={workOppor.remoteOnsite.id}>
                                        {workOppor.remoteOnsite.remoteOnsiteName}
                                    </Select.Option>
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item label='Time' name='woTimeId' initialValue={workOppor && workOppor.woTimeId}
                                   rules={[{required: true, message: 'Time cannot be blank'}]}>
                            <Select disabled>
                                {
                                    workOppor && workOppor.workOpportunityTime &&
                                    <Select.Option
                                        value={workOppor.workOpportunityTime.id}
                                        key={workOppor.workOpportunityTime.id}>
                                        {workOppor.workOpportunityTime.timeName}
                                    </Select.Option>
                                }
                            </Select>
                        </Form.Item>


                        <Form.Item label='Type' name='woTypeId' initialValue={workOppor && workOppor.woTypeId}
                                   rules={[{required: true, message: 'Type cannot be blank'}]}>
                            <Select disabled>
                                {
                                    workOppor && workOppor.workOpportunityType &&
                                    <Select.Option
                                        value={workOppor.workOpportunityType.id}
                                        key={workOppor.workOpportunityType.id}>
                                        {workOppor.workOpportunityType.typeName}
                                    </Select.Option>
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item label='Tags' name='woTags' validateTrigger={'selectedTags'}>
                            <AutoCompleteTags
                                tags={woTags}
                                singleTag={false}
                                unknownTag={true}
                                endpoint={'/Tag/SearchWithType/1/'}
                                disabled
                            />
                        </Form.Item>

                        <Form.Item label='Required Skills' name='woRequiredSkills'
                                   validateTrigger={'onChange'}
                            //rules={[{required: true, message: 'Prerequisites cannot be blank'}]}
                        >
                            <AutoCompleteSkills
                                tags={reqSkills}
                                //permissionEndpoint={'/UserSkill/TrainerPermission'}
                                singleTag={false}
                                unknownTag={false}
                                disabled
                            />
                        </Form.Item>

                        <Form.Item label='Reason' name='reason' initialValue={workOppor && workOppor.reason}>
                            <Input.TextArea
                                //rows={2}
                                autoSize={{minRows: 2, maxRows: 2}}
                                onChange={(e) => {
                                    setDesc(e.target.value)
                                }}
                            />
                        </Form.Item>

                        <Form.Item wrapperCol={{
                            lg: {span: 12, offset: 6},
                            md: {span: 12, offset: 6},
                            xs: {offset: 0},
                            sm: {span: 12, offset: 7}
                        }}>
                            <div className='d-flex justify-content-between'>
                                <Button className='responsive-button'
                                        onClick={() => onOk(false)}
                                        danger icon={<LeftOutlined/>}>Cancel</Button>

                                <Button className='responsive-button'
                                        type='primary' htmlType='submit'
                                        onClick={() => onOk(true)}
                                        icon={<CheckOutlined/>}>Submit</Button>
                            </div>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </div>
    )
};

export default ApproveWorkOpportunity;
