import {Spin} from "antd";
import React, {useEffect, useState} from "react";

interface FillWhiteSpaceProps {
    topElementClass: string,
    topElementId?: string
}

const Spinner: React.FunctionComponent<FillWhiteSpaceProps> = props => {
    const {topElementClass, topElementId} = props;
    const [fillSize, setFillSize] = useState<number>(0);

    useEffect(() => {
        let footerElement = document.querySelector('.footer-wrap');
        let topElement = topElementId.length > 0 ?
            document.getElementById(topElementId)
            :
            document.querySelector(topElementClass)
        ;

        if (footerElement == null || topElement == null)
            return;

        let topRect: DOMRect = topElement.getBoundingClientRect(),
            footerRect: DOMRect = footerElement.getBoundingClientRect();

        setFillSize(footerRect.top - topRect.bottom);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div style={{
            height: fillSize,
            width:'100%'
        }}>
            <Spin style={{
                position:"absolute",
                left:'50%',
                top:'50%'
            }}/>
        </div>
    );
};

Spinner.defaultProps = {
    topElementId : ''
}

export default Spinner;


