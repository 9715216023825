const API_KEY = 'AIzaSyB1Gil4fWcf_79YsA7T8_-E3IA4DufA56g';

const webStyles = {
    width: 'calc(100% - 40px)',
    height: 'calc(100% - 180px)'
};

const mobileStyles = {
    width: 'calc(100% - 40px)',
    height: 'calc(100% - 120px)'
};

// Greenwich
const greenwichCoord =
{
        lat: 51.477928,
        lng: -0.001545
};

export {
    API_KEY,
    webStyles,
    mobileStyles,
    greenwichCoord
}


