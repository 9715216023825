import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../interfaces/page-data';
import {Button, Card, Col, Form, Input, Row} from "antd";
import TextEditor from "../../components/TextEditor/TextEditorWithoutButton";
import {AppState, history} from "../../redux/store";
import {ISkillCreate, ISkillPrerequisite, IUser} from "../../interfaces/api";
import toast from "react-hot-toast";
import {useSelector} from "react-redux";
import {CheckOutlined, LeftOutlined} from "@ant-design/icons";
import AutoCompleteSkills, {ISkillAComplete} from "../../components/AutoCompleteSkills/AutoCompleteSkills";
import ImgUploader from "../../components/ImgUploader/ImgUploader";
import {TITLE_NAME_REGEX} from "../../utils/Constants";
import ApiService from "../../utils/services/api.service";

const SuggestSkill: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'Suggest a New Skill',
        loaded: true
    };

    const [prerequisitesTags, setPrerequisitesTags] = useState<any>([]);
    const [uploadFile, setUploadFile] = useState<any>(null);
    const [clearAcSelection, setClearAcSelection] = useState<boolean>(false);
    let user: IUser = useSelector((state: AppState) => state).user

    useEffect(() => {
        onSetPage(pageData);
        //getTagTypes();
    }, []);


    const onFinish = async (data: any) => {
        let skill: ISkillCreate = data as ISkillCreate;
        let prereqs = prerequisitesTags
            .filter((item) => item.key != item.label)
            .map((item: ISkillAComplete) => {
                return {prerequisiteSkillId: item.value} as ISkillPrerequisite
            });
        skill.strSkillPrerequisites = JSON.stringify(prereqs);
        skill.userId = user.id;
        skill.skillImage = uploadFile;

        let response = await ApiService.Skill.PostSkill(skill as ISkillCreate);
        if (response.status === 200){
            history.push('/vertical/skills')
        }
    }

    const getSrcFromFile = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
        });
    };

    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <Card className='card-head-none' title={
                        <Row className='card-title-page'>
                            <Col span={24}>
                                <span>Suggest a New Skill</span>
                            </Col>
                        </Row>}>
                        <Form
                            colon={false}
                            onFinish={onFinish}
                            //labelAlign="right"
                              labelCol={{sm: {span: 7}, lg: {span: 6}, md: {span: 6}}}
                              wrapperCol={{span: 12}}
                              requiredMark={false}
                        >
                            <Form.Item label='Name' name='skillName'
                                       rules={[{
                                           required: true,
                                           message: 'Skill name cannot be blank',
                                       }, {required:true, pattern: TITLE_NAME_REGEX, message:'Skill name wrong format'}]}>
                                <Input size='small'/>
                            </Form.Item>
                            <Form.Item label='Image'
                                       name='skillImage'
                                       rules={[{required: true, message: 'Image cannot be blank'}]}
                                       extra={<span
                                           style={{fontSize: '10px'}}>Image must be PNG and less than 5MB</span>}
                            >
                                <ImgUploader
                                    fileTypes={['image/png']}
                                    aspectRatio={1}
                                    imagesCount={1}
                                    fileMaxSize={5120}
                                    onChange={setUploadFile}
                                />

                            </Form.Item>
                            <Form.Item label='Description' name='description' valuePropName=''
                                       rules={[{required: true, message: 'Description cannot be blank'}]}>
                                <TextEditor emoji={false} image={false}/>
                            </Form.Item>
                            <Form.Item label='Prerequisites' name='skillPrerequisites'
                                       validateTrigger={'onChange'}>
                                <AutoCompleteSkills
                                    selectedTags={setPrerequisitesTags}
                                    singleTag={false}
                                    unknownTag={false}
                                    clearData={clearAcSelection}
                                />
                            </Form.Item>
                            <Form.Item wrapperCol={{
                                lg: {span: 12, offset: 6},
                                md: {span: 12, offset: 6},
                                xs: {offset: 0},
                                sm: {span: 12, offset: 7}
                            }}>
                                <div className='d-flex justify-content-between'>
                                    <Button className='responsive-button' onClick={() => {
                                        history.push('/vertical/skills')
                                    }} danger icon={<LeftOutlined/>}>Cancel</Button>

                                    <Button className='responsive-button' htmlType='submit' type='primary'
                                            icon={<CheckOutlined/>}>Submit</Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </div>
        </>
    )
};

export default SuggestSkill;
