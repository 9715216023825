import React, {useEffect, useState} from "react";
import {EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import './TextEditor.scss';
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

interface ITextEditorProps {
    defaultValue?: string
    text?: (text: string) => void;
    onChange?: (text: string) => void;
    image?: boolean,
    emoji?: boolean,
    toolBarHidden?: boolean
}


const TextEditor: React.FunctionComponent<ITextEditorProps> = props => {
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [uploadedImages, setUploadedImages] = useState([]);
    const [isRendered, setRendered] = useState<boolean>(false);
    let options = ['inline', 'blockType', 'list', 'link', 'embedded' /*, 'textAlign', 'history', 'fontSize'*/];

    useEffect(() => {
        setRendered(true);
        props.image && options.push('image');
        props.emoji && options.push('emoji');
        if (!props.defaultValue)
            return;
        setEditorState(EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(props.defaultValue && props.defaultValue)
            )
        ))
    }, [])

    useEffect(() => {
        if (!isRendered) {
            return;
        }
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        let markup = draftToHtml(
            rawContentState,
            editorState
        );
        if (markup === "<p></p>" || markup === "<p></p>\n") {
            props.text('');
            props.onChange('');
            return;
        }
        props.text(markup);
        props.onChange(markup);
    }, [editorState.getCurrentContent()])// eslint-disable-line react-hooks/exhaustive-deps

    const uploadCallback = (file) => {
        // long story short, every time we upload an image, we
        // need to save it to the state so we can get it's data
        // later when we decide what to do with it.

        // Make sure you have a uploadImages: [] as your default state
        let images = uploadedImages;

        const imageObject = {
            file: file,
            localSrc: URL.createObjectURL(file),
        }

        images.push(imageObject);

        setUploadedImages(images);
        // We need to return a promise with the image src
        // the img src we will use here will be what's needed
        // to preview it in the browser. This will be different than what
        // we will see in the index.md file we generate.
        return new Promise(
            (resolve, reject) => {
                resolve({data: {link: imageObject.localSrc}});
            }
        );
    }

    return (
        <div>
            <div /*className='text-editor-div'*/>
                <Editor
                    handlePastedText={() => false}
                    //editorStyle={{ border: "1px solid" , maxHeight:'200px', margin: '5px'}}
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    toolbarHidden={props.toolBarHidden}
                    editorClassName="editorClassName"
                    onEditorStateChange={(editorState) => {
                        setEditorState(editorState);
                    }}
                    toolbar={{
                        // options icine eklenmezse gozukmez ozellik
                        //options: ['inline', 'blockType', 'list', 'emoji','image','link' /*, 'textAlign', 'history', 'fontSize'*/],
                        options: options,
                        inline: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            // options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
                            options: ['bold', 'italic', 'underline'],
                            // bold: { icon: bold, className: undefined },
                            // italic: { icon: italic, className: undefined },
                            // underline: { icon: underline, className: undefined },
                            // strikethrough: { icon: strikethrough, className: undefined },
                            // monospace: { icon: monospace, className: undefined },
                            // superscript: { icon: superscript, className: undefined },
                            // subscript: { icon: subscript, className: undefined },
                        },
                        list: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            // options: ['unordered', 'ordered', 'indent', 'outdent'],
                            options: ['unordered', 'ordered'],
                            // unordered: { icon: unordered, className: undefined },
                            // ordered: { icon: ordered, className: undefined },
                            // indent: { icon: indent, className: undefined },
                            // outdent: { icon: outdent, className: undefined },
                        },
                        blockType: {
                            inDropdown: true,
                            //options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                            options: ['Normal', 'H1', 'H2', 'H3', 'H4'],
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                        },
                        emoji: {
                            //icon: emoji,
                            className: undefined,
                            component: undefined,
                            popupClassName: undefined,
                            emojis: [
                                '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
                                '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
                                '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
                                '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
                                '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
                                '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
                                '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
                                '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
                                '✅', '❎', '💯',
                            ],
                        },
                        image: {
                            //icon: image,
                            className: undefined,
                            component: undefined,
                            popupClassName: undefined,
                            urlEnabled: true,
                            uploadEnabled: true,
                            alignmentEnabled: true,
                            uploadCallback: uploadCallback,
                            previewImage: true,
                            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                            alt: {present: false, mandatory: false},
                            defaultSize: {
                                height: 'auto',
                                width: 'auto',
                            },
                        },
                        link: {
                            visible: true,
                            inDropdown: false,
                            addLink: {visible: true, icon: 'xxx.png',},
                            removeLink: {visible: true, icon: 'xxx.png',},
                        }
                    }}
                />

            </div>
        </div>
    );
};

TextEditor.defaultProps = {
    text: () => {
    },
    onChange: () => {
    },
    emoji: true,
    image: true,
    toolBarHidden: false
}

export default TextEditor;


