import NotFound from './pages/errors/NotFound/NotFound';
import LoggedInLayout from "./layouts/LoggedIn/LoggedIn";
import LoggedInVerticalLayout from "./layouts/LoggedInVertical/LoggedInVertical";

//region User pages
import PageEditProfile from './pages/userPages/EditProfile/EditProfile';
import PageClaimUsername from './pages/userPages/ClaimUsername/ClaimUsername';
import PageApplyForSkill from './pages/userPages/ApplyForSkill/ApplyForSkill';
import PageSecurityAndLogin from './pages/userPages/SecurityAndLogin/SecurityAndLogin';
import PageBuyMembership from './pages/userPages/BuyMembership/BuyMembership';
import PageHelp from './pages/userPages/Help/Help';
//endregion

//region Skills Applications
import PageSkillsApplications from './pages/SkillsApplications/SkillsApplications';
import PageApproveSkillsApplications from './pages/SkillsApplications/ApproveSkillsApplications';
import PagePreviewSkillsApplications from './pages/SkillsApplications/PreviewSkillApplications';
//endregion

//region Skills
import PageSkillAdmin from './pages/Skill/SkillAdmin';
import PageSkill from './pages/Skill/Skills';
import PageProposeSkill from './pages/Skill/SuggestSkill';
import PageApproveSkill from './pages/Skill/ApproveSkill';
import PageDetailSkill from './pages/Skill/DetailSkill';
//endregion

//region Training
import PageTrainingAdmin from './pages/Training/TrainingAdmin';
import PageTraining from './pages/Training/Trainings';
import PageTrainingNew from './pages/Training/NewTraining';
import PageTrainingApprove from './pages/Training/ApproveTraining';
import PageTrainingEnroll from './pages/Training/Enroll/EnrollTraining';
import PageTrainingsMy from './pages/Training/MyTrainings/MyTrainings';
import PageTrainingTrainer from './pages/Training/TrainingTeach/MyTrainingsTeach';
import PageTrainingTrainerDetail from './pages/Training/TrainingTeach/MyTrainingsTeachDetail';
//endregion

//region EventAdmin
import PageEventAdmin from './pages/Event/EventAdmin';
import PageEvent from './pages/Event/Events';
import PageEventNew from './pages/Event/NewEvent';
import PageEventApprove from './pages/Event/ApproveEvent';
import PageEventRegister from './pages/Event/Register/RegisterEvent';
import PageEventMy from './pages/Event/MyEvents/MyEvents';
import PageEventTrainer from './pages/Event/TeachEvents/MyEventsTeach';
import PageEventTrainerDetail from './pages/Event/TeachEvents/MyEventsTeachDetail';
//endregion

//region Payment
import PagePayment from './pages/Payment/Payment';
import PagePaymentDecline from './pages/Payment/DeclinePayment';
//endregion

//region Resources
import PageResourcesAdmin from './pages/Resources/ResourcesAdmin';
import PageResources from './pages/Resources/Resources';
import PageAddResources from './pages/Resources/AddResource';
import PageApproveResources from './pages/Resources/ApproveResource';
import PagePreviewResources from './pages/Resources/PreviewResource';
import PageDetailResources from './pages/Resources/DetailResource';
//endregion

//region Groups
import PageDiscussionGroups from './pages/DiscussionGroup/DiscussionGroups';
import PageDiscussionGroupDetail from './pages/DiscussionGroup/DiscussionGroupDetail';
//endregion

import PageWallet from './pages/Wallet/Wallet';
import PageHome from './pages/Homes/Home/Home';

//region Error Pages
import ErrorLayout from './layouts/Error/Error';
import Forbidden from './pages/errors/Forbidden/Forbidden';
//endregion

//region Authentication Pages
import AuthenticationLayout from './layouts/Authentication/Authentication';
import PageSignIn from './pages/authentifications/SignIn/SignIn';
import PageSignIn2 from './pages/authentifications/SignIn2/SignIn';
import PageSignUp from './pages/authentifications/SignUp/SignUp';
import PageVerifyAccount from './pages/authentifications/VerifyAccount/VerifyAccount';
import PageResetPassword from './pages/authentifications/ResetPassword/ResetPassword';
import PageResetPasswordForm from './pages/authentifications/ResetPassword/ResetPasswordForm';
//endregion

//region WorkOpportunities pages
import PageWorkOpportunitiesAdmin from './pages/WorkOpportunities/WorkOpportunitiesAdmin';
import PageWorkOpportunities from './pages/WorkOpportunities/WorkOpportunities';
import PageNewWorkOpportunities from './pages/WorkOpportunities/NewWorkOpportunity';
import PageApproveWorkOpportunities from './pages/WorkOpportunities/ApproveWorkOpportunity';
import PageApplicationWorkOpportunities from './pages/WorkOpportunities/Application/ApplicationWorkOpportunity';
import PageMyWorkOpportunities from './pages/WorkOpportunities/MyWOs/MyWorkOpportunities';
import PageMyOpenWorkOpportunities from './pages/WorkOpportunities/OpenWOs/MyWOsOpen';
import PageMyOpenWorkDetailOpportunities from './pages/WorkOpportunities/OpenWOs/MyWOsOpenDetail';
//endregion

import PageViewProfile from './pages/userPages/ViewProfile/ViewProfile';
import PageConnections from './pages/userPages/Connections/Connections';
import PageTest from './pages/testpage/TestPage';
import OpenWorldHome from "./pages/Homes/OpenWorldHome/OpenWorldHome";
import OpenWorldLayout from "./layouts/OpenWorld/OpenWorld";
import PageInvestment from './pages/Investment/Investment';
import PageSearch from './pages/Search/SearchPage';
import PageOnlyMapSearch from './pages/Search/SearchOnlyMapPage';

export interface IRoute {
    path: string;
    component: any;
    exact?: boolean;
    children?: IRoute[];
    auth?: number[]
}

export const loggedInRoutes: IRoute[] = [
    {
        path: '/work-opportunities-admin',
        component: PageWorkOpportunitiesAdmin,
        auth: [1, 2]
    },
    {
        path: '/work-opportunities',
        component: PageWorkOpportunities,
        auth: [1, 2, 3]
    },
    {
        path: '/work-opportunities/new',
        component: PageNewWorkOpportunities,
        auth: [1, 2, 3]
    },
    {
        path: '/work-opportunities/approve/:id',
        component: PageApproveWorkOpportunities,
        auth: [1, 2]
    },
    {
        path: '/work-opportunities/application/:id',
        component: PageApplicationWorkOpportunities,
        auth: [1, 2, 3]
    },
    {
        path: '/my-work-opportunities',
        component: PageMyWorkOpportunities,
        auth: [1, 2, 3]
    },
    {
        path: '/my-work-opportunities/open',
        component: PageMyOpenWorkOpportunities,
        auth: [1, 2, 3]
    },
    {
        path: '/my-work-opportunities/open/:id',
        component: PageMyOpenWorkDetailOpportunities,
        auth: [1, 2, 3]
    },
    {
        path: '/payment/:type/:id',
        component: PagePayment,
        auth: [1, 2, 3]
    },
    {
        path: '/payment/decline',
        component: PagePaymentDecline,
        auth: [1, 2, 3]
    },
    {
        path: '/search/:keyword',
        component: PageSearch,
        auth: [1, 2, 3]
    },
    {
        path: '/search-on-map',
        component: PageOnlyMapSearch,
        auth: [1, 2, 3]
    },
    {
        path: '/my-trainings/trainer/:id',
        component: PageTrainingTrainerDetail,
        auth: [1, 2, 3]
    },
    {
        path: '/my-trainings/trainer',
        component: PageTrainingTrainer,
        auth: [1, 2, 3]
    },
    {
        path: '/trainings-admin',
        component: PageTrainingAdmin,
        auth: [1, 2]
    },
    {
        path: '/trainings',
        component: PageTraining,
        auth: [1, 2, 3]
    },
    {
        path: '/my-trainings',
        component: PageTrainingsMy,
        auth: [1, 2, 3]
    },
    {
        path: '/trainings/new',
        component: PageTrainingNew,
        auth: [1, 2, 3]
    },
    {
        path: '/trainings/approve/:id',
        component: PageTrainingApprove,
        auth: [1, 2]
    },
    {
        path: '/trainings/enroll/:id',
        component: PageTrainingEnroll,
        auth: [1, 2, 3]
    },
    {
        path: '/my-events/organize/:id',
        component: PageEventTrainerDetail,
        auth: [1, 2, 3]
    },
    {
        path: '/my-events/organize',
        component: PageEventTrainer,
        auth: [1, 2, 3]
    },
    {
        path: '/events-admin',
        component: PageEventAdmin,
        auth: [1, 2]
    },
    {
        path: '/events',
        component: PageEvent,
        auth: [1, 2, 3]
    },
    {
        path: '/my-events',
        component: PageEventMy,
        auth: [1, 2, 3]
    },
    {
        path: '/events/new',
        component: PageEventNew,
        auth: [1, 2, 3]
    },
    {
        path: '/events/approve/:id',
        component: PageEventApprove,
        auth: [1, 2]
    },
    {
        path: '/events/register/:id',
        component: PageEventRegister,
        auth: [1, 2, 3]
    },
    {
        path: '/resources-admin',
        component: PageResourcesAdmin,
        auth: [1, 2]
    },
    {
        path: '/resources',
        component: PageResources,
        auth: [1, 2, 3]
    },
    {
        path: '/resources/add',
        component: PageAddResources,
        auth: [1, 2, 3]
    },
    {
        path: '/resources/approve/:id',
        component: PageApproveResources,
        auth: [1, 2]
    },
    {
        path: '/resources/preview/:id',
        component: PagePreviewResources,
        auth: [1, 2, 3]
    },
    {
        path: '/resources/detail/:id',
        component: PageDetailResources,
        auth: [1, 2, 3]
    },
    {
        path: '/skill-applications',
        component: PageSkillsApplications,
        auth: [1, 2]
    },
    {
        path: '/skill-applications/approve/:id',
        component: PageApproveSkillsApplications,
        auth: [1, 2]
    },
    // {
    //     path: '/skill-applications/detail/:id',
    //     component: PagePreviewSkillsApplications
    // },
    {
        path: '/skills-admin',
        component: PageSkillAdmin,
        auth: [1, 2]
    },
    {
        path: '/skills',
        component: PageSkill,
        auth: [1, 2, 3]
    },
    {
        path: '/skills/detail/:id',
        component: PageDetailSkill,
        auth: [1, 2, 3]
    },
    {
        path: '/skills/suggest',
        component: PageProposeSkill,
        auth: [1, 2, 3]
    },
    {
        path: '/skills/approve/:id',
        component: PageApproveSkill,
        auth: [1, 2]
    },
    {
        path: '/edit-profile',
        component: PageEditProfile,
        auth: [1, 2, 3]
    },
    {
        path: '/security-and-login',
        component: PageSecurityAndLogin,
        auth: [1, 2, 3]
    },
    {
        path: '/claim-username',
        component: PageClaimUsername,
        auth: [1, 2, 3]
    },
    {
        path: '/apply-for-skill',
        component: PageApplyForSkill,
        auth: [1, 2, 3]
    },
    {
        path: '/buy-membership',
        component: PageBuyMembership,
        auth: [1, 2, 3]
    },
    {
        path: '/help',
        component: PageHelp,
        auth: [1, 2, 3]
    },
    {
        path: '/404',
        component: NotFound,
        auth: [1, 2, 3]
    },
    {
        path: '/home',
        component: PageHome,
        auth: [1, 2, 3]
    },
    // {
    //     path: '/discussion-groups',
    //     component: PageDiscussionGroups
    // },
    // {
    //     path: '/discussion-group-detail',
    //     component: PageDiscussionGroupDetail
    // },
    {
        path: '/investment',
        component: PageInvestment,
        auth: [1, 2, 3]
    },
    {
        path: '/user-profile/:id',
        component: PageViewProfile,
        auth: [1, 2, 3]
    },
    {
        path: '/connections/:id',
        component: PageConnections,
        auth: [1, 2, 3]
    },
    {
        path: '/wallet',
        component: PageWallet,
        auth: [1, 2, 3]
    },
    {
        path: '/testpage',
        component: PageTest,
        auth: [1, 2, 3]
    }
];

export const authRoutes: IRoute[] = [
    {
        path: '/sign-in',
        component: PageSignIn,
        auth: [0, 1, 2, 3]
    },
    // {
    //     path: '/sign-in2',
    //     component: PageSignIn2,
    //     auth: [0, 1, 2, 3]
    // },
    {
        path: '/sign-up',
        component: PageSignUp,
        auth: [0, 1, 2, 3]
    },
    // {
    //     path: '/verify-account',
    //     component: PageVerifyAccount,
    //     auth: [0, 1, 2, 3]
    // },
    // {
    //     path: '/forgot-password',
    //     component: PageResetPassword,
    //     auth: [0, 1, 2, 3]
    // },
    // {
    //     path: '/reset-password/:token',
    //     component: PageResetPasswordForm,
    //     auth: [0, 1, 2, 3]
    // }
];

export const errorRoutes: IRoute[] = [
    {
        path: '/404',
        component: NotFound,
        auth: [0, 1, 2, 3]
    },
    {
        path: '/505',
        component: Forbidden,
        auth: [0, 1, 2, 3]
    }
];

export const openWorldRoutes: IRoute[] = [
    {
        path: '/home',
        component: OpenWorldHome,
        auth: [0, 1, 2, 3]
    }
];

// 0 No user
// 1 personel
// 2 Firm (non-land-based)
// 3 Firm (land-based)
export const routes: IRoute[] = [
    {
        path: '/logged-in',
        component: LoggedInLayout,
        children: loggedInRoutes,
        auth: [1, 2, 3]
    },
    {
        path: '/vertical',
        component: LoggedInVerticalLayout,
        children: loggedInRoutes,
        auth: [1, 2, 3]
    },
    {
        path: '/error',
        component: ErrorLayout,
        children: errorRoutes,
        auth: [0, 1, 2, 3]
    },
    {
        path: '/auth',
        component: AuthenticationLayout,
        children: authRoutes,
        auth: [0]
    },
    // {
    //     path: '/open-world',
    //     component: OpenWorldLayout,
    //     children: openWorldRoutes,
    //     auth: [0]
    // }
];
