import React, {useEffect, useRef} from 'react';
import {IPageData, IPageProps} from "../../../interfaces/page-data";
import {AppState} from "../../../redux/store";
import {useSelector} from "react-redux";
import {Button, Card, Form, Input} from "antd";
import {CheckOutlined} from "@ant-design/icons";
import toast from "react-hot-toast";
import {IUser, IUserUpdatePassword} from "../../../interfaces/api";
import ApiService from "../../../utils/services/api.service";


const PasswordForm: React.FC<any> = props => {
    const user: IUser = useSelector((state: AppState) => state).user;
    const formRef = useRef(null);
    const compareToFirstPassword = (rule, value, callback) => {
        if (value && value !== formRef.current.getFieldValue('new')) {
            callback('Passwords not matching');
        } else {
            callback();
        }
    };

    const onFinish = async (data) => {
        let response = await ApiService.User.PutUserUpdatePassword(user.id, {id: user.id, password: data.confirm, currentPassword: data.currentPassword} as IUserUpdatePassword);
        if (response.data.toLowerCase() === 'true')
            toast.success('Password changed successfully.');
        else
            toast.error('Password not changed.');
    }

    return (
        <Form
            colon={false}
            requiredMark='optional'
            onFinish={onFinish}
            ref={formRef}
            labelCol={{span: 5}} wrapperCol={{span: 19}}
        >
            <Form.Item label='Current password'
                       name='currentPassword'
                       rules={[
                           {
                               required: true,
                               message: 'Please input your password!',
                               whitespace: false,
                               pattern: new RegExp(/^\S*$/)
                           }, {
                               required: true,
                               min: 3,
                               message: 'Password must be at least 5 digits!'
                           }
                       ]}><Input.Password size='small'/>
            </Form.Item>

            <div>
                <Form.Item
                    label='New password'
                    name='new'
                    rules={[
                        {
                            required: true,
                            message: 'Please enter new password!',
                            whitespace: false,
                            pattern: new RegExp(/^\S*$/)
                        }, {
                            required: true,
                            min: 5,
                            message: 'Password must be at least 5 digits!'
                        }
                    ]}><Input.Password size='small'/>
                </Form.Item>

                <Form.Item
                    label='Confirm Password'
                    name='confirm'
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm new password!',
                            whitespace: false,
                            pattern: new RegExp(/^\S*$/)
                        }, {
                            required: true,
                            min: 5,
                            message: 'Password must be at least 5 digits!'
                        },
                        {
                            validator: compareToFirstPassword
                        }
                    ]}><Input.Password size='small'/>
                </Form.Item>
            </div>
            <Form.Item wrapperCol={{md: {span: 19, offset: 5}, xs: {offset: 0}}}>
                <div className='d-flex justify-content-between'>
                    <span/>
                    <Button className='responsive-button' type='primary' htmlType='submit' icon={<CheckOutlined />}>Change password</Button>
                </div>
            </Form.Item>
        </Form>
    );
};

const SecurityAndLogin: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'Security And Login',
        loaded: true
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => onSetPage(pageData), []);

    return (
        <div className='row justify-content-center'>
            <div className='col col-12 col-xl-8'>
                <Card title='Change password' className='mb-0'>
                    <PasswordForm/>
                </Card>
            </div>
        </div>
    );
};

export default SecurityAndLogin;
