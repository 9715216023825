import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../interfaces/page-data';
import {Button, Col, Row} from "antd";
import {history} from "../../redux/store";
import {IPageQuery, IResource} from "../../interfaces/api";
import {PlusOutlined} from "@ant-design/icons";
import ResponsiveButton from "../../components/ResponsiveButton/ResponsiveButton";
import ApiService from "../../utils/services/api.service";
import Spinner from "../../utils/Spinner";
import ResourceHorizontalCard from "../../components/ResourceCard/ResourceHorizontalCard";
import ResourceCard from "../../components/ResourceCard/ResourceCard";

let pQuery = {numOfRecords: 4, page: 1} as IPageQuery;
const Resources: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'Resources',
        loaded: true
    };
    const [resources, setResources] = useState<Array<IResource>>([]);
    const [dataIsLoaded, dataSetLoaded] = useState<boolean>(false);
    const [isMore, setIsMore] = useState<boolean>(true);

    useEffect(() => {
        onSetPage(pageData);
        pQuery = {numOfRecords: 4, page: 1}
        getResources().then(null);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getResources = async () => {
        dataSetLoaded(false);
        let resourceResponse = await ApiService.Resource.GetResourceListByPage(pQuery);
        let tpQuery = pQuery;
        let nextResponse = await ApiService.Resource.GetResourceListByPage({page: tpQuery.page+1, numOfRecords: tpQuery.numOfRecords});
        let t: IResource[] = resourceResponse.data;
        if (nextResponse.data.length > 0){
            setIsMore(true)
        } else setIsMore(false);
        setResources([...resources, ...t])
        dataSetLoaded(true);
    }

    const loadMore = async () => {
        pQuery.page = pQuery.page + 1;
        await getResources();
    }

    return (
        <div>
            <div className='d-flex justify-content-between'>
                <span/>
                <ResponsiveButton
                    text='Share Your Voice'
                    icon={<PlusOutlined/>}
                    onClick={() => {
                        history.push('/vertical/resources/add')
                    }}/>
            </div>
            <br/>
            <Row gutter={[23, 3]} >
                {
                    resources.map((item) => {
                        return (
                            <Col key={item.id} xl={{span: 6}} lg={{span: 8}} md={{span: 6}} sm={{span: 12}}
                                 xs={{span: 24}}>
                                <ResourceCard key={item.id} resource={item}/>
                            </Col>
                        );
                    })
                }
                {
                    !dataIsLoaded ?
                        <Spinner topElementClass='.main-content'/>
                        :
                        <Col span={24} style={{textAlign: 'center'}}>
                            {isMore ?
                                <div className='col-12' style={{textAlign: 'center'}}>
                                    <Button onClick={() => {
                                        loadMore()
                                    }}>Load More</Button>
                                </div>
                                :
                                <div style={{width: '100%', textAlign: 'center'}}>End of
                                    results</div>
                            }
                        </Col>
                }
            </Row>
        </div>
    )
};

export default Resources;
