import React, {useEffect, useState} from 'react';
import {IPageData, IPageProps} from "../../../interfaces/page-data";
import {AppState, history} from "../../../redux/store";
import {Button, Card, Form, Select, Upload} from "antd";
import {CheckOutlined, UploadOutlined} from "@ant-design/icons";
import toast from "react-hot-toast";
import TextEditor from "../../../components/TextEditor/TextEditorWithoutButton";
import AutoCompleteSkills from "../../../components/AutoCompleteSkills/AutoCompleteSkills";
import {IUserSkillCreate, IUser, IUserSkillType} from "../../../interfaces/api";
import {useSelector} from "react-redux";
import ApiService from "../../../utils/services/api.service";

const ApplyForSkill: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'Apply For Skill',
        loaded: true
    };
    const user: IUser = useSelector((state: AppState) => state).user;
    const [uploadFiles, setUploadFiles] = useState<any>(null);
    const [userTypes, setUserTypes] = useState<IUserSkillType[]>([]);

    useEffect(() => {
        onSetPage(pageData);

        const any = async () => {
            let skillTypesResponse = await ApiService.UserSkillType.GetUserSkillType();
            setUserTypes(skillTypesResponse.data)
        }
        any().then(null);
    }, []);


    const onFinish = async (data) => {
        console.dir(data);
        let tData: IUserSkillCreate =  data;
        tData.skillId = data.skillId[0].value;
        tData.userId = user.id;
        tData.certificates = uploadFiles
        console.dir(tData);

        let response = await ApiService.UserSkill.PostUserSkill(tData);
        if (response.status === 200){
            history.push('/vertical/home');
        }
    };

    return (
        <div className='row justify-content-center'>
            <div className='col col-12 col-xl-8'>
                <Card title='Apply For Skill' className='mb-0' loading={userTypes.length < 1}>
                    <Form colon={false} onFinish={onFinish} requiredMark='optional' labelCol={{span: 5}}
                          wrapperCol={{span: 19}}>
                        <Form.Item label='User Skill' name='userSkillTypeId' initialValue={3}
                                   rules={[{required: true, message: 'Skill type cannot be blank'}]}>
                            <Select size='middle'>
                                {
                                    userTypes.map( (type) => {
                                        return (<Select.Option key={type.id} value={type.id}>{type.name}</Select.Option>)
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label='Skill Name' name='skillId'
                                   rules={[{required: true, message: 'Skill name cannot be blank'}]}
                                   extra={<span style={{fontSize: '10px'}}>Find your skill</span>}>
                            <AutoCompleteSkills
                                singleTag={true}
                                unknownTag={false}
                            />
                        </Form.Item>
                        <Form.Item label='Certificates'
                                   name='certificates'
                            //valuePropName="fileList"
                                   rules={[{required: true, message: 'Certificates cannot be blank'}]}
                                   extra={<span style={{fontSize: '10px'}}>Any file smaller than 2MB in size is accepted.</span>}
                        >
                            <Upload
                                maxCount={3}
                                multiple={true}
                                listType='picture'
                                onChange={info => {
                                    let t : Array<any> = info.fileList.map( (file) => {
                                        return (file.originFileObj);
                                    })
                                    setUploadFiles(t);
                                    return false;
                                }}
                                beforeUpload={(file) => {
                                    const isLt2MB = file.size / 1024 < 2048;
                                    if (!isLt2MB) {
                                        toast.error('File must smaller than 2MB!')
                                        return Upload.LIST_IGNORE;
                                    }
                                    return false;
                                }}
                            >
                                <Button icon={<UploadOutlined/>} size='small'>Click to upload</Button>
                            </Upload>
                        </Form.Item>
                        <Form.Item label='Message' name='message'
                                   rules={[{required: true, message: 'Message cannot be blank'}]}>
                            <TextEditor image={false} toolBarHidden={true}/>
                        </Form.Item>
                        <Form.Item wrapperCol={{md: {span: 19, offset: 5}, xs: {offset: 0}}}>
                            <div className='d-flex justify-content-between'>
                                <span/>
                                <Button className='responsive-button' type='primary' htmlType='submit'
                                        icon={<CheckOutlined/>}>Apply</Button>
                            </div>

                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </div>
    );
};

export default ApplyForSkill;
