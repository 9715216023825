import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../interfaces/page-data';
import {Button, Card, Col, Modal, Row, Select, Space, Table} from "antd";
import {history} from "../../redux/store";
import {ITraining} from "../../interfaces/api";
import toast from "react-hot-toast";
import {CheckOutlined, DeleteOutlined, LeftOutlined, PlusOutlined} from "@ant-design/icons";
import moment from "moment";
import {DATETIME_FORMAT} from "../../utils/Constants";
import ResponsiveButton from "../../components/ResponsiveButton/ResponsiveButton";
import ApiService from "../../utils/services/api.service";

const TrainingAdmin: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'Training',
        loaded: true
    };
    useEffect(() => {
        onSetPage(pageData);
        trainingsSetLoaded(true);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const columnsInitial = [
        {
            title: "Name",
            dataIndex: "trainingName",
            ellipsis: false,
        },
        {
            title: "Deadline",
            dataIndex: "deadline",
            width: '150px',
            ellipsis: false,
            render: date => <>{
                date == "0001-01-01T00:00:00" ? "Uncertain Date" : moment(date).format(DATETIME_FORMAT)
            }
            </>
        },
        {
            title: "Start",
            dataIndex: "startDate",
            width: '150px',
            ellipsis: false,
            render: date => <>{
                date == "0001-01-01T00:00:00" ? "Uncertain Date" : moment(date).format(DATETIME_FORMAT)
            }
            </>
        },
        {
            title: "Finish",
            dataIndex: "finishDate",
            width: '150px',
            ellipsis: false,
            render: date => <>{
                date == "0001-01-01T00:00:00" ? "Uncertain Date" : moment(date).format(DATETIME_FORMAT)
            }</>
        },
        {
            title: "",
            dataIndex: "isApproved",
            width: '150px',
            ellipsis: false,
            hidden: false,
            render: (data, row: ITraining) => {
                if (data === true) {
                    return (
                        <div style={{textAlign: 'center'}}>
                            <Button
                                icon={<DeleteOutlined/>}
                                size='small'
                                shape='circle'
                                danger
                                onClick={() => {deleteTraining(row)}}>
                            </Button>
                        </div>
                    )
                }
                else {
                    return (<Space>
                        <Button size='small'
                                onClick={() => {
                                    history.push({
                                        pathname: `trainings/approve/${row.id}`
                                    });
                                }}>Approve</Button>
                        <Button
                            icon={<DeleteOutlined/>}
                            size='small'
                            shape='circle'
                            danger
                            onClick={() => {deleteTraining(row)}}>
                        </Button>
                    </Space>)
                }
            }
        }
    ]

    const [trainings, setTrainings] = useState<Array<ITraining>>([]);
    const [trainingsIsLoaded, trainingsSetLoaded] = useState<boolean>(false);
    const [isApproved, setApproved] = useState('approved');
    //const [columns, setColumns] = useState<Array<object>>(columnsInitial);
    const [columns, setColumns] = useState<Array<object>>(columnsInitial.filter(item => !item.hidden));

    const any = async () => {
        let response = isApproved === 'approved' ? await ApiService.Training.GetTrainingApproved() : await ApiService.Training.GetTrainingUnapproved();
        setTrainings(response.data);
        trainingsSetLoaded(false);
    }

    useEffect(() => {
        if (!trainingsIsLoaded)
            return;
        any().then(null);
    }, [trainingsIsLoaded])

    const deleteTraining = async (rowData: ITraining) => {
        Modal.confirm({
            closable: false,
            title: 'Are you sure to delete?',
            okText: 'Confirm',
            cancelText: 'Cancel',
            okButtonProps: {
                className: 'responsive-button',
                icon: <CheckOutlined/>
            },
            cancelButtonProps: {
                className: 'responsive-button',
                icon: <LeftOutlined/>,
                danger: true
            },
            onOk: async () => {
                let response = await ApiService.Training.DeleteTrainingById(rowData.id);
                if (response.status === 200){
                    trainingsSetLoaded(true);
                }
            },
            onCancel: async () => {
                return;
            }
        });
    }

    return (
        <div>
            <div className='row'>
                <div className='col-12'>
                    <Card title={
                        <Row gutter={[10, 10]}>
                            <Col xs={{span: 24}} md={{span: 8}}>
                                <span className='card-title-page'>Trainings</span>
                            </Col>
                            <Col xs={{span: 19}} sm={{span: 21}} md={{span: 6, offset: 8}} lg={{span: 6, offset: 7}}
                                 xl={{span: 4, offset: 10}} xxl={{span: 3, offset: 12}}>
                                <Select onChange={(e) => {
                                    setApproved(e);
                                    trainingsSetLoaded(true);
                                    e === 'approved' ? setColumns(columnsInitial.filter(item => !item.hidden)) : setColumns(columnsInitial);
                                }} defaultValue={isApproved}>
                                    <Select.Option key='1' value='approved'>Approved</Select.Option>
                                    <Select.Option key='2' value='unapproved'>Unapproved</Select.Option>
                                </Select>
                            </Col>
                            <Col xs={{span: 5}} sm={{span: 3}} md={{span: 2}} lg={{span: 3}} xl={{span: 2}}
                                 xxl={{span: 1}} style={{textAlign: 'right', paddingLeft:'0px'}}>
                                <ResponsiveButton
                                    text='Post'
                                    icon={<PlusOutlined/>}
                                    onClick={() => {
                                        history.push('/vertical/trainings/new')
                                    }}/>
                            </Col>
                        </Row>
                    }>
                        <Table
                            rowKey={(row: ITraining) => row.id}
                            dataSource={trainings.length > 0 && trainings}
                            columns={columns}
                            loading={trainingsIsLoaded}
                        />
                    </Card>
                </div>
            </div>

        </div>
    )
};

export default TrainingAdmin;
