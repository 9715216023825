import React, {useEffect} from 'react';
import {IPageData, IPageProps} from "../../../interfaces/page-data";

const Help: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    //const {user} = useSelector((state: AppState) => state);
    const pageData: IPageData = {
        title: 'Help',
        loaded: true
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => onSetPage(pageData), []);

    return (
        <>
            <div className='row'>
            </div>
        </>
    );
};

export default Help;
