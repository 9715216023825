import React, {useEffect, useState} from 'react';
import GoogleMapReact from 'google-map-react';
import './GMForModule.scss';
import SearchBox from "./SearchBox";
import {IMarker} from "../../../interfaces/api";
import {API_KEY, greenwichCoord, mobileStyles, webStyles} from '../Datas';
import {ModuleColors} from "../../../utils/Constants";

interface MapProps {
    onChange?: any,
    haveSearchBox?: boolean,
    disabled?: boolean,
    value?: {lat: number, lng: number},
    scrollWheel?: boolean,
    defaultValue?: {lat: number, lng: number},
    markerColorByModule: ModuleColors
}


let marker: google.maps.Marker = null;
const GMForModule: React.FC<MapProps> = props => {
    const {value} = props;
    const [style, setStyle] = useState(webStyles);
    //const [marker, setMarker] = useState<google.maps.Marker>(null);
    const [center, setCenter] = useState<{ lat: number, lng: number }>(greenwichCoord);
    const [mapApiLoaded, setMapApiLoaded] = useState<boolean>(false);
    const [mapsApi, setMapsApi] = useState<any>(null);
    let MAP_API: google.maps.Map;

    const isZeroMark = () => {
        if (value.lat === 0 && value.lng === 0)
            return true;
    }

    useEffect(() => {
        const updateMapStyles = () => {
            const styles = window.innerWidth < 768 ? mobileStyles : webStyles;
            setStyle(styles);
        };
        updateMapStyles();
        window.addEventListener('resize', updateMapStyles);
        return () => window.removeEventListener('resize', updateMapStyles);
    }, []);

    useEffect(() => {
        if (isZeroMark()) {
            marker = null
            setCenter(greenwichCoord);
        }
        else {
            updatePosition(value)
            setCenter(value);
        }
    }, [value]) // eslint-disable-line react-hooks/exhaustive-deps

    const apiHasLoaded = (map, maps) => {
        let dfValue = props.defaultValue;
        console.dir(props.defaultValue)
        createMarker(map, dfValue);
        setCenter(dfValue);

        google.maps.event.addListener(map, 'click', (event) => {
            if (props.disabled)
                return;

            let pos: IMarker = {id: 0, point: {lat: event.latLng.lat(), lng: event.latLng.lng()}};
            updatePosition(pos.point)
        });
        setMapApiLoaded(true);
        setMapsApi(maps);
        MAP_API = map;
    };

    const onPlaceChanged = (place) => {
        updatePosition({lat: place[0].geometry.location.lat(), lng: place[0].geometry.location.lng()})
        setCenter({lat: place[0].geometry.location.lat(), lng: place[0].geometry.location.lng()});
    };

    const createMarker = (map, point: {lat: number, lng: number}) => {
        //removeMarker();

        marker = new google.maps.Marker({
            position: point,
            map: map,
            icon: {
                path: google.maps.SymbolPath.CIRCLE,
                fillColor: props.markerColorByModule,
                fillOpacity: 0.6,
                strokeColor: 'white',
                strokeOpacity: 0.9,
                strokeWeight: 1,
                scale: 7
            },
            clickable: false,
            animation: google.maps.Animation.DROP
        });
        props.onChange({lat: marker.getPosition().lat(), lng: marker.getPosition().lng()});
    }

    const updatePosition = (point: {lat: number, lng: number}) => {
        if (marker == null){
            return;
        }
        marker.setPosition(point);
        props.onChange(point);
    }

    const removeMarker = () => {
        if (marker == null)
            return;
        marker.setMap(null);
        marker = null;
    }

    return (
        <div style={{
            height: `${props.haveSearchBox ? '66vh' : '60vh'}`,
            width: '100%',
            position: 'relative'
        }}>
            {
                mapApiLoaded &&
                <div style={{display: props.haveSearchBox ? "block" : "none"}}>
                    <SearchBox maps={mapsApi} onPlacesChanged={onPlaceChanged}
                               placeholder={'Enter an address or a coordinate'}/>
                </div>
            }

            <div
                style={{
                    height: '60vh',
                    width: '100%',
                    position: 'absolute',
                    bottom: 0
                }}
            >
                <GoogleMapReact
                    style={style}
                    defaultCenter={greenwichCoord}
                    center={center}
                    defaultZoom={15}
                    bootstrapURLKeys={{
                        key: API_KEY,
                        libraries: ['places', 'geometry'],
                    }}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({map, maps}) => apiHasLoaded(map, maps)}
                    options={{
                        scrollwheel: props.scrollWheel,
                        //panControl: props.scrollWheel
                    }}
                />
            </div>
        </div>
    );
};

GMForModule.defaultProps = {
    haveSearchBox: false,
    disabled: false,
    onChange: () => {},
    value: {lat: 0, lng:0},
    scrollWheel: true,
    defaultValue: greenwichCoord
}

export default GMForModule;
