import React, {useEffect, useState} from 'react';
import {Select, Spin} from 'antd';
import {IUser} from "../../interfaces/api";
import {ACOMPLETE_DEBOUNCE_TIMEOUT} from "../../utils/Constants";
import ApiService from "../../utils/services/api.service";

export interface IUserAComplete {
    label: string,
    value: string,
    key: string,
    disabled?: boolean
}

interface AutocompleteProps {
    selectedTags?: (text: any) => void,
    disabled?: boolean,
    singleTag?: boolean,
    onChange?: (text: any) => void,
    resultChild?: (text: any) => void,
    tags?: Array<IUserAComplete>
}

let debounce: NodeJS.Timeout;
const AutoCompleteUsers: React.FC<AutocompleteProps> = props => {

    const [children, setChildren] = useState<any>([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<Array<string>>([]);
    const [isRendered, setRendered] = useState<boolean>(false);

    const onSearch = async (searchText: string) => {
        clearTimeout(debounce);
        setChildren([]);
        if (searchText.length < 1) {
            return;
        }
        setLoading(true);
        debounce = setTimeout(async () => {
            let res: Array<IUserAComplete> = [];
            let response = await ApiService.User.GetUserSearchByKeyword(searchText, {page:1, numOfRecords: 99999, isAllResults: true});
            if (response.status !== 200){
                setLoading(false);
            }
            res = response.data.map((item: IUser) => {
                return {label: item.name + ' ' + item.surname, value: item.id} as IUserAComplete;
            })
            setLoading(false);
            setChildren(res)
        }, ACOMPLETE_DEBOUNCE_TIMEOUT);
    };

    const onSelect = () => {
        setChildren([]);
    }

    const onChange = (objs: Array<any>) => {
        setLoading(true);
        props.onChange(objs);
        if (objs == null)
            return;
        if (props.singleTag && objs.length > 1) {
            objs = objs.slice(1);
        }
        setData(objs);
        setLoading(false);
    }

    useEffect(() => {
        setRendered(true);
    }, [])


    useEffect(() => {
        if (!isRendered) {
            props.selectedTags([]);
            //props.onChange([]);
            return;
        }
        props.selectedTags(data);
        //props.onChange(data);
    }, [data])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let t: Array<any> = [...children, ...props.tags];
        setData(t)
    }, [props.tags])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Select
            filterOption={false}
            labelInValue
            mode='multiple'
            notFoundContent={isLoading ? <Spin size="small"/> : null}
            style={{width: '100%'}}
            onSearch={onSearch}
            onSelect={onSelect}
            onChange={onChange}
            options={children}
            disabled={props.disabled}
            aria-multiline={true}
            value={data}
            loading={isLoading}
        >
            {/*{children}*/}
        </Select>
    );
};

AutoCompleteUsers.defaultProps = {
    disabled: false,
    singleTag: false,
    onChange: () => {
    },
    selectedTags: () => {
    },
    resultChild: () => {
    },
    tags: []
}

export default AutoCompleteUsers;
