import React, {CSSProperties, useEffect, useState} from 'react';
import './Search.scss';
import {history} from '../../../redux/store';

import Autocomplete from '../../../components/Autocomplete/Autocomplete';
import className from '../../../utils/classNames';
import {IAutocompleteOption} from '../../../interfaces/autocomplete-option';
import {IMenuItem, IMenuItemSub} from '../../../interfaces/menu';
import {Button, Form, Input} from 'antd';
import {useParams} from "react-router-dom";
import useMediaQuery from "../../../utils/MediaQuery";
import {SearchOutlined} from "@ant-design/icons";
import AutocompleteSearch from "../../../components/AutoCompleteSearch/AutoCompleteSearch";

interface SearchProps {
    dataKey?: string;
    titleKey?: string;
    layout?: string;
    className?: string;
    style?: CSSProperties;
}

const Search: React.FunctionComponent<SearchProps> = props => {
    const [form] = Form.useForm(null);

    const searchClassName = className({
        search: true,
        [props.className]: props.className
    });

    const onOptionSelected = (keyword: string) => {
        history.push(`/vertical/search/${keyword}`);
    };

    return (
        <div className={searchClassName}>
            <Form form={form} layout='inline'>
                <Form.Item name='search' className='search-wrap'>
                    <AutocompleteSearch
                        style={props.style}
                        placeholder="Type keyword"
                        prefixIcon='icofont-search'
                        optionSelected={val => onOptionSelected(val)}/>
                </Form.Item>
            </Form>
        </div>
    );
};

Search.defaultProps = {
    dataKey: 'routing',
    titleKey: 'title',
    layout: 'vertical'
};

export default Search;
