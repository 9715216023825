import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../../interfaces/page-data';
import {Button, Card, Col, Modal, Row, Select, Space, Table} from "antd";
import {AppState, history} from "../../../redux/store";

import {IEvent, IUser} from "../../../interfaces/api";
import toast from "react-hot-toast";
import {CheckOutlined, CloseOutlined, InfoOutlined, LeftOutlined} from "@ant-design/icons";
import moment from "moment";
import {DATETIME_FORMAT} from "../../../utils/Constants";
import {useSelector} from "react-redux";
import ApiService from "../../../utils/services/api.service";

const MyEventsTeach: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'My Proposed Events',
        loaded: true
    };

    const user: IUser = useSelector((state: AppState) => state).user;
    const [events, setEvents] = useState<Array<IEvent>>([]);
    const [eventsIsLoaded, eventsSetLoaded] = useState<boolean>(false);
    const [isFinished, setFinished] = useState<string>('false');

    const columnsInitial = [
        {
            title: "Name",
            dataIndex: "eventName",
            ellipsis: false,
        },
        {
            title: "Deadline",
            dataIndex: "deadline",
            width: '150px',
            ellipsis: false,
            render: date => <>{
                date == "0001-01-01T00:00:00" ? "Uncertain Date" : moment(date).format(DATETIME_FORMAT)
            }
            </>
        },
        {
            title: "Start",
            dataIndex: "startDate",
            width: '150px',
            ellipsis: false,
            render: date => <>{
                date == "0001-01-01T00:00:00" ? "Uncertain Date" : moment(date).format(DATETIME_FORMAT)
            }
            </>
        },
        {
            title: "Finish",
            dataIndex: "finishDate",
            width: '150px',
            ellipsis: false,
            render: date => <>{
                date == "0001-01-01T00:00:00" ? "Uncertain Date" : moment(date).format(DATETIME_FORMAT)
            }</>
        },
        {
            title: "",
            dataIndex: "isFinished",
            width: '150px',
            ellipsis: false,
            render: (cell, row: IEvent) => {
                return (<>
                    <div style={{textAlign: 'center'}}>
                        <Space>
                            {
                                //TODO kontrol ettir
                                // isActive &&
                                // !row.isFinished &&
                                // isFinish === 'false' &&
                                <Button size='small' onClick={() => {
                                    cancelEvent(row.id)
                                }}
                                        icon={<CloseOutlined/>}
                                        shape='circle'
                                        danger
                                >
                                </Button>
                            }
                            <Button size='small' onClick={() => {
                                console.log('participations list')
                                history.push(`/vertical/my-events/organize/${row.id}`)
                            }}
                                    icon={<InfoOutlined/>}
                                    shape='circle'
                            >
                            </Button>
                        </Space>
                    </div>
                </>)
            }
        }
    ]

    useEffect(() => {
        onSetPage(pageData);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        console.log(eventsIsLoaded)
        if (eventsIsLoaded)
            return;
        getEvents();
    }, [eventsIsLoaded])

    const getEvents = async () => {
        let response = await ApiService.Event.GetEventByOrganizerId(user.id, {isFinished: isFinished === 'true'});
        setEvents(response.data);
        eventsSetLoaded(true);
    }

    const cancelEvent = (eventId: string) => {
        Modal.confirm({
            closable: false,
            title: 'Cancel Event',
            okText: 'Confirm',
            okButtonProps: {
                className: 'responsive-button',
                icon: <CheckOutlined/>
            },
            onOk: async () => {
                let response = await ApiService.Event.PutEventCancelByEventId(eventId);
                if (response.status === 200){
                    eventsSetLoaded(false)
                }
            },
            cancelButtonProps: {
                className: 'responsive-button',
                icon: <LeftOutlined/>,
                danger: true
            }
        });
    }

    return (
        <div>
            <div className='row'>
                <div className='col-12'>
                    <Card title={
                        <Row>
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <span className='card-title-page'>My Organised Events</span>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 4, offset: 8}}>
                                <Select onChange={(e) => {
                                    setEvents([]);
                                    setFinished(e);
                                    eventsSetLoaded(false);
                                }} defaultValue={isFinished}>
                                    <Select.Option key='1' value='true'>Finished</Select.Option>
                                    <Select.Option key='2' value='false'>Not finished</Select.Option>
                                </Select>
                            </Col>
                        </Row>
                    }>
                        <Table
                            rowKey={(row: IEvent) => row.id}
                            dataSource={events.length > 0 && events}
                            columns={columnsInitial}
                            loading={!eventsIsLoaded}
                        />
                    </Card>
                </div>
            </div>
        </div>
    )
};

export default MyEventsTeach;
