import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../../interfaces/page-data';
import {Button, Col, Row, Image, Card, Tag} from "antd";
import {
    ISkill,
    ITraining,
    ITrainingEnroll,
    ITrainingImage,
    IUser,
    ITrainingApprovePayment,
    ITrainingApplication, ITrainingTag
} from "../../../interfaces/api";
import toast from "react-hot-toast";
import {useParams} from "react-router-dom";
import {DATETIME_FORMAT, ModuleColors, S3_SERVER_URL} from "../../../utils/Constants";
import {AppState, history} from "../../../redux/store";
import moment from "moment";
import './EnrollTraining.scss';
import {isGuid} from "../../../utils/GlobalFuncs";
import SkillCard from "../../../components/SkillCard/SkillCard";
import Countdown from "../../../components/Countdown/Countdown";
import PersonCard from "../../../components/PersonCard/PersonCard";
import {useSelector} from "react-redux";
import GMForModule from "../../../components/GoogleMaps/ForModule/GMForModule";
import {ITagAComplete} from "../../../components/AutoCompleteTags/AutoCompleteTags";
import ImgViewer from "../../../components/ImgViewer/ImgViewer";
import ApiService from "../../../utils/services/api.service";

const EnrollTraining: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'Training Enroll',
        loaded: true
    };

    const user: IUser = useSelector((state: AppState) => state).user;
    const [isLoaded, setLoaded] = useState<boolean>(false);
    const [training, setTraining] = useState<ITraining>({} as ITraining);
    const [provider, setProvider] = useState<IUser>({} as IUser);
    const [trainingTags, setTrainingTags] = useState<ITrainingTag[]>([]);
    const [providedSkills, setProvidedSkills] = useState<ISkill[]>([]);
    const [requiredSkills, setRequiredSkills] = useState<ISkill[]>([]);
    const [isApplicant, setApplicant] = useState<boolean>(false);
    const [images, setImages] = useState<ITrainingImage[]>([]);
    const [deadlineIsOver, setDeadlineIsOver] = useState<boolean>(false);
    const trainingId = useParams<{ id?: string }>().id;
    const gutter: any = [0, 0];
    useEffect(() => {
        onSetPage(pageData);
        if (!isGuid(trainingId))
            return;

        const any = async () => {
            let trainingResponse = await ApiService.Training.GetTrainingById(trainingId);
            let tmpTraining: ITraining = trainingResponse.data;
            setTraining(tmpTraining);
            setDeadlineIsOver((moment(tmpTraining.deadline).valueOf() - moment().valueOf()) / 1000 < 0);
            setProvider(tmpTraining.providedBy == null ? {} as IUser : tmpTraining.providedBy);

            let applicantResponse = await ApiService.Training.GetTrainingByApplicant(user.id);
            let trainingIsExist: boolean = applicantResponse.data.findIndex((item) => item.trainingId === trainingId) > -1;
            setApplicant(trainingIsExist);

            let tagsResponse = await ApiService.TrainingTag.GetTrainingTagByTraining(trainingId);
            setTrainingTags(tagsResponse.data);

            let providedResponse = await ApiService.TrainingReward.GetTrainingRewardByTrainingId(trainingId);
            setProvidedSkills(providedResponse.data);

            let requiredResponse = await ApiService.TrainingPrerequisite.GetTrainingPrerequisiteByTrainingId(trainingId);
            setRequiredSkills(requiredResponse.data);

            let imagesResponse = await ApiService.TrainingImage.GetTrainingImageByTrainingId(trainingId);
            setImages(imagesResponse.data);

            setLoaded(true);
        }
        any().then(null);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const enrollClick = async () => {
        //toast.success('Enrolling...');
        if (training.participationFee !== 0) {
            // toast.success('Routing payment page...');
            history.push(`/vertical/payment/training/${trainingId}`);
        }
        else {
            let data = {
                trainingId: trainingId,
                userId: user.id
            } as ITrainingEnroll;
            let applicationResponse = await ApiService.TrainingApplication.PostTrainingApplicationEnroll(data);
            if (applicationResponse.status !== 200){
                // todo
                return;
            }

            let data2 = {
                trainingId: trainingId,
                userId: user.id
            } as ITrainingApprovePayment;
            let approveResponse = await ApiService.Training.PostTrainingApprovePayment(data2);
            if (approveResponse.status === 200){
                history.push('/vertical/my-trainings');
            }
        }
    }

    return (
        <div className='enroll-training-page'>
            <Card
                className='ant-card-invisible'
                // bodyStyle={{padding:'20px'}}
                loading={!isLoaded}>
                {/*Training Details*/}
                <Row>
                    {/*Training name, desc, time left....*/}
                    <Col className='cell-gap' md={{order: 1, span: 12}} lg={{order: 1, span: 12}}
                         xs={{order: 2, span: 24}}>
                        {/*<Row>*/}
                        {/*    <Col xl={{span: 6}} lg={{span: 5}} md={{span: 4}} sm={{span: 3}} xs={{span: 5}}>*/}
                        {/*        <span className='when-hour'>When: </span>*/}
                        {/*    </Col>*/}
                        {/*    <Col span={10}>*/}
                        {/*        <span*/}
                        {/*            className='when-hour-date'>{moment(training.startDate).format("DD.MM.YYYY")}</span>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        {/*<Row>*/}
                        {/*    <Col xl={{span: 6}} lg={{span: 5}} md={{span: 4}} sm={{span: 3}} xs={{span: 5}}>*/}
                        {/*        <span className='when-hour'>Hour: </span>*/}
                        {/*    </Col>*/}
                        {/*    <Col span={10}>*/}
                        {/*        <span className='when-hour-date'>{moment(training.startDate).format("HH:mm")}</span>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        {/*<br/>*/}
                        <h3>{training.trainingName}</h3>
                        <br/>
                        <br/>
                        <div style={{padding: '10px'}} className='desc-tag'>
                            <div dangerouslySetInnerHTML={{__html: training && training.description}}/>
                        </div>
                        {/*{*/}
                        {/*    (!objIsEmpty(provider)) &&*/}
                        {/*    <>*/}
                        {/*        <div>{provider.name + ' ' + provider.surname}</div>*/}
                        {/*    </>*/}
                        {/*}*/}

                        {
                            user.id === provider.id ?
                                <>
                                </>
                                :
                                <>{
                                    isApplicant ?
                                        <h5>
                                            You have already enrolled
                                        </h5>
                                        :
                                        <>
                                            {
                                                deadlineIsOver ?
                                                    <h5>
                                                        Enrollment is over
                                                    </h5>
                                                    :
                                                    <>
                                                        <h6>Time left before enroll ends</h6>
                                                        <div className='align-items'>
                                                            <Countdown date={training.deadline}/>
                                                        </div>
                                                        <br/>
                                                        <div className='align-items'>
                                                            <Button onClick={enrollClick} type='primary'>Enroll Now</Button>
                                                        </div>
                                                    </>
                                            }
                                        </>
                                }</>
                        }

                        {/*<h6>Deadline</h6>*/}
                        {/*<div>{moment(training.deadline).format(DATE_FORMAT)}</div>*/}
                        {/*<h6>Start - Finish</h6>*/}
                        {/*<div>{moment(training.startDate).format(DATE_FORMAT)}-{moment(training.finishDate).format(DATE_FORMAT)}</div>*/}

                    </Col>
                    {/*training image, price, space left, location*/}
                    <Col className='cell-gap' md={{order: 2, span: 12}}
                         lg={{order: 2, span: 12}} xs={{order: 1, span: 24}}>
                        <Row className='training-info-border'>
                            <Col span={24}>
                                <div className='img-item'>
                                    <ImgViewer src={S3_SERVER_URL + training.uploadedThumbnail} ratio={2} width={'100%'}/>
                                </div>
                            </Col>
                            {/*<div className='info-item'>*/}
                            <Col className='info-item' span={24}>
                                <div>
                                    <span className='header'>Start Date</span>
                                    <br/>
                                    <span
                                        className='info'>{moment(training.startDate).format(DATETIME_FORMAT)}</span>
                                </div>
                            </Col>
                            <Col className='info-item' span={24}>
                                <div>
                                    <span className='header'>Finish Date</span>
                                    <br/>
                                    <span
                                        className='info'>{moment(training.finishDate).format(DATETIME_FORMAT)}</span>
                                </div>
                            </Col>

                            <Col className='info-item' span={24} sm={{span: 12}}>
                                <div>
                                    <span className='header'>Price</span>
                                    <br/>
                                    <span
                                        className='info'>{training.participationFee === 0 ? 'Free' : training.participationFee}</span>
                                </div>
                            </Col>
                            <Col className='info-item' span={24} sm={{span: 12}}>
                                <div>
                                    <span className='header'>Total Seat</span>
                                    <br/>
                                    <span
                                        className='info'>{training.maxParticipants == 0 ? 'No limit' : training.maxParticipants}{training.maxParticipants == 1 ? ' seat' : ' seats'}</span>
                                </div>
                            </Col>
                            {
                                training.locationOffline !== null &&
                                <Col className='info-item' span={24}>
                                    <div>
                                        <span className='header'>Offline Location</span>
                                        <br/>
                                        <span className='info'>{training.locationOffline}</span>
                                    </div>
                                </Col>
                            }
                            {
                                training.locationOnline !== null &&
                                <Col className='info-item' span={24}>
                                    <div>
                                        <span className='header'>Online Location</span>
                                        <br/>
                                        <span className='info'>{training.locationOnline}</span>
                                    </div>
                                </Col>
                            }
                            {/*</div>*/}
                        </Row>
                    </Col>
                </Row>

                {/*Event tags*/}
                <Row className='cell-gap' justify='space-around'>
                    {
                        trainingTags.length > 0 &&
                        <Col span={24}>
                            <h5>Tags</h5>
                        </Col>
                    }

                    <Col span={24}>
                        {trainingTags && trainingTags.map((tag, i) => (
                            <Tag
                                onClick={() => {
                                    history.push(`/vertical/search/${tag.tag.tagName}`);
                                }}
                                key={i}
                                //color={randomColor()}
                                color='#3FCC79'
                                style={{
                                    color: '#fff',
                                    borderRadius: 500,
                                    fontSize: 14,
                                    margin: '5px',
                                    cursor: 'pointer'
                                }}>
                                {tag.tag.tagName}
                            </Tag>
                        ))}
                    </Col>

                </Row>


                {/*Training Required*/}
                {
                    requiredSkills.length > 0 &&
                    <Row className='cell-gap' justify='center'>
                        <Col span={24} className='align-items'>
                            <h5>What Do You Need to Know?</h5>
                        </Col>
                        {
                            requiredSkills.map((item) => {
                                //const provider:IUser = item.providedBy;
                                return (
                                    <>
                                        <Col key={item.id + 1} lg={{span: 2.5}} md={{span: 2}}
                                             sm={{span: 2}}
                                             xs={{span: 8}}>
                                        </Col>
                                        <Col key={item.id} lg={{span: 3}} md={{span: 3}} sm={{span: 4}}
                                             xs={{span: 6}}>
                                            <SkillCard key={item.id} skill={item}/>
                                        </Col>
                                        <Col key={item.id + 2} lg={{span: 2.5}} md={{span: 2}}
                                             sm={{span: 2}}
                                             xs={{span: 8}}>
                                        </Col>
                                    </>
                                )
                            })
                        }
                    </Row>
                }

                {/*Training Provided*/}
                {
                    providedSkills.length > 0 &&
                    <Row className='cell-gap' justify='space-around'>
                        <Col span={24} className='align-items'>
                            <h5>What Will You Learn?</h5>
                        </Col>
                        {
                            providedSkills.map((item) => {
                                //const provider:IUser = item.providedBy;
                                return (
                                    <>
                                        <Col key={item.id + 1} lg={{span: 2.5}} md={{span: 2}}
                                             sm={{span: 2}}
                                             xs={{span: 8}}>
                                        </Col>
                                        <Col key={item.id} lg={{span: 3}} md={{span: 3}} sm={{span: 4}}
                                             xs={{span: 6}}>
                                            <SkillCard key={item.id} skill={item}/>
                                        </Col>
                                        <Col key={item.id + 2} lg={{span: 2.5}} md={{span: 2}}
                                             sm={{span: 2}}
                                             xs={{span: 8}}>
                                        </Col>
                                    </>
                                )
                            })
                        }
                    </Row>
                }

                {/*Training Images*/}
                {
                    images.length > 0 &&
                    <Row className='cell-gap' justify='center' gutter={[20, 14]}>
                        <Col span={24} className='align-items'>
                            <h5>Images</h5>
                        </Col>
                        {images.map((item) => {
                            return (
                                <>
                                    <Col key={item.id} md={{span: 8}} sm={{span: 12}} xs={{span: 24}}>
                                        <Image key={item.id} src={S3_SERVER_URL + item.imageURI}/>
                                    </Col>
                                </>
                            );
                        })}
                    </Row>
                }

                {/*Training Location*/}
                {
                    !training.isOfflineMeeting && training.locationOnline &&
                    <Row className='cell-gap'>
                        <Col span={24} className='align-items'>
                            <h5>Address</h5>
                            <span style={{paddingBottom: '5px'}}>{training.locationOnline}</span>
                        </Col>
                    </Row>
                }
                {
                    training.isOfflineMeeting &&
                    <Row className='cell-gap'>
                        <Col span={24} className='align-items'>
                            <h5>Address</h5>
                            <span style={{paddingBottom: '5px'}}>{training.locationOffline}</span>
                        </Col>
                        <Col span={24} className='map-row'>
                            <GMForModule
                                disabled
                                haveSearchBox={false}
                                value={{lat: training.latitude, lng: training.longitude}}
                                scrollWheel={false}
                                markerColorByModule={ModuleColors.Trainings}
                            />
                        </Col>
                    </Row>
                }

                {
                    training && provider &&
                    <Row gutter={gutter} className='cell-gap'>
                        <Col span={24}>
                            <h5>Trainer</h5>
                        </Col>
                        <Col span={24}>
                            <PersonCard user={provider}/>
                        </Col>
                    </Row>
                }


                {/*Training Enroll*/}
                <Row className='align-items cell-gap'>
                    <Col span={24}>
                        {
                            user.id !== provider.id &&
                                <>{
                                    isApplicant ?
                                        <h5>
                                            You have already enrolled
                                        </h5>
                                        :
                                        <>
                                            {
                                                deadlineIsOver ?
                                                    <h5>
                                                        Enrollment is over
                                                    </h5>
                                                    :
                                                    <>
                                                        <h6>Time left before enroll ends</h6>
                                                        <div className='align-items'>
                                                            <Countdown date={training.deadline}/>
                                                        </div>
                                                        <br/>
                                                        <div className='align-items'>
                                                            <Button onClick={enrollClick} type='primary'>Enroll Now</Button>
                                                        </div>
                                                    </>
                                            }
                                        </>
                                }</>
                        }

                    </Col>
                </Row>
            </Card>
        </div>
    )
};

export default EnrollTraining;
