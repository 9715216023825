import CookieService from "../utils/services/cookie.service";

export class StateLoader {

    loadState() {
        try {
            //let serializedState = localStorage.getItem("auth");
            let serializedState = CookieService.GET('auth')
            // console.log(serializedState);
            if (serializedState === null) {
                return this.initializeState();
            }

            return JSON.parse(serializedState);
        }
        catch (err) {
            return this.initializeState();
        }
    }

    saveState(state) {
        try {
            let serializedState = JSON.stringify(state);
            // console.log(serializedState);
            //localStorage.setItem("auth", serializedState);
            CookieService.SET('auth', serializedState, 100);
        }
        catch (err) {
        }
    }

    initializeState() {
        return {
            //state object
        }
    };

    removeState() {
        try {
            CookieService.DELETE('auth');
        }
        catch (err) {
            return this.initializeState();
        }
    }
}