import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../interfaces/page-data';
import {Button, Col, Row} from "antd";
import {history} from "../../redux/store";
import {IPageQuery, ISkill} from "../../interfaces/api";
import {PlusOutlined} from "@ant-design/icons";
import ResponsiveButton from "../../components/ResponsiveButton/ResponsiveButton";
import ApiService from "../../utils/services/api.service";
import Spinner from "../../utils/Spinner";
import SkillCard from "../../components/SkillCard/SkillCard";

let pQuery = {numOfRecords: 5, page: 1} as IPageQuery;

const Skills: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'Skills',
        loaded: true
    };
    const [skills, setSkills] = useState<Array<ISkill>>([]);
    const [dataIsLoaded, dataSetLoaded] = useState<boolean>(false);
    const [isMore, setIsMore] = useState<boolean>(true);

    useEffect(() => {
        onSetPage(pageData);
        pQuery = {numOfRecords: 5, page: 1} as IPageQuery
        getSkills().then(null);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getSkills = async () => {
        dataSetLoaded(true);
        let skillResponse = await ApiService.Skill.GetSkillListByPage(pQuery);
        let nextResponse = await ApiService.Skill.GetSkillListByPage({page: (pQuery.page+1), numOfRecords: pQuery.numOfRecords})
        let t : ISkill[] = skillResponse.data;
        if (nextResponse.data.length > 0){
            setIsMore(true)
        } else setIsMore(false);

        setSkills([...skills, ...t]);
        dataSetLoaded(false);
    }

    const loadMore = async () => {
        pQuery.page = pQuery.page + 1;
        await getSkills();
    }

    return (
        <div>
            {/*<div className='d-flex justify-content-between'>*/}
            {/*    <span/>*/}
            {/*    <ResponsiveButton*/}
            {/*        text='Suggest a New Skill'*/}
            {/*        icon={<PlusOutlined/>}*/}
            {/*        onClick={() => {*/}
            {/*            history.push('/vertical/skills/suggest')*/}
            {/*        }}/>*/}
            {/*</div>*/}
            <Row gutter={[23, 3]} style={{margin:"0px"}}>
                {
                    skills.map((item) => {
                        return (
                            <Col key={item.id} xl={{span: 3}} lg={{span: 8}} md={{span: 6}} sm={{span: 12}}
                                 xs={{span: 24}}>
                                <SkillCard key={item.id} skill={item}/>
                            </Col>
                        )
                    })
                }
                {
                    dataIsLoaded ?
                        <Spinner topElementClass='.main-content'/>
                        :
                        <Col span={24} style={{textAlign: 'center'}}>
                            {isMore ?
                                <div className='col-12' style={{textAlign: 'center'}}>
                                    <Button onClick={() => {
                                        loadMore()
                                    }}>Load More</Button>
                                </div>
                                :
                                <div style={{width: '100%', textAlign: 'center'}}>End of
                                    results</div>
                            }
                        </Col>
                }
            </Row>
        </div>
    )
};

export default Skills;
