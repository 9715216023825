import React, {useEffect, useState} from 'react';
import {Badge, Button, Card, Col, Image, Row, Space, Tag, Timeline, Upload} from 'antd';
import ReactHtmlParser from 'react-html-parser';
import {clearCacheData, fixContent, isGuid} from '../../../utils/GlobalFuncs';
import {IPageData, IPageProps} from '../../../interfaces/page-data';
import blankCover from '../../../assets/img/blank/blank-cover.jpg';
import blankPP from "../../../assets/img/blank/blank-pp.png";
import {AppState, history} from "../../../redux/store";
import {
    DeleteOutlined,
    FacebookFilled,
    GlobalOutlined,
    InstagramOutlined,
    LinkedinFilled,
    TwitterSquareFilled,
    UploadOutlined
} from "@ant-design/icons";
import {
    IFollowUser,
    ITimeline,
    IUser,
    IUserFile,
    IUserFollowerFollowingCount,
    IUserInterest,
    IUserSkill
} from "../../../interfaces/api";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {
    DATETIME_FORMAT,
    FACEBOOK_ROOT_URL,
    INSTAGRAM_ROOT_URL,
    LINKEDIN_ROOT_URL,
    S3_SERVER_URL,
    TWITTER_ROOT_URL
} from "../../../utils/Constants";
import useMediaQuery from '../../../utils/MediaQuery';
import './ViewProfile.scss';
import GUID from '../../../utils/GUID';

import SkillCard from "../../../components/SkillCard/SkillCard";
import moment from "moment";
import ImgUploader from "../../../components/ImgUploader/ImgUploader";
import ImgViewer from '../../../components/ImgViewer/ImgViewer';
import ApiService from "../../../utils/services/api.service";
import TimelineFormatter from "../../../components/TimelineFormatter/TimelineFormatter";
import {setUser, updateUser} from "../../../redux/user/actions";
import {useDispatch} from "react-redux";

interface IUserSkillList {
    trainee: IUserSkill[],
    trainer: IUserSkill[],
    associateTrainer: IUserSkill[]
};


let timelineCount: number = 1;
const PageViewProfile: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const dispatch = useDispatch();

    const loggedInUser: IUser = useSelector((state: AppState) => state).user; // Websitede oturum acmis kullanici

    const pageId = useParams<{ id?: string }>().id; // URL den alinan, profiline gidilen kullanici
    const [user, setUser] = useState<IUser>({} as IUser); // URL den alinan, user'in detaylari

    const [userFCount, setUserFCount] = useState<IUserFollowerFollowingCount>({} as IUserFollowerFollowingCount); // URL icerisinde yer alan user'in detaylari
    const [userInterests, setUserInterests] = useState<IUserInterest[]>([]);

    const [timelineItems, setTimelineItems] = useState<ITimeline[]>([]);
    const [isMoreTimeLine, setIsMoreTimeLine] = useState<boolean>(true);

    const [uploadedItems, setUploadedItems] = useState<IUserFile[]>([]);

    const [isFollowed, setFollowed] = useState<boolean>(null);
    const [userIsLoaded, userSetLoaded] = useState<boolean>(false);
    const [userSkills, setUserSkills] = useState<IUserSkillList>({
        trainee: [],// id 3
        trainer: [],// id 2
        associateTrainer: [] // id 1
    })

    const isDesktop = useMediaQuery('(min-width: 992px)');
    const pageData: IPageData = {
        title: 'View profile',
        loaded: true
    };

    useEffect(() => {
        onSetPage(pageData)
        asyncData().then();
        return (() => {
            userSetLoaded(false);
        })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const asyncData = async () => {
        if (userIsLoaded) {
            return;
        }

        const mInPageUser: IUser = await getUser();

        let ffCountResponse = await ApiService.User.PostUserFollowerFollowingCount({userId: mInPageUser.id});
        setUserFCount(ffCountResponse.data);

        let interestResponse = await ApiService.UserInterest.GetUserInterestByUserId(mInPageUser.id);
        setUserInterests(interestResponse.data);

        let checkFollowingResponse = mInPageUser.id !== loggedInUser.id && await ApiService.User.PostUserCheckFollowing({followedUser: mInPageUser.id, loggedInUser: loggedInUser.id});
        setFollowed(!checkFollowingResponse.data)

        let userFilesResponse = await ApiService.UserFile.GetUserFileByUserId(mInPageUser.id);
        setUploadedItems(userFilesResponse.data);

        let timeLineResponse = await ApiService.Timeline.GetByOwnerIdSelf(mInPageUser.id,{numOfRecords: 5, page: 1});
        let nextTimeLineResponse = await ApiService.Timeline.GetByOwnerIdSelf(mInPageUser.id,{numOfRecords: 5, page: timelineCount+1});
        setTimelineItems(timeLineResponse.data);
        if (nextTimeLineResponse.data.length < 1){
            setIsMoreTimeLine(false);
        } else setIsMoreTimeLine(true);

        //region User Skills
        let userSkillsResponse = await ApiService.UserSkill.GetUserSkillByUserId(mInPageUser.id);
        let userSkillTypesResponse = await ApiService.UserSkillType.GetUserSkillType();
        let userSkillTypes: { traineeId: number, trainerId: number, associateTrainerId: number } =
            {traineeId: 0, trainerId: 0, associateTrainerId: 0};
        userSkillTypes.traineeId = userSkillTypesResponse.data.find((item) => item.name.toLowerCase() == 'trainee').id;
        userSkillTypes.trainerId = userSkillTypesResponse.data.find((item) => item.name.toLowerCase() == 'trainer').id;
        userSkillTypes.associateTrainerId = userSkillTypesResponse.data.find((item) => item.name.toLowerCase() == 'associate trainer').id

        let t: IUserSkill[] = userSkillsResponse.data
        let traineeList: IUserSkill[] = t.filter((item) => {
            if (item.userSkillTypeId === userSkillTypes.traineeId) {
                return item.skill
            }
        })
        let trainerList: IUserSkill[] = t.filter((item) => {
            if (item.userSkillTypeId == userSkillTypes.trainerId) {
                return item.skill
            }
        })
        let assoTrainerList: IUserSkill[] = t.filter((item) => {
            if (item.userSkillTypeId == userSkillTypes.associateTrainerId) {
                return item.skill
            }
        })
        setUserSkills({trainee: traineeList, trainer: trainerList, associateTrainer: assoTrainerList});
        //endregion

        userSetLoaded(true);
    }

    const getUser = async () => {
        let userFromAPI = isGuid(pageId) ? await ApiService.User.GetUserById(pageId) : await ApiService.User.GetUserByUsername(pageId);
        setUser(userFromAPI.data);
        return userFromAPI.data;
    }

    const followRequest = async (type: string, obj: IFollowUser) => {
        let response = type === 'follow' ? await ApiService.User.PostUserFollow(obj) : await ApiService.User.PostUserUnfollow(obj);
        if (response.status === 200) {
            await ApiService.User.PostUserCheckFollowing({followedUser: user.id, loggedInUser: loggedInUser.id})
        }
    }

    const loadMoreTimeline = async () => {
        timelineCount = timelineCount + 1;
        let timeLineResponse = await ApiService.Timeline.GetByOwnerIdSelf(user.id,{numOfRecords: 5, page: timelineCount});
        let nextResponse = await ApiService.Timeline.GetByOwnerIdSelf(user.id,{numOfRecords: 5, page: timelineCount+1});
        setTimelineItems(prevState => {
            return [...prevState, ...timeLineResponse.data]
        });

        if (nextResponse.data.length < 1){
            setIsMoreTimeLine(false);
        } else setIsMoreTimeLine(true);
    }

    const updatePhoto = async (file: any, isPP: boolean) => {
        let pp: any = {id: loggedInUser.id, profilePhoto: file};
        let cover: any = {id: loggedInUser.id, coverImage: file};
        let response = isPP ? await ApiService.User.PutUserUpdateProfilePhoto(loggedInUser.id, pp) : await ApiService.User.PutUserUpdateCoverImage(loggedInUser.id, cover)
        if (response.status === 200) {
            let response = await getUser();
            clearCacheData();
            dispatch(updateUser(response));
            window.location.reload();
        }
    }

    const removePhoto = async (isPP: boolean) => {
        let response = isPP ? await ApiService.User.DeleteUserProfilePhoto(loggedInUser.id) : await ApiService.User.DeleteUserCoverImage(loggedInUser.id);
        if (response.status === 200) {
            let response = await getUser();
            clearCacheData();
            dispatch(updateUser(response));
            window.location.reload();
        }
    }

    return (
        <>
            <div className='row'>
                <div className='col col-12 col-md-5'>
                    <Card
                        loading={!userIsLoaded}
                        cover={
                            <div className='user-cover-image'>
                                {
                                    loggedInUser.id === user.id ?

                                        <Image
                                            src={(user.uploadedCoverImage) ? (S3_SERVER_URL + user.uploadedCoverImage + `?random=${GUID.newGuid()}`) : blankCover}
                                            width={'100%'}
                                            preview={{
                                                title: 'Change photo',
                                                visible: false,
                                                mask:
                                                    <div style={{textAlign: 'center', contain: 'content'}}>
                                                        {user.uploadedCoverImage && user.uploadedCoverImage.length > 0 && <> <Button
                                                            className='remove-button'
                                                            onClick={() => {
                                                                removePhoto(false)
                                                            }}
                                                            shape='circle'
                                                            size='small'
                                                            type='primary'
                                                            icon={<DeleteOutlined/>}
                                                        />
                                                            <br/>
                                                            <br/>
                                                        </>}

                                                        <ImgUploader
                                                            fileTypes={['image/png', 'image/jpg', 'image/jpeg']}
                                                            aspectRatio={2}
                                                            imagesCount={1}
                                                            fileMaxSize={2048}
                                                            onChange={(files, orgFiles) => {
                                                                if (files.length < 1 || orgFiles[0].status === 'uploading')
                                                                    return;
                                                                updatePhoto(files[0], false)
                                                            }}
                                                            innerHTML={
                                                                <Button
                                                                    type='primary'
                                                                    shape='circle'
                                                                    size='small'
                                                                    icon={<UploadOutlined/>}
                                                                />
                                                            }
                                                        />
                                                    </div>
                                            }}
                                        />

                                        :
                                        <ImgViewer
                                            src={(user.uploadedCoverImage) ? (S3_SERVER_URL + user.uploadedCoverImage + `?random=${GUID.newGuid()}`) : blankCover}
                                            width={'100%'}
                                            ratio={2}
                                            shape={'square'}
                                        />
                                }

                            </div>
                        }
                        className='personal-info-card'>

                        <div className='d-flex align-items-center justify-content-between user-actions'>
                            <div className='profile-photo-image'>
                                {
                                    loggedInUser.id === user.id ?

                                        <Image
                                            src={(user.uploadedProfilePhoto) ? (S3_SERVER_URL + user.uploadedProfilePhoto + `?random=${GUID.newGuid()}`) : blankPP}
                                            width={100}
                                            style={{
                                                borderRadius: '50%',
                                                border: '1px solid gray',
                                                backgroundColor: 'white'
                                            }}
                                            preview={{
                                                title: 'Change photo',
                                                visible: false,
                                                mask: <div style={{textAlign: 'center', contain: 'content'}}>
                                                    {user.uploadedProfilePhoto && user.uploadedProfilePhoto.length > 0 && <>
                                                        <Button
                                                        className='remove-button'
                                                        onClick={() => {
                                                            removePhoto(true)
                                                        }}
                                                        shape='circle'
                                                        size='small'
                                                        type='primary'
                                                        icon={<DeleteOutlined/>}
                                                    />
                                                        <br/>
                                                    </>}
                                                    <ImgUploader
                                                        fileTypes={['image/jpg','image/jpeg']}
                                                        aspectRatio={1}
                                                        imagesCount={1}
                                                        fileMaxSize={2048}
                                                        onChange={(files, orgFiles) => {
                                                            if (files.length < 1 || orgFiles[0].status === 'uploading')
                                                                return;
                                                            updatePhoto(files[0], true)
                                                        }}
                                                        innerHTML={
                                                            <Button
                                                                type='primary'
                                                                shape='circle'
                                                                size='small'
                                                                icon={<UploadOutlined/>}
                                                            />
                                                        }
                                                    />
                                                </div>
                                            }}
                                        />

                                        :
                                        <Image
                                            src={(user.uploadedProfilePhoto) ? (S3_SERVER_URL + user.uploadedProfilePhoto + `?random=${GUID.newGuid()}`) : blankPP}
                                            width={100}
                                            style={{
                                                borderRadius: '50%',
                                                border: '1px solid gray',
                                                backgroundColor: 'white'
                                            }}
                                            preview={false}
                                        />
                                }

                            </div>
                            <Space>
                                <Button
                                    style={{
                                        borderRadius: 500,
                                    }}
                                    onClick={() => {
                                        if (pageId === loggedInUser.id || pageId === loggedInUser.username) {
                                            //window.location.reload();
                                            history.push(`/vertical/edit-profile`)
                                        } else {
                                            // TODO Follow or Unfollow
                                            if (isFollowed) {
                                                followRequest('unfollow', {
                                                    followerId: loggedInUser.id,
                                                    followedUserId: pageId
                                                } as IFollowUser)
                                            } else {
                                                followRequest('follow', {
                                                    followerId: loggedInUser.id,
                                                    followedUserId: pageId
                                                } as IFollowUser)
                                            }
                                        }
                                    }}
                                >
                                    {pageId === loggedInUser.id || pageId === loggedInUser.username ? 'Edit Profile' : (isFollowed ? 'Unfollow' : 'Follow')}
                                </Button>
                            </Space>
                        </div>

                        <div className='d-flex align-items-center justify-content-between'>
                            {
                                user.userTypeId !== 1 ?
                                    <h5 className='mb-0 mt-0 mr-1'>{user.name}</h5>
                                    :
                                    <h5 className='mb-0 mt-0 mr-1'>{user.name + ' ' + user.surname}</h5>
                            }
                        </div>

                        <p>
                            {
                                user.bio &&
                                <>
                                    {/*<div dangerouslySetInnerHTML={{__html: user.bio}}/>*/}
                                    {ReactHtmlParser(user.bio)}
                                </>
                            }
                        </p>

                        {
                            (pageId === loggedInUser.id || pageId === loggedInUser.username) &&
                            <div style={{color: '#8f8f90', fontSize: '12px'}}>
                                <Space size='small'>
                                    <a onClick={() => {
                                        history.push(`/vertical/connections/${user.username ? user.username : user.id}`);
                                    }}>
                                        Followers {userFCount && userFCount.followerCount}
                                    </a>
                                    <a onClick={() => {
                                        history.push(`/vertical/connections/${user.username ? user.username : user.id}`);
                                    }}>
                                        Following {userFCount && userFCount.followingCount}
                                    </a>
                                </Space>
                            </div>
                        }
                        {
                            (user.socialLinkedin || user.socialInstagram || user.socialTwitter || user.socialFacebook || user.webAddress) &&
                            <div className='social-with-icons'>
                                <Space className='person-card-social' size='middle'>
                                    {
                                        user.webAddress.length > 0 &&
                                        <a href={user.webAddress} target="_blank">
                                            <GlobalOutlined className='social-icon'/>
                                        </a>
                                    }
                                    {
                                        user.socialLinkedin.length > 0 &&
                                        <a href={LINKEDIN_ROOT_URL + user.socialLinkedin} target="_blank">
                                            <LinkedinFilled className='social-icon'/>
                                        </a>
                                    }
                                    {
                                        user.socialInstagram.length > 0 &&
                                        <a href={INSTAGRAM_ROOT_URL + user.socialInstagram} target="_blank">
                                            <InstagramOutlined className='social-icon'
                                                               style={{borderRadius: '7px'}}/>
                                        </a>
                                    }
                                    {
                                        user.socialFacebook.length > 0 &&
                                        <a href={FACEBOOK_ROOT_URL + user.socialFacebook} target="_blank">
                                            <FacebookFilled className='social-icon'/>
                                        </a>
                                    }
                                    {
                                        user.socialTwitter.length > 0 &&
                                        <a href={TWITTER_ROOT_URL + user.socialTwitter} target="_blank">
                                            <TwitterSquareFilled className='social-icon'/>
                                        </a>
                                    }
                                </Space>
                            </div>
                        }
                    </Card>

                    {
                        uploadedItems.length > 0 &&
                        <Badge.Ribbon text='Uploaded Items'>
                            <Card>
                                {uploadedItems.map((item, index) => {
                                    return (
                                        <div key={item.id} style={{paddingBottom: '5px', textAlign: 'center'}}>
                                            <Button key={item.id} type='primary' size='small'>
                                                <a
                                                    key={item.id}
                                                    href={S3_SERVER_URL + item.fileURI}
                                                    target={'_blank'}
                                                    //download={S3_SERVER_URL + item.fileURI}
                                                    download
                                                >Download #{index + 1}</a>
                                            </Button>
                                        </div>
                                    )
                                })}
                            </Card>
                        </Badge.Ribbon>
                    }

                    {
                        userSkills.associateTrainer.length > 0 &&
                        <Badge.Ribbon text='Associate Trainer Skills'>
                            <Card loading={!userIsLoaded}>
                                <div className='row'>
                                    {userSkills.associateTrainer.map((userSkill: IUserSkill, i) => (
                                        <div key={userSkill.skill.id} className='col col-12 col-sm-6 col-md-4 col-lg-3'
                                             style={{textAlign: 'center'}}>
                                            <SkillCard key={userSkill.skill.id} skill={userSkill.skill}/>
                                        </div>
                                    ))}
                                </div>
                            </Card>
                        </Badge.Ribbon>
                    }


                    {
                        userSkills.trainer.length > 0 &&
                        <Badge.Ribbon text='Trainer Skills'>
                            <Card loading={!userIsLoaded}>
                                <div className='row'>
                                    {userSkills.trainer.map((userSkill: IUserSkill, i) => (
                                        <div className='col col-12 col-sm-6 col-md-4 col-lg-3'
                                             style={{textAlign: 'center'}}>
                                            <SkillCard key={userSkill.skill.id} skill={userSkill.skill}/>
                                        </div>
                                    ))}
                                </div>
                            </Card>
                        </Badge.Ribbon>
                    }

                    {
                        userSkills.trainee.length > 0 &&
                        <Badge.Ribbon text='Trainee Skills'>
                            <Card loading={!userIsLoaded}>
                                <div className='row'>
                                    {userSkills.trainee.map((userSkill: IUserSkill, i) => (
                                        <div key={userSkill.skill.id} className='col col-12 col-sm-6 col-md-4 col-lg-3'
                                             style={{textAlign: 'center'}}>
                                            <SkillCard key={userSkill.skill.id} skill={userSkill.skill}/>
                                        </div>
                                    ))}
                                </div>
                            </Card>
                        </Badge.Ribbon>
                    }

                    {
                        userInterests.length > 0 &&
                        <Badge.Ribbon text='Interests'>
                            <Card loading={!userIsLoaded}>
                                {userInterests && userInterests.map((interest, i) => (
                                    <Tag
                                        onClick={() => {
                                            history.push(`/vertical/search/${interest.tag.tagName}`);
                                        }}
                                        key={i}
                                        //color={randomColor()}
                                        color='#3FCC79'
                                        style={{
                                            color: '#fff',
                                            borderRadius: 500,
                                            fontSize: 14,
                                            margin: '5px',
                                            cursor: 'pointer'
                                        }}>
                                        {interest.tag.tagName}
                                    </Tag>
                                ))}
                            </Card>
                        </Badge.Ribbon>
                    }
                </div>

                <div className='col col-12 col-md-7'>
                    {
                        timelineItems.length > 0 &&
                        <Badge.Ribbon text='Flow of Life'>
                            <Card loading={!userIsLoaded}>
                                <Timeline>
                                    {
                                        timelineItems.map((item,index) => {
                                            return (
                                                <Timeline.Item key={index}>
                                                    <div style={{opacity: 0.8}} className='date w-100 mb-2'>
                                                        {moment(item.createdAt).format(DATETIME_FORMAT)}
                                                    </div>
                                                    <span className='primary-text mb-4'>
                                                        <TimelineFormatter
                                                            text={item.timelineEventText}
                                                            user1={item.user1Text}
                                                            user2={item.user2Text}
                                                            title={item.timelineEventTitle}
                                                            eventId={item.timelineEventId}
                                                        />
                                                    </span>
                                                </Timeline.Item>
                                            )
                                        })
                                    }
                                </Timeline>

                                <div style={{textAlign: 'center', display: isMoreTimeLine ? 'block' : 'none'}}>
                                    <Button className='load-more' onClick={loadMoreTimeline} type='primary'>Load
                                        More</Button>
                                </div>
                            </Card>
                        </Badge.Ribbon>
                    }
                </div>
            </div>
        </>
    );
};

export default PageViewProfile;
