import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../../interfaces/page-data';
import {Button, Card, Col, Modal, Row, Select, Space, Table} from "antd";
import {AppState, history} from "../../../redux/store";
import {IUser, IWorkOpportunity} from "../../../interfaces/api";
import toast from "react-hot-toast";
import {CheckOutlined, CloseOutlined, InfoOutlined, LeftOutlined} from "@ant-design/icons";
import moment from "moment";
import {DATETIME_FORMAT} from "../../../utils/Constants";
import {useSelector} from "react-redux";
import ApiService from "../../../utils/services/api.service";

const MyWOsOpen: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'My Open Work Opportunities',
        loaded: true
    };

    const user: IUser = useSelector((state: AppState) => state).user;
    const [wos, setWos] = useState<Array<IWorkOpportunity>>([]);
    const [wosIsLoaded, setWosIsLoaded] = useState<boolean>(false);
    const [isFinished, setFinished] = useState<boolean>(false);

    const columnsInitial = [
        {
            title: "Title",
            dataIndex: "title",
            ellipsis: false,
        },
        {
            title: "Deadline",
            dataIndex: "deadline",
            width: '150px',
            ellipsis: false,
            render: date => <>{
                date == "0001-01-01T00:00:00" ? "Uncertain Date" : moment(date).format(DATETIME_FORMAT)
            }
            </>
        },
        {
            title: "",
            dataIndex: "isFinished",
            width: '150px',
            ellipsis: false,
            render: (cell, row: IWorkOpportunity) => {
                return (<>
                    <div style={{textAlign: 'center'}}>
                        <Space>
                            {
                                //TODO kontrol ettir
                                // isActive &&
                                // !row.isFinished &&
                                // isFinish === 'false' &&
                                <Button size='small' onClick={() => {
                                    cancelWO(row.id)
                                }}
                                        icon={<CloseOutlined/>}
                                        shape='circle'
                                        danger
                                >
                                </Button>
                            }
                            <Button size='small' onClick={() => {
                                console.log('participations list')
                                history.push(`/vertical/my-work-opportunities/open/${row.id}`)
                            }}
                                    icon={<InfoOutlined/>}
                                    shape='circle'
                            >
                            </Button>
                        </Space>
                    </div>
                </>)
            }
        }
    ]

    useEffect(() => {
        onSetPage(pageData);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (wosIsLoaded)
            return;
        getWOs();
    }, [wosIsLoaded])

    const getWOs = async () => {
        let response = await ApiService.WorkOpportunity.GetWOByProviderId(user.id,{isFinished: isFinished});
        setWos(response.data);
        setWosIsLoaded(true);
    }

    const cancelWO = (woId: string) => {
        // todo http post
        Modal.confirm({
            closable: false,
            title: 'Cancel Work Opportunity',
            okText: 'Confirm',
            okButtonProps: {
                className: 'responsive-button',
                icon: <CheckOutlined/>
            },
            onOk: async () => {
                let response = await ApiService.WorkOpportunity.PutWOCancelByEventId(woId);
                if (response.status === 200)
                    setWosIsLoaded(false);
            },
            cancelButtonProps: {
                className: 'responsive-button',
                icon: <LeftOutlined/>,
                danger: true
            }
        });
    }

    return (
        <div>
            <div className='row'>
                <div className='col-12'>
                    <Card title={
                        <Row>
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <span className='card-title-page'>My Open Work Opportunites</span>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 4, offset: 8}}>
                                <Select onChange={(e) => {
                                    setWos([]);
                                    setFinished(e);
                                    setWosIsLoaded(false);
                                }} defaultValue={isFinished}>
                                    <Select.Option key='1' value={true}>Finished</Select.Option>
                                    <Select.Option key='2' value={false}>Not finished</Select.Option>
                                </Select>
                            </Col>
                        </Row>
                    }>
                        <Table
                            rowKey={(row: IWorkOpportunity) => row.id}
                            dataSource={wos.length > 0 && wos}
                            columns={columnsInitial}
                            loading={!wosIsLoaded}
                        />
                    </Card>
                </div>
            </div>
        </div>
    )
};

export default MyWOsOpen;
