import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../interfaces/page-data';
import {Button, Card, Col, Form, Input, Row, Space, Select} from "antd";
import {AppState, history} from "../../redux/store";
import {
    ISkill,
    IUserSkill,
    IUserSkillApprove,
    IUserSkillCertificate
} from "../../interfaces/api";
import toast from "react-hot-toast";
import {useSelector} from "react-redux";
import {IUser} from "../../interfaces/api";
import {useParams} from "react-router-dom";
import AutoCompleteSkills, {ISkillAComplete} from "../../components/AutoCompleteSkills/AutoCompleteSkills";
import {CheckOutlined, CloseOutlined, LeftOutlined} from "@ant-design/icons";
import {S3_SERVER_URL} from "../../utils/Constants";
import ApiService from "../../utils/services/api.service";
import {setUser} from "../../redux/user/actions";

const ApproveSkillApplications: React.FC<IPageProps> = props => {

    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'Approve Skill Application',
        loaded: true
    };
    const [desc, setDesc] = useState<string>('');
    const user: IUser = useSelector((state: AppState) => state).user;
    const skillId = useParams<{ id?: string }>().id;
    const [userSkill, setUserSkill] = useState<IUserSkill>(null);
    const [skill, setSkill] = useState<ISkill>(null);
    const [certificates, setCertificates] = useState<IUserSkillCertificate[]>([]);
    const [isLoad, setLoad] = useState<boolean>(false);
    // const [userSkillTypes, setUserSkillTypes] = useState<IUserSkillType[]>([]);

    useEffect(() => {
        onSetPage(pageData);
        const any = async () => {
            if (isLoad) {
                return;
            }

            let userSkillResponse = await ApiService.UserSkill.GetUserSkillById(skillId);
            let userSkill: IUserSkill = userSkillResponse.data;
            setUserSkill(userSkillResponse.data);

            let skillResponse = await ApiService.Skill.GetSkillById(userSkill.skillId);
            setSkill(skillResponse.data);


            let certificateResponse = await ApiService.UserSkillCertificate.GetUserSkillCertificateGetByUserSkill(userSkill.id);
            setCertificates(certificateResponse.data);

            setLoad(true);
        }
        any().then(null);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const onOk = async (isOk) => {
        // isOk => false unapprove
        // isOk => true approve
        let data = {
            userSkillId: skillId,
            approvedBy: user.id,
            reason: desc
        } as IUserSkillApprove
        let response = isOk ? await ApiService.UserSkill.PostUserSkillApprove(data) : await ApiService.UserSkill.PostUserSkillReject(data);
        if (response.status === 200){
            history.push('/vertical/skill-applications')
        }
    }

    return (
        <div className='row'>
            <div className='col-12'>
                <Card className='card-head-none' loading={!isLoad} title={<Row>
                    <Col span={24}>
                        <span className='card-title-page'>Approve Skill Application</span>
                    </Col>
                </Row>}>
                    <Form
                        colon={false}
                        labelCol={{sm: {span: 7}, lg: {span: 6}, md: {span: 6}}}
                        wrapperCol={{span: 12}}
                    >
                        <Form.Item label='Person Type' name='userSkillTypeId'
                                   initialValue={userSkill && userSkill.userSkillTypeId}>
                            <Select disabled>
                                {userSkill &&
                                    <Select.Option
                                        key={userSkill.userSkillTypeId}
                                        value={userSkill.userSkillTypeId}
                                    >
                                        {userSkill.userSkillType.name}
                                    </Select.Option>
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label='Skill Name' name='skillName'
                                   validateTrigger={'onChange'}>
                            <AutoCompleteSkills
                                tags={skill && [{value: skill.id, label: skill.skillName} as ISkillAComplete]}
                                selectedTags={() => {
                                }}
                                disabled
                            />
                        </Form.Item>
                        <Form.Item label='Certificate' name='skillName'
                                   initialValue={userSkill && userSkill.skillId}
                                   extra={<span style={{fontSize: '10px'}}>Click to download</span>}
                        >
                            {certificates.map((item, index) => {
                                return (
                                    <div style={{paddingBottom:'5px'}}>
                                        <Button type='primary' size='small'>
                                            <a
                                                href={S3_SERVER_URL + item.certificateURI}
                                                target={'_blank'}
                                                download={S3_SERVER_URL + item.certificateURI}
                                                style={{color:'inherit'}}
                                            >Download
                                                File #{index + 1}</a>
                                        </Button>
                                    </div>
                                )
                            })}
                        </Form.Item>

                        <Form.Item label='Message' name='message' initialValue={userSkill && userSkill.message}>
                            <div style={{padding: 20}}>
                                <div dangerouslySetInnerHTML={{__html: userSkill && userSkill.message}}/>
                            </div>
                        </Form.Item>

                        <Form.Item label='Reason' name='reason' initialValue={userSkill && userSkill.reason}>
                            <Input.TextArea
                                //rows={2}
                                autoSize={{minRows: 2, maxRows: 2}}
                                onChange={(e) => {
                                    setDesc(e.target.value)
                                }}
                            />
                        </Form.Item>

                        <Form.Item wrapperCol={{
                            lg: {span: 12, offset: 6},
                            md: {span: 12, offset: 6},
                            xs: {offset: 0},
                            sm: {span: 12, offset: 7}
                        }}>
                            <div className='d-flex justify-content-between'>

                                <Button className='responsive-button' onClick={() => {
                                    history.push('/vertical/skill-applications')
                                }} danger icon={<LeftOutlined/>}>Cancel</Button>

                                <Space>
                                    <Button className='responsive-button' type='primary' htmlType='submit' danger
                                            onClick={() => onOk(false)} icon={<CloseOutlined/>}>Decline</Button>
                                    <Button className='responsive-button' type='primary' htmlType='submit'
                                            onClick={() => onOk(true)} icon={<CheckOutlined/>}>Approve</Button>
                                </Space>

                            </div>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </div>
    )
};

export default ApproveSkillApplications;
