import React, {useEffect, useState} from 'react';
import ReactPlayer from "react-player";
import {Card} from "antd";

interface VideoPlayerProps {
    url: string
}

const VideoPlayer: React.FC<VideoPlayerProps> = props => {
    const [url, setUrl] = useState<string>(null);
    //const [playing, setPlaying] = useState<boolean>(false);
    const [played, setPlayed] = useState<number>(0);
    //const [volume, setVolume] = useState<number>(0.8);
    // const [muted, setMuted] = useState<boolean>(false);

    useEffect(() => {
        setUrl(props.url);
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        console.log(played);
    }, [played])

    // const handlePlayPause = () => {
    //     setPlaying(!playing);
    // }

    // const handleStop = () => {
    //     setUrl(null)
    //     setPlaying(false);
    // }

    // const handleToggleMuted = () => {
    //     setMuted(!muted);
    // }

    // const handleSeekChange = (e: any) => {
    //     console.dir(e);
    //     //setPlayed(parseFloat(e.target.value));
    //     setPlayed(parseFloat(e));
    // }

    // const handleVolumeChange = (e) => {
    //     //setVolume(parseFloat(e.target.value));
    //     setVolume(parseFloat(e))
    // }

    const handleProgress = (state) => {
        console.dir(state)
        setPlayed(state.played)
    }

    return (
        <div
            //style={{width: '450px', height: '300px'}}
        >
            <Card className='ant-card-invisible'>
                <ReactPlayer
                    url={url}
                    width='100%'
                    height='100%'
                    //playing={playing}
                    // muted={muted}
                    //volume={volume}
                    controls={true} // linkten gelen default oynatici
                    //onProgress={(state) => handleProgress(state)}
                />
            </Card>

            {/*
            <Card className='ant-card-invisible' style={{textAlign: 'center'}}>
                <Space>
                    <i className="icofont-stop clickable-item" onClick={handleStop}
                       style={{fontSize: '30px', color: 'red'}}/>
                    <div>
                        {!playing ?
                            <PlayCircleFilled onClick={handlePlayPause} style={{fontSize: '30px', color: 'green'}}/>
                            :
                            <PauseCircleFilled onClick={handlePlayPause} style={{fontSize: '30px', color: 'red'}}/>
                        }
                    </div>

                    <div>
                        Play
                        <Slider
                            min={0}
                            max={0.99999}
                            step={0.00001}
                            value={played}
                            style={{width: '200px'}}
                            tooltipVisible={false}
                            onChange={(e) => {
                                handleSeekChange(e)
                            }}
                        />
                    </div>

                    <div>
                        Volume
                        <Slider
                            min={0}
                            max={1}
                            value={volume}
                            onChange={handleVolumeChange}
                            step={0.001}
                            style={{width: '200px'}}
                            tooltipVisible={false}
                        />
                    </div>

                </Space>
            </Card>
*/}
        </div>
    );
};

VideoPlayer.defaultProps = {
    url: ''
}

export default VideoPlayer;
