import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../interfaces/page-data';
import {Col, Row, Spin} from "antd";
import {history} from "../../redux/store";
import {IWorkOpportunity} from "../../interfaces/api";
import {PlusOutlined} from "@ant-design/icons";
import ResponsiveButton from "../../components/ResponsiveButton/ResponsiveButton";
import ApiService from "../../utils/services/api.service";
import Spinner from "../../utils/Spinner";
import WOCard from "../../components/WOCard/WOCard";
import InfiniteScroll from "react-infinite-scroll-component";
import TrainingCard from "../../components/TrainingCard/TrainingCard";

let hasMore: boolean = true;
const WorkOpportunities: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'Work Opportunities',
        loaded: true
    };
    const [wos, setWos] = useState<Array<IWorkOpportunity>>([]);
    const [dataIsLoaded, dataSetLoaded] = useState<boolean>(false);
    const [loadMoreCount, setLoadMoreCount] = useState<number>(10);

    useEffect(() => {
        onSetPage(pageData);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const loadMore = async () => {
        dataSetLoaded(false);
        let response = await ApiService.WorkOpportunity.GetWOListLastN({numOfRecords: loadMoreCount});
        let nextResponse = await ApiService.WorkOpportunity.GetWOListLastN({numOfRecords: loadMoreCount+10});
        hasMore = response.data.length < nextResponse.data.length;
        await setWos(response.data);
        dataSetLoaded(true);
    }

    useEffect(() => {
        loadMore();
    }, [loadMoreCount])
    return (
        <div>
            <div className='d-flex justify-content-between'>
                <span/>
                <ResponsiveButton
                    text='Post'
                    icon={<PlusOutlined/>}
                    onClick={() => {
                        history.push('/vertical/work-opportunities/new')
                    }}/>
            </div>
            <br/>

            {
                !dataIsLoaded ?
                    <Spinner topElementClass={'main-content'}></Spinner>
                    :
                    <InfiniteScroll
                        loader={
                            <div style={{width: '100%', textAlign: 'center'}}>
                                <Spin/>
                            </div>
                        }
                        endMessage={
                            <div style={{width: '100%', textAlign: 'center'}}>
                                <p style={{textAlign: "center"}}>
                                    <b>Yay! You have seen it all</b>
                                </p>
                            </div>
                        }
                        hasMore={hasMore}
                        dataLength={wos.length}
                        next={() => {
                            console.log('next')
                            setLoadMoreCount(loadMoreCount + 10)
                        }}
                        scrollableTarget='main-content'
                    >
                        <Row gutter={[23, 3]} style={{marginRight: 0}}>
                            {
                                wos.length > 0 ? wos.map((item) => {
                                        return (
                                            <Col key={item.id} xl={{span: 6}} lg={{span: 8}} md={{span: 6}} sm={{span: 12}}
                                                 xs={{span: 24}}>
                                                <WOCard provider={item.providedBy} key={item.id} workOpportunity={item}/>
                                            </Col>
                                        )
                                    }) :
                                    <Col span={24} style={{textAlign: 'center'}}>
                                        <h6>No Work Opportunity</h6>
                                    </Col>
                            }

                        </Row>
                    </InfiniteScroll>
            }
        </div>
    )
};

export default WorkOpportunities;
