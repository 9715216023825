import EncryptionService from "./encryption.service";

class CookieService {
    static SET = (name: string, value: string, minutes: number) => {
        let encrypted = EncryptionService.encrypt(value);
        let expires = "";
        if (minutes) {
            let date = new Date();
            // TODO - Set from BACKEND
            // Token - 1 dk
            // Refresh Token - 1 gün
            date.setTime(date.getTime() + (minutes*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (encrypted || "")  + expires + "; path=/";
    }

    static GET = (name: string) => {
        let nameEQ = name + "=";
        let ca = document.cookie.split(';');
        for(let i=0;i < ca.length;i++) {
            let c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return EncryptionService.decrypt(c.substring(nameEQ.length,c.length));
        }
        return null;
    }

    static UPDATE  = (name: string,value: string, minutes: number) => {
        let encrypted = EncryptionService.encrypt(value);
        CookieService.SET(name, encrypted, minutes);
    }

    static DELETE = (name: string) => {
        document.cookie = name + "=" +  ""  + ";expires=Thu, 01 Jan 1970 00:00:01 GMT" + "; path=/";
    }
}

export default CookieService