import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../../interfaces/page-data';
import {Button, Card, Modal, Table} from "antd";
import {AppState, history} from "../../../redux/store";
import {IUser, IWOApplication, IWorkOpportunity} from "../../../interfaces/api";
import {
    CheckOutlined,
    FileExcelFilled,
    FileOutlined,
    LeftOutlined,
    ReadOutlined
} from "@ant-design/icons";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import ExportJsonExcel from "js-export-excel";
import {S3_SERVER_URL} from "../../../utils/Constants";
import ReactHtmlParser from 'react-html-parser';
import ApiService from "../../../utils/services/api.service";


// IsSelected bilgisi sadece wo secilip secilmeme durumunu temsil eder
const MyWOsOpenDetail: React.FC<IPageProps> = props => {

    const woId = useParams<{ id?: string }>().id;
    const user: IUser = useSelector((state: AppState) => state).user;
    const [participations, setParticipations] = useState<IWOApplication[]>([]);
    const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
    const [wosIsLoaded, setWosIsLoaded] = useState<boolean>(false);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [wos, setWos] = useState<IWorkOpportunity>(null);

    const {onSetPage} = props;
    const pageData: IPageData = {
        title: wos !== null && wos.title,
        loaded: true
    };

    const columnsInitial = [
        {
            title: "User Name",
            dataIndex: "name",
            ellipsis: false,
            render: (cell, row: IWOApplication) => {
                return (
                    <span>
                        {row.user.name + (row.user.userTypeId == 1 ? ` ${row.user.surname}` : '')}
                </span>
                )
            }
        },
        {
            title: "Email",
            dataIndex: "email",
            ellipsis: false
        },
        {
            title: "Message",
            dataIndex: "message",
            ellipsis: false,
            render: (cell, row: IWOApplication) => {
                return (
                    <Button type='primary'
                            size='small'
                            shape='circle'
                            icon={<ReadOutlined/>}
                            onClick={() => {
                                readMessage(cell)
                            }}/>
                )
            }
        },
        {
            title: "Files",
            dataIndex: "files",
            ellipsis: false,
            render: (cell, row: IWOApplication) => {
                return (
                    row.files.map(item => {
                        return (
                            <a href={S3_SERVER_URL + item.fileURI} target={'_blank'} download
                               style={{paddingRight: '5px'}}>
                                <Button
                                    type='primary'
                                    size='small'
                                    shape='circle'
                                    icon={<FileOutlined/>}
                                />
                            </a>
                        )
                    })
                )
            }
        },
        Table.SELECTION_COLUMN
    ]

    useEffect(() => {
        onSetPage(pageData);
        const any = async () => {
            let woAppResponse = await ApiService.WOApplication.GetWOApplicationByWOId(woId);
            setParticipations(woAppResponse.data);
            console.dir(woAppResponse.data);
            let results: string[] = woAppResponse.data.filter((x) => x.isSelected);
            console.dir(results)
            setSelectedRowIds(results);

            let woResponse = await ApiService.WorkOpportunity.GetWOById(woId);
            setWos(woResponse.data);

            setWosIsLoaded(true);
        }
        any().then(null);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const rowSelection = {
        selectedRowKeys: selectedRowIds,
        //columnTitle: <>Is Finished <Checkbox/></>,
        onChange: (selectedRowKeys) => {
            console.dir(selectedRowKeys);
            setSelectedRowIds(selectedRowKeys)
        },
        getCheckboxProps: (record: IWOApplication) => ({
            disabled: record.isFinished
        })
        // renderCell: (checked, record, index, originNode) => {
        //     return (<Switch checked={checked}/>)
        // }
    };

    const readMessage = (message: string) => {
        console.log(message);
        Modal.info({
            closable: false,
            width: '50%',
            title: 'Application Message',
            content: <>{ReactHtmlParser(message)}</>,
            okText: 'Okay',
            okButtonProps: {
                className: 'responsive-button',
                icon: <CheckOutlined/>
            }
        });
    }

    const exportToExcel = async () => {
        let data = participations
        var option: any = {};
        let dataTable = [];
        if (data) {
            for (let i in data) {
                if (data) {

                    let obj = {
                        'Name': data[i].user.name,
                        'Surname': data[i].user.surname,
                        'Is Selected': data[i].isSelected,
                        'Is Finished': data[i].isFinished,
                    }
                    dataTable.push(obj);
                }
            }
        }
        option.fileName = 'WO Participations'
        option.datas = [
            {
                sheetData: dataTable,
                sheetName: 'sheet',
                sheetFilter: ['Name', 'Surname', 'Is Selected', 'Is Finished'],
                sheetHeader: ['Name', 'Surname', 'Is Selected', 'Is Finished'],
            }
        ];

        var toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
    }

    const submit = async () => {
        console.log('submit');
        let results: { userId: string, score: boolean }[] = participations.map(item => {
            if (selectedRowIds.find(x => x === item.userId)) {
                return {score: true, userId: item.userId};
            }
            else {
                return {score: false, userId: item.userId};
            }
        });
        console.dir(results);
        let response = await ApiService.WOApplication.PostWOApplicationSetApplicantSelection(results,{workOpportunityId: woId, providerId: user.id});
        if (response.status === 200){
            setModalVisible(false);
            history.push('/vertical/my-work-opportunities/open');
        }
    }
    return (
        <div>
            <div className='row'>
                <div className='col-12'>
                    <Card title={wos !== null && wos.title}>
                        <Table
                            rowKey={(row: IWOApplication) => row.userId}
                            rowSelection={rowSelection}
                            dataSource={participations.length > 0 && participations}
                            columns={columnsInitial}
                            loading={!wosIsLoaded}
                        />
                    </Card>
                </div>
                <div className='col-12'>
                    <div className='d-flex justify-content-between'>
                        <Button className='responsive-button' onClick={() => {
                            history.push('/vertical/my-work-opportunities/open')
                        }} danger icon={<LeftOutlined/>}>Cancel</Button>

                        <Button className='responsive-button'
                                type='primary'
                                onClick={() => {
                                    exportToExcel()
                                }}
                                icon={<FileExcelFilled/>}>Download as Excel</Button>

                        <Button className='responsive-button' type='primary'
                                onClick={() => {
                                    setModalVisible(true);
                                }}
                                icon={<CheckOutlined/>}>Submit</Button>
                    </div>
                </div>
            </div>

            <Modal
                closable={false}
                title='Submit Results'
                visible={modalVisible}
                destroyOnClose={true}
                onOk={submit}
                okText={'Confirm'}
                okButtonProps={{
                    className: 'responsive-button',
                    icon: <CheckOutlined/>
                }}
                onCancel={() => {
                    setModalVisible(false)
                }}
                cancelButtonProps={{
                    className: 'responsive-button',
                    icon: <LeftOutlined/>,
                    danger: true
                }}
            >
                <span>{selectedRowIds.length} out of {participations.length} applicant(s) are approved for their work opportunity application. Would you like to continue?</span>
            </Modal>

        </div>
    )
};

export default MyWOsOpenDetail;

