import axios from 'axios';
import {IResponse} from "../../interfaces/api/IResponse";

import {store} from '../../redux/store';
import {IUser} from "../../interfaces/api";
import {API_URL, API_REQUEST_TIMEOUT} from "../Constants";
import CookieService from "./cookie.service";

const user: IUser = store.getState().user;
axios.defaults.baseURL = API_URL;
axios.defaults.timeout = API_REQUEST_TIMEOUT;
//axios.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8';
//axios.defaults.headers = { 'Content-Type': 'application/json; charset=utf-8' };
axios.defaults.headers = {
    'Access-Control-Allow-Origin': '*',
    //'Content-Security-Policy': 'upgrade-insecure-requests',
    'Authorization': 'Bearer ' + user.token,
    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
    //'Accept':'text/plain'
};
//axios.defaults.headers = { 'Content-Security-Policy': 'upgrade-insecure-requests'};

// Status 200 ise result.data
// Status 200 disinda herhangi x ise response.status ve response.statusText
// Network vb hatalar ise error.response error.message (response undefined)

axios.interceptors.response.use(
    (response) => {
        // console.dir(response);
        return response;
    },
    (error) => {
        // console.dir(error);
        let response: IResponse = {} as IResponse;
        //response.dateTime = error.response.headers['server-date'];
        if (error.response === undefined || error.response === typeof undefined) {
            // API'in cevap donemedigi durumlarda yasanan hata
            response.data = null;
            response.status = 0;
            response.errorText = error.message;
        }
        else if (error.response.status === 401) {
            // User unauthorized
            //CookieService.DELETE('auth');
            response.data = null;
            response.status = 401;
            response.errorText = error.response.statusText;

        }
        else {
            response.data = null;
            response.status = error.response.status;
            if (error.response.data == null)
                response.errorText = error.response.statusText;
            //let t = JSON.parse(error.response.data);
            //response.errorText = t.message;
            response.errorText = error.response.data.message;
        }
        return Promise.reject(response);
    }
);

class HTTPService {
    static API_URL: any = API_URL;

    static GET = (URL: string, query: object = {}) => {
        query = query || {};

        let response: IResponse = {} as IResponse;
        return axios
            .get(URL,{
                params: query
            })
            .then((result) => {
                //console.dir(result);
                response.status = result.status;
                response.errorText = result.statusText;
                response.data = JSON.stringify(result.data);
                response.dateTime = result.headers['server-date'];
                return response;
            })
            .catch((response) => {
                return response;
            });
    }

    static POST = (URL: string, data: Object, query? :Object) => {
        let response: IResponse = {} as IResponse;
        return axios
            .post(URL, data,{params: query})
            .then((result) => {
                //console.dir(result);
                response.status = result.status;
                response.errorText = result.statusText;
                response.data = JSON.stringify(result.data);
                response.dateTime = result.headers['server-date'];
                return response;
            })
            .catch((response) => {
                return response;
            });
    }

    static PUT = (URL: string, data: Object) => {
        let response: IResponse = {} as IResponse;
        return axios
            .put(URL, data)
            .then((result) => {
                //console.dir(result);
                response.status = result.status;
                response.errorText = result.statusText;
                response.data = JSON.stringify(result.data);
                response.dateTime = result.headers['server-date'];
                return response;
            })
            .catch((response) => {
                return response;
            });
    }

    static POST_FORMDATA = (URL: string, fData: any) => {
        let response: IResponse = {} as IResponse;
        let formData: FormData = new FormData();
        Object.keys(fData).forEach((item) => {
            if (Array.isArray(fData[item])){
                let t = fData[item];
                fData[item].map( (x,index) => {
                    console.log(x)
                    formData.append(item, t[index]);
                })
                return;
            }
            formData.append(item, fData[item]);
        });
        return axios
            .post(URL, formData, {
                headers: {
                    //"Content-Type": "multipart/form-data; boundary=WebFileRequest"
                }
            })
            .then((result) => {
                //console.dir(result);
                response.status = result.status;
                response.errorText = result.statusText;
                response.data = JSON.stringify(result.data);
                response.dateTime = result.headers['server-date'];
                return response;
            })
            .catch((response) => {
                return response;
            });
    }

    static PUT_FORMDATA = (URL: string, fData: any) => {
        let response: IResponse = {} as IResponse;
        let formData: FormData = new FormData();
        Object.keys(fData).forEach((item) => {
            formData.set(item, fData[item]);
        });

        return axios
            .put(URL, formData, {
                headers: {
                    //"Content-Type": "multipart/form-data; boundary=WebFileRequest"
                }
            })
            .then((result) => {
                //console.dir(result);
                response.status = result.status;
                response.errorText = result.statusText;
                response.data = JSON.stringify(result.data);
                response.dateTime = result.headers['server-date'];
                return response;
            })
            .catch((response) => {
                return response;
            });
    }

    static DELETE = (URL: string) => {
        let response: IResponse = {} as IResponse;
        return axios
            .delete(URL)
            .then((result) => {
                //console.dir(result);
                response.status = result.status;
                response.errorText = result.statusText;
                response.data = JSON.stringify(result.data);
                response.dateTime = result.headers['server-date'];
                return response;
            })
            .catch((response) => {
                return response;
            });
    }
}

export default HTTPService;