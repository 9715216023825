import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../interfaces/page-data';
import {Col, Row, Card, Tag, Tooltip, Divider, Button, Modal} from "antd";
import {
    IResource,
    IResourceDislike,
    IResourceLike,
    IResourceLikeStatus,
    IResourceTag,
    IResourceType, IResourceUpdate,
    IUser
} from "../../interfaces/api";
import {useParams} from "react-router-dom";
import {S3_SERVER_URL} from "../../utils/Constants";
import {isGuid} from "../../utils/GlobalFuncs";
import {AppState, history} from "../../redux/store";
import ResourcePlayer from "../../components/ResourceComps/ResourcePlayer";
import './DetailResource.scss';
import {
    AudioOutlined, CheckOutlined,
    FileImageOutlined,
    FileTextOutlined,
    LeftOutlined,
    VideoCameraOutlined
} from "@ant-design/icons";
import {useSelector} from "react-redux";
import ReactHtmlParser from 'react-html-parser';
import ResourceHorizontalCard from "../../components/ResourceCard/ResourceHorizontalCard";
import ImgViewer from "../../components/ImgViewer/ImgViewer";
import ApiService from "../../utils/services/api.service";
import Heart from "./Heart";
import toast from "react-hot-toast";
import ErrImg from '../../assets/img/blank/error-image.png';

let loadMoreCount = 1;
const DetailResource: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'Detail Resource',
        loaded: true,
    };
    const [isLoaded, setLoaded] = useState<boolean>(false);
    const [resource, setResource] = useState<IResource>({} as IResource);
    const [resourceOther, setResourceOther] = useState<IResource[]>([]);
    const [isMore, setIsMore] = useState<boolean>(true);
    const [resourceType, setResourceType] = useState<IResourceType>({} as IResourceType);
    const [resourceTags, setResourceTags] = useState<IResourceTag[]>([]);
    const resourceId = useParams<{ id?: string }>().id;
    const [likeStatus, setLikeStatus] = useState<IResourceLikeStatus>(null);
    const user: IUser = useSelector((state: AppState) => state).user;
    const [uploadedById, setUploadedById] = useState<string>(null);
    const [isAdminOrOwner, setIsAdminOrOwner] = useState<boolean>(false);

    useEffect(() => {
        onSetPage(pageData);
        if (!isGuid(resourceId))
            return;

        const any = async () => {
            let resourceResponse = await ApiService.Resource.GetResourceById(resourceId);
            let tResource: IResource = resourceResponse.data;
            console.dir(user)
            if (user.id === tResource.uploadedById || user.userRoleId !== 3){
                setIsAdminOrOwner(true);
            }
            await setUploadedById(tResource.uploadedById)
            if (resourceResponse.status !== 200){
                toast.error('Resource not found. Routing home');
                history.push('/vertical/home');
                return;
            }
            setResource(resourceResponse.data);

            let tagsResponse = await ApiService.ResourceTag.GetResourceTagByResource(resourceId);
            // let t1 = tagsResponse.data.length > 0 ? tagsResponse.data.map((item: IResourceTag) => {
            //     return {value: item.tag.id, label: item.tag.tagName } as ITagAComplete
            // }) : [];
            setResourceTags(tagsResponse.data);

            //region Type
            let typesResponse = await ApiService.ResourceType.GetResourceType();
            let t2: IResourceType[] = typesResponse.data;
            let type: IResourceType = t2.find((item) => {
                if (item.id === tResource.resourceTypeId) {
                    return item;
                }
            });
            setResourceType(type);
            //endregion

            await getLikeStatus();

            await getOtherResources(loadMoreCount, tResource.uploadedById);

            setLoaded(true);
        }
        any().then(() => {
        });
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const getOtherResources = async (pageNo: number, userId: string) => {
        let response = await ApiService.Resource.GetResourceByUser(userId, {page: pageNo, numOfRecords: 2})
        let nextResponse = await ApiService.Resource.GetResourceByUser(userId, {page: pageNo+1, numOfRecords: 2})
        let t: IResource[] = response.data
        if (nextResponse.data.length < 1) {
            setIsMore(false);
        }
        setResourceOther([...resourceOther, ...t]);
    }

    const getLikeStatus = async () => {
        let response = await ApiService.ResourceLike.GetResourceLikeByResource(resourceId, {userId: user.id});
        setLikeStatus(response.data);
    }

    const likeFeedback = async (like: boolean) => {
        let likeObj: IResourceLike = {
            resourceId: resourceId,
            userId: user.id
        } as IResourceLike;
        let dislikeObj: IResourceDislike = {
            userId: user.id,
            resourceLikeId: likeStatus.likeGuid
        } as IResourceDislike;
        let response = like ? await ApiService.ResourceLike.PostResourceLike(likeObj) : await ApiService.ResourceLike.PostResourceDislike(dislikeObj);
        if (response.status === 200) {
            getLikeStatus();
        }
    }

    const isEmbedVideoOrAudio = () => {
        return ((resource.resourceTypeId == 2 || resource.resourceTypeId == 1))
    }

    const loadMoreOtherResource = () => {
        loadMoreCount++;
        getOtherResources(loadMoreCount, uploadedById);
    }

    const removeResource = async () => {
        Modal.confirm({
            closable: false,
            title: 'Are you sure you want to delete this resource?',
            okText: 'Confirm',
            okButtonProps: {
                className: 'responsive-button',
                icon: <CheckOutlined/>
            },
            onOk: async () => {
                let tResource: IResourceUpdate = {
                    fileURI : resource.fileURI,
                    resourceTypeId : resource.resourceTypeId,
                    userId : user.id,
                    description : resource.description,
                    embedCode : resource.linkOrEmbedCode,
                    isEmbedCode : resource.isEmbedCode,
                    title : resource.title,
                    id : resource.id,
                    isApproved : false
                };
                console.dir(tResource);
                let response = await ApiService.Resource.PutResource(tResource.id, tResource);
                if (response.status === 200){
                    history.push('/vertical/resources')
                }
            },
            cancelButtonProps: {
                className: 'responsive-button',
                icon: <LeftOutlined/>,
                danger: true
            }
        });
    }

    return (
        <div className='detail-resource-page'>
            <Card
                className='ant-card-invisible'
                loading={!isLoaded}>
                {/*Training Details*/}
                {
                    isAdminOrOwner &&
                    <Row>
                        <Col span={24} className='cell-gap-left' style={{textAlign:'right'}}>
                            <Button danger type='primary' onClick={removeResource}>Delete</Button>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col className='cell-gap-right' md={{order: 2, span: 12}}
                         lg={{order: 2, span: 12}} xs={{order: 2, span: 24}}>
                        {/*Training name, desc, time left....*/}
                        <h4>{resource.title}</h4>
                        <br/>
                        <br/>

                        <div style={{padding: '10px'}} className='desc-tag'>
                            {/*<span className='desc-tag-sub'*/}
                            {/*     dangerouslySetInnerHTML={{__html: resource && resource.description}}*/}
                            {/*/>*/}
                            {ReactHtmlParser(resource && resource.description)}
                        </div>
                        <br/>

                        {
                            !isEmbedVideoOrAudio() &&

                            <div style={{textAlign: 'center', paddingTop: '10px'}}>
                                <ResourcePlayer
                                    resourceId={resourceId}
                                    URIorEmbedCode={
                                        resource && (resource.isEmbedCode ? resource.linkOrEmbedCode : resource.fileURI)
                                    }
                                    isEmbedCode={resource && resource.isEmbedCode}
                                    resourceTypeId={resource && resource.resourceTypeId}
                                    likeFeedback={true}
                                />
                            </div>
                        }
                    </Col>
                    {/*resource image, price, space left, location*/}
                    <Col className='cell-gap-left' md={{order: 2, span: 12}}
                         lg={{order: 2, span: 12}} xs={{order: 1, span: 24}}>
                        <Row className='resource-info-border'>
                            <Col span={24}>
                                <Tooltip title={`${resourceType.resourceTypeName} file`}>
                                    <div className='img-item'>
                                        <ImgViewer src={resource.uploadedThumbnail && S3_SERVER_URL + resource.uploadedThumbnail} width={'180px'}
                                                   ratio={2} onErrorImage={ErrImg}/>

                                        <span className='icon-container'>
                                            {
                                                resourceType.id === 1 &&
                                                <AudioOutlined/>
                                            }
                                            {
                                                resourceType.id === 2 &&
                                                <VideoCameraOutlined/>
                                            }
                                            {
                                                resourceType.id === 3 &&
                                                <FileImageOutlined/>
                                            }
                                            {
                                                resourceType.id === 4 &&
                                                <FileTextOutlined/>
                                            }
                                            {
                                                resourceType.id === 5 &&
                                                <span className='icon-other-text'
                                                >
                                                Other
                                            </span>
                                            }

                                    </span>
                                    </div>
                                </Tooltip>
                            </Col>
                            <Col span={24} xl={{span: 22, offset: 1}}>
                                <div style={{textAlign: 'right'}}>

                                    <Heart
                                        isChecked={likeStatus && !(likeStatus.likeGuid === '00000000-0000-0000-0000-000000000000')}
                                        onClick={async (checked) => {
                                            if (checked)
                                                likeFeedback(true)
                                            else
                                                likeFeedback(false)
                                        }}
                                        count={likeStatus && likeStatus.likeCount}
                                    />

                                </div>
                            </Col>
                            {/*</div>*/}
                        </Row>
                    </Col>
                </Row>

                {
                    isEmbedVideoOrAudio() &&
                    <Row className='cell-gap' justify='space-around'>
                        <Col span={24} style={{textAlign: 'center', paddingTop: '15px'}}>
                            <ResourcePlayer
                                resourceId={resourceId}
                                URIorEmbedCode={
                                    resource && (resource.isEmbedCode ? resource.linkOrEmbedCode : resource.fileURI)
                                }
                                isEmbedCode={resource && resource.isEmbedCode}
                                resourceTypeId={resource && resource.resourceTypeId}
                                likeFeedback={true}
                            />
                        </Col>
                    </Row>
                }
                <Row className='cell-gap' justify='space-around'>
                    {
                        resourceTags.length > 0 &&
                        <Col span={24}>
                            <h5>Tags</h5>
                        </Col>
                    }

                    <Col span={24}>
                        {resourceTags && resourceTags.map((tag, i) => (
                            <Tag
                                onClick={() => {
                                    history.push(`/vertical/search/${tag.tag.tagName}`);
                                }}
                                key={i}
                                //color={randomColor()}
                                color='#3FCC79'
                                style={{
                                    color: '#fff',
                                    borderRadius: 500,
                                    fontSize: 14,
                                    margin: '5px',
                                    cursor: 'pointer'
                                }}>
                                {tag.tag.tagName}
                            </Tag>
                        ))}
                    </Col>

                </Row>

                <Row className='cell-gap' justify='space-around'>
                    {
                        resourceOther.length > 0 &&
                        <Col span={24}>
                            <h5>Other Resources From This User</h5>
                        </Col>
                    }

                    <Col span={24}>
                        {resourceOther && resourceOther.map((x, i) => (
                            <>
                                <Divider/>
                                <ResourceHorizontalCard
                                    key={i}
                                    resource={x}
                                />
                            </>
                        ))}
                        {
                            isMore &&
                            <Col span={24} style={{textAlign: 'center'}}>
                                <Button type='primary' onClick={loadMoreOtherResource}>Load More</Button>
                            </Col>
                        }
                    </Col>

                </Row>
            </Card>
        </div>
    )
};

export default DetailResource;
