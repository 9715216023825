import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../interfaces/page-data';
import {Button} from "antd";
import {AppState, history} from "../../redux/store";

import {useSelector} from "react-redux";
import {IEventRegister, ITrainingEnroll, IUser} from "../../interfaces/api";
import {useParams} from "react-router-dom";
import ApiService from "../../utils/services/api.service";
import toast from "react-hot-toast";

const Payment: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'Payment',
        loaded: true
    };

    const user: IUser = useSelector((state: AppState) => state).user;
    const [enrollRegister, setEnrollRegister] = useState<boolean>(false);
    const paymentId = useParams<{ type?: string, id?: string }>().id;
    const paymentType = useParams<{ type?: string, id?: string }>().type;
    useEffect(() => {
        onSetPage(pageData);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    const registerClick = async () => {
        toast.error("Your payment process declined.")
    }

    return (
        <div>
            <h5>Payment Process</h5>
            <br/>

            <Button
                onClick={registerClick}>Enrollment or Register payment process declined.</Button>
        </div>
    )
};

export default Payment;
