import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../../interfaces/page-data';
import {IUser} from "../../../interfaces/api";
import toast from "react-hot-toast";
import {Col, Row} from "antd";
import EventCard from "../../../components/EventCard/EventCard";
import {useSelector} from "react-redux";
import {AppState} from "../../../redux/store";
import {IEventApplication} from "../../../interfaces/api";
import ApiService from "../../../utils/services/api.service";

const MyEvents: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'My Events',
        loaded: true
    };
    useEffect(() => {
        onSetPage(pageData);
        eventsSetLoaded(true);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const [events, setEvents] = useState<Array<IEventApplication>>([]);
    const [eventsIsLoaded, eventsSetLoaded] = useState<boolean>(false);
    const user: IUser = useSelector((state: AppState) => state).user;

    const getEvents = async () => {
        let response = await ApiService.Event.GetEventByApplicant(user.id);
        if (response.status === 200){
            setEvents(response.data);
        }
    }

    useEffect(() => {
        if (!eventsIsLoaded)
            return;
        getEvents();
    }, [eventsIsLoaded])

    return (
        <div>
            <Row gutter={[23, 3]}>
                {
                    events.length > 0 ? events.map((item) => {
                        //const provider:IUser = item.providedBy;
                        return (
                            <Col key={item.id} xl={{span: 6}} md={{span: 8}} sm={{span: 12}} xs={{span: 24}}>
                                <EventCard isOwned={true} provider={item.user} key={item.id} applicant={item} event={item.event}/>
                            </Col>
                        )
                    }) :
                        <Col span={24} style={{textAlign:'center'}}>
                            <h5>You have not registered to any events yet.</h5>
                        </Col>
                }

            </Row>
        </div>
    )
};

export default MyEvents;
