import React, {useEffect, useState} from 'react';
import {
    IEvent,
    IPageQuery,
    IResource, ISearchMapResult,
    ISearchResult,
    ITraining,
    IUser,
    IWorkOpportunity
} from "../../interfaces/api";
import {Button, Card, Checkbox, Col, Collapse, Tabs} from "antd";
import {IPageData, IPageProps} from "../../interfaces/page-data";
import {useParams} from "react-router-dom";
import GMForSearch from "../../components/GoogleMaps/ForSearch/GMForSearch";
import ApiService from "../../utils/services/api.service";
import TrainingHorizontalCard from "../../components/TrainingCard/TraniningHorizontalCard";
import EventHorizontalCard from "../../components/EventCard/EventHorizontalCard";
import PersonCard from "../../components/PersonCard/PersonCard";
import WOHorizontalCard from "../../components/WOCard/WOHorizontalCard";
import ResourceHorizontalCard from "../../components/ResourceCard/ResourceHorizontalCard";
import './SearchPage.scss'
import {LoadingOutlined} from "@ant-design/icons";

enum SearchType {
    User = 1, // <i class="icofont-people"></i>
    Tags = 2, // <i class="icofont-tags"></i>
    //Skills,  // <i class="icofont-badge"></i>
    WorkOpportunities = 3, // <i class="icofont-search-job"></i>
    Trainings = 4, // <i class="icofont-notebook"></i>
    Events = 5,
    Resources = 6,
}

//region Page Queries For Datas
// TODO - Fix the number of records maybe 15
let usersPQuery = {numOfRecords: 2, page: 1} as IPageQuery;
let wosPQuery = {numOfRecords: 2, page: 1} as IPageQuery;
let trainingsPQuery = {numOfRecords: 2, page: 1} as IPageQuery;
let eventsPQuery = {numOfRecords: 2, page: 1} as IPageQuery;
let resourcesPQuery = {numOfRecords: 2, page: 1} as IPageQuery;
//endregion

const SearchPage: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    //const user: IUser = useSelector((state: AppState) => state).user;
    const keyWord = useParams<{ keyword?: string }>().keyword;
    const [tabsOrMap, setTabsOrMap] = useState<boolean>(true);
    const [activeTabKey, setActiveTabKey] = useState<number>(SearchType.Events);
    const [isMore, setIsMore] = useState<boolean>(true);

    //region For Datas
    const [usersData, setUsersData] = useState<IUser[]>([]);
    const [usersDataLoading, setUsersDataLoading] = useState<boolean>(false);

    const [wosData, setWOsData] = useState<IWorkOpportunity[]>([]);
    const [wosDataLoading, setWosDataLoading] = useState<boolean>(false);

    const [trainingsData, setTrainingsData] = useState<ITraining[]>([]);
    const [trainingsDataLoading, setTrainingsDataLoading] = useState<boolean>(false);

    const [eventsData, setEventsData] = useState<IEvent[]>([]);
    const [eventsDataLoading, setEventsDataLoading] = useState<boolean>(false);

    const [resourcesData, setResourcesData] = useState<IResource[]>([]);
    const [resourcesDataLoading, setResourcesDataLoading] = useState<boolean>(false);

    const [mapsData, setMapsData] = useState<ISearchMapResult>();
    const [filteredMapsData, setFilteredMapsData] = useState<ISearchMapResult>();
    //endregion

    //region For maps filter
    const cboxOptions = [
        'Events',
        //'Resources',
        'Trainings',
        'Users',
        'Work Opportunities'
    ];
    const [checkedList, setCheckedList] = React.useState<string[]>(cboxOptions);
    const [checkAll, setCheckAll] = React.useState(true);
    //endregion

    const pageData: IPageData = {
        title: `Search result for "${keyWord}"`,
        loaded: true
    };

    //region  Tab View Effects
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        onSetPage(pageData);
        tabsOnChange(activeTabKey);
        return () => {
            setUsersData([]);
            setWOsData([]);
            setTrainingsData([]);
            setEventsData([]);
            setResourcesData([]);
            // setMapsData([]);
        }
    }, [keyWord]);

    const tabsOnChange = (info) => {
        console.log('Tabs on changed')
        setActiveTabKey(info);
        switch (parseInt(info)) {
            case SearchType.Events:
                if (eventsData.length < 1) getEvents();
                break;
            case SearchType.Resources:
                if(resourcesData.length < 1) getResources();
                break;
            case SearchType.Trainings:
                if(trainingsData.length < 1) getTrainings();
                break;
            case SearchType.User:
                if(usersData.length < 1)getUsers();
                break;
            case SearchType.WorkOpportunities:
                if(wosData.length < 1)getWOs();
                break;
        }
    }

    // Switch to Map or Tabs
    const switchView = () => {
        //tabsOrMap == true && MAP VIEW
        //tabsOrMap == false && LIST VIEW
        if (tabsOrMap) {
            getMapPoints();
        } else {
            getEvents();
        }
        setTabsOrMap(!tabsOrMap);
    }

    //region Map View
    const onChangeCBoxGroup = (list: any[]) => {
        setCheckedList(list);
        setCheckAll(list.length === cboxOptions.length);
    }

    const onCheckAllChange = e => {
        setCheckedList(e.target.checked ? cboxOptions : []);
        setCheckAll(e.target.checked);
    };

    const filterBy = () => {
        // JSON parse stringify methodlari birlikte kullanilmazsa
        // referans da aktarildigindan mapsData degeri de degisir
        var tmpData: ISearchMapResult = JSON.parse(JSON.stringify(mapsData));
        if (cboxOptions.length === checkedList.length) {
            setFilteredMapsData(tmpData);
        }
        let diffArr: string[] = cboxOptions.filter((opt) => !checkedList.includes(opt));
        diffArr.forEach((item) => {
            if (item === 'Events') {
                tmpData.EventResults.Results = []
            } else if (item === 'Trainings') {
                tmpData.TrainingResults.Results = []
            } else if (item === 'Users') {
                tmpData.UserResults.Results = []
            } else if (item === 'Work Opportunities') {
                tmpData.WOResults.Results = []
            }
        })
        setFilteredMapsData(tmpData);
    };
    //endregion

    const loadMore = async (type: SearchType) => {
        switch (type) {
            case SearchType.User:
                usersPQuery.page = usersPQuery.page + 1;
                await getUsers();
                return;
            case SearchType.WorkOpportunities:
                wosPQuery.page = wosPQuery.page + 1;
                await getWOs();
                return;
            case SearchType.Trainings:
                trainingsPQuery.page = trainingsPQuery.page + 1;
                await getTrainings();
                return;
            case SearchType.Events:
                eventsPQuery.page = eventsPQuery.page + 1;
                await getEvents();
                return;
            case SearchType.Resources:
                resourcesPQuery.page = resourcesPQuery.page + 1;
                await getResources();
                return;
        }
    }

    //region HTTP Requests
    const getMapPoints = async () => {
        let response = await ApiService.Search.GetSearchMapByKeyword(keyWord);
        setMapsData(response.data);
        setFilteredMapsData(response.data);
    }

    const getUsers = async () => {
        setUsersDataLoading(true);
        let response = await ApiService.User.GetUserSearchByKeyword(keyWord, {...usersPQuery, isAllResults: false});
        let nextResponse = await ApiService.User.GetUserSearchByKeyword(keyWord, {page: usersPQuery.page+1, numOfRecords: usersPQuery.numOfRecords, isAllResults:false});
        let t: IUser[] = response.data;
        if (nextResponse.data.length > 0) {
            setIsMore(true);
        } else setIsMore(false);
        setUsersData([...usersData, ...t]);
        setUsersDataLoading(false);
    }

    const getWOs = async () => {
        setWosDataLoading(true);
        let response = await ApiService.WorkOpportunity.GetWOSearchByKeyword(keyWord, wosPQuery);
        let nextResponse = await ApiService.WorkOpportunity.GetWOSearchByKeyword(keyWord, {page: wosPQuery.page+1, numOfRecords: wosPQuery.numOfRecords});
        let t: IWorkOpportunity[] = response.data;
        if (nextResponse.data.length > 0) {
            setIsMore(true);
        } else setIsMore(false);
        setWOsData([...wosData, ...t]);
        setWosDataLoading(false);
    }

    const getTrainings = async () => {
        setTrainingsDataLoading(true);
        let response = await ApiService.Training.GetTrainingSearchByKeyword(keyWord, trainingsPQuery);
        let nextResponse = await ApiService.Training.GetTrainingSearchByKeyword(keyWord, {page: trainingsPQuery.page+1, numOfRecords: trainingsPQuery.numOfRecords});
        let t: ITraining[] = response.data;
        if (nextResponse.data.length > 0) {
            setIsMore(true);
        } else setIsMore(false);
        setTrainingsData([...trainingsData, ...t]);
        setTrainingsDataLoading(false);
    }

    const getEvents = async () => {
        setEventsDataLoading(true);
        let response = await ApiService.Event.GetEventSearchByKeyword(keyWord, eventsPQuery);
        let nextResponse = await ApiService.Event.GetEventSearchByKeyword(keyWord, {page: eventsPQuery.page+1, numOfRecords: eventsPQuery.numOfRecords});
        let t: IEvent[] = response.data;
        if (nextResponse.data.length > 0) {
            setIsMore(true);
        } else setIsMore(false);
        setEventsData([...eventsData, ...t]);
        setEventsDataLoading(false);
    }

    const getResources = async () => {
        setResourcesDataLoading(true);
        let response = await ApiService.Resource.GetResourceSearchByKeyword(keyWord, resourcesPQuery);
        let nextResponse = await ApiService.Resource.GetResourceSearchByKeyword(keyWord, {page: resourcesPQuery.page+1, numOfRecords: resourcesPQuery.numOfRecords});
        let t: IResource[] = response.data;
        if (nextResponse.data.length > 0) {
            setIsMore(true);
        } else setIsMore(false);
        setResourcesData([...resourcesData, ...t]);
        setResourcesDataLoading(false);
    }
    //endregion

    return (
        <div className='search-page'>
            <div className='row'>
                <div className='col-12 d-flex justify-content-between' style={{alignItems: 'center'}}>
                    <h5>Search result for "{keyWord}"</h5>
                    <Button type='primary' onClick={switchView} className='responsive-button'
                            icon={!tabsOrMap ? <i className="icofont-listine-dots"/> :
                                <i className="icofont-search-map"/>}
                    >{!tabsOrMap ? 'Tabs' : 'Map'} View</Button>
                </div>

            </div>
            {
                tabsOrMap ?
                    <div className='row search-tabs'>
                        <div className='col-12'>
                            <Card className='ant-card-invisible'>
                                <Tabs defaultActiveKey={`${SearchType.Events}`} style={{width: '100%'}} size='large'
                                      onChange={tabsOnChange}>
                                    <Tabs.TabPane tab='Events' key={`${SearchType.Events}`}>
                                        <div>
                                            {
                                                eventsData.map((item) => {
                                                    return (
                                                        <Col key={item.id} span={24}
                                                             className='col-line-gap'>
                                                            <EventHorizontalCard key={item.id}
                                                                                 event={item}/>
                                                        </Col>
                                                    )
                                                })
                                            }
                                            {
                                                eventsDataLoading ?
                                                    <div style={{width: '100%', height: '50px', textAlign: 'center'}}>
                                                        <LoadingOutlined style={{fontSize: '24px'}}/>
                                                    </div>
                                                    :
                                                    <>
                                                        {isMore ?
                                                            <div className='col-12' style={{textAlign: 'center'}}>
                                                                <Button onClick={() => {
                                                                    loadMore(SearchType.Events)
                                                                }}>Load More</Button>
                                                            </div>
                                                            :
                                                            <div style={{width: '100%', textAlign: 'center'}}>End of
                                                                results</div>
                                                        }
                                                    </>
                                            }
                                        </div>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab='Resources' key={`${SearchType.Resources}`}>
                                        <div>
                                            {
                                                    resourcesData.map((item) => {
                                                        return (
                                                            <Col key={item.id} span={24} className='col-line-gap'>
                                                                <ResourceHorizontalCard key={item.id} resource={item}/>
                                                            </Col>
                                                        )
                                                    })
                                            }
                                            {
                                                resourcesDataLoading ?
                                                    <div style={{width: '100%', height: '50px', textAlign: 'center'}}>
                                                        <LoadingOutlined style={{fontSize: '24px'}}/>
                                                    </div>
                                                    :
                                                    <>
                                                        {isMore ?
                                                            <div className='col-12' style={{textAlign: 'center'}}>
                                                                <Button onClick={() => {
                                                                    loadMore(SearchType.Resources)
                                                                }}>Load More</Button>
                                                            </div>
                                                            :
                                                            <div style={{width: '100%', textAlign: 'center'}}>End of
                                                                results</div>
                                                        }
                                                    </>
                                            }
                                        </div>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab='Trainings' key={`${SearchType.Trainings}`}>
                                        <div>
                                            {
                                                    trainingsData.map((item) => {
                                                        return (
                                                            <Col key={item.id} span={24} className='col-line-gap'>
                                                                <TrainingHorizontalCard key={item.id}
                                                                                        training={item}/>
                                                            </Col>
                                                        )
                                                    })
                                            }
                                            {
                                                trainingsDataLoading ?
                                                    <div style={{width: '100%', height: '50px', textAlign: 'center'}}>
                                                        <LoadingOutlined style={{fontSize: '24px'}}/>
                                                    </div>
                                                    :
                                                    <>
                                                        {isMore ?
                                                            <div className='col-12' style={{textAlign: 'center'}}>
                                                                <Button onClick={() => {
                                                                    loadMore(SearchType.Trainings)
                                                                }}>Load More</Button>
                                                            </div>
                                                            :
                                                            <div style={{width: '100%', textAlign: 'center'}}>End of
                                                                results</div>
                                                        }
                                                    </>
                                            }
                                        </div>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab='Users' key={`${SearchType.User}`}>
                                        <div>
                                            {
                                                    usersData.map((item) => {
                                                        return (
                                                            <Col key={item.id} span={24} className='col-line-gap'>
                                                                <PersonCard user={item}/>
                                                            </Col>
                                                        )
                                                    })
                                            }
                                            {
                                                usersDataLoading ?
                                                    <div style={{width: '100%', height: '50px', textAlign: 'center'}}>
                                                        <LoadingOutlined style={{fontSize: '24px'}}/>
                                                    </div>
                                                    :
                                                    <>
                                                        {isMore ?
                                                            <div className='col-12' style={{textAlign: 'center'}}>
                                                                <Button onClick={() => {
                                                                    loadMore(SearchType.User)
                                                                }}>Load More</Button>
                                                            </div>
                                                            :
                                                            <div style={{width: '100%', textAlign: 'center'}}>End of
                                                                results</div>
                                                        }
                                                    </>
                                            }
                                        </div>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab='Work Opportunities' key={`${SearchType.WorkOpportunities}`}>
                                        <div>
                                            {
                                                    wosData.map((item) => {
                                                        return (
                                                            <Col key={item.id} span={24} className='col-line-gap'>
                                                                <WOHorizontalCard wo={item}/>
                                                            </Col>
                                                        )
                                                    })
                                            }
                                            {
                                                wosDataLoading ?
                                                    <div style={{width: '100%', height: '50px', textAlign: 'center'}}>
                                                        <LoadingOutlined style={{fontSize: '24px'}}/>
                                                    </div>
                                                    :
                                                    <>
                                                        {isMore ?
                                                            <div className='col-12' style={{textAlign: 'center'}}>
                                                                <Button onClick={() => {
                                                                    loadMore(SearchType.WorkOpportunities)
                                                                }}>Load More</Button>
                                                            </div>
                                                            :
                                                            <div style={{width: '100%', textAlign: 'center'}}>End of
                                                                results</div>
                                                        }
                                                    </>
                                            }
                                        </div>
                                    </Tabs.TabPane>
                                </Tabs>
                            </Card>
                        </div>
                    </div>
                    :
                    <div className='row search-map'>
                        {/*region Filter*/}
                        <div className='col-12' style={{textAlign: 'center', padding: '20px 10px'}}>
                            <Collapse bordered={false} ghost>
                                <Collapse.Panel header='Filter' key='1'>
                                    <Checkbox onChange={onCheckAllChange} checked={checkAll}>All</Checkbox>
                                    <Checkbox.Group options={cboxOptions} value={checkedList}
                                                    onChange={onChangeCBoxGroup}/>
                                    <div className='d-flex justify-content-between'>
                                        <span/>
                                        <Button type='primary' onClick={filterBy}>Filter</Button>
                                    </div>
                                </Collapse.Panel>
                            </Collapse>
                        </div>
                        {/*endregion*/}

                        {/*region Search Map*/}
                        <div className='col-12'>
                            <GMForSearch markers={filteredMapsData} scrollWheel={false}/>
                        </div>
                        {/*endregion*/}
                    </div>
            }
        </div>
    );
};

export default SearchPage;
