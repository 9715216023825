import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../../interfaces/page-data';
import {Button, Card, Modal, Table} from "antd";
import {AppState, history} from "../../../redux/store";

import {IEvent, IEventApplication, IUser} from "../../../interfaces/api";
import toast from "react-hot-toast";
import {CheckOutlined, FileExcelFilled, LeftOutlined} from "@ant-design/icons";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import ExportJsonExcel from "js-export-excel";
import ApiService from "../../../utils/services/api.service";

const columnsInitial = [
    {
        title: "User Name",
        dataIndex: "name",
        ellipsis: false,
        render: (cell, row: IEventApplication) => {
            return (
                <span>
                    {row.user.name} {row.user.surname}
                </span>
            )
        }
    },
    Table.SELECTION_COLUMN
    // {
    //     title: "isComplete",
    //     dataIndex: "",
    //     width: '150px',
    //     ellipsis: false,
    //     hidden: true,
    //     render: () => {
    //         return (<>
    //             <div style={{textAlign: 'center'}}>
    //                 <Switch/>
    //             </div>
    //         </>)
    //     }
    // }
]

// Score bilgisi sadece event e katilip katilmama durumunu temsil eder, gercek bir notlama degildir
const MyEventsTeachDetail: React.FC<IPageProps> = props => {


    const eventId = useParams<{ id?: string }>().id;
    const user: IUser = useSelector((state: AppState) => state).user;
    const [participations, setParticipations] = useState<IEventApplication[]>([]);
    const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
    const [eventsIsLoaded, eventsSetLoaded] = useState<boolean>(false);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [event, setEvent] = useState<IEvent>(null);

    const {onSetPage} = props;
    const pageData: IPageData = {
        title: event !== null && event.eventName,
        loaded: true
    };
    useEffect(() => {
        onSetPage(pageData);
        const any = async () => {
            eventsSetLoaded(false);

            let partiResponse = await ApiService.EventApplication.GetEventApplication(eventId);
            setParticipations(partiResponse.data);
            let results: string[] = partiResponse.data.map(item => {
                console.log(item.score)
                if (item.score) {
                    return item.userId;
                }
            });
            setSelectedRowIds(results);

            let eventResponse = await ApiService.Event.GetEventById(eventId);
            setEvent(eventResponse.data);

            eventsSetLoaded(true);
        }
        any().then(null);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const rowSelection = {
        selectedRowKeys: selectedRowIds,
        //columnTitle: <>Is Finished <Checkbox/></>,
        onChange: (selectedRowKeys) => {
            console.dir(selectedRowKeys);
            setSelectedRowIds(selectedRowKeys)
        },
        getCheckboxProps: (record: IEventApplication) => ({
            disabled: record.isFinished
        })
        // renderCell: (checked, record, index, originNode) => {
        //     return (<Switch checked={checked}/>)
        // }
    };

    const exportToExcel = async () => {
        let data = participations
        var option: any = {};
        let dataTable = [];
        if (data) {
            for (let i in data) {
                if (data) {

                    let obj = {
                        'Name': data[i].user.name,
                        'Surname': data[i].user.surname,
                        'Is Join': data[i].score
                    }
                    dataTable.push(obj);
                }
            }
        }
        option.fileName = 'Event Participations'
        option.datas = [
            {
                sheetData: dataTable,
                sheetName: 'sheet',
                sheetFilter: ['Name', 'Surname', 'Is Join'],
                sheetHeader: ['Name', 'Surname', 'Is Join'],
            }
        ];

        var toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
    }

    const submit = async () => {
        console.log('submit');
        let results: { userId: string, score: boolean }[] = participations.map(item => {
            if (selectedRowIds.find(x => x === item.userId)) {
                return {score: true, userId: item.userId};
            }
            else {
                return {score: false, userId: item.userId};
            }
        });

        let response = await ApiService.EventApplication.PostEventApplicationSetApplicantScore(results,{eventId: eventId, providerId: user.id});
        if (response.status === 200){
            setModalVisible(false);
            history.push('/vertical/my-events/organize');
        }
    }
    return (
        <div>
            <div className='row'>
                <div className='col-12'>
                    <Card title={event !== null && event.eventName}>
                        <Table
                            rowKey={(row: IEventApplication) => row.userId}
                            rowSelection={rowSelection}
                            dataSource={participations.length > 0 && participations}
                            columns={columnsInitial}
                            loading={!eventsIsLoaded}
                        />
                    </Card>
                </div>
                <div className='col-12'>
                    <div className='d-flex justify-content-between'>
                        <Button className='responsive-button' onClick={() => {
                            history.push('/vertical/my-events/organize')
                        }} danger icon={<LeftOutlined/>}>Cancel</Button>

                        <Button className='responsive-button'
                                type='primary'
                                onClick={() => {
                                    exportToExcel()
                                }}
                                icon={<FileExcelFilled/>}>Download as Excel</Button>

                        <Button className='responsive-button' type='primary'
                                onClick={() => {setModalVisible(true);}}
                                icon={<CheckOutlined/>}>Submit</Button>
                    </div>
                </div>
            </div>

            <Modal
                closable={false}
                title='Submit Results'
                visible={modalVisible}
                destroyOnClose={true}
                onOk={submit}
                okText={'Confirm'}
                okButtonProps={{
                    className:'responsive-button',
                    icon:<CheckOutlined/>
                }}
                onCancel={() => {setModalVisible(false)}}
                cancelButtonProps={{
                    className:'responsive-button',
                    icon:<LeftOutlined/>,
                    danger: true
                }}
            >
                <span>{selectedRowIds.length} out of {participations.length} applicants are approved for completing the event. Would you like to continue?</span>
            </Modal>

        </div>
    )
};

export default MyEventsTeachDetail;
