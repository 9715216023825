import React, {useEffect} from 'react';
import {ITraining, ITrainingApplication, IUser} from "../../interfaces/api";
import {Button, Card, Tooltip} from "antd";
import {history} from "../../redux/store";
import blankImg from "../../assets/img/blank/blank-image.png";
import {DATE_FORMAT, S3_SERVER_URL} from "../../utils/Constants";
import moment from "moment";
import './TrainingCard.scss';
import {objIsEmpty} from "../../utils/GlobalFuncs";
import {CheckCircleTwoTone, CloseCircleTwoTone} from "@ant-design/icons";
import ImgViewer from "../ImgViewer/ImgViewer";

interface CardProps {
    training: ITraining,
    provider: IUser,
    isOwned?: boolean,
    applicant?: ITrainingApplication
}

const TrainingCard: React.FC<CardProps> = props => {
    const training = props.training;
    const provider = props.provider;

    return (
        <div className='training-card'>
            <Card
                loading={objIsEmpty(training)}
                hoverable
                onClick={() => {
                    history.push(`/vertical/trainings/enroll/${training.id}`);
                }}
                cover={
                    <>
                        <div
                            className='img-item-wrapper'
                        >
                            {training.uploadedThumbnail.length < 1 ?
                                <ImgViewer src={blankImg} ratio={2}/>
                                :
                                <ImgViewer src={S3_SERVER_URL + training.uploadedThumbnail} ratio={2} width='100%'/>
                            }
                            {props.isOwned &&
                                <>
                            {
                                props.applicant.isFinished ?

                                <div className='complete-status'>
                            {props.applicant.score ?
                                // <CheckCircleFilled style={{color:'green', fontSize:'24px', backgroundColor: 'white'}}/>
                                <CheckCircleTwoTone twoToneColor='green'
                                style={{fontSize: '32px'}}/>
                                :
                                <CloseCircleTwoTone twoToneColor='red' style={{fontSize: '32px'}}/>
                            }
                                </div>
                                :
                                <>
                                <span className='complete-status continues-frame'>
                                <span>Continues</span>
                                </span>
                                </>
                            }
                                </>
                            }
                                </div>

                                </>
                            }
                            // actions={[<InfoCircleOutlined onClick={() => {
                            //     history.push(`/trainings/${item.id}`);
                            // }}/>]}
                        >
                        <
                            Card.Meta
                            title = {
                            <Tooltip title={training.trainingName}>
                                <div className='meta-title'>
                                    {training.trainingName}
                                </div>

                            </Tooltip>
                        }
                            description = {
                            <div className='meta-desc'>
                                <div className='sub-desc' dangerouslySetInnerHTML={{__html: training.description.replace('\r\n','')}}/>
                                {
                                    provider &&
                                    <div className='meta-provider-text'>
                                        {provider.userTypeId == 1 ? (provider.name + ' ' + provider.surname) : provider.name}
                                    </div>
                                }


                                {props.isOwned ?
                                    <>
                                        <div className='meta-deadline-text'>
                                            {moment(training.startDate).format(DATE_FORMAT)} - {moment(training.finishDate).format(DATE_FORMAT)}
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div
                                            className='meta-seat-text'>
                                            {training.maxParticipants == 0 ? 'No limit ' : 'Total '} {training.maxParticipants} {training.maxParticipants == 1 ? ' seat' : ' seats'}
                                        </div>
                                        <div className='meta-deadline-text'>
                                            Deadline&nbsp;
                                            {moment(training.deadline).format(DATE_FORMAT)}&nbsp;
                                            <i className="icofont-ui-calendar"/>
                                        </div>
                                    </>

                                }


                                {/*<div className='date-title'>*/}
                                {/*    Between */}
                                {/*    {moment(training.startDate).format(DATE_FORMAT)}*/}
                                {/*    -*/}
                                {/*    {moment(training.finishDate).format(DATE_FORMAT)}*/}
                                {/*</div>*/}
                                {
                                    !props.isOwned &&
                                    <div className='meta-price-title'>
                                        {
                                            training.participationFee === 0 ?
                                                <div>
                                                    Free
                                                </div>
                                                :
                                                <div>
                                                    {training.participationFee === 0 ? 'Free' : training.participationFee}<i
                                                    className="icofont-dollar"/>
                                                </div>
                                        }

                                    </div>
                                }
                                {
                                    props.isOwned && props.applicant.isFinished &&
                                    <>
                                        <div className='meta-deadline-text'>Skill1</div>
                                        <div className='meta-deadline-text'>Skill2</div>
                                    </>


                                }

                                <div className='fill'/>
                            </div>
                        }
                            />
                        </Card>
                        </div>
                        )
                            ;
                        };

                            TrainingCard.defaultProps = {
                            training: {} as ITraining,
                            applicant: {} as ITrainingApplication,
                            provider: {} as IUser,
                            isOwned: false
                        }

                            export default TrainingCard;
