import React, {useEffect, useState} from 'react';
import { ISearchMapResult} from "../../interfaces/api";
import {Button, Checkbox, Collapse} from "antd";
import {IPageData, IPageProps} from "../../interfaces/page-data";
import GMForSearch from "../../components/GoogleMaps/ForSearch/GMForSearch";
import {
    // NotebookIconPath,
    // TagsIconPath,
    // PeopleIconPath,
    // SearchJobPath,
    // BadgeIconPath,
    TestIcon1Path
} from "../../components/GoogleMaps/ForSearch/Icons";
import ApiService from "../../utils/services/api.service";
import './SearchOnlyMapPage.scss'

const SearchPage: React.FC<IPageProps> = props => {
    const {onSetPage} = props;

    //region For Datas
    const [mapsData, setMapsData] = useState<ISearchMapResult>();
    const [filteredMapsData, setFilteredMapsData] = useState<ISearchMapResult>();
    //endregion


    //region For maps filter
    const cboxOptions = [
        'Events',
        //'Resources',
        'Trainings',
        'Users',
        'Work Opportunities'
    ];
    const [checkedList, setCheckedList] = React.useState<string[]>(cboxOptions);
    const [checkAll, setCheckAll] = React.useState(true);
    //endregion

    const pageData: IPageData = {
        title: `Search result for everything`,
        loaded: true
    };

    //region  Tab View Effects
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        onSetPage(pageData);
        getMapPoints();

        // First method for search page
        //getEvents();
    }, []);

    //region Map View
    const onChangeCBoxGroup = (list: any[]) => {
        setCheckedList(list);
        setCheckAll(list.length === cboxOptions.length);
    }

    const onCheckAllChange = e => {
        setCheckedList(e.target.checked ? cboxOptions : []);
        setCheckAll(e.target.checked);
    };

    const filterBy = () => {
        console.log('filterBy, ' + checkedList);
        console.dir(mapsData);
        // JSON parse stringify methodlari birlikte kullanilmazsa
        // referans da aktarildigindan mapsData degeri de degisir
        var tmpData: ISearchMapResult = JSON.parse(JSON.stringify(mapsData));
        if (cboxOptions.length === checkedList.length) {
            setFilteredMapsData(tmpData);
        }
        let diffArr: string[] = cboxOptions.filter((opt) => !checkedList.includes(opt));
        console.dir(diffArr)
        diffArr.forEach((item) => {
            console.log(item)
            if (item === 'Events') {
                tmpData.EventResults.Results = []
            }
            else if (item === 'Trainings') {
                tmpData.TrainingResults.Results = []
            }
            else if (item === 'Users') {
                tmpData.UserResults.Results = []
            }
            else if (item === 'Work Opportunities') {
                tmpData.WOResults.Results = []
            }
        })
        setFilteredMapsData(tmpData);
    };
    //endregion

    const getMapPoints = async () => {
        // TODO - New search map method will add (no keyword)
        let response = await ApiService.Search.GetSearchMapAllResults();
        setMapsData(response.data);
        setFilteredMapsData(response.data);
    }

    return (
        <div className='search-only-map-page'>

            <div className='row search-map'>
                {/*region Filter*/}
                <div className='col-12' style={{textAlign: 'center', padding: '20px 10px'}}>
                    <Collapse bordered={false} ghost defaultActiveKey='1'>
                        <Collapse.Panel header='Filter' key='1'>
                            <Checkbox onChange={onCheckAllChange} checked={checkAll}>All</Checkbox>
                            <Checkbox.Group options={cboxOptions} value={checkedList}
                                            onChange={onChangeCBoxGroup}/>
                            <div className='d-flex justify-content-between'>
                                <span/>
                                <Button type='primary' onClick={filterBy}>Filter</Button>
                            </div>
                        </Collapse.Panel>
                    </Collapse>
                </div>
                {/*endregion*/}

                {/*region Search Map*/}
                <div className='col-12'>
                    <GMForSearch markers={filteredMapsData} scrollWheel={false}/>
                </div>
                {/*endregion*/}
            </div>

        </div>
    );
};

export default SearchPage;
