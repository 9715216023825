import React, {useEffect, useState} from 'react';
import {Col, Spin} from 'antd';
import {IPageData, IPageProps} from '../../../interfaces/page-data';
import {IEvent, IResource, ISkill, ITimeline, ITraining, IUser, IWorkOpportunity} from "../../../interfaces/api";
import './Home.scss';
import ApiService from "../../../utils/services/api.service";
import InfiniteScroll from "react-infinite-scroll-component";
import WallItem from "../../../components/WallComponents/WallItem";
import MiniCard from "../../../components/MiniCard/MiniCard";
import {store} from "../../../redux/store";
import TimelineFormatter from "../../../components/TimelineFormatter/TimelineFormatter";
import Spinner from "../../../utils/Spinner";
import {IMiniCard} from "../../../interfaces/IMiniCard";

let hasMore: boolean = true;
let timelineCount: number = 0;
const Home: React.FunctionComponent<IPageProps> = props => {
    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'Home',
        loaded: true
    };

    const user: IUser = store.getState().user;
    const [isLoading, setLoading] = useState<boolean>(true);
    const [timeline, setTimeline] = useState<ITimeline[]>([]);
    const [news, setNews] = useState<IMiniCard[]>([]);

    useEffect(() => {
        timelineCount = 0;
        console.log('use effect triggered')
        onSetPage(pageData);
        any().then(null);
    }, []);

    const any = async () => {
        await setLoading(true);
        let tmpNews: IMiniCard[] = [];
        let eventResponse = await ApiService.Event.GetEventListLastN({numOfRecords: 5});
        eventResponse.data.map((item: IEvent) => {
            let t: IMiniCard = {
                id: item.id, newsType: 'Event', newsName: item.eventName, createdAt: item.createdAt,
                providerId: item.providedBy.id, thumbnail: item.uploadedThumbnail
            }
            tmpNews = [...tmpNews, t]
        });

        let trainingResponse = await ApiService.Training.GetTrainingListLastN({numOfRecords: 5});
        trainingResponse.data.map((item: ITraining) => {
            let t: IMiniCard = {
                id: item.id, newsType: 'Training', newsName: item.trainingName, createdAt: item.createdAt,
                providerId: item.providedBy.id, thumbnail: item.uploadedThumbnail
            }
            tmpNews = [...tmpNews, t]
        });

        let woResponse = await ApiService.WorkOpportunity.GetWOListLastN({numOfRecords: 5});
        woResponse.data.map((item: IWorkOpportunity) => {
            let t: IMiniCard = {
                id: item.id, newsType: 'WO', newsName: item.title, createdAt: item.createdAt,
                providerId: item.providedBy.id, thumbnail: item.uploadedImage
            }
            tmpNews = [...tmpNews, t]
        });

        let skillResponse = await ApiService.Skill.GetSkillListLastN({numOfRecords: 5});
        skillResponse.data.map((item: ISkill) => {
            let t: IMiniCard = {
                id: item.id, newsType: 'Skill', newsName: item.skillName, createdAt: item.createdAt,
                providerId: item.userId, thumbnail: item.uploadedSkillImage
            }
            tmpNews = [...tmpNews, t]
        });

        let resourceResponse = await ApiService.Resource.GetResourceListLastN({numOfRecords: 5});
        resourceResponse.data.map((item: IResource) => {
            let t: IMiniCard = {
                id: item.id, newsType: 'Resource', newsName: item.title, createdAt: item.createdAt,
                providerId: item.uploadedById, thumbnail: item.uploadedThumbnail
            }
            tmpNews = [...tmpNews, t]
        });

        tmpNews.sort((a, b) => {
            return b.createdAt.localeCompare(a.createdAt)
        });
        console.dir(tmpNews);
        setNews(tmpNews);
        await setLoading(false);

        await loadMore();

        // let timelineResponse = await ApiService.Timeline.GetByOwnerId(user.id, {numOfRecords: 5, page:1});
        // setTimeline(timelineResponse.data);
    }

    //region Load more item
    const loadMore = async () => {
        console.dir(timelineCount)
        timelineCount++;
        let response = await ApiService.Timeline.GetByOwnerId(user.id, {numOfRecords: 10, page: timelineCount});
        if (response.data.length < 10) {
            hasMore = false;
        }
        console.dir([...timeline, ...response.data]);
        if (timeline.length < 1)
            setTimeline(response.data);
        else {
            setTimeline(prevState => {
                return [...prevState, ...response.data]
            });
        }
    }
    //endregion

    return (
        <div>
            <div className='row'>
                <div className='col-12'>
                    <h5>News</h5>
                </div>
            </div>

            <div className='row '>
                <div className='col-12'>
                    <div className='horizontal-wrapper'>
                        {
                            isLoading ? <Spinner topElementClass='col-12'></Spinner>
                                :
                                <>
                                    {
                                        news.length > 0 ? news.map((item) => {
                                                //const provider:IUser = item.providedBy;
                                                return (
                                                    <span key={item.id} style={{display: 'inline-block'}}>
                                                        <MiniCard
                                                            key={item.id}
                                                            newsTitle={item.newsName}
                                                            newsGuid={item.id}
                                                            newsType={item.newsType}
                                                            thumbnail={item.thumbnail}
                                                            newsProviderPP={item.providerId}
                                                        />
                                                    </span>
                                                )
                                            }) :
                                            <Col span={24} style={{textAlign: 'center'}}>
                                                <h6>No event</h6>
                                            </Col>
                                    }
                                </>
                        }
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-12'>
                    <h5>Wall</h5>
                </div>
            </div>

            <div>
                <InfiniteScroll
                    loader={
                        <div style={{width: '100%', textAlign: 'center'}}>
                            <Spin/>
                        </div>
                    }
                    endMessage={
                        <div style={{width: '100%', textAlign: 'center'}}>
                            <p style={{textAlign: "center"}}>
                                <b>Yay! You have seen it all</b>
                            </p>
                        </div>
                    }
                    hasMore={hasMore}
                    dataLength={timeline.length}
                    next={async () => {
                        console.log('next');
                        await loadMore();
                    }}
                    scrollableTarget='main-content'
                >
                    {
                        timeline.map((item) => {

                            return (
                                <div style={{width: '98%'}} key={item.id+'d'}>
                                    <WallItem
                                        key={item.id+'wi'}
                                        data={
                                            <TimelineFormatter
                                                text={item.timelineEventText}
                                                user1={item.user1Text}
                                                user2={item.user2Text}
                                                title={item.timelineEventTitle}
                                                eventId={item.timelineEventId}/>
                                        }
                                        ownerGuid={item.user1}
                                        ownerName={item.user1Text}
                                        createdAt={item.createdAt}
                                    />
                                </div>
                            )
                        })
                    }
                </InfiniteScroll>
            </div>
        </div>
    )
        ;
};

export default Home;
