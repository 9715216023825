import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../interfaces/page-data';
import {Button, Card, Col, Row, Select, Spin, Table} from "antd";
import {history} from "../../redux/store";
import {IEvent} from "../../interfaces/api";
import {PlusOutlined} from "@ant-design/icons";
import ResponsiveButton from "../../components/ResponsiveButton/ResponsiveButton";
import ApiService from "../../utils/services/api.service";
import EventCard from "../../components/EventCard/EventCard";
import Spinner from "../../utils/Spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import WallItem from "../../components/WallComponents/WallItem";

let hasMore: boolean = true;
const Events: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'Events',
        loaded: true
    };
    const [events, setEvents] = useState<Array<IEvent>>([]);
    const [dataIsLoaded, dataSetLoaded] = useState<boolean>(false);
    const [loadMoreCount, setLoadMoreCount] = useState<number>(10);

    useEffect(() => {
        onSetPage(pageData);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const loadMore = async () => {
        dataSetLoaded(false);
        let response = await ApiService.Event.GetEventListLastN({numOfRecords: loadMoreCount});
        let nextResponse = await ApiService.Event.GetEventListLastN({numOfRecords: loadMoreCount+10});
        hasMore = response.data.length < nextResponse.data.length;
        await setEvents(response.data);
        dataSetLoaded(true);
    }

    useEffect(() => {
        loadMore();
    }, [loadMoreCount])

    return (
        <div>
            <div className='d-flex justify-content-between'>
                <span/>
                <ResponsiveButton
                    text='Post'
                    icon={<PlusOutlined/>}
                    onClick={() => {
                        history.push('/vertical/events/new')
                    }}/>
            </div>
            <br/>

            {
                !dataIsLoaded ?
                    <Spinner topElementClass={'main-content'}></Spinner>
                    :
                    <InfiniteScroll
                        loader={
                            <div style={{width: '100%', textAlign: 'center'}}>
                                <Spin/>
                            </div>
                        }
                        endMessage={
                            <div style={{width: '100%', textAlign: 'center'}}>
                                <p style={{textAlign: "center"}}>
                                    <b>Yay! You have seen it all</b>
                                </p>
                            </div>
                        }
                        hasMore={hasMore}
                        dataLength={events.length}
                        next={() => {
                            console.log('next')
                            setLoadMoreCount(loadMoreCount + 10)
                        }}
                        scrollableTarget='main-content'
                    >
                        <Row gutter={[23, 3]} style={{margin: "0px"}}>

                            {
                                events.length > 0 ? events.map((item) => {
                                        return (
                                            <Col key={item.id} xxl={{span: 6}} xl={{span: 6}} lg={{span: 8}} md={{span: 6}}
                                                 sm={{span: 12}}
                                                 xs={{span: 24}}>
                                                <EventCard provider={item.providedBy} key={item.id} event={item}/>
                                            </Col>
                                        )
                                    }) :
                                    <Col span={24} style={{textAlign: 'center'}}>
                                        <h6>No event</h6>
                                    </Col>
                            }
                        </Row>
                    </InfiniteScroll>
            }
        </div>
    )
};

export default Events;
