import React from 'react';
import {IUser} from "../../interfaces/api";
import {Card, Col, Row, Space} from "antd";
import blankUser from '../../assets/img/blank/blank-user.png';
import './PersonCard.scss';
import {
    FacebookFilled,
    GlobalOutlined,
    InstagramOutlined,
    LinkedinFilled,
    TwitterSquareFilled
} from "@ant-design/icons";
import {
    FACEBOOK_ROOT_URL,
    INSTAGRAM_ROOT_URL,
    LINKEDIN_ROOT_URL,
    S3_SERVER_URL,
    TWITTER_ROOT_URL, WEBADDRESS_ROOT_URL
} from "../../utils/Constants";
import {objIsEmpty} from "../../utils/GlobalFuncs";
import ImgViewer from "../ImgViewer/ImgViewer";
import {history} from "../../redux/store";

interface CardProps {
    user: IUser
}

const PersonCard: React.FC<CardProps> = props => {
    const user: IUser = props.user;

    const goPersonPage = () => {
        history.push(`/vertical/user-profile/${user.username ? user.username : user.id}`);
    };
    return (
        <Card className='ant-card-invisible' loading={objIsEmpty(user)} >
            <div className='person-card-wrapper'>
                <Row className='person-card' align="middle">
                    <Col className='person-card-image' xs={{span: 6}} sm={{span: 6}} md={{span: 5}} lg={{span: 4}} onClick={goPersonPage}>
                        <ImgViewer
                            src={user.uploadedProfilePhoto ? S3_SERVER_URL + user.uploadedProfilePhoto : blankUser}
                            shape='circle'
                            ratio={1}
                            width={'80px'}
                            maxWidth={'100%'}
                        />
                    </Col>
                    <Col className='person-card-info' xs={{span: 18}} sm={{span: 18}} md={{span: 19}} lg={{span: 20}}>
                        <Row>
                            <Col span={24} className='user-name' onClick={goPersonPage}>{user.userTypeId == 1 ? (user.name + ' ' + user.surname) : user.name}</Col>
                            <Col span={24} className='user-bio' onClick={goPersonPage}>{user.bio &&
                                <div dangerouslySetInnerHTML={{__html: user.bio.replace('\r\n','')}}/>
                            }</Col>
                            {
                                (user.socialLinkedin || user.socialInstagram || user.socialTwitter || user.socialFacebook || user.webAddress) &&
                                <Col span={24} className='user-social'>

                                    <Space className='person-card-social' size='middle'>
                                        {
                                            user.webAddress.length > 0 &&
                                            <a href={WEBADDRESS_ROOT_URL + user.webAddress} target="_blank">
                                                <GlobalOutlined className='social-icon'/>
                                            </a>
                                        }
                                        {
                                            user.socialLinkedin.length > 0 &&
                                            <a href={LINKEDIN_ROOT_URL + user.socialLinkedin} target="_blank">
                                                <LinkedinFilled className='social-icon'/>
                                            </a>
                                        }
                                        {
                                            user.socialInstagram.length > 0 &&
                                            <a href={INSTAGRAM_ROOT_URL + user.socialInstagram} target="_blank">
                                                <InstagramOutlined className='social-icon' style={{borderRadius: '7px'}}/>
                                            </a>
                                        }
                                        {
                                            user.socialFacebook.length > 0 &&
                                            <a href={FACEBOOK_ROOT_URL + user.socialFacebook} target="_blank">
                                                <FacebookFilled className='social-icon'/>
                                            </a>
                                        }
                                        {
                                            user.socialTwitter.length > 0 &&
                                            <a href={TWITTER_ROOT_URL + user.socialTwitter} target="_blank">
                                                <TwitterSquareFilled className='social-icon'/>
                                            </a>
                                        }
                                    </Space>
                                </Col>
                            }
                        </Row>
                    </Col>
                </Row>

            </div>
        </Card>
    );
};

PersonCard.defaultProps = {
    user: {} as IUser
}

export default PersonCard;
