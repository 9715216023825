import React, {useEffect, useState} from 'react';
import {IPageData, IPageProps} from "../../../interfaces/page-data";
import {AppState} from "../../../redux/store";
import {useDispatch, useSelector} from "react-redux";
import {Button, Card, Form, Input} from "antd";
import {IUser, IUserClaimUsername} from "../../../interfaces/api";
import toast from "react-hot-toast";
import {updateUser} from "../../../redux/user/actions";
import {
    CheckCircleTwoTone,
    CheckOutlined,
    CloseCircleTwoTone,
    SyncOutlined
} from "@ant-design/icons";
import ApiService from "../../../utils/services/api.service";

const ClaimUsername: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const user: IUser = useSelector((state: AppState) => state).user;
    const [isConfirmable, setConfirmable] = useState<boolean>(false);

    enum State {
        Load = 0,
        Null = 1,
        Success = 2,
        Failed = 3
    }
    const dispatch = useDispatch();
    const [isUsableUN, setUsableUN] = useState<State>(State.Null);
    const pageData: IPageData = {
        title: 'Claim Username',
        loaded: true
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => onSetPage(pageData), []);

    const onFinish = async (e) => {
        let obj: IUserClaimUsername = {
            userId: user.id,
            requestedUsername: e.requestedUsername
        } as IUserClaimUsername

        let response = await ApiService.User.PutUserClaimUsername(e.requestedUsername, obj);
        console.log(response.data)
        // TODO - what is the failed response
        let isOk:boolean = response.data;
        if (isOk){
            toast.success('Username confirmed successfully.');
            user.username = e.requestedUsername;
            dispatch(updateUser(user));
        }
        else {
            toast.error('Username not confirmed.');
        }
    }

    const onChangeInput = async (e) => {
        let uname: string = e.target.value;
        if (uname.length < 1) {
            setConfirmable(false);
            setUsableUN(State.Null);
            return;
        }
        setConfirmable(false);
        setUsableUN(State.Load);

        let response = await ApiService.User.GetUserCheckUnameAvailability(uname);
        let isOk: boolean = response.data;
        setConfirmable(isOk);
        if (isOk){
            setUsableUN(State.Success);
        }
        else {
            setUsableUN(State.Failed);
            toast.error('Username already taken');
        }
    };

    const setSuffix = () => {
        switch (isUsableUN) {
            case State.Null:
                return '';
            case State.Load:
                return (<SyncOutlined spin/>);
            case State.Success:
                return (<CheckCircleTwoTone twoToneColor='green'/>);
            case State.Failed:
                return (<CloseCircleTwoTone twoToneColor='red'/>);
        }
    }

    return (
        <div className='row justify-content-center'>
            <div className='col col-12 col-xl-8'>
                <Card title='Claim Username' className='mb-0'>
                    <Form
                        colon={false}
                        requiredMark={null}
                        onFinish={onFinish}
                        labelCol={{span: 5}} wrapperCol={{span: 19}}
                    >
                        <Form.Item label='Username' name='requestedUsername' initialValue={user.username}
                            //rules={[{required: true, message: 'Username cannot be blank'}]}
                        >
                            <Input
                                size='small'
                                onChange={onChangeInput}
                                suffix={setSuffix()}
                            />
                        </Form.Item>
                        <Form.Item wrapperCol={{md: {span: 19, offset: 5}, xs: {offset: 0}}}>
                            <div className='d-flex justify-content-between'>
                                {/*<Button className='responsive-button' onClick={() => {*/}
                                {/*    history.go(-1);*/}
                                {/*}} danger icon={<LeftOutlined/>}>Cancel</Button>*/}
                                <span/>

                                <Button
                                    className='responsive-button'
                                    type='primary'
                                    htmlType='submit'
                                    icon={<CheckOutlined/>}
                                    disabled={!isConfirmable}
                                >
                                    Confirm
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </Card>
            </div>

            {/* TODO Login list gelecek buraya */}
        </div>
    );
};

export default ClaimUsername;
