import React, {useEffect, useRef, useState} from 'react';
import GoogleMap from 'google-map-react';
import './GMForSearch.scss';
import {
    ICustomMapResult,
    IEvent,
    IPoint, IResource,
    IResultType,
    ISearchMapResult,
    ITraining,
    IUser, IWorkOpportunity
} from "../../../interfaces/api";
import {Col, Row, Spin} from 'antd';
import ReactDOM from "react-dom";
import './CustomIW.scss'
import {API_KEY, mobileStyles, webStyles} from "../Datas";
import ImgViewer from '../../ImgViewer/ImgViewer';
import moment, {max} from "moment";
import {DATE_FORMAT, ModuleColors, S3_SERVER_URL} from "../../../utils/Constants";
import {TestIcon1Path} from "./Icons";
import ApiService from "../../../utils/services/api.service";

interface MapProps {
    scrollWheel?: boolean,
    markers?: ISearchMapResult,
}

//region CustomInfoWindow

interface CustomIWProps {
    id: string,
    type: string,
}

const CustomIW: React.FC<CustomIWProps> = props => {
    interface IGenericTypeByAPI {
        image: string,
        title: string,
        description: string,
        type: string,
        icon: any,
        deadline: string,
        price: number,
        seat: number,
        remoteOnsite: string,
        url: string
    }

    const [data, setData] = useState<IGenericTypeByAPI>();

    useEffect(() => {
        const any = async () => {
            let icon = <></>;

            switch (props.type) {
                case 'Users':
                    let response1 = await ApiService.User.GetUserById(props.id);
                    let user: IUser = response1.data;
                    setData({
                        image: S3_SERVER_URL + user.uploadedCoverImage,
                        title: user.name + ' ' + user.username,
                        type: props.type,
                        icon: <i className="icofont-ui-user"/>,
                        description: user.bio,
                        url: `/vertical/user-profile/${user.id}`
                    } as IGenericTypeByAPI)
                    break;
                case 'Trainings':
                    let response2 = await ApiService.Training.GetTrainingById(props.id);
                    let training: ITraining = response2.data;
                    setData({
                        image: S3_SERVER_URL + training.uploadedThumbnail,
                        title: training.trainingName,
                        type: props.type,
                        icon: <i className="icofont-notebook"/>,
                        description: training.description,
                        deadline: training.deadline,
                        price: training.participationFee,
                        seat: training.maxParticipants,
                        url: `/vertical/trainings/enroll/${training.id}`
                    } as IGenericTypeByAPI)
                    break;
                case 'Events':
                    let response3 = await ApiService.Event.GetEventById(props.id);
                    let event: IEvent = response3.data;
                    setData({
                        image: S3_SERVER_URL + event.uploadedThumbnail,
                        title: event.eventName,
                        type: props.type,
                        icon: <i className="icofont-ebook"/>,
                        description: event.description,
                        deadline: event.deadline,
                        price: event.participationFee,
                        seat: event.maxParticipants,
                        url: `/vertical/events/register/${event.id}`
                    } as IGenericTypeByAPI)
                    break;
                case 'Resources':
                    icon = <i className="icofont-dropbox"/>;
                    let response4 = await ApiService.Resource.GetResourceById(props.id);
                    let resource: IResource = response4.data;
                    setData({
                        image: S3_SERVER_URL + resource.uploadedThumbnail,
                        title: resource.title,
                        type: props.type,
                        icon: <i className="icofont-ebook"/>,
                        description: resource.description,
                        url: `/vertical/resources/detail/${resource.id}`
                    } as IGenericTypeByAPI)
                    break;
                case 'WorkOpportunities':
                    icon = <i className="icofont-search-job"/>
                    let response5 = await ApiService.WorkOpportunity.GetWOById(props.id);
                    let wo: IWorkOpportunity = response5.data;
                    setData({
                        image: S3_SERVER_URL + wo.uploadedImage,
                        title: wo.title,
                        type: props.type,
                        icon: <i className="icofont-ebook"/>,
                        description: wo.description,
                        deadline: wo.deadline,
                        remoteOnsite: wo.remoteOnsite.remoteOnsiteName,
                        url: `/vertical/work-opportunities/${wo.id}`
                    } as IGenericTypeByAPI)
                    break;
            }

        };
        any().then();
    }, []);

    return (
        <div className='custom-iw' onClick={() => {
            window.open(data.url, '_blank', 'noopener,noreferrer');
        }}>
            {
                data ?
                    <div style={{width: '250px'}}>
                        <Row>
                            <Col span={24}>
                                <div className='image'>
                                    <ImgViewer src={data.image} maxWidth='250px' width='100%' ratio={2}/>
                                    {data?.deadline &&
                                        <div className='deadline-container'>
                            <span className='deadline-item'>
                                <i className="icofont-ui-calendar"/>&nbsp;
                                <span>{moment(data?.deadline).format(DATE_FORMAT)}</span>
                            </span>
                                        </div>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <div className='info'>
                                    <div className='title'>{data.title}</div>
                                    <div className='content d-flex justify-content-between'
                                         style={{paddingBottom: '8px'}}>
                                    <span>
                                        {data.type}
                                    </span>
                                        <span>
                                        {data.icon}
                                    </span>
                                    </div>
                                    <div className='description'>
                                        <div dangerouslySetInnerHTML={{__html: data.description}}/>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between'
                                         style={{paddingTop: '8px'}}>
                                        {
                                            data.price > -1 &&
                                            <span className='price'>
                                                {data.price === 0 ? 'Free' : `${data.price}`}
                                            </span>
                                        }
                                        {
                                            data.seat > -1 &&
                                            <span className='seat'>
                                                {data.seat == 0 ? 'No limit ' : 'Total '} {data.seat !== 0 && data.seat} {data.seat == 1 ? ' seat' : ' seats'}
                                            </span>
                                        }
                                    </div>
                                    {
                                        data.remoteOnsite &&
                                        <span className='remote-onsite'>
                                        {data.remoteOnsite}
                                    </span>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                    :
                    <div className='loading-container'>
                        <Spin/>
                    </div>
            }

        </div>
    )
};
//endregion

const GMForSearch: React.FC<MapProps> = props => {
    const [style, setStyle] = useState(webStyles);
    const [gmap, setGmap] = useState<any>(undefined);
    const prevMarkersRef = useRef([]);

    let CENTER_COORD: IPoint =
        {
            //lat: 51.477928, lng: -0.001545 // Greenwich
            lat: 39.925533, lng: 32.866287 // TR
        };
    const DEFAULT_ZOOM: number = 5;

    const placeCard = async (cardWrapper: Element, iwProps: CustomIWProps) => {
        const cardUIIsExist = document.getElementById('place-card');
        cardUIIsExist && cardUIIsExist.remove();
        const cardUI = document.createElement('div');
        cardUI.setAttribute('id', 'place-card')
        ReactDOM.render(<CustomIW {...iwProps}/>, cardUI);
        cardWrapper.appendChild(cardUI);
    }

    const initMap = (map: any) => {
        setGmap(map);

        //region GeoJSON
        // map.data.loadGeoJson("./data/Turkey.geojson");
        // map.data.setStyle({
        //     fillColor: 'white',
        //     fillOpacity: 0.01,
        //     strokeWeight: 1
        // });

        //endregion

        //region Marker
        // Create an info window to share between markers.
        const placeCardWrapper = document.createElement("div");
        placeCardWrapper.style.display = 'none';
        placeCardWrapper.style.zIndex = '100';

        map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(placeCardWrapper);
        // TODO tiklanmis markerin renginin koyu kalmasi

        props.markers && Object.entries(props.markers).map((entry) => {
            let currentValue: IResultType = entry[1];

            currentValue.Results.map((marker: ICustomMapResult) => {
                let iconPath = 'SVG';
                let color = null;
                switch (currentValue.TypeName) {
                    case 'Users':
                        color = ModuleColors.User;
                        iconPath = TestIcon1Path;
                        break;
                    case 'Trainings':
                        color = ModuleColors.Trainings;
                        iconPath = TestIcon1Path;
                        break;
                    case 'Events':
                        color = ModuleColors.Events;
                        iconPath = TestIcon1Path;
                        break;
                    case 'Resources':
                        color = ModuleColors.Resources;
                        iconPath = TestIcon1Path;
                        break;
                    case 'WorkOpportunities':
                        color = ModuleColors.WorkOpportunities;
                        iconPath = TestIcon1Path;
                        break;
                }

                const zeroPoint = new google.maps.Point(0, 0);
                //const shiftedPoint = new google.maps.Point(12, 24);
                const shiftedPoint = new google.maps.Point(6, 12);
                let standartIcon: any = {
                    path: google.maps.SymbolPath.CIRCLE,
                    fillColor: color,
                    fillOpacity: 1,
                    strokeColor: 'white',
                    strokeOpacity: 0.9,
                    strokeWeight: 1.6,
                    scale: 4,
                    filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))'
                };

                const tMarker = new google.maps.Marker({
                    position: {lat: marker.Latitude, lng: marker.Longitude},
                    map,
                    icon: standartIcon,
                    clickable: true,
                    title: marker.Title
                });
                prevMarkersRef.current.push(tMarker);
                let tMarkerListener = async () => {
                    tMarker.addListener("click", () => {
                        const iwProps = {
                            id: marker.Id,
                            type: currentValue.TypeName
                        } as CustomIWProps;
                        placeCard(placeCardWrapper, iwProps);
                        placeCardWrapper.style.display = 'block';
                        standartIcon.fillColor = 'black';
                        tMarker.setIcon(standartIcon);
                    });

                    tMarker.addListener('mouseover', () => {
                        standartIcon.fillColor = 'black';
                        tMarker.setIcon(standartIcon);
                    });
                    tMarker.addListener('mouseout', () => {
                        standartIcon.fillColor = color;
                        tMarker.setIcon(standartIcon);
                    });
                };

                console.log('zoom changed, ' + map.getZoom());
                // Default zoom level 5
                tMarkerListener();

                standartIcon.scale = 4;

                // Zoom level 3-22
                map.addListener('zoom_changed', () => {
                    if (!map.getZoom()){
                        return;
                    }

                    let zoom = map.getZoom();
                    let relativePixelSize = Math.trunc(zoom / 2);

                    if (zoom <= 4)
                        relativePixelSize = 2.5;

                    //if (zoom < 6) //when zooming < 6, fix pixel size to 9
                    //    relativePixelSize = 2;

                    // if (zoom >= 9) {
                    //     tMarker.setClickable(true);
                    //     tMarkerListener();
                    // }
                    // else if (zoom < 9) {
                    //     tMarker.setClickable(false);
                    //     tMarker.setCursor('');
                    //     google.maps.event.clearInstanceListeners(tMarker);
                    // }

                    if (zoom >= 14) {
                        standartIcon.path = iconPath;
                        standartIcon.anchor = shiftedPoint
                        relativePixelSize = 3;
                    }
                    else if (zoom > 7 && zoom < 14) {
                        standartIcon.path = google.maps.SymbolPath.CIRCLE
                        standartIcon.anchor = zeroPoint
                        relativePixelSize = 6;
                    }
                    else if (zoom > 4 && zoom <= 7) {
                        standartIcon.path = google.maps.SymbolPath.CIRCLE
                        standartIcon.anchor = zeroPoint
                        relativePixelSize = 4;
                    }
                    standartIcon.scale = relativePixelSize
                    tMarker.setIcon(standartIcon)
                });
            })
        })

        map.addListener('click', () => {
            if (placeCardWrapper.style.display === 'block') {
                //console.log('PLACE CARD INSIVIBLE NOW')
                placeCardWrapper.style.display = 'none';
            }
        })
        //endregion

        //region StreetView
        const panorama = new google.maps.StreetViewService();
        map.setStreetView(panorama);
        //endregion
    }

    useEffect(() => {
        if (props.markers === undefined || gmap === undefined)
            return;

        // for clearing markers
        for (let prevMarker of prevMarkersRef.current) {
            prevMarker.setMap(null);
        }
        initMap(gmap)
    }, [props.markers])

    useEffect(() => {
        const updateMapStyles = () => {
            const styles = window.innerWidth < 768 ? mobileStyles : webStyles;
            setStyle(styles);
        };
        updateMapStyles();
        window.addEventListener('resize', updateMapStyles);
        return () => window.removeEventListener('resize', updateMapStyles);
    }, []);

    return (
        <div
            className='map-card-positioner'
            style={{
                height: '600px',
                width: '100%',
                position: 'relative'
            }}>

            <div
                style={{
                    height: '600px',
                    width: '100%',
                    position: 'absolute',
                    bottom: 0
                }}
            >
                <GoogleMap
                    id='map'
                    style={style}
                    defaultCenter={CENTER_COORD}
                    defaultZoom={DEFAULT_ZOOM}
                    bootstrapURLKeys={{
                        key: API_KEY,
                        libraries: ['places', 'geometry'],
                    }}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({map}) => initMap(map)}
                    options={{
                        scrollwheel: props.scrollWheel,
                        //panControl: props.scrollWheel
                        mapTypeControl: true,
                        streetViewControl: true,
                        scaleControl: true,
                        mapTypeControlOptions: {
                            //position: 3, // google.maps.ControlPosition.TOP_RIGHT
                            style: 2, //google.maps.MapTypeControlStyle.DROPDOWN_MENU
                        },
                        fullscreenControlOptions: {
                            position: 3, //TOP_LEFT
                        },
                        streetViewControlOptions: {
                            position: 9, //google.maps.ControlPosition.RIGHT_BOTTOM
                        }
                    }}
                >
                </GoogleMap>
            </div>
        </div>
    );
};

GMForSearch.defaultProps = {
    scrollWheel: true
}

export default GMForSearch;
