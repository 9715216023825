import React, {useEffect, useState} from 'react';
import GoogleMapReact from 'google-map-react';
import './GMForProfile.scss';
import {IHeatMap, IMarker, IPoint, IPolygon, IPolyline} from "../../../interfaces/api";
import SearchBox from "./SearchBox";
import {randomColor} from '../../../utils/GlobalFuncs';
import {GM_PROFILE_MAX_POLYGON} from "../../../utils/Constants";
import {API_KEY, greenwichCoord, mobileStyles, webStyles} from "../Datas";

interface MapProps {
    selectedMark?: any,
    disabled?: boolean,
    value?: any,
    scrollWheel?: boolean,
    eventCallback: (type: EVENT_TYPES, data: IMarker|IPolygon) => void,
    polygons?: IPolygon[],
    heatmap?: IHeatMap[],
    polyLines?: IPolyline[],
    markers?: IMarker[]
}

export enum EVENT_TYPES {
    MARKER_CREATED='MARKER_CREATED',
    MARKER_UPDATED='MARKER_UPDATED',
    MARKER_REMOVED='MARKER_REMOVED',
    POLYGON_CREATED='POLYGON_CREATED',
    POLYGON_UPDATED ='POLYGON_UPDATED',
    POLYGON_REMOVED='POLYGON_REMOVED'
}

const MAX_MARKER_COUNT: number =  1;
const MAX_POLYGON_COUNT: number =  GM_PROFILE_MAX_POLYGON;


// Biseyler edit edilmek istenirse var olan silinip yenisi olusuturulmali
// Yenisi olusuturulken de drag ve insert icin engel olmamali.

let markerCount: number = 0;
let polygonCount: number = 0;
let drawingManager: google.maps.drawing.DrawingManager = null;
let isLoad: boolean = false;
const GMForProfile: React.FC<MapProps> = props => {

    const [style, setStyle] = useState(webStyles);

    const DEFAULT_ZOOM: number = 9;

    const initMap = (map: any) => {
        markerCount = props.markers.length;
        polygonCount = props.polygons.length;

        initializeMarkers(map);
        initializePolygons(map);

        //region Drawing Manager for updating markers and polygons
        let tDrawingManager = new google.maps.drawing.DrawingManager({
            //drawingMode: google.maps.drawing.OverlayType.,
            drawingControl: true,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [
                    google.maps.drawing.OverlayType.MARKER,
                    google.maps.drawing.OverlayType.POLYGON,
                ],
            },
            markerOptions: {
                //animation: google.maps.Animation.DROP,
                clickable: true,
                draggable: true,
                icon: {
                    path: google.maps.SymbolPath.CIRCLE,
                    fillColor: '#00F',
                    fillOpacity: 0.6,
                    strokeColor: 'white',
                    strokeOpacity: 0.9,
                    strokeWeight: 1,
                    scale: 7
                },
            },
            polygonOptions: {
                editable: true,
                draggable: true
            }
        });

        //setDrawingManager(tDrawingManager);
        drawingManager = tDrawingManager;

        tDrawingManager.setOptions({
            drawingControl: true,
            drawingControlOptions: {
                drawingModes: [
                    markerCount < MAX_MARKER_COUNT && google.maps.drawing.OverlayType.MARKER,
                    polygonCount < MAX_POLYGON_COUNT && google.maps.drawing.OverlayType.POLYGON
                ],
                position: google.maps.ControlPosition.TOP_CENTER}
        });

        tDrawingManager.setMap(map);

        // Handle polygon completed event
        google.maps.event.addListener(tDrawingManager, 'overlaycomplete', (event) => {
            let lastDrawnShape = event.overlay;

            let markerPoint: IMarker = {id: markerCount, point: {lat: 0, lng: 0}} as IMarker;
            let polygonPoint: IPolygon = {id: polygonCount, points: []} as IPolygon;

            let isDragging: boolean = false;
            //region POLYGON create and update events using DrawingManager
            if (event.type == google.maps.drawing.OverlayType.POLYGON) {
                let latLngArray: any[] = event.overlay.getPath().getArray();
                polygonPoint.points = latLngArray.map( x => ({lat: x.lat(), lng: x.lng()} as IPoint));
                event.overlay.getPaths().forEach(function (path, index) {
                    google.maps.event.addListener(path, 'insert_at', function () {
                        // New point
                        polygonPoint.points = event.overlay.getPath().getArray().map( x => ({lat: x.lat(), lng: x.lng()} as IPoint));
                        completedPolygon(EVENT_TYPES.POLYGON_UPDATED,polygonPoint, latLngArray);
                    });

                    google.maps.event.addListener(path, 'remove_at', function () {
                        // Point was removed
                        polygonPoint.points = event.overlay.getPath().getArray().map( x => ({lat: x.lat(), lng: x.lng()} as IPoint));
                        completedPolygon(EVENT_TYPES.POLYGON_UPDATED,polygonPoint, latLngArray);
                    });

                    google.maps.event.addListener(path, 'set_at', function () {
                        // Point was moved
                        if(isDragging)
                            return;
                        polygonPoint.points = event.overlay.getPath().getArray().map( x => ({lat: x.lat(), lng: x.lng()} as IPoint));
                        completedPolygon(EVENT_TYPES.POLYGON_UPDATED,polygonPoint, latLngArray);
                    });
                });

                google.maps.event.addListener(event.overlay, 'dragstart', function() {
                    // polygon dragend event
                    isDragging = true;
                });
                google.maps.event.addListener(event.overlay, 'dragend', function() {
                    // polygon dragend event
                    isDragging = false;
                    polygonPoint.points = event.overlay.getPath().getArray().map( x => ({lat: x.lat(), lng: x.lng()} as IPoint));
                    completedPolygon(EVENT_TYPES.POLYGON_UPDATED,polygonPoint, latLngArray);
                });
                completedPolygon(EVENT_TYPES.POLYGON_CREATED,polygonPoint, latLngArray);
            }
            //endregion
            //region MARKER create and update events using DrawingManager
            else if (event.type == google.maps.drawing.OverlayType.MARKER) {
                let latLngArray: any = event.overlay.getPosition();
                markerPoint.point.lat = latLngArray.lat();
                markerPoint.point.lng = latLngArray.lng();

                google.maps.event.addListener(event.overlay, 'dragend', function() {
                    // marker dragend event

                    markerPoint.point.lat = event.overlay.getPosition().lat();
                    markerPoint.point.lng = event.overlay.getPosition().lng();
                    completedMarker(EVENT_TYPES.MARKER_UPDATED,markerPoint, latLngArray);
                });

                completedMarker(EVENT_TYPES.MARKER_CREATED, markerPoint, latLngArray);
            }
            //endregion

            //region POLYGON or MARKER remove with right click
            google.maps.event.addListener(event.overlay, 'rightclick', function () {
                if (event.type == google.maps.drawing.OverlayType.POLYGON){
                    completedPolygon(EVENT_TYPES.POLYGON_REMOVED, polygonPoint, null);
                    //event.overlay.setMap(null);
                }
                else if (event.type == google.maps.drawing.OverlayType.MARKER){
                    completedMarker(EVENT_TYPES.MARKER_REMOVED,markerPoint, null);
                    //event.overlay.setMap(null);
                }
                lastDrawnShape.setMap(null);
            });
            //endregion
        });
        //endregion
    }

    const initializePolygons = (map: any) => {
        //region Render existing polygons
        // Define the LatLng points for the polygon's path.
        // let polygons: any[] = props.polygons.map((item) => {
        //     return item.points.map((point) => {
        //         return ({id: item.id, lat: point.lat, lng: point.lng});
        //     });
        // });
        //console.dir(polygons);

        let polygonsFromProps : IPolygon[] = props.polygons;

        if (polygonsFromProps.length < 1) {
            return;
        }

        // sorting items by angle or create date
        polygonsFromProps.forEach( (polygon) => {
            polygon.points.sort( (a, b) => {
                return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
            });
            console.dir(polygon.points);
        })

        polygonsFromProps.map((polygon) => {
            console.dir(polygon.points);
            let tmpPoly = new google.maps.Polygon({
                paths: polygon.points,
                strokeColor: randomColor(),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "white",
                fillOpacity: 0.35,
                //editable: true
            });
            tmpPoly.setMap(null);

            tmpPoly.addListener("rightclick", () => {
                completedPolygon(EVENT_TYPES.POLYGON_REMOVED,polygon, tmpPoly);
            });

            //let polygonPoint: IPolygon = {id: polygon.id, points: []} as IPolygon;
            //let latLngArray: any[] = polygon.getPath().getArray()
            // tmpPoly.getPaths().forEach(function (path, index) {
            //     google.maps.event.addListener(path, 'insert_at', function () {
            //         // New point
            //         completedPolygon(EVENT_TYPES.POLYGON_UPDATED, polygonPoint, path);
            //     });
            //
            //     google.maps.event.addListener(path, 'remove_at', function () {
            //         // Point was removed
            //         completedPolygon(EVENT_TYPES.POLYGON_UPDATED,polygonPoint, path);
            //     });
            //
            //     google.maps.event.addListener(path, 'set_at', function () {
            //         // Point was moved
            //         completedPolygon(EVENT_TYPES.POLYGON_UPDATED,polygonPoint, path);
            //     });
            // });

            tmpPoly.setMap(map);
        })
        //endregion
    }

    const initializeMarkers = (map: any) => {



        //region Render existing markers
        props.markers.map((marker) => {
            const tMarker = new google.maps.Marker({
                position: {lat: marker.point.lat, lng: marker.point.lng},
                map,
                title: marker.id.toString(),
                icon: {
                    path: google.maps.SymbolPath.CIRCLE,
                    fillColor: '#00F',
                    fillOpacity: 0.6,
                    strokeColor: 'white',
                    strokeOpacity: 0.9,
                    strokeWeight: 1,
                    scale: 7
                },
                clickable: false,
                //animation: google.maps.Animation.BOUNCE
            });

            tMarker.addListener("rightclick", () => {
                completedMarker(EVENT_TYPES.MARKER_REMOVED,marker, tMarker);
            });
        })
        //endregion
    }

    // region Marker or Polygon completed events
    const completedMarker = (type:EVENT_TYPES,markerPoint, markerGM) => {
        if (type === EVENT_TYPES.MARKER_REMOVED) {
            markerGM && markerGM.setMap(null);
            markerCount = markerCount-1;
        }
        else if (type === EVENT_TYPES.MARKER_CREATED){
            markerCount = markerCount+1;
        }
        markerPolygonCountEvent();
        props.eventCallback(type,markerPoint);
        return;
    }

    const completedPolygon = (type:EVENT_TYPES,polygonPoint, polygonGM) => {
        // latLngArray.forEach((latlng, key) => {
        //     let lat = latlng.lat();
        //     let lon = latlng.lng();
        //     polygonPoint.points.push({lat: lat, lng: lon} as IPoint);
        //     console.log(lat, lon);
        // });
        if (type === EVENT_TYPES.POLYGON_REMOVED) {
            polygonCount = polygonCount -1;
            polygonGM && polygonGM.setMap(null);
        }
        else if (type === EVENT_TYPES.POLYGON_CREATED){
            polygonCount = polygonCount +1;
        }
        markerPolygonCountEvent();
        props.eventCallback(type, polygonPoint);
        return;
    }
    //endregion

    //region Marker and Polygon control (Each object can be created once)


    const markerPolygonCountEvent = () => {
        if (!isLoad)
            return;
        let tDM = drawingManager;
        if (markerCount >= MAX_MARKER_COUNT && polygonCount >= MAX_POLYGON_COUNT){
            // Drawing mode is empty now
            tDM.setOptions({drawingControl: true, drawingControlOptions: {drawingModes: [null], position: google.maps.ControlPosition.TOP_CENTER}})
            tDM.setDrawingMode(null);
            return;
        }
        if(markerCount >= MAX_MARKER_COUNT && polygonCount < MAX_POLYGON_COUNT){
            // Marker is okay but no polygon
            tDM.setOptions({drawingControl: true, drawingControlOptions: {drawingModes: [google.maps.drawing.OverlayType.POLYGON], position: google.maps.ControlPosition.TOP_CENTER}})
            tDM.setDrawingMode(null);
            return;
        }
        if(markerCount < MAX_MARKER_COUNT && polygonCount >= MAX_POLYGON_COUNT){
            // Polygons are okay but no marker
            tDM.setOptions({drawingControl: true, drawingControlOptions: {drawingModes: [google.maps.drawing.OverlayType.MARKER], position: google.maps.ControlPosition.TOP_CENTER}})
            tDM.setDrawingMode(null);
            return;
        }
        if(markerCount < MAX_MARKER_COUNT && polygonCount < MAX_POLYGON_COUNT){
            tDM.setOptions({drawingControl: true, drawingControlOptions: {drawingModes: [google.maps.drawing.OverlayType.MARKER,google.maps.drawing.OverlayType.POLYGON], position: google.maps.ControlPosition.TOP_CENTER}})
            tDM.setDrawingMode(null);
            return;
        }
        //setDrawingManager(tDM);
        drawingManager = tDM;
    }
    //endregion

    //region Map style dynamiclly
    useEffect(() => {
        const updateMapStyles = () => {
            const styles = window.innerWidth < 768 ? mobileStyles : webStyles;
            setStyle(styles);
        };
        updateMapStyles();
        window.addEventListener('resize', updateMapStyles);
        return () => window.removeEventListener('resize', updateMapStyles);
    }, []);
    //endregion

    return (
        <div
            className='map-card-positioner'
            style={{
                height: '550px',
                width: '100%',
                position: 'relative'
            }}>

            <div
                style={{
                    height: '550px',
                    width: '100%',
                    position: 'absolute',
                    bottom: 0
                }}
            >
                <GoogleMapReact
                    id='map'
                    style={style}
                    defaultCenter={ (props.markers.length > 0 && !isLoad) ? {lat: props.markers[0].point.lat, lng:props.markers[0].point.lng} : greenwichCoord}
                    defaultZoom={DEFAULT_ZOOM}
                    bootstrapURLKeys={{
                        key: API_KEY,
                        libraries: ['places', 'geometry', 'drawing'],
                    }}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({map}) => {
                        isLoad = true;
                        initMap(map);
                    }}
                    options={{
                        scrollwheel: props.scrollWheel,
                        //panControl: props.scrollWheel
                    }}
                >
                </GoogleMapReact>
            </div>
        </div>
    );
};

GMForProfile.defaultProps = {
    disabled: false,
    selectedMark: null,
    value: null,
    scrollWheel: true,
    polygons: [],
    heatmap: [],
    polyLines: [],
    markers: []
}

export default GMForProfile;
