import { createStore, combineReducers } from 'redux';
import {createBrowserHistory} from 'history'
import { settingsReducer } from './settings/reducer';
import { pageDataReducer } from './pages/reducer';
import { connectRouter } from 'connected-react-router'
import {userReducer} from "./user/reducer";
import {StateLoader} from "./StateLoader";
const stateLoader = new StateLoader();

let browserHistoryOptions = {
    basename: '/'
};
export const history = createBrowserHistory(browserHistoryOptions);
//export const MemoryHistory = createMemoryHistory();

const rootReducer = combineReducers({
    settings: settingsReducer,
    pageData: pageDataReducer,
    router: connectRouter(history),
    user: userReducer
});

export type AppState = ReturnType<typeof rootReducer>;
export const store = createStore(rootReducer, {user: stateLoader.loadState()});

store.subscribe(() => {
    stateLoader.saveState(store.getState().user);
});



// import {createStore, combineReducers, applyMiddleware} from 'redux';
// import {createMemoryHistory} from 'history'
// import {settingsReducer} from './settings/reducer';
// import {pageDataReducer} from './pages/reducer';
// import {connectRouter} from 'connected-react-router'
// import {userReducer} from "./user/reducer";
// import {persistStore, persistReducer} from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
// import thunk from 'redux-thunk';
// export const history = createMemoryHistory();
//
// const persistConfig = {
//     key: 'primary',
//     storage,
//     version: 1,
//     whitelist: ['user'],
//     blacklist: ['settings', 'pageData', 'router']
// }
//
// const rootReducer = combineReducers({
//     settings: settingsReducer,
//     pageData: pageDataReducer,
//     router: connectRouter(history),
//     user: userReducer
//     // user: persistReducer(
//     //     {
//     //       key: "user", // key for localStorage key, will be: "persist:form"
//     //       storage,
//     //       whitelist: ['user']
//     //     },
//     //     userReducer
//     // )
// });
//
// const persistedReducer = persistReducer(persistConfig, rootReducer);
//
// export const store = createStore(
//     persistedReducer,
//     {},
//     applyMiddleware(thunk));
//
// export type AppState = ReturnType<typeof persistedReducer>;
// export const persistor = persistStore(store, undefined, () => {
//     console.log(store.getState().user);
// });


// // With out persist
// import { createStore, combineReducers } from 'redux';
//
// import {createMemoryHistory} from 'history'
// import { settingsReducer } from './settings/reducer';
// import { pageDataReducer } from './pages/reducer';
// import { connectRouter } from 'connected-react-router'
// import {userReducer} from "./user/reducer";
//
// export const history = createMemoryHistory();
//
// const rootReducer = combineReducers({
//   settings: settingsReducer,
//   pageData: pageDataReducer,
//   router: connectRouter(history),
//   user: userReducer
// });
//
// export type AppState = ReturnType<typeof rootReducer>;
//
// export const store = () => {
//   return createStore(rootReducer);
// };
