import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Row, Timeline} from 'antd';
import {DATETIME_FORMAT, S3_SERVER_URL} from "../../utils/Constants";
import './WallItem.scss';
import ImgViewer from "../ImgViewer/ImgViewer";
import blankUser from "../../assets/img/blank/blank-user.png";
import moment from 'moment';

interface TimelineProps {
    ownerName: string,
    ownerGuid: string,
    data: any,
    createdAt: string
}

const WallItem: React.FC<TimelineProps> = props => {

    return (
        <div className='wall-item-container'>
            <Card
                loading={props.ownerName === null}
            >
                <Card.Meta
                    title={
                        // <div className='wall-item-title'>
                        //     <Row align="middle" gutter={[5,5]}>
                        //         <Col xs={{span: 4}} sm={{span: 3}} md={{span: 2}} lg={{span: 2}} xl={{span: 2}}
                        //              xxl={{span: 1}}>
                        //             <span className='image'>
                        //                 <ImgViewer
                        //                     src={props.ownerGuid ? S3_SERVER_URL + `profile/profile_${props.ownerGuid}.png` : blankUser}
                        //                     shape='circle'
                        //                     ratio={1}
                        //                     width={'40px'}
                        //                 />
                        //             </span>
                        //         </Col>
                        //         {/*<Col span={4} className='user-detail'>*/}
                        //         {/*    <span>*/}
                        //         {/*        {props.ownerName}*/}
                        //         {/*    </span>*/}
                        //         {/*</Col>*/}
                        //     </Row>
                        //
                        // </div>
                        null
                    }
                    description={
                        <Row align="middle" gutter={[5,5]}>
                            <Col xs={{span: 12, order: 1}}
                                 xl={{span: 2, order: 1}}
                                 xxl={{span: 1, order: 1}}>
                                    <span className='image'>
                                        <ImgViewer
                                            src={props.ownerGuid ? S3_SERVER_URL + `profile/profile_${props.ownerGuid}.jpg` : blankUser}
                                            shape='circle'
                                            ratio={1}
                                            width={'40px'}
                                            onErrorImage={blankUser}
                                        />
                                    </span>
                            </Col>
                            {/*<Col span={4} className='user-detail'>*/}
                            {/*    <span>*/}
                            {/*        {props.ownerName}*/}
                            {/*    </span>*/}
                            {/*</Col>*/}
                            <Col xs={{span: 24, order: 3}}
                                 xl={{span: 20, order: 2}}
                                 xxl={{span: 21}}>
                                <div className='wall-item-desc'>
                                    {props.data}
                                </div>
                            </Col>
                            <Col xs={{span: 12, order: 2}}
                                 xl={{span: 2, order: 3}}
                                 xxl={{span: 2}} style={{textAlign: 'right'}}>
                                {moment.utc(props.createdAt).local().startOf('seconds').fromNow()}
                            </Col>
                        </Row>

                    }
                />
                {/*<div className='d-flex align-items-center justify-content-between wall-item-footer'*/}
                {/*     style={{marginTop: '8px'}}>*/}
                    {/*<StopOutlined/>*/}
                    {/*<StopOutlined/>*/}
                {/*</div>*/}
            </Card>
        </div>
    );
};

WallItem.defaultProps = {
    data: '',
    ownerName: null,
    ownerGuid: null,
    createdAt: null
}


export default WallItem;
