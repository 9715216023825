import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../interfaces/page-data';
import {IMarker, IPolygon, IUser} from "../../interfaces/api";
import WallItem from "../../components/WallComponents/WallItem";
import ImgViewer from "../../components/ImgViewer/ImgViewer";
import ApiService from "../../utils/services/api.service";

import ReactHtmlParser from 'react-html-parser';

let testPolygons: IPolygon[] = [
    {
        // Bermuda Triangle
        id: 1,
        points: [
            {lat: 25.774, lng: -80.19},
            {lat: 18.466, lng: -66.118},
            {lat: 32.321, lng: -64.757},
            {lat: 25.774, lng: -80.19},
        ]
    } as IPolygon,
    {
        id: 2,
        points: [
            {lat: 27.774, lng: -72.19},
            {lat: 20.466, lng: -60.118},
            {lat: 34.321, lng: -58.757},
            {lat: 27.774, lng: -70.19},
        ]
    } as IPolygon
]

let testMarkers: IMarker[] = [
    {
        id: 1,
        point: {lat: 28.774, lng: -72.19}
    } as IMarker,
    // {
    //     id: 2,
    //     point: {lat: 30.001, lng: -54.993}
    // } as IMarker,
    // {
    //     id: 3,
    //     point: {lat: 30.002, lng: -54.990}
    // } as IMarker,
    // {
    //     id: 4,
    //     point: {lat: 30.003, lng: -54.984}
    // } as IMarker
]

interface ITimeline {
    user1: string,
    user2: string,
    event1: string,
    event2: string,
    clause: string,
}

const timeline: ITimeline = {
    user1: 'guid-user-1',
    user2: 'guid-user-2',
    event1: 'guid-event-1',
    event2: 'guid-event-2',
    clause: '{user1} registration occurred to {event1}',
}

const TestPage: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const [user, setUser] = useState<IUser>({} as IUser);

    const pageData: IPageData = {
        title: 'Test Page',
        loaded: true
    };

    useEffect(() => {
        onSetPage(pageData)
        getUser()
        //unauthTest();
    }, []);

    const stringToElement = (item: ITimeline) => {
        let txt = item.clause;
        console.log()
        txt = txt.replace(/({user)(?=[0-9])/ig,`<a href=/vertical/user-profile/${item.user1}>${item.user1}`);

        //txt.replaceAll('{user',`<a href=/vertical/user-profile/${item.user1}>{`);

        txt = txt.replaceAll(/({event)(?=[0-9])/ig,`<a href=/vertical/event/register/${item.event1}>${item.event1}`);

        txt = txt.replaceAll('}','</a>');
        let res = `<div>${txt}</div>`;
        console.dir(res);
        return ReactHtmlParser(res);
    }


    const getUser = async () => {
        let response = await ApiService.User.GetUserById('e481993d-8565-49a9-9b6d-4c8a610677f5');
        setUser(response.data);
    }

    const unauthTest = async () => {
        let response = await ApiService.Test.Unauthorized();
        console.dir(response);
    }

    // const [polygons, setPolygons] = useState<IPolygon[]>(testPolygons);
    // const [markers, setMarkers] = useState<IMarker[]>(testMarkers);
    return (
        <>
            {/*<GMForModule value={markers[0].point} onChange={(data) => {console.log(data)}} haveSearchBox/>*/}
            <ImgViewer src={user.uploadedCoverImage} shape={'square'} ratio={2}/>
            <WallItem  data={stringToElement(timeline)} ownerName={user.name} ownerGuid={user.id} createdAt={null}/>

            {/*<WallItem  data={'Keçi otlatma dersini başarı bir şekilde tamamlayarak, Keçi 101 rozetini kazandı.'} user={user}/>*/}
            {/*<WallItem  data={'Keçi otlatma dersini başarı bir şekilde tamamlayarak, Keçi 101 rozetini kazandı.'} user={user}/>*/}
            {/*<WallItem  data={'Keçi otlatma dersini başarı bir şekilde tamamlayarak, Keçi 101 rozetini kazandı.'} user={user}/>*/}
            {/*<WallItem  data={'Keçi otlatma dersini başarı bir şekilde tamamlayarak, Keçi 101 rozetini kazandı.'} user={user}/>*/}
        </>
    )
};

export default TestPage;
