import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../interfaces/page-data';
import {Button, Card, Col, Modal, Row, Select, Space, Table} from "antd";
import {history} from "../../redux/store";
import {IWorkOpportunity} from "../../interfaces/api";
import toast from "react-hot-toast";
import {CheckOutlined, DeleteOutlined, LeftOutlined, PlusOutlined} from "@ant-design/icons";
import moment from "moment";
import {DATETIME_FORMAT} from "../../utils/Constants";
import ResponsiveButton from "../../components/ResponsiveButton/ResponsiveButton";
import ApiService from "../../utils/services/api.service";

const WorkOpportunitiesAdmin: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'Work Opportunities',
        loaded: true
    };
    useEffect(() => {
        onSetPage(pageData);
    }, []);

    useEffect(() => {
        onSetPage(pageData);
        workOpportunitiesSetLoaded(true);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const columnsInitial = [
        {
            title: "Title",
            dataIndex: "title",
            ellipsis: false,
        },
        {
            title: "Deadline",
            dataIndex: "deadline",
            width: '150px',
            ellipsis: false,
            render: date => <>{
                date == "0001-01-01T00:00:00" ? "Uncertain Date" : moment(date).format(DATETIME_FORMAT)
            }
            </>
        },
        {
            title: 'Remote Onsite',
            dataIndex: 'remoteOnsiteId',
            //width: '200px',
            ellipsis: false,
            render: (woRemoteOnsiteId, fullRow: IWorkOpportunity) => <>
                {fullRow.remoteOnsite && fullRow.remoteOnsite.remoteOnsiteName}
            </>
        },
        {
            title: 'Times',
            dataIndex: 'woTimeId',
            ellipsis: false,
            //width: '200px',
            render: (woTimeId, fullRow: IWorkOpportunity) => <>
                {fullRow.workOpportunityTime && fullRow.workOpportunityTime.timeName}
            </>
        },
        {
            title: 'Types',
            dataIndex: 'woTypeId',
            //width: '200px',
            ellipsis: false,
            render: (woTypeId, fullRow: IWorkOpportunity) => <>
                {fullRow.workOpportunityType && fullRow.workOpportunityType.typeName}
            </>
        },
        {
            title: "",
            dataIndex: "isApproved",
            width: '150px',
            ellipsis: false,
            hidden: false,
            render: (data, row: IWorkOpportunity) => {
                if (data === true) {
                    return (
                        <div style={{textAlign: 'center'}}>
                            <Button
                                icon={<DeleteOutlined/>}
                                size='small'
                                shape='circle'
                                danger
                                onClick={() => {deleteWO(row)}}>
                            </Button>
                        </div>
                    )
                }
                else {
                    return (<Space>
                        <Button size='small'
                            onClick={() => {
                                history.push({
                                    pathname: `work-opportunities/approve/${row.id}`
                                });
                            }}>Approve</Button>
                        <Button
                            icon={<DeleteOutlined/>}
                            size='small'
                            shape='circle'
                            danger
                            onClick={() => {deleteWO(row)}}>
                        </Button>
                    </Space>)
                }
            }
        }
    ]

    const [workOpportunities, setWorkOpportunities] = useState<IWorkOpportunity[]>([]);
    const [workOpportunitiesIsLoaded, workOpportunitiesSetLoaded] = useState<boolean>(false);
    const [isApproved, setApproved] = useState('approved');
    //const [columns, setColumns] = useState<Array<object>>(columnsInitial);
    const [columns, setColumns] = useState<Array<object>>(columnsInitial.filter(item => !item.hidden));

    const getOppors = async () => {
        //let response = await ApiService.WorkOpportunity.
        let response = isApproved == 'approved' ? await ApiService.WorkOpportunity.GetWOApproved() : await ApiService.WorkOpportunity.GetWOUnapproved();
        setWorkOpportunities(response.data);
        workOpportunitiesSetLoaded(false);
    }

    useEffect(() => {
        if (!workOpportunitiesIsLoaded)
            return;
        getOppors();
    }, [workOpportunitiesIsLoaded])

    const deleteWO = async (rowData: IWorkOpportunity) => {
        Modal.confirm({
            closable: false,
            title: 'Are you sure to delete?',
            okText: 'Confirm',
            cancelText: 'Cancel',
            okButtonProps: {
                className: 'responsive-button',
                icon: <CheckOutlined/>
            },
            cancelButtonProps: {
                className: 'responsive-button',
                icon: <LeftOutlined/>,
                danger: true
            },
            onOk: async () => {
                let response = await ApiService.WorkOpportunity.DeleteWOById(rowData.id);
                if (response.status === 200){
                    workOpportunitiesSetLoaded(true)
                }
            },
            onCancel: async () => {
                return;
            }
        });
    }

    return (
        <div>
            <div>
                <div className='row'>
                    <div className='col-12'>
                        <Card title={
                            <Row gutter={[10, 10]}>
                                <Col xs={{span: 24}} md={{span: 8}}>
                                    <span className='card-title-page'>Work Opportunities</span>
                                </Col>
                                <Col xs={{span: 19}} sm={{span: 21}} md={{span: 6, offset: 8}} lg={{span: 6, offset: 5}}
                                     xl={{span: 4, offset: 8}} xxl={{span: 3, offset: 10}}>
                                    <Select onChange={(e) => {
                                        setApproved(e);
                                        workOpportunitiesSetLoaded(true);
                                        e === 'approved' ? setColumns(columnsInitial.filter(item => !item.hidden)) : setColumns(columnsInitial);
                                    }} defaultValue={isApproved}>
                                        <Select.Option key='1' value='approved'>Approved</Select.Option>
                                        <Select.Option key='2' value='unapproved'>Unapproved</Select.Option>
                                    </Select>
                                </Col>
                                <Col xs={{span: 5}} sm={{span: 3}} md={{span: 2}} lg={{span: 5}} xl={{span: 4}}
                                     xxl={{span: 3}} style={{textAlign: 'right', paddingLeft:'0px'}}>
                                    <ResponsiveButton
                                        text='New Opportunity'
                                        icon={<PlusOutlined/>}
                                        onClick={() => {
                                            history.push('/vertical/work-opportunities/new')
                                        }}/>
                                </Col>
                            </Row>
                        }>
                            <Table
                                rowKey={(row: IWorkOpportunity) => row.id}
                                dataSource={workOpportunities.length > 0 && workOpportunities}
                                columns={columns}
                                loading={workOpportunitiesIsLoaded}
                            />
                        </Card>
                    </div>
                </div>

            </div>
        </div>
    )
};

export default WorkOpportunitiesAdmin;
