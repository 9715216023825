import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../interfaces/page-data';
import {Button, Card, Col, Modal, Row, Select, Space, Table} from "antd";
import {history} from "../../redux/store";
import {ISkill} from "../../interfaces/api";
import toast from "react-hot-toast";
import {CheckOutlined, CloseOutlined, DeleteOutlined, LeftOutlined, PlusOutlined} from "@ant-design/icons";
import moment from "moment";
import {DATETIME_FORMAT, SERVER_DATETIME_FORMAT} from "../../utils/Constants";
import ResponsiveButton from "../../components/ResponsiveButton/ResponsiveButton";
import ApiService from "../../utils/services/api.service";

const SkillAdmin: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'Skill',
        loaded: true
    };

    const columnsInitial = [
        {
            title: "Name",
            dataIndex: "skillName",
            ellipsis: false,
        },
        {
            title: "Created At",
            dataIndex: "createdAt",
            width: "150px",
            ellipsis: false,
            render: date => <>{
                date && moment(date).format(DATETIME_FORMAT)
            }</>
        },
        {
            title: "",
            dataIndex: "isApproved",
            width: '150px',
            ellipsis: false,
            // onFilter: (value: boolean, record: ISkill) => value == record.isApproved,
            // filters: [
            //     {text: 'Approved', value: true},
            //     {text: 'Non-approved', value: false},
            // ],
            render: (data, row: ISkill) => {
                if (data === true) {
                    return (
                        <Space>
                            <Button
                                size='small'
                                onClick={() => {history.push({pathname: `skills/detail/${row.id}`});}}>
                                Detail
                            </Button>

                            <Button
                                icon={<DeleteOutlined/>}
                                size='small'
                                shape='circle'
                                danger
                                onClick={() => {deleteSkill(row)}}>
                            </Button>
                        </Space>)
                }
                else {
                    return (
                        <Space>
                            <Button
                                size='small'
                                onClick={() => {history.push({pathname: `skills/approve/${row.id}`});}}>
                                Approve
                            </Button>

                            <Button
                                icon={<DeleteOutlined/>}
                                size='small'
                                shape='circle'
                                danger
                                onClick={() => {deleteSkill(row)}}>
                            </Button>
                        </Space>)
                }
            }
        }
    ]

    const [skills, setSkills] = useState<Array<ISkill>>([]);
    const [skillIsLoaded, skillSetLoaded] = useState<boolean>(false);
    const [isApproved, setApproved] = useState('approved');
    //const [columns, setColumns] = useState<Array<object>>(columnsInitial.filter(item => !item.hidden));
    useEffect(() => {
        onSetPage(pageData);
        skillSetLoaded(true);
    }, []);

    const any = async () => {
        let response = isApproved === 'approved' ? await ApiService.Skill.GetSkillApproved() : await ApiService.Skill.GetSkillUnapproved();
        setSkills(response.data);
        skillSetLoaded(false)
    }

    useEffect(() => {
        if (!skillIsLoaded)
            return;
        any().then(null);
    }, [skillIsLoaded])

    const deleteSkill = async (rowData: ISkill) => {
        Modal.confirm({
            closable: false,
            title: 'Are you sure to delete?',
            okText: 'Confirm',
            cancelText: 'Cancel',
            okButtonProps: {
                className: 'responsive-button',
                icon: <CheckOutlined/>
            },
            cancelButtonProps: {
                className: 'responsive-button',
                icon: <LeftOutlined/>,
                danger: true
            },
            onOk: async () => {
                let response = await ApiService.Skill.DeleteSkill(rowData.id);
                if (response.status === 200){
                    skillSetLoaded(true);
                }
            },
            onCancel: async () => {
                return;
            }
        });
    }

    return (
        <div>
            <div className='row'>
                <div className='col-12'>
                    <Card title={
                        <Row gutter={[10, 10]}>
                            <Col xs={{span: 24}} md={{span: 8}}>
                                <span className='card-title-page'>Skills</span>
                            </Col>
                            <Col xs={{span: 19}} sm={{span: 21}} md={{span: 6, offset: 8}} lg={{span: 6, offset: 4}}
                                 xl={{span: 4, offset: 7}} xxl={{span: 3, offset: 10}}>
                                <Select onChange={(e) => {
                                    setApproved(e);
                                    skillSetLoaded(true);
                                    //e === 'approved' ? setColumns(columnsInitial.filter(item => !item.hidden)) : setColumns(columnsInitial);
                                }} defaultValue={isApproved}>
                                    <Select.Option key='1' value='approved'>Approved</Select.Option>
                                    <Select.Option key='2' value='unapproved'>Unapproved</Select.Option>
                                </Select>
                            </Col>
                            <Col xs={{span: 5}} sm={{span: 3}} md={{span: 2}} lg={{span: 6}} xl={{span: 5}}
                                 xxl={{span: 3}} style={{textAlign: 'right'}}>
                                <ResponsiveButton
                                    text='Suggest a New Skill'
                                    icon={<PlusOutlined/>}
                                    onClick={() => {
                                        history.push('/vertical/skills/suggest')
                                    }}/>
                            </Col>
                        </Row>
                    }>
                        <Table
                            rowKey={(row: ISkill) => row.id}
                            dataSource={skills.length > 0 && skills}
                            columns={columnsInitial}
                            loading={skillIsLoaded}
                        />
                    </Card>
                </div>
            </div>

        </div>
    )
};

export default SkillAdmin;
