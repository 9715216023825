import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../interfaces/page-data';
import {Button, Card, Col, Form, Image, Input, Row, Select, Switch} from "antd";
import {AppState, history} from "../../redux/store";
import {IResource, IResourceTag, IResourceType} from "../../interfaces/api";
import {useSelector} from "react-redux";
import {IUser} from "../../interfaces/api";
import {LeftOutlined} from "@ant-design/icons";
import {toast} from "react-hot-toast";
import {useParams} from 'react-router-dom';
import ResourcePlayer from "../../components/ResourceComps/ResourcePlayer";
import {S3_SERVER_URL} from "../../utils/Constants";
import AutoCompleteTags, {ITagAComplete} from "../../components/AutoCompleteTags/AutoCompleteTags";
import ApiService from "../../utils/services/api.service";

const PreviewResource: React.FC<IPageProps> = props => {

    const {onSetPage} = props;
    const resourceId = useParams<{ id?: string }>().id;
    const user: IUser = useSelector((state: AppState) => state).user;
    const [resource, setResource] = useState<IResource>(null);
    const [resourceTypes, setResourceTypes] = useState<IResourceType[]>([]);
    const [resourceTags, setResourceTags] = useState<ITagAComplete[]>([]);
    const pageData: IPageData = {
        title: 'Approve Resource',
        loaded: true
    };

    useEffect(() => {
        onSetPage(pageData);
        any().then(null);

    }, []);

    const any = async () => {
        let resourceResponse = await ApiService.Resource.GetResourceById(resourceId);
        setResource(resourceResponse.data);

        let rTypesResponse = await ApiService.ResourceType.GetResourceType();
        setResourceTypes(rTypesResponse.data);

        let rTagsResponse = await ApiService.ResourceTag.GetResourceTagByResource(resourceId);
        let t: Array<ITagAComplete> = rTagsResponse.data.map((item) => {
            return ({label: item.tag.tagName, key: item.tagId, value: item.tagId} as ITagAComplete);
        })
        setResourceTags(t);
    }

    return (
        <div className='row'>
            <div className='col-12'>
                <Card className='card-head-none' loading={resourceTypes.length < 1} title={<Row>
                    <Col span={24}>
                        <span className='card-title-page'>Preview Resource</span>
                    </Col>
                </Row>}>
                    <Form
                        colon={false}
                        labelCol={{sm: {span: 7}, lg: {span: 6}, md: {span: 6}}}
                        wrapperCol={{span: 12}}
                    >
                        <Form.Item label='Resource Type' name='resourceTypeId'
                                   initialValue={resource && resource.resourceTypeId}>
                            <Select disabled>
                                {
                                    resourceTypes && resourceTypes.map((item) => {
                                        return (<Select.Option key={item.id}
                                                               value={item.id}>{item.resourceTypeName}</Select.Option>)
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label='Title' name='title' initialValue={resource && resource.title}>
                            <Input disabled size='small'/>
                        </Form.Item>
                        <Form.Item label='Thumbnail' name='uploadedThumbnail' valuePropName={'src'}
                                   initialValue={resource && S3_SERVER_URL + resource.uploadedThumbnail}>
                            <Image preview={true} width={200}/>
                        </Form.Item>
                        <Form.Item label='Description' name='description'
                                   initialValue={resource && resource.description}>
                            <div style={{padding: 20}}>
                                <div dangerouslySetInnerHTML={{__html: resource && resource.description}}/>
                            </div>
                        </Form.Item>

                        <Form.Item label='Tags' name='resourceTags'
                                   initialValue={resource && resource.description}>
                            <AutoCompleteTags
                                endpoint=''
                                disabled
                                tags={resourceTags}
                            />
                        </Form.Item>

                        <Form.Item label='Is Embed Code' name='isEmbedCode' valuePropName='checked'
                                   initialValue={resource && resource.isEmbedCode}>
                            <Switch disabled/>
                        </Form.Item>

                        <Form.Item label='Preview' name='fileURI'>
                            <ResourcePlayer
                                resourceId={resourceId}
                                URIorEmbedCode={
                                    resource && (resource.isEmbedCode ? resource.linkOrEmbedCode : resource.fileURI)
                                }
                                isEmbedCode={resource && resource.isEmbedCode}
                                resourceTypeId={resource && resource.resourceTypeId}
                                likeFeedback={false}
                            />
                        </Form.Item>

                        <Form.Item wrapperCol={{
                            lg: {span: 12, offset: 6},
                            md: {span: 12, offset: 6},
                            xs: {offset: 0},
                            sm: {span: 12, offset: 7}
                        }}>
                            <div className='d-flex justify-content-between'>

                                <Button className='responsive-button' onClick={() => {
                                    history.push('/vertical/resources')
                                }} icon={<LeftOutlined/>}>Back</Button>

                                <span/>
                            </div>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </div>
    )
};


export default PreviewResource;
