import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../../interfaces/page-data';
import {IUser, IWOApplication} from "../../../interfaces/api";
import {Col, Row} from "antd";
import {useSelector} from "react-redux";
import {AppState} from "../../../redux/store";
import WOCard from "../../../components/WOCard/WOCard";
import ApiService from "../../../utils/services/api.service";

const MyWorkOpportunities: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'My Work Opportunities',
        loaded: true
    };
    useEffect(() => {
        onSetPage(pageData);
        wosSetLoaded(true);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const [wos, setWos] = useState<Array<IWOApplication>>([]);
    const [wosIsLoaded, wosSetLoaded] = useState<boolean>(false);
    const user: IUser = useSelector((state: AppState) => state).user;

    const getWOs = async () => {
        let response = await ApiService.WorkOpportunity.GetWOByApplicant(user.id);
        setWos(response.data);
        wosSetLoaded(false);
    }

    useEffect(() => {
        if (!wosIsLoaded)
            return;
        getWOs();
    }, [wosIsLoaded])

    return (
        <div>
            <Row gutter={[23, 3]}>
                {
                    wos.length > 0 ? wos.map((item) => {
                            //const provider:IUser = item.providedBy;
                            return (
                                <Col key={item.id} xl={{span: 6}} md={{span: 8}} sm={{span: 12}} xs={{span: 24}}>
                                    <WOCard isOwned={true} provider={item.user} key={item.id} applicant={item} workOpportunity={item.workOpportunity}/>
                                </Col>
                            )
                        }) :
                        <Col span={24} style={{textAlign:'center'}}>
                            <h5>You have not application to any work opportunity yet.</h5>
                        </Col>
                }

            </Row>
        </div>
    )
};

export default MyWorkOpportunities;
