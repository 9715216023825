import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../interfaces/page-data';
import {Button, Card, Col, Input, Modal, Row, Select, Space, Table} from "antd";
import {history} from "../../redux/store";
import {IEvent} from "../../interfaces/api";
import {CheckOutlined, DeleteOutlined, LeftOutlined, PlusOutlined} from "@ant-design/icons";
import moment from "moment";
import {DATETIME_FORMAT} from "../../utils/Constants";
import ResponsiveButton from "../../components/ResponsiveButton/ResponsiveButton";
import ApiService from "../../utils/services/api.service";
import AutoCompleteSearch from "../../components/AutoCompleteSearch/AutoCompleteSearch";
import SearchGrid, {SEARCH_STATUS} from "../../components/SearchGrid/SearchGrid";

const EventAdmin: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'Event',
        loaded: true
    };

    const columnsInitial = [
        {
            title: "Name",
            dataIndex: "eventName",
            ellipsis: false,
        },
        {
            title: "Deadline",
            dataIndex: "deadline",
            width: '150px',
            ellipsis: false,
            render: date => <>{
                date == "0001-01-01T00:00:00" ? "Uncertain Date" : moment(date).format(DATETIME_FORMAT)
            }
            </>
        },
        {
            title: "Start",
            dataIndex: "startDate",
            width: '150px',
            ellipsis: false,
            render: date => <>{
                date == "0001-01-01T00:00:00" ? "Uncertain Date" : moment(date).format(DATETIME_FORMAT)
            }
            </>
        },
        {
            title: "Finish",
            dataIndex: "finishDate",
            width: '150px',
            ellipsis: false,
            render: date => <>{
                date == "0001-01-01T00:00:00" ? "Uncertain Date" : moment(date).format(DATETIME_FORMAT)
            }</>
        },
        {
            title: "",
            dataIndex: "isApproved",
            width: '150px',
            ellipsis: false,
            hidden: false,
            render: (data, row: IEvent) => {
                if (data === true) {
                    return (<div style={{textAlign: 'center'}}>
                        <Button
                            icon={<DeleteOutlined/>}
                            size='small'
                            shape='circle'
                            danger
                            onClick={() => {deleteEvent(row)}}>
                        </Button>
                    </div>)
                }
                else {
                    return (<Space>
                        <Button size='small'
                            onClick={() => {
                                history.push({
                                pathname: `events/approve/${row.id}`});
                            }}>Approve</Button>
                        <Button
                            icon={<DeleteOutlined/>}
                            size='small'
                            shape='circle'
                            danger
                            onClick={() => {deleteEvent(row)}}>
                        </Button>
                    </Space>)
                }
            }
        }
    ]

    const [events, setEvents] = useState<IEvent[]>([]);
    const [dataIsLoaded, dataSetLoaded] = useState<boolean>(false);
    const [isApproved, setApproved] = useState('approved');
    const [columns, setColumns] = useState<object[]>(columnsInitial.filter(item => !item.hidden));


    useEffect(() => {
        onSetPage(pageData);
        //requets().then(null);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const requets = async () => {
        dataSetLoaded(true);
        let eventApproved = isApproved == 'approved' ? await ApiService.Event.GetEventApproved() : await ApiService.Event.GetEventUnapproved();
        dataSetLoaded(false);
        setEvents(eventApproved.data)
    }

    const callbackSearch = async (status, data) => {
        if (status === SEARCH_STATUS.Loading){
            dataSetLoaded(true);
        }
        else if (status === SEARCH_STATUS.Loaded){
            dataSetLoaded(false);
            await setEvents(data);
        }
    }

    useEffect(() => {
        requets().then(null);
    },[isApproved])

    const deleteEvent = async (rowData: IEvent) => {
        Modal.confirm({
            closable: false,
            title: 'Are you sure to delete?',
            okText: 'Confirm',
            cancelText: 'Cancel',
            okButtonProps: {
                className: 'responsive-button',
                icon: <CheckOutlined/>
            },
            cancelButtonProps: {
                className: 'responsive-button',
                icon: <LeftOutlined/>,
                danger: true
            },
            onOk: async () => {
                let response = await ApiService.Event.DeleteEventById(rowData.id);
                if (response.status === 200){
                    await requets();
                }
            },
            onCancel: async () => {
                return;
            }
        });
    }

    return (
        <div>
            <div className='row'>
                <div className='col-12'>
                    <Card title={
                        <Row gutter={[10, 10]}>
                            <Col xs={{span: 24}} md={{span: 8}}>
                                <span className='card-title-page'>Events</span>
                            </Col>
                            <Col xs={{span: 24}} sm={{span: 24}} md={{span: 6, offset: 1}} lg={{span: 6}}
                                 xl={{span: 4, offset: 5}} xxl={{span: 6}}>
                                {/*<SearchGrid*/}
                                {/*    request={ApiService.Event.GetEventSearchByKeyword}*/}
                                {/*    callback={callbackSearch}*/}
                                {/*    clear={clear}*/}
                                {/*/>*/}
                            </Col>
                            <Col xs={{span: 19}} sm={{span: 21}} md={{span: 6, offset: 1}} lg={{span: 6, offset: 1}}
                                 xl={{span: 4}} xxl={{span: 3}}>
                                <Select onChange={(e) => {
                                    setApproved(e);
                                    dataSetLoaded(true);
                                    e === 'approved' ? setColumns(columnsInitial.filter(item => !item.hidden)) : setColumns(columnsInitial);
                                }} defaultValue={isApproved}>
                                    <Select.Option key='1' value='approved'>Approved</Select.Option>
                                    <Select.Option key='2' value='unapproved'>Unapproved</Select.Option>
                                </Select>
                            </Col>
                            <Col xs={{span: 5}} sm={{span: 3}} md={{span: 2}} lg={{span: 3}} xl={{span: 2}}
                                 xxl={{span: 1}} style={{textAlign: 'right', paddingLeft:'0px'}}>
                                <ResponsiveButton
                                    text='Post'
                                    icon={<PlusOutlined/>}
                                    onClick={() => {
                                        history.push('/vertical/events/new')
                                    }}/>
                            </Col>
                        </Row>
                    }>
                        <Table
                            rowKey={(row: IEvent) => row.id}
                            dataSource={events}
                            columns={columns}
                            loading={dataIsLoaded}
                        />
                    </Card>
                </div>
            </div>

        </div>
    )
};

export default EventAdmin;
