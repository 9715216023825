class Guid {
    static newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}
export default Guid;

// Example of a bunch of GUIDs
// for (var i = 0; i < 100; i++) {
//     var id = Guid.newGuid();
//     console.log(id);
// }