import React from 'react';

import {Route} from 'react-router';

import {errorRoutes} from '../../routes';

import './Error.scss';
import {useSelector} from "react-redux";
import {AppState} from "../../redux/store";
import {IUser} from "../../interfaces/api";

const ErrorLayout = props => {
    const user: IUser = useSelector((state: AppState) => state).user;

    return (
        <div className='error-layout'>
            {errorRoutes.filter((route) => route.auth?.includes(user.userRoleId)).map((route, i) => (
                <Route
                    exact
                    key={i}
                    path={`/error${route.path}`}
                    render={() => <route.component onSetPage={props.onSetPage}/>}
                />
            ))}
        </div>
    );
};

export default ErrorLayout;
