import React, {useEffect, useState} from 'react';
import {Select, Spin} from 'antd';
import {ISkill} from "../../interfaces/api";
import {ACOMPLETE_DEBOUNCE_TIMEOUT} from "../../utils/Constants";
import ApiService from "../../utils/services/api.service";
import toast from 'react-hot-toast';


export interface ISkillAComplete {
    label: string,
    value: string,
    key: string,
    isNew?: boolean,
    disabled?: boolean
}

interface AutocompleteProps {
    selectedTags?: (data: any, prereqData: any) => void,
    controlPrereq?: boolean,
    disabled?: boolean,
    singleTag?: boolean,
    unknownTag?: boolean,
    onChange?: (text: any) => void,
    tags?: Array<ISkillAComplete>, //current tags in select
    clearData?: boolean,
    helperTags?: Array<ISkillAComplete>,
    permissionEndpoint?: string,
    providerId?: string
}


let debounce: NodeJS.Timeout;
const AutoCompleteSkills: React.FC<AutocompleteProps> = props => {

    const [children, setChildren] = useState<any>([]); // listelenecek skill ler
    const [isLoading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<Array<ISkillAComplete>>([]); // Secilmis olan skill ler
    const [prereqData, setPrereqData] = useState<Array<ISkillAComplete>>([]); // secilme olan skill erin prereq leri
    const [isRendered, setRendered] = useState<boolean>(false);

    const onSearch = (searchText: string) => {
        setChildren([]);
        if (searchText.length < 1) {
            return;
        }
        clearTimeout(debounce);
        setLoading(true);
        debounce = setTimeout(async () => {
            let res: Array<ISkillAComplete> = [];
            let responseFromAPI = await ApiService.Skill.GetSkillSearch(searchText);
            let result: Array<ISkill> = responseFromAPI.data;
            if (result.length < 1) {
                setLoading(false)
                return;
            }

            let filteredResult: Array<ISkill> = result.filter(item => {
                if (props.helperTags.findIndex(x => x.value === item.id) == -1) {
                    return item;
                }
            });
            res = filteredResult.map((item: ISkill) => {
                return {label: item.skillName, value: item.id, key: item.id} as ISkillAComplete;
            });

            setLoading(false);

            if (props.unknownTag) {
                res.push({label: searchText, value: searchText, key: searchText} as ISkillAComplete);
            }
            setChildren(res)
        }, ACOMPLETE_DEBOUNCE_TIMEOUT);
    };

    const onSelect = () => {
        setChildren([]);
    }

    const onChange = async (objs: Array<any>) => {
        // Herhangi bir child secilirse burasi tetiklenir ve data guncellenmis olur
        setLoading(true);
        props.onChange(objs);
        let skillsResult: Array<ISkillAComplete> = data;
        let skillPrereqResult: Array<ISkillAComplete> = [];
        if (objs == null)
            return;
        if (props.singleTag && objs.length > 1) {
            objs = objs.slice(1);
        }

        if (props.controlPrereq) {
            let requiredSkills: ISkillAComplete[] = [];
            for (let objItem of objs) {
                let responseFromAPI = await ApiService.Skill.GetSkillPrerequisitesById(objItem.value);
                let result: Array<ISkill> = responseFromAPI.data;
                if (result.length < 1) {
                    break;
                }
                let mappedRes: ISkillAComplete[] = result.map((item) => {
                    return ({label: item.skillName, value: item.id, key: item.id} as ISkillAComplete);
                    //requiredSkills.push({label: item.skillName, value: item.id, key: item.id} as ISkillAComplete);
                })
                requiredSkills = [...requiredSkills, ...mappedRes];
            }
            let filteredResult: ISkillAComplete[] = objs.filter((reqItem) => {
                if (requiredSkills.findIndex(x => x.value === reqItem.value) === -1) {
                    return reqItem;
                }
            })
            if (filteredResult.length < 1){
                console.log('Filtered result is empty');
                
            }
            skillPrereqResult = requiredSkills;
            skillsResult = filteredResult
        }
        else {
            skillsResult = objs;
        }


        // TODO New training icinde kullaniyor
        // TODO Trainer permission ile prereq skill yer degistir
        // Trianing permission li method burada provided skill ac icinde yer alacak ve bu sonuca gore donuyor olacak
        // prereq sonucu gelen skill lere permission var mi diye yeniden osruyor olacagiz

        // if (props.permissionEndpoint.length > 0) {
        //     // trainer permission control
        //     // provider ID = trainer ID
        //     let filteredResult: ISkillAComplete[] = prereqData;
        //     console.dir(filteredResult);
        //     for (let objItem of objs) {
        //         HTTPService.GET(`/UserSkill/TrainerPermission?trainerId=${props.providerId}&skillId=${objItem.value}`).then((response) => {
        //             console.dir(response);
        //             if (!response.data){
        //                 return;
        //             }
        //             let result: boolean = response.data.toString() === 'true';
        //             if (!result) {
        //                 console.log('result ' + result)
        //                 filteredResult.filter(item => {
        //                     return (item.value === objItem)
        //                 })
        //             }
        //             else {
        //                 console.log('result ' + result)
        //             }
        //         });
        //     }
        //     setData(filteredResult);
        // }

        if (props.permissionEndpoint.length > 0) {
            // trainer permission control
            // provider ID = trainer ID
            let filteredResult : ISkillAComplete[] = [];
            for (let objItem of skillsResult) {
                let response = await ApiService.UserSkill.GetUserSkillTrainerPermission({trainerId: props.providerId, skillId: objItem.value});
                let result: boolean = response.data.toString() === 'true';
                if (result) {
                    filteredResult.push(objItem);
                }
                else {
                    toast.error("Skill trainer permission not founded.");
                }
            }
            skillsResult = filteredResult;
        }
        setPrereqData(skillPrereqResult);
        setData(skillsResult);
        setLoading(false);
    }

    //region Effects
    useEffect(() => {
        setRendered(true);
    }, [])

    useEffect(() => {
        if (!isRendered) {
            props.selectedTags([], []);
            //props.onChange([]);
            return;
        }
        props.selectedTags(data, prereqData);
        //props.onChange(data);
    }, [data])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setData(null);
        setPrereqData(null)
    }, [props.clearData])

    useEffect(() => {
        // let t: Array<any> = [...children, ...props.tags];
        // setData(t)
        if (props.permissionEndpoint.length > 0) {
            // trainer permission control
            // provider ID = trainer ID
            let filteredResult: ISkillAComplete[] = [];
            props.tags.forEach( async (objItem) => {
                let responseFromAPI = await ApiService.UserSkill.GetUserSkillTrainerPermission({trainerId: props.providerId, skillId: objItem.value})
                if (!responseFromAPI.data){
                    return;
                }
                let result: boolean = responseFromAPI.data.toString() === 'true';
                if (result) {
                    filteredResult.push(objItem);
                }
                else {
                }
            })
            setData(filteredResult);
        }
        else {
            setData(props.tags);
        }
    }, [props.tags])// eslint-disable-line react-hooks/exhaustive-deps
    //endregion

    return (
        <Select
            filterOption={false}
            labelInValue
            mode='multiple'
            notFoundContent={isLoading ? <Spin size="small"/> : null}
            style={{width: '100%'}}
            onSearch={onSearch}
            onSelect={onSelect}
            onChange={onChange}
            options={children}
            disabled={props.disabled}
            aria-multiline={true}
            value={data}
            loading={isLoading}
        >
            {/*{children}*/}
        </Select>
    );
};

AutoCompleteSkills.defaultProps = {
    unknownTag: true,
    disabled: false,
    singleTag: false,
    onChange: () => {
    },
    selectedTags: () => {
    },
    tags: [],
    clearData: false,
    controlPrereq: false,
    helperTags: [],
    permissionEndpoint: '',
    providerId: ''
}

export default AutoCompleteSkills;