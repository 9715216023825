import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../../interfaces/page-data';
import {ITraining, ITrainingApplication, IUser} from "../../../interfaces/api";
import toast from "react-hot-toast";
import {Col, Row} from "antd";
import TrainingCard from "../../../components/TrainingCard/TrainingCard";
import {useSelector} from "react-redux";
import {AppState} from "../../../redux/store";
import ApiService from "../../../utils/services/api.service";

const MyTrainings: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'My Trainings',
        loaded: true
    };
    useEffect(() => {
        onSetPage(pageData);
        trainingsSetLoaded(true);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const [trainings, setTrainings] = useState<Array<ITrainingApplication>>([]);
    const [trainingsIsLoaded, trainingsSetLoaded] = useState<boolean>(false);
    const user: IUser = useSelector((state: AppState) => state).user;

    const getTrainings = async () => {
        let response = await ApiService.Training.GetTrainingByApplicant(user.id);
        setTrainings(response.data);
    }

    useEffect(() => {
        if (!trainingsIsLoaded)
            return;
        getTrainings().then(null);
    }, [trainingsIsLoaded])

    return (
        <div>
            <Row gutter={[23, 3]}>
                {
                    trainings.length > 0 ? trainings.map((item) => {
                        //const provider:IUser = item.providedBy;
                        return (
                            <Col key={item.id} xl={{span: 6}} md={{span: 8}} sm={{span: 12}} xs={{span: 24}}>
                                <TrainingCard isOwned={true} provider={item.user} key={item.id} applicant={item} training={item.training}/>
                            </Col>
                        )
                    }) :
                        <Col span={24} style={{textAlign:'center'}}>
                            <h5>You have not enrolled to any trainings yet.</h5>
                        </Col>
                }
            </Row>
        </div>
    )
};

export default MyTrainings;
