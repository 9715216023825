import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../../interfaces/page-data';
import {Button, Col, Row, Image, Card, Tag} from "antd";
import {
    ISkill,
    IUser,
    IEvent,
    IEventImage,
    IEventApplication,
    IEventApprovePayment,
    IEventRegister,
    IEventTag
} from "../../../interfaces/api";

import toast from "react-hot-toast";
import {useParams} from "react-router-dom";
import {DATETIME_FORMAT, ModuleColors, S3_SERVER_URL} from "../../../utils/Constants";
import {AppState, history} from "../../../redux/store";
import moment from "moment";
import './RegisterEvent.scss';
import {isGuid} from "../../../utils/GlobalFuncs";
import SkillCard from "../../../components/SkillCard/SkillCard";
import Countdown from "../../../components/Countdown/Countdown";
import PersonCard from "../../../components/PersonCard/PersonCard";
import {useSelector} from "react-redux";
import GMForModule from "../../../components/GoogleMaps/ForModule/GMForModule";
import ImgViewer from "../../../components/ImgViewer/ImgViewer";
import ApiService from "../../../utils/services/api.service";

const RegisterEvent: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'Register Event',
        loaded: true
    };

    const user: IUser = useSelector((state: AppState) => state).user;
    const [isLoaded, setLoaded] = useState<boolean>(false);
    const [event, setEvent] = useState<IEvent>({} as IEvent);
    const [provider, setProvider] = useState<IUser>({} as IUser);
    const [awardSkills, setAwardSkills] = useState<ISkill[]>([]);
    const [requiredSkills, setRequiredSkills] = useState<ISkill[]>([]);
    const [isApplicant, setApplicant] = useState<boolean>(false);
    const [images, setImages] = useState<IEventImage[]>([]);
    const [deadlineIsOver, setDeadlineIsOver] = useState<boolean>(false);
    const [eventTags, setEventTags] = useState<IEventTag[]>([]);
    const eventId = useParams<{ id?: string }>().id;
    const gutter: any = [0, 0];
    useEffect(() => {
        onSetPage(pageData);
        if (!isGuid(eventId))
            return;

        const any = async () => {
            let eventResponse = await ApiService.Event.GetEventById(eventId);
            let event: IEvent = eventResponse.data;
            setDeadlineIsOver((moment(event.deadline).valueOf() - moment().valueOf()) / 1000 < 0)
            setEvent(event);
            setProvider(event.providedBy == null ? {} as IUser : event.providedBy)

            let applicantResponse = await ApiService.Event.GetEventByApplicant(user.id);

            let eventIsExist: boolean = applicantResponse.data.findIndex((item) => item.eventId === eventId) > -1;
            setApplicant(eventIsExist);

            let tagsResponse = await ApiService.EventTag.GetEventTagByEvent(eventId);
            setEventTags(tagsResponse.data);

            let awardResponse = await ApiService.EventReward.GetEventRewardByEvent(eventId);
            setAwardSkills(awardResponse.data);

            let requiredResponse = await ApiService.EventRequiredSkill.GetEventRequiredSkillByEvent(eventId);
            setRequiredSkills(requiredResponse.data);

            let imagesResponse = await ApiService.EventImage.GetEventImageByEvent(eventId);
            setImages(imagesResponse.data);

            setLoaded(true);
        }
        any().then(null);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const registerClick = async () => {
        //toast.success('Registering...');
        if (event.participationFee !== 0) {
            // toast.success('Routing payment page...');
            history.push(`/vertical/payment/event/${eventId}`);
        }
        else {
            let registerData = {eventId: eventId, userId: user.id } as IEventRegister;
            let registerResponse = await ApiService.EventApplication.PostEventApplicationRegister(registerData);
            // TODO - Birsey yapilmali mi ?
            return registerResponse.status === 200;

            let paymentData = { eventId: eventId, userId: user.id } as IEventApprovePayment;
            let paymentResponse = await ApiService.Event.PostEventApprovePayment(paymentData);
            if(paymentResponse.status === 200){
                history.push('/vertical/my-events');
            }
        }
    }

    return (
        <div className='register-event-page'>
            <Card
                className='ant-card-invisible'
                // bodyStyle={{padding:'20px'}}
                loading={!isLoaded}>
                {/*Event Details*/}
                <Row>
                    {/*Event name, desc, time left....*/}
                    <Col className='cell-gap' md={{order: 1, span: 12}} lg={{order: 1, span: 12}}
                         xs={{order: 2, span: 24}}>
                        {/*<Row>*/}
                        {/*    <Col xl={{span: 6}} lg={{span: 5}} md={{span: 4}} sm={{span: 3}} xs={{span: 5}}>*/}
                        {/*        <span className='when-hour'>When: </span>*/}
                        {/*    </Col>*/}
                        {/*    <Col span={10}>*/}
                        {/*        <span*/}
                        {/*            className='when-hour-date'>{moment(event.startDate).format("DD.MM.YYYY")}</span>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        {/*<Row>*/}
                        {/*    <Col xl={{span: 6}} lg={{span: 5}} md={{span: 4}} sm={{span: 3}} xs={{span: 5}}>*/}
                        {/*        <span className='when-hour'>Hour: </span>*/}
                        {/*    </Col>*/}
                        {/*    <Col span={10}>*/}
                        {/*        <span className='when-hour-date'>{moment(event.startDate).format("HH:mm")}</span>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        {/*<br/>*/}
                        <h3>{event.eventName}</h3>
                        <br/>
                        <br/>
                        <div style={{padding: '10px'}} className='desc-tag'>
                            <div dangerouslySetInnerHTML={{__html: event && event.description}}/>
                        </div>
                        {/*{*/}
                        {/*    (!objIsEmpty(provider)) &&*/}
                        {/*    <>*/}
                        {/*        <div>{provider.name + ' ' + provider.surname}</div>*/}
                        {/*    </>*/}
                        {/*}*/}

                        {
                            user.id !== provider.id &&
                            <>
                                {
                                    isApplicant ?
                                        <h5>
                                            You have already registered
                                        </h5>
                                        :
                                        <>
                                            {
                                                deadlineIsOver ?
                                                    <h5>
                                                        Register is over
                                                    </h5>
                                                    :
                                                    <>
                                                        <h6>Time left before register ends</h6>
                                                        <div className='align-items'>
                                                            <Countdown date={event.deadline}/>
                                                        </div>
                                                        <br/>
                                                        <div className='align-items'>
                                                            <Button onClick={registerClick} type='primary'>Register Now</Button>
                                                        </div>
                                                    </>
                                            }
                                        </>
                                }
                            </>
                        }


                        {/*<h6>Deadline</h6>*/}
                        {/*<div>{moment(event.deadline).format(DATE_FORMAT)}</div>*/}
                        {/*<h6>Start - Finish</h6>*/}
                        {/*<div>{moment(event.startDate).format(DATE_FORMAT)}-{moment(event.finishDate).format(DATE_FORMAT)}</div>*/}

                    </Col>
                    {/*event image, price, space left, location*/}
                    <Col className='cell-gap' md={{order: 2, span: 12}}
                         lg={{order: 2, span: 12}} xs={{order: 1, span: 24}}>
                        <Row className='event-info-border'>
                            <Col span={24}>
                                <div className='img-item'>
                                    <ImgViewer src={S3_SERVER_URL + event.uploadedThumbnail} ratio={2} width={'100%'}/>
                                </div>
                            </Col>

                            {/*<div className='info-item'>*/}
                            <Col className='info-item' span={24}>
                                <div>
                                    <span className='header'>Start Date</span>
                                    <br/>
                                    <span
                                        className='info'>{moment(event.startDate).format(DATETIME_FORMAT)}</span>
                                </div>
                            </Col>

                            <Col className='info-item' span={24}>
                                <div>
                                    <span className='header'>Finish Date</span>
                                    <br/>
                                    <span
                                        className='info'>{moment(event.finishDate).format(DATETIME_FORMAT)}</span>
                                </div>
                            </Col>

                            <Col className='info-item' span={24} sm={{span: 12}}>
                                <div>
                                    <span className='header'>Price</span>
                                    <br/>
                                    <span
                                        className='info'>{event.participationFee === 0 ? 'Free' : event.participationFee}</span>
                                </div>
                            </Col>
                            <Col className='info-item' span={24} sm={{span: 12}}>
                                <div>
                                    <span className='header'>Total Seat</span>
                                    <br/>
                                    <span
                                        className='info'>{event.maxParticipants == 0 ? 'No limit' : event.maxParticipants}{event.maxParticipants == 1 ? ' seat' : ' seats'}</span>
                                </div>
                            </Col>
                            {
                                event.locationOffline !== null &&
                                <Col className='info-item' span={24}>
                                    <div>
                                        <span className='header'>Offline Location</span>
                                        <br/>
                                        <span className='info'>{event.locationOffline}</span>
                                    </div>
                                </Col>
                            }
                            {
                                event.locationOnline !== null &&
                                <Col className='info-item' span={24}>
                                    <div>
                                        <span className='header'>Online Location</span>
                                        <br/>
                                        <span className='info'>{event.locationOnline}</span>
                                    </div>
                                </Col>
                            }
                            <Col className='info-item' span={24} sm={{span: 12}}>
                                <div>
                                    <span className='header'>Total Seat</span>
                                    <br/>
                                    <span
                                        className='info'>{event.maxParticipants == 0 ? 'No limit' : event.maxParticipants}{event.maxParticipants == 1 ? ' seat' : ' seats'}</span>
                                </div>
                            </Col>

                            {/*<Row>*/}
                            {/*    <Col xl={{span: 6}} lg={{span: 5}} md={{span: 4}} sm={{span: 3}} xs={{span: 5}}>*/}
                            {/*        <span className='when-hour'>When: </span>*/}
                            {/*    </Col>*/}
                            {/*    <Col span={10}>*/}
                            {/*        <span*/}
                            {/*            className='when-hour-date'>{moment(event.startDate).format("DD.MM.YYYY")}</span>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            {/*<Row>*/}
                            {/*    <Col xl={{span: 6}} lg={{span: 5}} md={{span: 4}} sm={{span: 3}} xs={{span: 5}}>*/}
                            {/*        <span className='when-hour'>Hour: </span>*/}
                            {/*    </Col>*/}
                            {/*    <Col span={10}>*/}
                            {/*        <span className='when-hour-date'>{moment(event.startDate).format("HH:mm")}</span>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            {/*</div>*/}
                        </Row>
                    </Col>
                </Row>

                {/*Event tags*/}
                <Row className='cell-gap' justify='space-around'>
                    {
                        eventTags.length > 0 &&
                        <Col span={24}>
                            <h5>Tags</h5>
                        </Col>
                    }

                    <Col span={24}>
                        {eventTags && eventTags.map((tag, i) => (
                            <Tag
                                onClick={() => {
                                    history.push(`/vertical/search/${tag.tag.tagName}`);
                                }}
                                key={i}
                                //color={randomColor()}
                                color='#3FCC79'
                                style={{
                                    color: '#fff',
                                    borderRadius: 500,
                                    fontSize: 14,
                                    margin: '5px',
                                    cursor: 'pointer'
                                }}>
                                {tag.tag.tagName}
                            </Tag>
                        ))}
                    </Col>

                </Row>

                {/*Event Required*/}
                {
                    requiredSkills.length > 0 &&
                    <Row className='cell-gap' justify='center'>
                        <Col span={24} className='align-items'>
                            <h5>What Do You Need to Know?</h5>
                        </Col>
                        {
                            requiredSkills.map((item) => {
                                //const provider:IUser = item.providedBy;
                                return (
                                    <>
                                        <Col key={item.id + 1} lg={{span: 2.5}} md={{span: 2}}
                                             sm={{span: 2}}
                                             xs={{span: 8}}>
                                        </Col>
                                        <Col key={item.id} lg={{span: 3}} md={{span: 3}} sm={{span: 4}}
                                             xs={{span: 6}}>
                                            <SkillCard key={item.id} skill={item}/>
                                        </Col>
                                        <Col key={item.id + 2} lg={{span: 2.5}} md={{span: 2}}
                                             sm={{span: 2}}
                                             xs={{span: 8}}>
                                        </Col>
                                    </>
                                )
                            })
                        }
                    </Row>
                }

                {/*Event Provided*/}
                {
                    awardSkills.length > 0 &&
                    <Row className='cell-gap' justify='space-around'>
                        <Col span={24} className='align-items'>
                            <h5>What Will You Learn?</h5>
                        </Col>
                        {
                            awardSkills.map((item) => {
                                //const provider:IUser = item.providedBy;
                                return (
                                    <>
                                        <Col key={item.id + 1} lg={{span: 2.5}} md={{span: 2}}
                                             sm={{span: 2}}
                                             xs={{span: 8}}>
                                        </Col>
                                        <Col key={item.id} lg={{span: 3}} md={{span: 3}} sm={{span: 4}}
                                             xs={{span: 6}}>
                                            <SkillCard key={item.id} skill={item}/>
                                        </Col>
                                        <Col key={item.id + 2} lg={{span: 2.5}} md={{span: 2}}
                                             sm={{span: 2}}
                                             xs={{span: 8}}>
                                        </Col>
                                    </>
                                )
                            })
                        }
                    </Row>
                }

                {/*Event Images*/}
                {
                    images.length > 0 &&
                    <Row className='cell-gap' justify='center' gutter={[20, 14]}>
                        <Col span={24} className='align-items'>
                            <h5>Images</h5>
                        </Col>
                        {images.map((item) => {
                            return (
                                <>
                                    <Col key={item.id} md={{span: 8}} sm={{span: 12}} xs={{span: 24}}>
                                        <Image key={item.id} src={S3_SERVER_URL + item.imageURI}/>
                                    </Col>
                                </>
                            );
                        })}
                    </Row>
                }

                {/*Event Location*/}
                {
                    event.isOfflineMeeting &&
                    <Row className='cell-gap'>
                        <Col span={24} className='align-items'>
                            <h5>Address</h5>
                            <span style={{paddingBottom: '5px'}}>{event.locationOffline}</span>
                        </Col>
                        <Col span={24} className='map-row'>
                            <GMForModule
                                disabled
                                haveSearchBox={false}
                                defaultValue={{lat: event.latitude, lng: event.longitude}}
                                scrollWheel={false}
                                markerColorByModule={ModuleColors.Events}
                            />
                        </Col>
                    </Row>
                }

                {
                    event && provider &&
                    <Row gutter={gutter} className='cell-gap'>
                        <Col span={24}>
                            <h5>Provider</h5>
                        </Col>
                        <Col span={24}>
                            <PersonCard user={provider}/>
                        </Col>
                    </Row>
                }


                {/*Event Register*/}
                <Row className='align-items cell-gap'>
                    <Col span={24}>
                        {
                            user.id !== provider.id &&
                            <>
                                {
                                    isApplicant ?
                                        <h5>
                                            You have already registered
                                        </h5>
                                        :
                                        <>
                                            {
                                                deadlineIsOver ?
                                                    <h5>
                                                        Register is over
                                                    </h5>
                                                    :
                                                    <>
                                                        <h6>Time left before register ends</h6>
                                                        <div className='align-items'>
                                                            <Countdown date={event.deadline}/>
                                                        </div>
                                                        <br/>
                                                        <div className='align-items'>
                                                            <Button onClick={registerClick} type='primary'>Register Now</Button>
                                                        </div>
                                                    </>
                                            }
                                        </>
                                }
                            </>
                        }
                    </Col>
                </Row>
            </Card>
        </div>
    )
};

export default RegisterEvent;
