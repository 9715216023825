import React, {useEffect} from 'react';
import {IUser} from "../../interfaces/api";
import {Col, Row, Tooltip} from "antd";
import ImgViewer from "../ImgViewer/ImgViewer";
import './MiniCard.scss';
import {S3_SERVER_URL} from "../../utils/Constants";
import {history} from "../../redux/store";

interface MiniCardProps {
    newsTitle: string,
    newsGuid: string,
    newsType: string,
    thumbnail: string,
    newsProviderPP: string
}

const MiniCard: React.FC<MiniCardProps> = props => {

    const openLink = () => {
        if (props.newsType.toLowerCase().includes('event')){
            history.push(`/vertical/events/register/${props.newsGuid}`);
        }
        else if (props.newsType.toLowerCase().includes('training')){
            history.push(`/vertical/trainings/enroll/${props.newsGuid}`);
        }
        else if (props.newsType.toLowerCase().includes('wo')){
            history.push(`/vertical/work-opportunities/application/${props.newsGuid}`);
        }
        else if (props.newsType.toLowerCase().includes('skill')){
            history.push(`/vertical/skills/detail/${props.newsGuid}`);
        }
        else if (props.newsType.toLowerCase().includes('resource')){
            history.push(`/vertical/resources/detail/${props.newsGuid}`);
        }
    }

    return (
        <div className="mini-card-container">
                <div
                    onClick={openLink}
                >
                    <div className='mini-card' style={{backgroundImage: `url(${S3_SERVER_URL + props.thumbnail})`}}>
                        <span className='user-image'>
                            <ImgViewer src={S3_SERVER_URL + `profile/profile_${props.newsProviderPP}.jpg`} ratio={1} shape='circle'
                                       maxWidth='40px'/>
                        </span>
                        <span className='text-1-line type shadow-text'>{props.newsType}</span>
                        <span className='text-1-line title shadow-text'>{props.newsTitle}</span>
                    </div>
                </div>
        </div>
    );
};

MiniCard.defaultProps = {
    newsTitle: '',
    newsGuid: '',
    newsType: '',
    thumbnail: '',
    newsProviderPP: ''
}

export default MiniCard;
