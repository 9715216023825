import React, {useEffect} from "react";
import {IPageData, IPageProps} from '../../interfaces/page-data';

const Investment: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'Investment',
        loaded: true
    };

    useEffect(() => onSetPage(pageData), []);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            INVESTMENT
        </>
    )
};

export default Investment;
