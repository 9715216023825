import React from 'react';
import {ITraining, IUser} from "../../interfaces/api";
import {Card, Col, Row, Tooltip} from "antd";
import {history} from "../../redux/store";
import blankImg from "../../assets/img/blank/blank-image.png";
import {DATE_FORMAT, S3_SERVER_URL} from "../../utils/Constants";
import moment from "moment";
import './TrainingHorizontalCard.scss';
import ImgViewer from "../ImgViewer/ImgViewer";

interface Props {
    training: ITraining
}

const TrainingHorizontalCard: React.FC<Props> = props => {
    const training = props.training;
    const provider = props.training.providedBy;
    return (
        <div className='training-horizontal-card'>
            <Row className='training-quick-card'>
                <Col xxl={{span:6}} lg={{span:8}} xl={{span: 8}} md={{span:8}} sm={{span:24}} xs={{span:24}} className='training-info training-img'>
                    <ImgViewer
                        src={S3_SERVER_URL + training.uploadedThumbnail}
                        onClick={() => {history.push(`/vertical/trainings/enroll/${training.id}`);}}
                        ratio={2}
                        width={'100%'}
                    />
                </Col>
                <Col xxl={{span: 16}} lg={{span: 13}} xl={{span:14}} md={{span:14}} sm={{span:21}} xs={{span:20}} className='training-info'>

                    <div style={{fontWeight:'600', fontSize:'18px', cursor:'pointer'}}
                         onClick={() => {history.push(`/vertical/trainings/enroll/${training.id}`);}}
                    >{training.trainingName}</div>
                    <div className='sub-desc' style={{padding:'2px'}}>
                                                        <div dangerouslySetInnerHTML={{__html: training.description.replace('\r\n','')}}/>
                                                    </div>
                    <div>{training.maxParticipants == 0 ? 'No limit ' : 'Total '}{training.maxParticipants}{training.maxParticipants == 1 ? ' seat' : ' seats'}</div>
                    <div className='meta-deadline-text'>
                        Deadline&nbsp;
                        {moment(training.deadline).format(DATE_FORMAT)}&nbsp;
                        <i className="icofont-ui-calendar"/>
                    </div>
                    <div>
                        {provider && (provider.userTypeId == 1 ? (provider.name + ' ' + provider.surname) : provider.name)}
                    </div>
                </Col>
                <Col xxl={{span:2}} lg={{span: 3}} xl={{span: 2}} md={{span:2}} sm={{span:3}} xs={{span:4}} className='training-info training-fee'>
                    {training.participationFee === 0 ? 'Free' : training.participationFee}
                </Col>
            </Row>
        </div>
    );
};

TrainingHorizontalCard.defaultProps = {
    training: {} as ITraining
}

export default TrainingHorizontalCard;
