import React, { useEffect, useState } from 'react';
import { Select, Spin } from 'antd';
import {ITag} from "../../interfaces/api";
import ApiService from "../../utils/services/api.service";
import {ACOMPLETE_DEBOUNCE_TIMEOUT} from "../../utils/Constants";


export interface IUserInterestAComplete {
    label: string,
    value: string,
    key: string,
    isNew?: boolean
}

interface AutocompleteProps {
    selectedTags?: (text: any) => void,
    endpoint?: string,
    disabled?: boolean,
    singleTag?: boolean,
    unknownTag?: boolean,
    onChange?: (text: any) => void,
    resultChild?: (text: any) => void,
    tags?: Array<IUserInterestAComplete>,
    clearData?: boolean,
    helperTags?: Array<IUserInterestAComplete>,
}

let debounce: NodeJS.Timeout;
const AutoCompleteInterests: React.FC<AutocompleteProps> = props => {

    const [children, setChildren] = useState<any>([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<Array<string>>([]);
    const [isRendered, setRendered] = useState<boolean>(false);

    const onSearch = async (searchText: string) => {
        clearTimeout(debounce);
        setChildren([]);
        setLoading(true);
        if (searchText.length < 1) {
            return;
        }

        debounce = setTimeout( async () => {
            let children: Array<IUserInterestAComplete> = [];
            let response = await ApiService.Tag.GetTagSearchWithType('1', searchText);
            let resultFromResponse: Array<ITag> = response.data;
            if (response.status !== 200){
                setLoading(false);
                return;
            }
            let filteredResult: Array<ITag> = resultFromResponse.filter(item => {
                if(props.helperTags.findIndex(x => x.value === item.id) == -1){
                    return item;
                }
            });
            children = filteredResult.map((item: ITag) => {
                return { label: item.tagName, value: item.id, key: item.id } as IUserInterestAComplete;
            });
            setLoading(false)

            if (props.unknownTag) {
                children.push({ label: searchText, value: searchText, key: searchText } as IUserInterestAComplete);
            }
            setChildren(children)
        }, ACOMPLETE_DEBOUNCE_TIMEOUT)
    };

    const onSelect = () => {
        setChildren([]);
    }

    const onChange = (objs: Array<any>) => {
        props.onChange(objs);
        if (objs == null)
            return;
        if (props.singleTag && objs.length > 1) {
            objs = objs.slice(1);
        }
        setData(objs);
    }

    useEffect(() => {
        setRendered(true);
    }, [])


    useEffect(() => {
        if (!isRendered) {
            props.selectedTags([]);
            //props.onChange([]);
            return;
        }
        props.selectedTags(data);
        //props.onChange(data);
    }, [data])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect( () => {
        setData(null);
    },[props.clearData])

    useEffect(() => {
        let t: Array<any> = [...children, ...props.tags];
        setData(t)
    }, [props.tags])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Select
            filterOption={false}
            labelInValue
            mode='multiple'
            notFoundContent={isLoading ? <Spin size="small" /> : null}
            style={{ width: '100%' }}
            onSearch={onSearch}
            onSelect={onSelect}
            onChange={onChange}
            options={children}
            disabled={props.disabled}
            aria-multiline={true}
            value={data}
        >
            {/*{children}*/}
        </Select>
    );
};

AutoCompleteInterests.defaultProps = {
    unknownTag: true,
    disabled: false,
    singleTag: false,
    onChange: () => {
    },
    selectedTags: () => {
    },
    resultChild: () => {
    },
    tags: [],
    clearData: false,
    helperTags: []
}

export default AutoCompleteInterests;