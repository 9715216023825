import React, {CSSProperties, useEffect, useState} from 'react';

import classNames from '../../../utils/classNames'

import './FooterAuth.scss';
import {Button, Col, Modal, Radio, Row} from "antd";
import {useTranslation} from "react-i18next";
import { NavLink } from 'react-router-dom';

interface FooterProps {
    loaded?: boolean;
    boxed?: boolean;
}

const FooterAuth: React.FunctionComponent<FooterProps> = props => {
    let footerClasses = classNames({
        'footer': true,
        'auth': true,
        'loaded': props.loaded,
        'boxed': props.boxed,
        //'full-filled': true
    });
    const [langVisible, setLangVisible] = useState<boolean>(false);
    const {i18n} = useTranslation();
    const [selectedLang, setSelectedLang] = useState();
    const radioBtnStyle: CSSProperties = {
        width: '100%'
    }

    //['Sign Up', 'Login', 'About', 'Privacy', 'Terms', 'Contact']
    const footerPages: Array<{title: string, route: string}> = [
        {
            title: 'Sign Up',
            route: 'sign-up'
        },
        {
            title: 'Login',
            route: 'sign-in'
        },
        {
            title: 'About',
            route: ''
        },
        {
            title: 'Privacy',
            route: ''
        },
        {
            title: 'Terms',
            route: ''
        },
        {
            title: 'Contact',
            route: ''
        }
    ]

    return (
        <div className={footerClasses}>
            <div className='footer-wrap'>
                <Row>
                    <Col span={24}>
                        <Row justify="center">
                            {
                                footerPages.map( (item,index) => {
                                    return(<Col key={index} xs={{span:12}} sm={{span:12}} md={{span:6}} lg={{span:2}}>
                                        <NavLink
                                            to={item.route.length > 0 && item.route}
                                            className='link'
                                            activeClassName='active'
                                            replace
                                        >
                                            {item.title}
                                        </NavLink>
                                    </Col>)
                                })
                            }
                        </Row>
                    </Col>
                </Row>
                <Row className='footer-logo-info' justify='center'>
                    <Col xs={{span: 24}} md={{span: 24}} style={{textAlign: 'center'}}>
                        <div className='info'>
                            &#169; 2022 RegenHive
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default FooterAuth;