import React, {useEffect} from 'react';
import {IUser, IWOApplication, IWorkOpportunity} from "../../interfaces/api";
import {Card, Tooltip} from "antd";
import {history} from "../../redux/store";
import blankImg from "../../assets/img/blank/blank-image.png";
import {DATE_FORMAT, S3_SERVER_URL} from "../../utils/Constants";
import moment from "moment";
import './WOCard.scss';
import {objIsEmpty} from "../../utils/GlobalFuncs";
import {CheckCircleTwoTone, CloseCircleTwoTone} from "@ant-design/icons";
import ImgViewer from '../ImgViewer/ImgViewer';

interface CardProps {
    workOpportunity: IWorkOpportunity,
    provider: IUser,
    isOwned?: boolean,
    applicant?: IWOApplication
}

const WOCard: React.FC<CardProps> = props => {
    const workOppor = props.workOpportunity;
    const provider = props.provider;

    useEffect(() => {
        console.dir(workOppor)
        console.dir(workOppor.description)
    },[]);

    return (
        <div className='wo-card'>
            <Card
                loading={objIsEmpty(workOppor)}
                hoverable
                onClick={() => {
                    history.push(`/vertical/work-opportunities/application/${workOppor.id}`);
                }}
                cover={
                    <>
                        <div className='img-item-wrapper'>
                            {workOppor.uploadedImage.length < 1 ?
                                <ImgViewer src={blankImg}  ratio={2}/>
                                :
                                <ImgViewer src={S3_SERVER_URL + workOppor.uploadedImage} ratio={2} width='100%'/>}
                            {props.isOwned &&
                                <>
                                    {
                                        props.applicant.isFinished ?
                                            <div className='complete-status'>
                                                {props.applicant.isSelected ?
                                                    // <CheckCircleFilled style={{color:'green', fontSize:'24px', backgroundColor: 'white'}}/>
                                                    <CheckCircleTwoTone twoToneColor='green'
                                                                        style={{fontSize: '32px'}}/>
                                                    :
                                                    <CloseCircleTwoTone twoToneColor='red' style={{fontSize: '32px'}}/>
                                                }
                                            </div>
                                            :
                                            <>
                                                <span className='complete-status continues-frame'>
                                                    <span>Continues</span>
                                                </span>
                                            </>
                                    }
                                </>
                            }
                        </div>

                    </>
                }
            >
                <Card.Meta
                    title={
                        <Tooltip title={workOppor.title}>
                            <div className='meta-title'>
                                {workOppor.title}
                            </div>

                        </Tooltip>
                    }
                    description={<div className='meta-desc'>
                        <div className='sub-desc' dangerouslySetInnerHTML={{__html: workOppor.description.replace('\r\n','')}}/>
                        {
                            provider &&
                            <div className='meta-provider-text'>
                                    {provider.userTypeId == 1 ? (provider.name + ' ' + provider.surname) : provider.name}
                            </div>
                        }
                        <div className='meta-deadline-text'>
                            Deadline&nbsp;
                            {moment(workOppor.deadline).format(DATE_FORMAT)}&nbsp;
                            <i className="icofont-ui-calendar"/>
                        </div>
                        {
                            props.isOwned && props.applicant.isFinished &&
                            <>
                                <div className='meta-deadline-text'>Skill1</div>
                                <div className='meta-deadline-text'>Skill2</div>
                            </>
                        }

                        <div className='fill'/>
                    </div>
                    }
                />
            </Card>
        </div>
    );
};

WOCard.defaultProps = {
    workOpportunity: {} as IWorkOpportunity,
    applicant: {} as IWOApplication,
    provider: {} as IUser,
    isOwned: false
}

export default WOCard;
