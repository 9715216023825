import React from 'react';
import {ISkill} from "../../interfaces/api";
import {Card, Tooltip} from "antd";
import blankImg from "../../assets/img/blank/blank-image.png";
import {S3_SERVER_URL} from "../../utils/Constants";
import './SkillCard.scss';
import {objIsEmpty} from "../../utils/GlobalFuncs";
import {history} from "../../redux/store";
import ImgViewer from '../ImgViewer/ImgViewer';

interface CardProps {
    skill: ISkill
}

const SkillCard: React.FC<CardProps> = props => {
    const skill = props.skill;
    return (
        <div className='skill-card'>
            <Card
                className='ant-card-invisible'
                loading={objIsEmpty(skill)}
                //hoverable
                onClick={() => {
                    history.push(`/vertical/skills/detail/${skill.id}`);
                }}
                cover={
                    <div className='img-item'>
                        {skill.uploadedSkillImage.length < 1 ?
                            <ImgViewer src={blankImg} ratio={1}/>
                            :
                            <ImgViewer src={S3_SERVER_URL + skill.uploadedSkillImage} ratio={1}/>}
                    </div>
                }
            >
                <Card.Meta
                    title={
                        <Tooltip title={skill.skillName}>
                            <div className='meta-title'>
                                {skill.skillName}
                            </div>
                        </Tooltip>
                    }
                />
            </Card>
        </div>
    );
};

SkillCard.defaultProps = {
    skill: {} as ISkill
}

export default SkillCard;
