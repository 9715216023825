import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../interfaces/page-data';
import {Button, Card, Col, DatePicker, Form, Input, InputNumber, Row, Switch, Upload} from "antd";
import {AppState, history} from "../../redux/store";
import {ITrainerTags, ITrainingCreate, IUser} from "../../interfaces/api";
import toast from "react-hot-toast";
import TextEditor from "../../components/TextEditor/TextEditorWithoutButton";
import {CheckOutlined, LeftOutlined, UploadOutlined} from "@ant-design/icons";
import AutoCompleteSkills, {ISkillAComplete} from "../../components/AutoCompleteSkills/AutoCompleteSkills";
import moment from "moment";
import {useSelector} from "react-redux";
import {ITrainingRequiredSkills, ITrainingProvidedSkills} from "../../interfaces/api";
import {
    DATETIME_FORMAT,
    MAX_APPLICATIONS_FOR_MODULES, ModuleColors,
    SERVER_DATETIME_FORMAT,
    TITLE_NAME_REGEX
} from "../../utils/Constants";
import ImgUploader from "../../components/ImgUploader/ImgUploader";
import GMForModule from "../../components/GoogleMaps/ForModule/GMForModule";
import AutoCompleteTags from "../../components/AutoCompleteTags/AutoCompleteTags";
import ApiService from "../../utils/services/api.service";
import AutoCompleteUsers, {IUserAComplete} from "../../components/AutoCompleteUsers/AutoCompleteUsers";


const NewTraining: React.FC<IPageProps> = props => {
    const [requiredSkills, setRequiredSkills] = useState<Array<ISkillAComplete>>([]);
    const [providedSkills, setProvidedSkills] = useState<Array<ISkillAComplete>>([]);
    const [isOnlineMeeting, setIsOnlineMeeting] = useState<boolean>(true);
    const [isOfflineMeeting, setIsOfflineMeeting] = useState<boolean>(true);
    const user: IUser = useSelector((state: AppState) => state).user;
    const {onSetPage} = props;
    const [form] = Form.useForm();
    const pageData: IPageData = {
        title: 'New Training',
        loaded: true
    };

    useEffect(() => {
        onSetPage(pageData);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    //region Date time picker methods
    const disableDatesStartFinish = (current) => {
        let deadline = moment(form.getFieldsValue(['deadline']).deadline).format(DATETIME_FORMAT);
        return moment(deadline, DATETIME_FORMAT).add(-1, "minutes").endOf('minutes') > current ||
            moment().add(6, "months").endOf('minutes') < current;
    }

    const disableDateDeadline = (current) => {
        return (
            moment().add(-1, "minutes") > current ||
            moment().add(6, "months").endOf('minutes') < current
        )
    };

    const deadLineChange = (date: any, dateString: string) => {
        form.setFieldsValue({
            'startFinishDate': null
        })
    }
    //endregion

    const onFinish = async (values) => {
        let training: ITrainingCreate = values as ITrainingCreate;
        console.dir(values);
        //region Date-time
        let startFinishDates = form.getFieldsValue(['startFinishDate']).startFinishDate;
        let startDate = moment(startFinishDates[0]).format(SERVER_DATETIME_FORMAT);
        let finishDate = moment(startFinishDates[1]).format(SERVER_DATETIME_FORMAT);
        training.deadline = moment(values.deadline).format(SERVER_DATETIME_FORMAT);
        training.startDate = startDate;
        training.finishDate = finishDate;
        //endregion

        //region Trainers
        // let trainers: Array<ITrainerTags> = values.trainers.map((item: IUserAComplete) => {
        //     return {trainerId: item.value} as ITrainerTags;
        // })
        //endregion

        //region Required and Provided skills
        let required: Array<ITrainingRequiredSkills> = requiredSkills
            .filter((item) => item.key != item.label)
            .map((item: ISkillAComplete) => {
                return {skillId: item.value} as ITrainingRequiredSkills
            });

        let provided: Array<ITrainingProvidedSkills> = providedSkills
            .filter((item) => item.key != item.label)
            .map((item: ISkillAComplete) => {
                return {skillId: item.value} as ITrainingProvidedSkills
            });

        training.strTrainingPrerequisites = JSON.stringify(required);
        training.strTrainingRewards = JSON.stringify(provided);
        //endregion

        //region Training Tags
        let trainingTags = values.trainingTags ? values.trainingTags : [];
        training.trainingTags = trainingTags.filter((item) => item.value !== item.label).map((item) => {
            return item.value
        });

        training.unknownTags = trainingTags.filter((item) => item.value === item.label).map((item) => {
            return item.label
        }).join(',');
        //endregion

        training.additionalImages = values.additionalImages ? values.additionalImages.fileList.map((file) => {
            return (file.originFileObj);
        }) : [];
        training.userId = user.id;
        training.providedBy = user.id;
        training.latitude = isOfflineMeeting ? values.address.lat : 0;
        training.longitude = isOfflineMeeting ? values.address.lng : 0;
        training.locationOnline = (training.locationOnline == undefined ? '' : training.locationOnline);
        training.locationOffline = (training.locationOffline == undefined ? '' : training.locationOffline);

        let response = await ApiService.Training.PostTraining(values as ITrainingCreate);
        if (response.status === 200)
            history.push('/vertical/trainings')
    }

    return (
        <div className='row'>
            <div className='col-12'>
                <Card className='card-head-none' title={<Row>
                    <Col span={24}>
                        <span className='card-title-page'>New Training</span>
                    </Col>
                </Row>}>
                    <Form
                        colon={false}
                        form={form}
                        labelCol={{sm: {span: 7}, lg: {span: 6}, md: {span: 6}}}
                        wrapperCol={{span: 12}}
                        onFinish={onFinish}
                        requiredMark={false}
                        initialValues={{'maxParticipants': 0, participationFee: 0}}
                    >
                        <Form.Item label='Name' name='trainingName'
                                   rules={[{required: true, message: 'Training name cannot be blank'},
                                       {
                                           required: true,
                                           pattern: TITLE_NAME_REGEX,
                                           message: 'Training name wrong format'
                                       }]}>
                            <Input size='small'/>
                        </Form.Item>
                        <Form.Item label='Trainers' name='trainers' rules={[{required: true, message: 'Trainer cannot be blank'}]}>
                            <AutoCompleteUsers
                                singleTag={false}/>
                        </Form.Item>
                        <Form.Item label='Thumbnail'
                                   name='thumbnail'
                                   rules={[{required: true, message: 'Thumbnail cannot be blank'}]}
                                   extra={<span
                                       style={{fontSize: '10px'}}>Image must be PNG and less than 5MB</span>}>
                            <ImgUploader
                                fileTypes={['image/png']}
                                aspectRatio={2}
                                imagesCount={1}
                                fileMaxSize={5120}
                            />
                        </Form.Item>
                        <Form.Item label='Description' name='description'
                                   rules={[{required: true, message: 'Description cannot be blank'}]}>
                            <TextEditor image={false}/>
                        </Form.Item>

                        <Form.Item label='Additional Images'
                                   name='additionalImages'
                                   validateTrigger={'onChange'}
                                   valuePropName={'file'}
                                   extra={<span
                                       style={{fontSize: '10px'}}>Files must be image(JPG, JPEG, PNG) and less than 5MB</span>}>
                            <Upload
                                accept='image'
                                maxCount={5}
                                multiple={true}
                                listType='picture'
                                beforeUpload={(file) => {
                                    const isPNG =
                                        file.type === 'image/png' ||
                                        file.type === 'image/jpeg' ||
                                        file.type === 'image/jpg';
                                    const isLt250KB = file.size / 1024 < 5120;
                                    if (!isPNG) {
                                        toast.error('File must be image(PNG,JPG,JPEG)!')
                                        return Upload.LIST_IGNORE;
                                    }
                                    if (!isLt250KB) {
                                        toast.error('Image must smaller than 5MB!')
                                        return Upload.LIST_IGNORE;
                                    }
                                    return false;
                                }}
                            >
                                <Button icon={<UploadOutlined/>} size='small'>Click to upload</Button>
                            </Upload>
                        </Form.Item>

                        <Form.Item label='Max Participants' name='maxParticipants'
                                   rules={[
                                       {required: true, message: 'Max participants cannot be blank'},
                                       {
                                           required: true,
                                           validator: (rule, value) => {
                                               let val: number = parseInt(value);
                                               if (val < 0 || val > MAX_APPLICATIONS_FOR_MODULES)
                                                   return Promise.reject(`Max applications should be 0-${MAX_APPLICATIONS_FOR_MODULES}`)
                                               return Promise.resolve();
                                           }
                                       }
                                   ]}
                                   extra={<span style={{fontSize: '10px'}}>Use 0 for unlimited participants</span>}
                        >
                            <Input size='small' type='number'/>
                        </Form.Item>
                        <Form.Item label='Online Meeting' style={{marginBottom: 0}}>
                            <Form.Item name='isOnlineMeeting' valuePropName="checked" initialValue={isOnlineMeeting}
                                       dependencies={['isOfflineMeeting']}
                                       style={form.getFieldValue('isOnlineMeeting') ? {
                                           display: 'inline-block',
                                           width: 'calc(15% - 12px)'
                                       } : {display: 'inline-block', width: '100%'}}
                                       rules={[
                                           {required: true, message: 'Online type cannot be blank'},
                                           ({getFieldValue}) => ({
                                               validator(_, value) {
                                                   if (getFieldValue('isOfflineMeeting') === true) {
                                                       return Promise.resolve();
                                                   }
                                                   else if (value === true) {
                                                       return Promise.resolve();
                                                   }
                                                   return Promise.reject(new Error('At least one location type must be true'));
                                               },
                                           })]}>
                                <Switch onChange={setIsOnlineMeeting}/>
                            </Form.Item>

                            {
                                isOnlineMeeting &&
                                <>
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            width: '24px',
                                            lineHeight: '32px',
                                            textAlign: 'center'
                                        }}/>
                                    <Form.Item name='locationOnline'
                                               style={{display: 'inline-block', width: 'calc(85% - 12px)'}}
                                               //rules={[{required: true, message: 'Location cannot be blank'}]}
                                    >
                                        <Input size='small' placeholder='Enter an online meeting link'/>
                                    </Form.Item>
                                </>
                            }
                        </Form.Item>

                        <Form.Item label='Physical Meeting' style={{marginBottom: 0}}>
                            <Form.Item name='isOfflineMeeting' valuePropName="checked" initialValue={isOfflineMeeting}
                                       dependencies={['isOnlineMeeting']}
                                       style={form.getFieldValue('isOfflineMeeting') ? {
                                           display: 'inline-block',
                                           width: 'calc(15% - 12px)'
                                       } : {display: 'inline-block', width: '100%'}}
                                       rules={[{required: true, message: 'Offline type cannot be blank'},
                                           ({getFieldValue}) => ({
                                               validator(_, value) {
                                                   if (getFieldValue('isOnlineMeeting') === true) {
                                                       return Promise.resolve();
                                                   }
                                                   else if (value === true) {
                                                       return Promise.resolve();
                                                   }
                                                   return Promise.reject(new Error('At least one location type must be true'));
                                               },
                                           })]}>
                                <Switch onChange={setIsOfflineMeeting}/>
                            </Form.Item>
                            {
                                isOfflineMeeting &&
                                <>
                                <span
                                    style={{
                                        display: 'inline-block',
                                        width: '24px',
                                        lineHeight: '32px',
                                        textAlign: 'center'
                                    }}/>
                                    <Form.Item name='locationOffline'
                                               style={{display: 'inline-block', width: 'calc(85% - 12px)'}}
                                               rules={[{required: true, message: 'Location cannot be blank'}]}
                                    >
                                        <Input size='small' placeholder='Enter a physical meeting location'/>
                                    </Form.Item>

                                </>
                            }
                        </Form.Item>
                        {
                            isOfflineMeeting &&
                            <Form.Item label={'Address'} name='address' validateTrigger={'onChange'}>
                                <GMForModule
                                    haveSearchBox
                                    markerColorByModule={ModuleColors.Trainings}
                                />
                            </Form.Item>
                        }
                        <Form.Item label='Participation Fee' name='participationFee'
                                   rules={[{required: true, message: 'Price cannot be blank'}]}
                                   extra={<span style={{fontSize: '10px'}}>Use 0 for free enroll</span>}
                        >
                            <Input type='number' size='small'/>
                        </Form.Item>
                        <Form.Item label='Open Until' name='deadline'
                                   rules={[{required: true, message: 'Deadline date cannot be blank'}]}>
                            <DatePicker format={DATETIME_FORMAT} placeholder='' size='small'
                                        style={{width: '100%'}}
                                        disabledDate={disableDateDeadline}
                                        showTime={{format: 'HH:mm'}}
                                        onChange={deadLineChange}
                            />
                        </Form.Item>
                        <Form.Item label='Start-Finish' name='startFinishDate'
                                   rules={[{required: true, message: 'Start and finish date cannot be blank'}]}>
                            <DatePicker.RangePicker
                                format={DATETIME_FORMAT} size='small'
                                placeholder={['', '']}
                                disabledDate={disableDatesStartFinish}
                                style={{width: '100%'}}
                                showTime={{format: 'HH:mm'}}
                            />
                        </Form.Item>

                        <Form.Item label='Tags' name='trainingTags' validateTrigger={'selectedTags'}>
                            <AutoCompleteTags
                                singleTag={false}
                                unknownTag={true}
                                endpoint={'/Tag/SearchWithType/1/'}/>
                        </Form.Item>

                        <Form.Item label='Required Skills' name='trainingRequiredSkills'
                                   validateTrigger={'onChange'}
                            //rules={[{required: true, message: 'Prerequisites cannot be blank'}]}
                        >
                            <AutoCompleteSkills
                                tags={requiredSkills}
                                singleTag={false}
                                unknownTag={false}
                                disabled
                                //clearData={clearAcSelection}
                            />
                        </Form.Item>
                        <Form.Item
                            label='Provided Skills'
                            name='trainingProvidedSkills'
                            validateTrigger={'onChange'}
                        >
                            <AutoCompleteSkills
                                helperTags={requiredSkills}
                                permissionEndpoint={'/UserSkill/TrainerPermission'}
                                providerId={user.id}
                                controlPrereq={true}
                                selectedTags={(values: ISkillAComplete[], prereqValues: ISkillAComplete[]) => {
                                    if (values.length < 1) {
                                        setRequiredSkills([]);
                                        setProvidedSkills([]);
                                        return;
                                    }
                                    setRequiredSkills((currValues) => {
                                        return prereqValues;
                                    })
                                    setProvidedSkills(values);
                                    console.dir(prereqValues);
                                }}
                                singleTag={false}
                                unknownTag={false}
                            />
                        </Form.Item>
                        <Form.Item wrapperCol={{
                            lg: {span: 12, offset: 6},
                            md: {span: 12, offset: 6},
                            xs: {offset: 0},
                            sm: {span: 12, offset: 7}
                        }}>
                            <div className='d-flex justify-content-between'>
                                <Button className='responsive-button' onClick={() => {
                                    history.push('/vertical/trainings')
                                }} danger icon={<LeftOutlined/>}>Cancel</Button>

                                <Button className='responsive-button' type='primary' htmlType='submit'
                                        icon={<CheckOutlined/>}>Submit</Button>
                            </div>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </div>
    )
};

export default NewTraining;
