import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../interfaces/page-data';
import {Button, Col, Row,  Card, Divider} from "antd";
import {IEventListByPage, ISkill, ITrainingListByPage, IUser} from "../../interfaces/api";
import {useParams} from "react-router-dom";
import {S3_SERVER_URL} from "../../utils/Constants";
import './DetailSkill.scss';
import {isGuid} from "../../utils/GlobalFuncs";
import SkillCard from "../../components/SkillCard/SkillCard";
import TrainingHorizontalCard from "../../components/TrainingCard/TraniningHorizontalCard";
import EventHorizontalCard from "../../components/EventCard/EventHorizontalCard";
import ImgViewer from "../../components/ImgViewer/ImgViewer";
import ApiService from "../../utils/services/api.service";

let loadMoreTrainingPageNo: number = 1;
let loadMoreEventPageNo: number = 1;
const DetailSkill: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'Skill',
        loaded: true,
    };
    const [isLoaded, setLoaded] = useState<boolean>(false);
    const [skill, setSkill] = useState<ISkill>({} as ISkill);
    // const [provider, setProvider] = useState<IUser>({} as IUser);
    const [prereqs, setPrereqs] = useState<ISkill[]>([]);
    const [trainings, setTrainings] = useState<ITrainingListByPage[]>([]);
    const [events, setEvents] = useState<IEventListByPage[]>([]);
    const skillId = useParams<{ id?: string }>().id;

    useEffect(() => {
        onSetPage(pageData);
        if (!isGuid(skillId))
            return;

        const any = async () => {
            let skillResponse = await ApiService.Skill.GetSkillById(skillId);
            setSkill(skillResponse.data);

            let prereqResponse = await ApiService.Skill.GetSkillPrerequisitesById(skillId);
            setPrereqs(prereqResponse.data);

            await getTrainings(skillId);

            await getEvents(skillId);

            setLoaded(true);
        }
        any().then(() => {
        });
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const getTrainings = async (skillId: string) => {
        let trainingResponse = await ApiService.Training.GetTrainingListByPage({
            numOfRecords: 1,
            page: loadMoreTrainingPageNo,
            skillId: skillId
        });
        let tPageNo = loadMoreTrainingPageNo + 1;
        let nextTrainingResponse = await ApiService.Training.GetTrainingListByPage({
            numOfRecords: 1,
            page: tPageNo,
            skillId: skillId
        });
        let t: ITrainingListByPage[] = trainingResponse.data;
        if (nextTrainingResponse.data.length < 1) {
            let loadMoreWrapper: any = document.getElementById('load-more-trainings');
            if(!loadMoreWrapper)
                return;
            loadMoreWrapper.style.display = 'none';
        }
        setTrainings([...trainings, ...t])
    }

    const getEvents = async (skillId: string) => {
        let eventResponse = await ApiService.Event.GetEventListByPage({
            numOfRecords: 1,
            page: loadMoreEventPageNo,
            skillId: skillId
        });
        let tPageNo = loadMoreEventPageNo + 1;
        let nextEventResponse = await ApiService.Event.GetEventListByPage({
            numOfRecords: 1,
            page: tPageNo,
            skillId: skillId
        });
        let t: IEventListByPage[] = eventResponse.data;
        if (nextEventResponse.data.length < 1) {
            let loadMoreWrapper: any = document.getElementById('load-more-events');
            if(!loadMoreWrapper)
                return;
            loadMoreWrapper.style.display = 'none';
        }
        setEvents([...events, ...t])
    }

    const loadMoreTraining = () => {
        loadMoreTrainingPageNo = loadMoreTrainingPageNo + 1;
        getTrainings(skill.id);
    }

    const loadMoreEvent = () => {
        loadMoreEventPageNo = loadMoreEventPageNo + 1;
        getEvents(skill.id);
    }

    return (
        <div className='detail-skill-page'>
            <Card
                className='ant-card-invisible'
                // bodyStyle={{padding:'20px'}}
                loading={!isLoaded}>
                {/*Training Details*/}
                <Row>
                    <Col className='cell-gap-right' md={{order: 2, span: 12}}
                         lg={{order: 2, span: 12}} xs={{order: 2, span: 24}}>
                        {/*Training name, desc, time left....*/}
                        <h4>{skill.skillName}</h4>
                        <br/>
                        <br/>
                        <div style={{padding: '10px'}} className='desc-tag'>
                            <div dangerouslySetInnerHTML={{__html: skill && skill.description}}/>
                        </div>
                        <br/>
                    </Col>
                    {/*skill image, price, space left, location*/}
                    <Col className='cell-gap-left' md={{order: 2, span: 12}}
                         lg={{order: 2, span: 12}} xs={{order: 1, span: 24}}>
                        <Row className='skill-info-border'>
                            <Col span={24}>
                                <div style={{textAlign: 'center'}}>
                                    <ImgViewer src={S3_SERVER_URL + skill.uploadedSkillImage} width={'180px'}/>
                                </div>
                            </Col>
                            {/*</div>*/}
                        </Row>
                    </Col>
                </Row>

                {/*Skill Prereqs*/}
                {
                    prereqs.length > 0 &&
                    <Row className='cell-gap' justify='center'>
                        <Col span={24}>
                            <h5>Required Skills</h5>
                        </Col>
                        {
                            prereqs.map((item) => {
                                //const provider:IUser = item.providedBy;
                                return (
                                    <>
                                        {/*<Col key={item.id + 1} lg={{span: 2.5}} md={{span: 2}}*/}
                                        {/*     sm={{span: 2}}*/}
                                        {/*     xs={{span: 8}}>*/}
                                        {/*</Col>*/}
                                        {/*<Col key={item.id} lg={{span: 3}} md={{span: 3}} sm={{span: 4}}*/}
                                        {/*     xs={{span: 6}}>*/}
                                        {/*    <SkillCard key={item.id} skill={item}/>*/}
                                        {/*</Col>*/}
                                        {/*<Col key={item.id + 2} lg={{span: 2.5}} md={{span: 2}}*/}
                                        {/*     sm={{span: 2}}*/}
                                        {/*     xs={{span: 8}}>*/}
                                        {/*</Col>*/}
                                        <Col key={item.id + 1}
                                             xl={{span: 2.5}}
                                             lg={{span: 2}}
                                             md={{span: 2}}
                                             sm={{span: 2}}
                                             xs={{span: 6}}>
                                        </Col>
                                        <Col
                                            key={item.id}
                                            xl={{span: 3}}
                                            lg={{span: 4}}
                                            md={{span: 3}}
                                            sm={{span: 8}}
                                            xs={{span: 12}}>
                                            <SkillCard key={item.id} skill={item}/>
                                        </Col>
                                        <Col key={item.id + 2}
                                             xl={{span: 2.5}}
                                             lg={{span: 2}}
                                             md={{span: 2}}
                                             sm={{span: 2}}
                                             xs={{span: 6}}>
                                        </Col>
                                    </>
                                )
                            })
                        }
                    </Row>
                }

                {/*Trainings that offer this Skill*/}
                {
                    trainings.length > 0 &&
                    <Row className='cell-gap' justify='space-around'>
                        <Col span={24}>
                            <h5>Trainings that offer this skill</h5>
                        </Col>
                        {
                            trainings.map((item) => {
                                return (
                                    <>
                                        <Divider/>
                                        <Col key={item.id} span={24}>
                                            <TrainingHorizontalCard key={item.id} training={item.training}/>
                                        </Col>
                                    </>
                                );
                            })
                        }
                        <Col id='load-more-trainings' span={24} style={{textAlign: 'center'}}>
                            <Button onClick={loadMoreTraining} type='primary'>Load More Training</Button>
                        </Col>
                    </Row>
                }


                {
                    events.length > 0 &&
                    <Row className='cell-gap' justify='space-around'>
                        <Col span={24}>
                            <h5>Events that offer this skill</h5>
                        </Col>
                        {
                            events.map((item) => {
                                return (
                                    <>
                                        <Divider/>
                                        <Col key={item.id} span={24}>
                                            <EventHorizontalCard key={item.id} event={item.event}/>
                                        </Col>
                                    </>
                                );
                            })
                        }
                        <Col id='load-more-events' span={24} style={{textAlign: 'center'}}>
                            <Button onClick={loadMoreEvent} type='primary'>Load More</Button>
                        </Col>
                    </Row>
                }
            </Card>
        </div>
    )
};

export default DetailSkill;
