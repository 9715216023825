import React, {useEffect, useState} from "react";
import {IPageData, IPageProps} from '../../interfaces/page-data';
import {Button, Card, Col, DatePicker, Form, Image, Input, Row, Space, Switch} from "antd";
import {AppState, history} from "../../redux/store";
import {IEvent, IEventApprove, IEventImage, IEventTag, IUser} from "../../interfaces/api";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import AutoCompleteSkills, {ISkillAComplete} from "../../components/AutoCompleteSkills/AutoCompleteSkills";
import {CheckOutlined, CloseOutlined, LeftOutlined} from "@ant-design/icons";
import {DATETIME_FORMAT, ModuleColors, S3_SERVER_URL} from "../../utils/Constants";
import moment from "moment";
import GMForModule from "../../components/GoogleMaps/ForModule/GMForModule";
import AutoCompleteTags, {ITagAComplete} from "../../components/AutoCompleteTags/AutoCompleteTags";
import ApiService from "../../utils/services/api.service";

const ApproveEvent: React.FC<IPageProps> = props => {
    const {onSetPage} = props;
    const pageData: IPageData = {
        title: 'Approve Event',
        loaded: true
    };
    const [desc, setDesc] = useState<string>('');
    const user: IUser = useSelector((state: AppState) => state).user;
    const eventId = useParams<{ id?: string }>().id;
    const [event, setEvent] = useState<IEvent>(null);
    const [additionalImages, setAdditionalImages] = useState<Array<IEventImage>>([]);
    const [requiredSkills, setRequiredSkills] = useState<Array<ISkillAComplete>>();
    const [rewardSkills, setRewardSkills] = useState<Array<ISkillAComplete>>();
    const [eventTags, setEventTags] = useState<ITagAComplete[]>([]);


    useEffect(() => {
        onSetPage(pageData);
        any().then(null);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const any = async () => {
        let eventResponse = await ApiService.Event.GetEventById(eventId);
        setEvent(eventResponse.data);

        let eventTagResponse = await ApiService.EventTag.GetEventTagByEvent(eventId);
        let t1 = eventTagResponse.data.length > 0 ? eventTagResponse.data.map((item: IEventTag) => {
            return {value: item.tag.id, label: item.tag.tagName } as ITagAComplete
        }) : [];
        setEventTags(t1);

        let reqSkillsResponse = await ApiService.EventRequiredSkill.GetEventRequiredSkillByEvent(eventId);
        setRequiredSkills(reqSkillsResponse.data);

        let rewardSkillsResponse = await ApiService.EventReward.GetEventRewardByEvent(eventId)
        setRewardSkills(rewardSkillsResponse.data)

        let imagesResponse = await ApiService.EventImage.GetEventImageByEvent(eventId);
        setAdditionalImages(imagesResponse.data);
    }

    const onOk = async (isOk) => {
        // isOk => false unapprove
        // isOk => true approve
        let data = {
            eventId: eventId,
            approvedBy: user.id,
            reason: desc
        } as IEventApprove;
        let response = isOk ? await ApiService.Event.PostEventApprove(data) : await ApiService.Event.PostEventReject(data);
        if (response.status === 200){
            history.push('/vertical/events')
        }
    }

    return (
        <div className='row'>
            <div className='col-12'>
                <Card className='card-head-none' loading={event == null} title={<Row>
                    <Col span={24}>
                        <span className='card-title-page'>Approve Event</span>
                    </Col>
                </Row>}>
                    <Form
                        labelCol={{sm: {span: 7}, lg: {span: 6}, md: {span: 6}}}
                        wrapperCol={{span: 12}}
                        requiredMark={false}
                    >
                        <Form.Item label='Name' name='eventName' initialValue={event && event.eventName}>
                            <Input size='small' disabled/>
                        </Form.Item>
                        <Form.Item label='Image' name='uploadedThumbnail' valuePropName={'src'}
                                   initialValue={event && S3_SERVER_URL + event.uploadedThumbnail}>
                            <Image preview={true} width={200}/>
                        </Form.Item>
                        <Form.Item label='Description' name='description'
                                   initialValue={event && event.description}>
                            <div style={{padding: 20}}>
                                <div dangerouslySetInnerHTML={{__html: event && event.description}}/>
                            </div>
                        </Form.Item>

                        <Form.Item label='Additional Images' name='additionalImages'
                                   initialValue={additionalImages}
                            //extra={<span style={{fontSize: '10px'}}>Open preview so if you can check other files too</span>}
                        >
                            <Image.PreviewGroup>
                                {
                                    additionalImages.map((item) => {
                                        return (<div style={{padding: '2px'}}><Image width={200}
                                                                                     src={S3_SERVER_URL + item.imageURI}
                                                                                     key={item.id}/></div>)
                                    })
                                }
                            </Image.PreviewGroup>
                        </Form.Item>

                        <Form.Item label='Max Participants' name='maxParticipants'
                                   initialValue={event && event.maxParticipants}>
                            <Input size='small' type='number' disabled/>
                        </Form.Item>
                        <Form.Item label='Online Meeting' style={{marginBottom: 0}}>
                            <Form.Item name='isOnlineMeeting' valuePropName="checked"
                                       style={{display: 'inline-block', width: 'calc(15% - 12px)'}}
                                       initialValue={event && event.isOnlineMeeting}
                                       rules={[{required: true, message: 'Location type cannot be blank'}]}>
                                <Switch disabled/>
                            </Form.Item>
                            <span
                                style={{
                                    display: 'inline-block',
                                    width: '24px',
                                    lineHeight: '32px',
                                    textAlign: 'center'
                                }}/>
                            {
                                event && event.isOnlineMeeting &&
                                <>
                                    <Form.Item name='locationOnline'
                                               style={{display: 'inline-block', width: 'calc(85% - 12px)'}}
                                               initialValue={event && event.locationOnline}>
                                        <Input size='small' disabled/>
                                    </Form.Item>
                                </>
                            }
                        </Form.Item>
                        <Form.Item label='Physical Meeting' style={{marginBottom: 0}}>
                            <Form.Item name='isOfflineMeeting' valuePropName="checked"
                                       style={{display: 'inline-block', width: 'calc(15% - 12px)'}}
                                       initialValue={event && event.isOfflineMeeting}
                                       rules={[{required: true, message: 'Location type cannot be blank'}]}>
                                <Switch disabled/>
                            </Form.Item>
                            <span
                                style={{
                                    display: 'inline-block',
                                    width: '24px',
                                    lineHeight: '32px',
                                    textAlign: 'center'
                                }}/>
                            {
                                event && event.isOfflineMeeting &&
                                <Form.Item name='locationOffline'
                                           style={{display: 'inline-block', width: 'calc(85% - 12px)'}}
                                           initialValue={event && event.locationOffline}>
                                    <Input size='small' disabled/>
                                </Form.Item>

                            }
                        </Form.Item>
                        {
                            event && event.isOfflineMeeting &&
                            <>

                                <Form.Item label={'Address'} valuePropName={'value'}>
                                    <GMForModule
                                        disabled
                                        scrollWheel={false}
                                        haveSearchBox={false}
                                        defaultValue={{lat: event.latitude, lng: event.longitude}}
                                        markerColorByModule={ModuleColors.Events}
                                    />
                                </Form.Item>
                            </>
                        }

                        <Form.Item label='Participation Fee' name='participationFee'
                                   initialValue={event && event.participationFee}>
                            <Input type='number' size='small' disabled/>
                        </Form.Item>
                        <Form.Item label='Open Until'
                                   name='deadline' initialValue={event && moment(event.deadline)}
                        >
                            <DatePicker
                                format={DATETIME_FORMAT}
                                size='small'
                                style={{width: '100%'}}
                                disabled
                            />
                        </Form.Item>
                        <Form.Item label='Start-Finish' name='startFinishDate'
                                   initialValue={event && [moment(event.startDate), moment(event.finishDate)]}>
                            <DatePicker.RangePicker format={DATETIME_FORMAT} size='small'
                                                    style={{width: '100%'}} placeholder={['', '']} disabled/>
                        </Form.Item>

                        <Form.Item label='Tags' name='eventTags' validateTrigger={'selectedTags'}>
                            <AutoCompleteTags
                                tags={eventTags}
                                singleTag={false}
                                unknownTag={true}
                                disabled
                                endpoint={'/Tag/SearchWithType/1/'}/>
                        </Form.Item>

                        <Form.Item label='Required Skills' name='eventRequiredSkills'
                                   validateTrigger={'onChange'}
                        >
                            <AutoCompleteSkills
                                tags={requiredSkills}
                                singleTag={false}
                                unknownTag={false}
                                disabled
                            />
                        </Form.Item>
                        <Form.Item label='Provided Skills' name='eventProvidedSkills' validateTrigger={'onChange'}
                            //rules={[{required: true, message: 'Rewards cannot be blank'}]}
                        >
                            <AutoCompleteSkills
                                tags={rewardSkills}
                                singleTag={false}
                                unknownTag={false}
                                disabled
                            />
                        </Form.Item>

                        <Form.Item label='Reason' name='reason' initialValue={event && event.reason}>
                            <Input.TextArea
                                //rows={2}
                                autoSize={{minRows: 2, maxRows: 2}}
                                onChange={(e) => {
                                    setDesc(e.target.value)
                                }}
                            />
                        </Form.Item>

                        <Form.Item wrapperCol={{
                            lg: {span: 12, offset: 6},
                            md: {span: 12, offset: 6},
                            xs: {offset: 0},
                            sm: {span: 12, offset: 7}
                        }}>
                            <div className='d-flex justify-content-between'>
                                <Button className='responsive-button' onClick={() => {
                                    history.push('/vertical/events')
                                }} danger icon={<LeftOutlined/>}>Cancel</Button>

                                <Space>
                                    <Button className='responsive-button' type='primary' htmlType='submit' danger
                                            onClick={() => onOk(false)} icon={<CloseOutlined/>}>Decline</Button>
                                    <Button className='responsive-button' type='primary' htmlType='submit'
                                            onClick={() => onOk(true)} icon={<CheckOutlined/>}>Approve</Button>
                                </Space>

                            </div>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </div>
    )
};

export default ApproveEvent;
