import React, {useEffect, useState} from 'react';
import {Button, Col, Row, Tabs, Tag} from 'antd';
import {IPageData, IPageProps} from '../../../interfaces/page-data';
import User from '../../../layouts/components/User/User';
import className from '../../../utils/classNames';
import {history} from "../../../redux/store";
import {useParams} from "react-router-dom";
import toast from "react-hot-toast";
import {
    IFollowers,
    IFollowing,
    IFollowUser,
    IUser,
    IUserFollow, IUserFollowDetail, IUserFollowDetailResponse,
    IUserFollowerFollowingCount
} from "../../../interfaces/api";
import {LeftOutlined} from "@ant-design/icons";
import Spinner from '../../../utils/Spinner';
import ApiService from "../../../utils/services/api.service";

const PageConnections: React.FC<IPageProps> = props => {
    const [followerList, setFollowerList] = useState<Array<IUserFollow>>([]);
    const [followingList, setFollowingList] = useState<Array<IUserFollow>>([]);
    const [userFCount, setUserFCount] = useState<IUserFollowerFollowingCount>({} as IUserFollowerFollowingCount); // URL icerisinde yer alan user'in detaylari
    const [isAlreadyFollowedList , setIsAlreadyFollowedList] = useState<IUserFollowDetailResponse[]>([]);
    const [isLoad, setLoad] = useState<boolean>(false);
    const pageId = useParams<{ id?: string }>().id;
    const [followersQuery, setFollowersQuery] = useState<{ numOfRecords: number, page: number }>({
        numOfRecords: 1,
        page: 1
    });
    const [followingQuery, setFollowingQuery] = useState<{ numOfRecords: number, page: number }>({
        numOfRecords: 1,
        page: 1
    });

    const userClasses = i =>
        className({
            'mb-xl-0': 4 > followerList.length - i,
            'mb-lg-0': 3 > followerList.length - i
        });
    const pageData: IPageData = {
        title: 'Connections',
        loaded: true
    };
    const {onSetPage} = props;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        onSetPage(pageData);
        const any = async () => {
            //await getUser();
            await getUserFollowCount(pageId);
            await followingFollowerList('followers', {userId: pageId} as IFollowers);
            //await followRequest('following', {userId: pageId} as IFollowing);
        }
        any().then(null);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const loadMore = (type: string) => {
        if (type === 'Followers') {
            let t = followersQuery;
            t.page = t.page + 1;
            setFollowersQuery(t);
        }
        else {
            let t = followingQuery;
            t.page = t.page + 1;
            setFollowingQuery(t);
        }
        followingFollowerList(type, {userId: pageId} as IFollowers);
    }

    const callbackUser = async (fUser: IUser, isFollow: boolean, type: string) => {
        //isFollow = false unFollow req
        //isFollow = false Follow req
        let follow: IFollowUser = {followerId: pageId, followedUserId: fUser.id} as IFollowUser;
        let unfollow: IFollowUser = {followerId: pageId, followedUserId: fUser.id} as IFollowUser;
        let response = isFollow ? await ApiService.User.PostUserFollow(follow) : await ApiService.User.PostUserUnfollow(unfollow);
        if (response.status === 200) {
            isFollow ?
                await followingFollowerList('followers', {userId: pageId} as IFollowers, true) :
                await followingFollowerList('following', {userId: pageId} as IFollowing, true) ;

            await getUserFollowCount(pageId);
        }
    }

    const getUserFollowCount = async (userId: string) => {
        let response = await ApiService.User.PostUserFollowerFollowingCount({userId});
        setUserFCount(response.data ? response.data : null);
    }

    const followingFollowerList = async (type: string, obj: IFollowers | IFollowing, isCleared: boolean = false) => {
        setLoad(false);
        let response = type ?
            await ApiService.User.PostUserFollowers(obj, followersQuery) :
            await ApiService.User.PostUserFollowing(obj, followingQuery);
        // TODO - Loadmore method business will add.
        let nextResponse = type ?
            await ApiService.User.PostUserFollowers(obj, {page: followersQuery.page+1, numOfRecords: followersQuery.numOfRecords}) :
            await ApiService.User.PostUserFollowing(obj, {page: followingQuery.page+1, numOfRecords: followingQuery.numOfRecords});
        let data: IUserFollow[] = response.data;
        if (data.length < 1) {
            if (type.toLowerCase() === 'followers') {
                console.log('blank followers');
                setFollowerList([])
            }
            else if (type.toLowerCase() === 'following') {
                console.log('blank following');
                setFollowingList([])
            }
            setLoad(true);
            return;
        }
        console.dir(data);
        let followDetails : IUserFollowDetail = { userId: obj.userId, usersToCheck: []} as IUserFollowDetail

        if (type.toLowerCase() === 'followers') {

            if (isCleared){
                setFollowerList(data);
            }else {
                setFollowerList([...followerList, ...data])
            }
            data.map(item => {
                followDetails.usersToCheck.push(item.followerId)
            })
        }
        else if (type.toLowerCase() === 'following') {
            console.dir(data);
            if (isCleared){
                setFollowingList(data);
            }else {
                setFollowingList([...followerList, ...data])
            }
            data.map(item => {
                followDetails.usersToCheck.push(item.userId)
            })
        }

        let getFollowResponse = await ApiService.User.PostUserGetFollowDetails(obj.userId, followDetails);
        if (getFollowResponse.status !== 200){
            return;
        }
        setIsAlreadyFollowedList(getFollowResponse.data);

        let t = [...followingList, ...data];
        console.dir(t[0].user)
        console.dir(t[0].follower)

        setLoad(true);
        let userResponse = type ?
            await ApiService.User.PostUserFollowers(obj, followersQuery) :
            await ApiService.User.PostUserFollowing(obj, followingQuery);
        if (userResponse.status !== 200){
            setLoad(false);
            return;
        }
        console.dir(userResponse.data)
        // TODO - Check this fucntion work flow
        const res: IUserFollow[] = userResponse.data;
        console.dir(res);
        console.log(type.toLowerCase());
    }

    const tabOnChange = async (key: string) => {

        if (key.toLowerCase() === 'followers') {
            //setFollowers([]);
            let t = followersQuery;
            t.page = 1;
            setFollowersQuery(t);
            console.log(followerList)
            await followingFollowerList('followers', {userId: pageId} as IFollowers, true);
        }
        else if (key.toLowerCase() === 'following') {
            //setFollowing([])
            let t = followingQuery;
            t.page = 1;
            setFollowingQuery(t);
            console.log(followingList)
            await followingFollowerList('following', {userId: pageId} as IFollowing, true);
        }
    }

    return (
        <>
            <>
                <div className='row'>
                    <div className='col-12'>
                        <Tabs defaultActiveKey="Followers"
                              style={{padding: 2}}
                              onChange={tabOnChange}
                              tabBarExtraContent={
                                  <Button className='responsive-button'
                                          icon={<LeftOutlined/>} onClick={() => {
                                      history.go(-1)
                                  }}>Back</Button>}
                        >
                            <Tabs.TabPane tab={<span>Followers <Tag color='green'>{userFCount.followerCount}</Tag></span>}
                                          key="Followers">
                                {
                                    isLoad ?
                                        <>
                                            <Row gutter={[40, 24]}>
                                                {followerList.length > 0 && followerList.map((user, i) => (
                                                    <Col xxl={{span: 6}} xl={{span: 6}} lg={{span: 8}}
                                                         md={{span: 8}}
                                                         sm={{span: 12}} xs={{span: 24}} key={i}>
                                                        <User className={userClasses(i)}
                                                              isFollowed={isAlreadyFollowedList.find(x => x.userId).isAlreadyFollowed}
                                                              user={user.follower}
                                                              callback={(user, b) => {
                                                                  callbackUser(user, b, 'followers');
                                                              }}/>
                                                    </Col>
                                                ))}
                                            </Row>
                                            {
                                                followerList.length > 0 &&
                                                <Row>
                                                    <Col className='load-more' span={24} style={{textAlign: 'center', padding:'20px 0'}}>
                                                        <Button onClick={() => {
                                                            loadMore('Followers')
                                                        }} type='primary'>Load More</Button>
                                                    </Col>
                                                </Row>
                                            }
                                        </>
                                        :
                                        <Spinner topElementClass='.ant-tabs'/>
                                }
                            </Tabs.TabPane>

                            <Tabs.TabPane tab={<span>Following <Tag
                                color='green'>{userFCount.followingCount}</Tag></span>}
                                          key="Following">
                                {
                                    isLoad ?
                                        <>
                                            <Row gutter={[16, 16]}>
                                                {followingList.length > 0 && followingList.map((user, i) => (
                                                    <Col xxl={{span: 6}} xl={{span: 6}} lg={{span: 8}}
                                                         md={{span: 8}}
                                                         sm={{span: 12}} xs={{span: 24}} key={i}>
                                                        <User className={userClasses(i)}
                                                              isFollowed={isAlreadyFollowedList.find(x => x.userId).isAlreadyFollowed}
                                                              user={user.user}
                                                              callback={(user, b) => callbackUser(user, b, 'following')}/>
                                                    </Col>
                                                ))}
                                            </Row>
                                            {
                                                followingList.length > 0 &&
                                                <Row>
                                                    <Col className='load-more' span={24} style={{textAlign: 'center',  padding:'20px 0'}}>
                                                        <Button onClick={() => {
                                                            loadMore('Following')
                                                        }} type='primary'>Load More</Button>
                                                    </Col>
                                                </Row>
                                            }

                                        </>
                                        :
                                        <Spinner topElementClass='.ant-tabs'/>
                                }
                            </Tabs.TabPane>

                            {/*<Tabs.TabPane tab={<span>Request<Tag/></span>} key="3">*/}
                            {/*  Content of Tab Pane 3*/}
                            {/*</Tabs.TabPane>*/}
                        </Tabs>
                    </div>
                </div>
            </>
        </>
    );
};

export default PageConnections;
